import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
	Box,
	DialogActions,
	ThemeProvider,
	Typography,
	useTheme,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import {
	useActions,
	useCreatorActions,
	useLogActions,
} from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
	decryptContacts,
	getApplicableRules,
	isValidImageType,
	stringIsValidEmail,
	stringIsValidName,
} from '../../../utils/helpers/helperFuncs'
import { PageWrapper } from '../../components/reusableComponents'
import { TabContainer } from '../../components/TabComponent'
import { SubmitButton, UnborderedButton } from '../../components/Buttons'
import CreatorInfoComponent from './CreatorInfoComponent'
import { CreatorFinanceInfo } from './CreatorFinanceComponent'
import CreatorCapabilities from './CreatorCapabilities'
import EditPageHeader from '../../components/EditPageHeader'
import CustomModal from '../../components/CustomModal'
import CompanyInformationHeader from '../../components/CompanyInformationHeader'
import CustomDialog from '../../components/CustomDialog'
import defaultIcon from '../../../assets/default-icon.webp'
import { ACCEPTED_FILES } from '../../../config'
import CustomFileUploader from '../../components/CustomFileUploader'
import { CheckCircle } from '@mui/icons-material'
import { editPageTopPanelStyle } from '../../../utils/helpers/commonStyles'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { ContactInterface } from '../../../models/contact.interface'
import CustomContactsComponent from '../../components/contacts/CustomContactsComponent'
import { ContractsInterface } from '../../../models/model.interface'
import CreatorMetaEdit from './CreatorMetaEdit'

export interface AllowedPlatform {
	title: string
	value: boolean
}
interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

//this is the main pop-up that renders either when we edit or create a new publisher.
//one of the main differences between advertiser and publisher is that we have media plan for publishers.

export interface SingleCapability {
	category: string
	percentage: number
}
export interface CapabilityObj {
	gender: { male: number; female: number }
	age: {
		age18to24: number
		age25to34: number
		age35to44: number
		age45to54: number
		age55toInfinity: number
	}
	vertical: string[]
	engagement_rate: number
	rate_per_video: number
	geo: SingleCapability[]
	notes: string | undefined
	uuid: string
	type: string
}
const CreatorPopUp = (props: {
	nullify?: any
	PopUpVisible: boolean
	setPopUpVisible: any
	setLoading: any
	row?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	loginRole: string
	isNewCreator?: any
	setIsNewCreator?: any
	reloadRow: any
}): JSX.Element => {
	const {
		uploadIcon,
		deleteIcon,
		// removeAppP360,
	} = useActions()
	const { insertCreatorAction, updateCreatorAction } = useCreatorActions()
	const { insertLog } = useLogActions()
	const theme = useTheme()
	const navigate = useNavigate()
	const params = useParams()
	const paramId = params.id ? params.id.toString().trim() : ''

	const { login, settings, creator, users } = useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_CREATOR),
		preview: useIsAuthorized(permissionNames.PREVIEW_CREATORS),
	}
	const [name, setName] = useState<string>('')
	const [userName, setUserName] = useState<string>('')
	const [campaign, setCampaign] = useState<string[]>([])
	const [type, setType] = useState('')
	const [email, setEmail] = useState('')
	const [gender, setGender] = useState('')
	const [geo, setGeo] = useState<string[]>([])
	const [instagram, setInstagram] = useState('')
	const [tiktok, setTiktok] = useState('')
	const [youtube, setYoutube] = useState('')
	const [linkedin, setLinkedin] = useState('')
	const [portfolio, setPortfolio] = useState('')
	const [fiverr, setFiverr] = useState('')
	const [linkToIO, setLinkToIO] = useState('')
	const [linkToDrafts, setLinkToDrafts] = useState('')
	const [ownerEmail, setOwnerEmail] = useState<string | null>(login.user.email)
	const [address, setAddress] = useState<string>('')
	const [financeId, setFinanceId] = useState<string>('')
	const [contacts, setContacts] = useState<ContactInterface[]>([])

	const [capabilities, setCapabilities] = useState<CapabilityObj[]>([])

	const [paymentTerm, setPaymentTerm] = useState<string>('')
	const [vat, setVat] = useState<string>('')

	const [hasAgent, setHasAgent] = useState<boolean>(false)
	const [goodCommunicationBadge, setGoodCommunicationBadge] =
		useState<boolean>(false)
	const [highQualityWorkBadge, setHighQualityWorkBadge] =
		useState<boolean>(false)

	const [status, setStatus] = useState(true)
	const [createdBy, setCreatedBy] = useState<string>('')
	const [pastOwnerFinance, setPastOwnerFinance] = useState<any>([])
	const [tab, setTab] = useState(0)
	const [isValid, setIsValid] = useState<boolean>(false)
	const [prevState, setPrevState] = useState<any>(null)
	const [uuid, setUuid] = useState('')
	const [originalUsername, setOriginalUsername] = useState<string>('') // Track the original username
	const [newId, setNewId] = useState<number | null>(null) // Initialize it to null
	const [contracts, setContracts] = useState<ContractsInterface[]>([])
	const [branch, setBranch] = useState('')
	const [financeIdInUse, setFinanceIdInUse] = useState<boolean>(false)
	// const [notes, setNotes] = useState<string>('')
	// const [financeId, setFinanceId] = useState<string>('')
	// const [salesRep, setSalesRep] = useState('')
	// const [type, setType] = useState<string>('')
	// const [linkToAdvFile, setLinkToAdvFile] = useState('')
	// const [linkToCreative, setLinkToCreative] = useState('')
	// const [platforms, setPlatforms] = useState<string | null>(null)
	// const [pids, setPIDs] = useState<string[]>([])
	// const [contacts, setContacts] = useState<any[]>([])
	// const [skypeGroup, setSkypeGroup] = useState<string>('')
	// const [internalSegment, setInternalSegment] = useState<string[]>([])
	// const [capabilities, setCapabilities] = useState<AllowedPlatform[]>([])
	// const [linkToPubFile, setLinkToPubFile] = useState<string>('')
	// const [linkToLinkedin, setLinkToLinkedin] = useState<string>('')
	// const [linkToWebsite, setLinkToWebsite] = useState<string>('')
	// const [freeText, setFreeText] = useState<string>('')
	// const [externalId, setExternalId] = useState<number | null>(null)
	// const [externalIdInUse, setExternalIdInUse] = useState<boolean>(false)
	// const [publicSkype, setPublicSkype] = useState<boolean>(true)
	// const [skypeAllowedUsers, setSkypeAllowedUsers] = useState<string[]>([])
	// const [skypeGroupUserAllowed, setSkypeGroupUserAllowed] =
	//  useState<boolean>(false)
	// const [dataFilled, setDataFilled] = useState(false)

	const [loadedRow, setLoadedRow] = useState<any>(undefined)

	const [uploadStatus, setUploadStatus] = useState<{
		message: string
		success: boolean
	} | null>(null)

	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

	const row = useTypedSelector((state) =>
		state.creator.creator.find(
			(creator: any) => creator?.id?.toString().trim() === params.id,
		),
	)

	const [iconUrl, setIconUrl] = useState<string>(row?.creator_icon)
	const [fieldValidations, setFieldValidations] = useState({
		name: false,
		userName: false,
		type: false,
		email: false,
		gender: false,
		campaign: false,
		address: false,
		ownerEmail: false,
	})

	const [mandatoryFields, setMandatoryFields] = useState({
		name: true,
		userName: true,
		type: true,
		email: true,
		gender: true,
		campaign: true,
		address: true,
		ownerEmail: true,
	})

	const isCompanyOwner = props.isNewCreator
		? true
		: row?.company_id === login.company.id

	//we use this to handle the logs.
	const preparePrevState = (prevState: any) => {
		try {
			const copiedState = _.cloneDeep(prevState)
			const { id, createdAt, name_history, ...result } = copiedState
			return result
		} catch (error) {
			return null
		}
	}

	const prepareNewState = (prevState: any) => {
		const { user_login, name_history, ...result } = prevState
		return result
	}

	const isDuplicate = (
		valueToCheck: string,
		currentCreatorId: number | null,
	): boolean => {
		const duplicateExists = creator.creator.some((existingCreator: any) => {
			// Check if username exists before calling toLowerCase()
			if (!existingCreator.username) {
				return false
			}

			// Compare if the user being edited is the same one
			const isSameUser = existingCreator.id === currentCreatorId

			// Compare if the username is the same (case insensitive)
			const isSameUsername =
				existingCreator.username.toLowerCase() === valueToCheck.toLowerCase()

			// If the same user is being edited and the username hasn’t changed, don’t flag as duplicate
			if (isSameUser && isSameUsername) {
				return false
			}

			// Return true if there's a different creator with the same username
			return isSameUsername && !isSameUser
		})

		return duplicateExists
	}

	const nullifyFields = () => {
		setName('')
		setUserName('')
		setCampaign([])
		setType('')
		setEmail('')
		setGender('')
		setGeo([])
		setInstagram('')
		setTiktok('')
		setYoutube('')
		setLinkedin('')
		setPortfolio('')
		setFiverr('')
		setLinkToIO('')
		setLinkToDrafts('')
		setOwnerEmail(login.user.email)
		setAddress('')
		// setCreatorId('')
		setOwnerEmail(null)
		setStatus(false)
		setVat('')
		setPastOwnerFinance([])
		setContacts([])
		setPaymentTerm('')
		setHasAgent(false)
		setGoodCommunicationBadge(false)
		setHighQualityWorkBadge(false)
		setUuid('')
		setFinanceId('')
		setContracts([])
		setBranch('')
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	const handleOpenUploadModal = () => setIsUploadModalOpen(true)

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setDeleteModalOpen(true)
		}
	}

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const handleIconUrl = (iconUrl: string) => {
		return !iconUrl || iconUrl === '' ? defaultIcon : iconUrl
	}

	const handleUploadSuccess = (filename: string) => {
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 100)
	}

	const handleUploadError = (filename: string) => {
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 100)
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			// const headers = { Authorization: `Bearer ${login.user.token}` }
			deleteIcon('CREATOR', row.name, row.id)
			setDeleteModalOpen(false)
			setIconUrl(defaultIcon)
		}
	}

	const handleFileUpload = async (files: any) => {
		const creatorId = row?.id || newId // Fallback to newId if row doesn't exist yet
		if (!creatorId) {
			props.setErrorMessage(
				'Please save the creator first before uploading an icon.',
			)
			return
		}

		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				props.setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}

			const uploadResult = await uploadIcon(
				'CREATOR',
				file,
				row?.name || name,
				creatorId,
			)
			setIconUrl(uploadResult.toString())
		}
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	// const handleLog = async (payload: any) => {
	// 	if (prevState && prevState.capabilities) {
	// 		prevState['capabilities'] = capabilitiesFromJson(prevState?.capabilities)
	// 	}
	// 	const shallow = shallowEqual(prevState, prepareNewState(payload))
	// 	if (shallow.length > 0) {
	// 		const log = {
	// 			email: login.user.email,
	// 			object: name! + ' in Publishers',
	// 			change: JSON.stringify(shallow),
	// 		}
	// 		await insertLog(log)
	// 	}
	// }

	const submit = async () => {
		const isEditMode = !!row // Determine if we are in edit mode (row exists)
		let failed: any
		let newCreatorId: number | null = null

		if (!isEditMode && !isValid) {
			setIsValid(false)
			return
		}

		if (isValid) {
			// Prepare the payload for submission
			let payload: any = {
				id: row?.id,
				name,
				username: userName, // Checking username or other fields
				campaign,
				type,
				email,
				gender,
				geo,
				instagram,
				tiktok,
				youtube,
				linkedin,
				portfolio,
				fiverr,
				contacts,
				link_to_io: linkToIO,
				link_to_drafts: linkToDrafts,
				owner: ownerEmail,
				address,
				status,
				vat,
				creator_icon: iconUrl || '',
				past_owner_finance: pastOwnerFinance,
				payment_term: paymentTerm,
				user_login: login.user.email,
				has_agent: hasAgent,
				good_communication_badge: goodCommunicationBadge,
				high_quality_work_badge: highQualityWorkBadge,
				capabilities,
				uuid,
				finance_id: financeId,
				contracts: contracts || [],
				branch,
			}

			// Insert or update creator
			if (isEditMode) {
				failed = await updateCreatorAction(payload, props.setErrorMessage) // Update existing creator
			} else {
				const response: any = await insertCreatorAction(
					payload,
					props.setErrorMessage,
				)
				if (!response) {
					props.setErrorMessage('Failed to create creator.')
					return
				}

				newCreatorId = response.master.id // Save the new ID
			}

			// Turn off loading spinner
			props.setLoading(false)

			// Handle success
			if (!failed) {
				// handleLog(payload)
				// props.setIsSuccessPromptOpened(true)

				// Navigate to the new URL first
				const idToNavigate = row?.id || newCreatorId
				if (idToNavigate) {
					navigate(`/creators/${idToNavigate}`)
				}
			}
		}
	}

	//once again, based on the user he will be able to see the skype link or not (in case it's set to private)
	// useEffect(() => {
	//  const allowedList = []
	//  if (settings.settings && users.users) {
	//      for (const teamLeader of settings.settings.teams) {
	//          for (const user of users.users) {
	//              if (
	//                  (teamLeader === user.name ||
	//                      user.role === 'super' ||
	//                      user.role === 'admin' ||
	//                      user.position === 'publisher manager') &&
	//                  allowedList.indexOf(user.email) === -1
	//              ) {
	//                  allowedList.push(user.email)
	//              }
	//          }
	//      }
	//      setSkypeAllowedUsers(allowedList)
	//  }
	// }, [settings, users])

	// useEffect(() => {
	//  console.log(skypeAllowedUsers, skypeAllowedUsers.indexOf(login.user.email))
	//  if (skypeAllowedUsers.length > 0) {
	//      if (skypeAllowedUsers.indexOf(login.user.email) >= 0) {
	//          setSkypeGroupUserAllowed(true)
	//      }
	//  }
	// }, [skypeAllowedUsers])

	//this is how we fill the data when opening a publisher.
	useEffect(() => {
		if (row) {
			setOriginalUsername(row.username)
		}
		const fillFields = async (row: any) => {
			setName(row!.name)
			setUserName(row!.username)
			setCampaign(row!.campaign)
			setType(row!.type)
			setEmail(row!.email)
			setGender(row!.gender)
			setGeo(row!.geo)
			setInstagram(row!.instagram)
			setTiktok(row!.tiktok)
			setYoutube(row!.youtube)
			setLinkedin(row!.linkedin)
			setPortfolio(row!.portfolio)
			setFiverr(row!.fiverr)
			setLinkToIO(row!.link_to_io)
			setLinkToDrafts(row!.link_to_drafts)
			setOwnerEmail(row!.owner)
			setAddress(row!.address)
			// setCreatorId(row!.creator_id)
			setStatus(row!.status)
			setVat(row!.vat)
			setPastOwnerFinance(row!.past_owner_finance)
			setPaymentTerm(row!.payment_term)
			setHasAgent(row!.has_agent)
			setCapabilities(row!.capabilities)
			setGoodCommunicationBadge(row!.good_communication_badge)
			setHighQualityWorkBadge(row!.high_quality_work_badge)
			setUuid(row!.uuid)
			setFinanceId(row!.finance_id)
			let decryptedContacts = row.contacts
				? await decryptContacts(row.contacts)
				: []
			setContacts(decryptedContacts)
			setContracts(row!.contracts || [])
			setBranch(row!.branch)
		}
		if (row) {
			fillFields(row)
		}
	}, [row])

	useEffect(() => {
		const obj = _.cloneDeep(row)
		const prev = preparePrevState(obj)
		if (!prevState) {
			setPrevState({ ...prev })
		}
		// console.log('FIRST STATE CHECK : ', row)
	}, [])
	//we use this in order to save the logs for capabilities.
	const capabilitiesFromJson = (capabilities: any[]) => {
		let result: any[] = []
		capabilities?.forEach((c) => {
			if (typeof c === 'string') {
				return result.push(JSON.parse(c))
			} else result.push(c)
		})
		return result
	}
	//we want to be sure that some inputs are correct.
	const isValidValue = (value: string | null | undefined | string[]) => {
		if (value === null || undefined) {
			return false
		}
		if (Array.isArray(value)) {
			if (value.length === 0) {
				return false
			}
		}
		if (typeof value === 'string' && value?.trim() === '') {
			return false
		}
		return true
	}
	useEffect(() => {
		if (!row) {
			// If creating a new creator, enforce required fields
			const isNameValid = isValidValue(name)
			const isUserNameValid = isValidValue(userName)
			const isTypeValid = isValidValue(type)
			const isEmailValid = isValidValue(email) && stringIsValidEmail(email)
			const isGenderValid = isValidValue(gender)
			const isCampaignValid = isValidValue(campaign)
			const isAddressValid = isValidValue(address)
			const isOwnerEmailValid = isValidValue(ownerEmail)

			const mandatoryFieldsValid =
				isNameValid &&
				isUserNameValid &&
				isTypeValid &&
				isEmailValid &&
				isGenderValid &&
				isCampaignValid &&
				isAddressValid &&
				isOwnerEmailValid

			setIsValid(mandatoryFieldsValid)
		} else {
			// In update mode, enable button if any field changed
			const hasChanges =
				name !== prevState?.name ||
				userName !== prevState?.username ||
				campaign !== prevState?.campaign ||
				type !== prevState?.type ||
				email !== prevState?.email ||
				gender !== prevState?.gender ||
				geo !== prevState?.geo ||
				instagram !== prevState?.instagram ||
				tiktok !== prevState?.tiktok ||
				youtube !== prevState?.youtube ||
				linkedin !== prevState?.linkedin ||
				portfolio !== prevState?.portfolio ||
				fiverr !== prevState?.fiverr ||
				linkToIO !== prevState?.link_to_io ||
				linkToDrafts !== prevState?.link_to_drafts ||
				ownerEmail !== prevState?.owner ||
				address !== prevState?.address ||
				status !== prevState?.status ||
				vat !== prevState?.vat ||
				financeId !== prevState?.finance_id ||
				JSON.stringify(contacts) !== JSON.stringify(prevState?.contacts) ||
				paymentTerm !== prevState?.payment_term ||
				hasAgent !== prevState?.has_agent ||
				goodCommunicationBadge !== prevState?.good_communication_badge ||
				highQualityWorkBadge !== prevState?.high_quality_work_badge ||
				JSON.stringify(capabilities) !== JSON.stringify(prevState?.capabilities)

			setIsValid(hasChanges)
		}
	}, [
		row,
		name,
		userName,
		campaign,
		type,
		email,
		gender,
		geo,
		instagram,
		tiktok,
		youtube,
		linkedin,
		portfolio,
		fiverr,
		linkToIO,
		linkToDrafts,
		ownerEmail,
		address,
		status,
		vat,
		financeId,
		contacts,
		paymentTerm,
		hasAgent,
		goodCommunicationBadge,
		highQualityWorkBadge,
		capabilities,
	])

	// useEffect(() => {
	//  if (externalId) {
	//      const checkIfRepeat = publisher.publisher.some(
	//          (pub: any) =>
	//              pub.external_id !== null &&
	//              pub.external_id === parseInt(externalId as unknown as string) &&
	//              pub.publisher_name !== name,
	//      )
	//      setIsValid(!checkIfRepeat)
	//      setExternalIdInUse(checkIfRepeat)
	//  }
	// }, [externalId])
	// useEffect(() => {
	//  if (financeId) {
	//      const checkIfRepeat = publisher.publisher.some(
	//          (pub: any) =>
	//              pub.finance_id !== null &&
	//              pub.finance_id === parseInt(financeId as unknown as string) &&
	//              pub.publisher_name !== name,
	//      )
	//      setIsValid(!checkIfRepeat)
	//      setFinanceIdInUse(checkIfRepeat)
	//  }
	// }, [financeId])

	useEffect(() => {
		if (!props.PopUpVisible) nullifyFields()
	}, [props.PopUpVisible])

	const closeDetailedView = () => {
		navigate('/creators')
		props.setPopUpVisible(false)
	}
	const openPreview = () => {
		navigate('/creators/' + params.id + '/preview')
	}

	const handleRemoveContact = (contact: ContactInterface) => {
		setContacts((prevContacts) => {
			const updatedContacts = prevContacts.filter(
				(c) => c.uuid !== contact.uuid,
			)
			return updatedContacts
		})
	}

	const tabOptions = [
		{ label: 'Company Information', tabIndex: 0 },
		{ label: 'Capabilities', tabIndex: 1 },
		{ label: 'Finance', tabIndex: 2 },
	]

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				onPreview={openPreview}
				onUpload={handleOpenUploadModal}
				tabValue={tab}
				handleChangeTab={handleChangeTab}
				hasPreview={!!(row && permissions.preview && params.id)}
				options={tabOptions}
				customStyles={editPageTopPanelStyle}
				arialabel='basic tabs'
			/>

			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={() => setIsUploadModalOpen(false)}
			>
				{uploadStatus ? (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
					>
						<Typography variant='subtitle1'>{uploadStatus.message}</Typography>
						<CheckCircle style={{ color: 'green', fontSize: 40 }} />
					</Box>
				) : (
					<CustomFileUploader
						onSetFiles={handleFileUpload}
						onSuccess={handleUploadSuccess}
						onError={handleUploadError}
						acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
					/>
				)}
			</CustomModal>

			<CompanyInformationHeader
				name={name || ''}
				iconUrl={handleIconUrl(iconUrl)}
				onClickIcon={
					iconUrl !== defaultIcon && iconUrl ? handleIconClick : undefined
				}
				tooltipText={
					iconUrl === defaultIcon || !iconUrl
						? 'No icon to delete'
						: 'Click to delete icon'
				}
				cursorStyle={
					iconUrl === defaultIcon || !iconUrl ? 'default' : 'pointer'
				}
				style={{
					display: 'flex',
					alignContent: 'center',
					marginLeft: 98,
					marginTop: 16,
				}}
			/>

			<CustomDialog
				open={isDeleteModalOpen}
				onClose={handleCloseDeleteModal}
				onConfirm={handleDeleteIcon}
				imageUrl={handleIconUrl(iconUrl)}
				message='Click delete to remove the icon.'
			/>

			<PageWrapper
				style={{
					margin: '2vh 8vh',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
					marginTop: '0px',
				}}
			>
				{isCompanyOwner ? (
					<>
						<TabPanel value={tab} index={0}>
							<CreatorInfoComponent
								name={name}
								setName={setName}
								userName={userName}
								setUserName={setUserName}
								campaign={campaign}
								setCampaign={setCampaign}
								type={type}
								setType={setType}
								email={email}
								setEmail={setEmail}
								gender={gender}
								setGender={setGender}
								geo={geo}
								setGeo={setGeo}
								instagram={instagram}
								setInstagram={setInstagram}
								tiktok={tiktok}
								setTiktok={setTiktok}
								youtube={youtube}
								setYoutube={setYoutube}
								linkedin={linkedin}
								setLinkedin={setLinkedin}
								portfolio={portfolio}
								setPortfolio={setPortfolio}
								fiverr={fiverr}
								setFiverr={setFiverr}
								linkToIO={linkToIO}
								setLinkToIO={setLinkToIO}
								linkToDrafts={linkToDrafts}
								setLinkToDrafts={setLinkToDrafts}
								ownerEmail={ownerEmail}
								setOwnerEmail={setOwnerEmail}
								address={address}
								setAddress={setAddress}
								status={status}
								setStatus={setStatus}
								isValid={isValid}
								hasAgent={hasAgent}
								setHasAgent={setHasAgent}
								goodCommunicationBadge={goodCommunicationBadge}
								setGoodCommunicationBadge={setGoodCommunicationBadge}
								highQualityWorkBadge={highQualityWorkBadge}
								setHighQualityWorkBadge={setHighQualityWorkBadge}
								mandatoryFields={mandatoryFields}
								fieldValidations={fieldValidations}
								permissions={permissions}
							/>
						</TabPanel>
						<TabPanel value={tab} index={1}>
							<CreatorCapabilities
								capabilities={capabilities}
								setCapabilities={setCapabilities}
							/>
						</TabPanel>
						<TabPanel value={tab} index={2}>
							<CreatorFinanceInfo
								paymentTerm={paymentTerm}
								setPaymentTerm={setPaymentTerm}
								vat={vat}
								setVat={setVat}
								settings={settings}
								financeId={financeId}
								setFinanceId={setFinanceId}
								name={name}
								iconUrl={iconUrl}
								branch={branch}
								setBranch={setBranch}
								contracts={contracts}
								setContracts={setContracts}
							/>
						</TabPanel>
					</>
				) : (
					<>
						{login.company.type === 'agency' && (
							<>
								<TabPanel value={tab} index={0}>
									<CreatorMetaEdit
										permissions={permissions}
										settings={settings}
										name={name}
										setName={setName}
										userName={userName}
										setUserName={setUserName}
										type={type}
										setType={setType}
										gender={gender}
										setGender={setGender}
										address={address}
										setAddress={setAddress}
										geo={geo}
										setGeo={setGeo}
										instagram={instagram}
										setInstagram={setInstagram}
										tiktok={tiktok}
										setTiktok={setTiktok}
										youtube={youtube}
										setYoutube={setYoutube}
										linkedin={linkedin}
										setLinkedin={setLinkedin}
										portfolio={portfolio}
										setPortfolio={setPortfolio}
										fiverr={fiverr}
										setFiverr={setFiverr}
										linkToDrafts={linkToDrafts}
										setLinkToDrafts={setLinkToDrafts}
										branch={branch}
										setBranch={setBranch}
										fieldValidations={mandatoryFields}
									/>
								</TabPanel>
								<TabPanel value={tab} index={1}>
									<CreatorCapabilities
										capabilities={capabilities}
										setCapabilities={setCapabilities}
									/>
								</TabPanel>
								<TabPanel value={tab} index={2}>
									<CreatorFinanceInfo
										paymentTerm={paymentTerm}
										setPaymentTerm={setPaymentTerm}
										vat={vat}
										setVat={setVat}
										settings={settings}
										financeId={financeId}
										setFinanceId={setFinanceId}
										name={name}
										iconUrl={iconUrl}
										branch={branch}
										setBranch={setBranch}
										contracts={contracts}
										setContracts={setContracts}
									/>
								</TabPanel>
							</>
						)}
					</>
				)}

				<DialogActions
					style={{
						paddingBottom: '80px',
						display: 'flex',
						justifyContent: 'center',
						gap: 80,
					}}
				>
					<UnborderedButton
						onClick={() => {
							nullifyFields()
							navigate('/creators')
							props.setPopUpVisible(false)
						}}
						label='Cancel'
					/>
					{permissions.edit && (
						<SubmitButton
							disabled={row ? false : !isValid}
							onClick={() => submit()}
						>
							{row ? 'Update' : 'Save'}
						</SubmitButton>
					)}
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}
export default CreatorPopUp
