import React from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import { DropList } from '../components/SelectableInputs'
import { DatePicker } from '../components/dateInputs/DateInputs'
import { FileUploadButton } from '../components/reusableComponents'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ContractsInterface } from '../../models/model.interface'
import { EditableGridItem } from './PreviewComponents'
import { format, parse } from 'date-fns'
import { convertFileToBase64 } from '../../utils/helpers/helperFuncs'

interface DocumentRowProps {
	document: ContractsInterface
	fileTypes: string[]
	onChange: (arg0: any) => void
	onDelete: () => void
}

const DocumentRow: React.FC<DocumentRowProps> = ({
	document,
	fileTypes,
	onChange,
	onDelete,
}) => {
	const theme = useTheme()

	const handleFileChange = async (file: File) => {
		const base64String = await convertFileToBase64(file)
		const updatedDocument = {
			...document,
			file: base64String,
			fileName: file.name,
		}
		onChange(updatedDocument)
	}

	const handleFileTypeChange = (fileType: string) => {
		onChange({ ...document, fileType })
	}

	const handleDateChange = (expiration: Date | number) => {
		const formattedDate = format(expiration, 'dd/MM/yyyy')

		onChange({ ...document, expiration: formattedDate || '' })
	}

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(12, 1fr)',
				gap: 6,
				alignItems: 'center',
				width: '100%',
				justifyContent: 'center',
				columnGap: '30px',
				padding: '0px',
				// marginLeft: '30px',
			}}
		>
			<EditableGridItem gridColumnSpan={3}>
				<DropList
					label='Doc Type'
					options={fileTypes}
					value={document.fileType || ''}
					onChange={handleFileTypeChange}
					mandatory
				/>
			</EditableGridItem>

			<EditableGridItem gridColumnSpan={3}>
				<DatePicker
					label='Expiration'
					value={
						document.expiration
							? parse(document.expiration, 'dd/MM/yyyy', new Date())
							: null
					}
					onChange={handleDateChange}
					format='dd/MM/yyyy'
					disabled={false}
					sx={{ background: theme.colors.base.white }}
				/>
			</EditableGridItem>

			<div
				style={{
					gridColumn: 'span 6',
					flexDirection: 'row',
					gridTemplateColumns: 'repeat(5, 1fr)',
					display: 'grid',
				}}
			>
				<div style={{ gridColumn: 'span 4' }}>
					<FileUploadButton
						onUpload={(event) => {
							const file = event.target.files?.[0]
							if (file) handleFileChange(file)
						}}
						uploadedFile={document.fileName ? document : null}
						onDelete={() => {}}
						label='File'
						// sx={{ marginTop: '20px' }}
					/>
				</div>
				<div
					style={{
						gridColumn: 'span 1',
						display: 'flex',
						alignItems: 'flex-end',
						justifyContent: 'flex-end',
						paddingBottom: 4,
					}}
				>
					<IconButton
						onClick={onDelete}
						sx={{
							backgroundColor: theme.colors.buttons.inactive,
							padding: '6px',
							'&:hover': {
								backgroundColor: theme.colors.buttons.hovered_white,
							},
						}}
					>
						<DeleteIcon
							style={{ color: theme.colors.text.titles, scale: '0.9' }}
						/>
					</IconButton>
				</div>
			</div>
		</Box>
	)
}

export default DocumentRow
