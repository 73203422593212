import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import { StyledNameField } from '../components/reusableComponents'
import {
	getApplicableRules,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import {
	OwnerSelect,
	DropList,
	CountryListSelector,
} from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { SubmitButton } from '../components/Buttons'
import { useState } from 'react'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { Owner } from './AdvertiserPopUp'
import { SwitchComponent } from '../components/ToggleComponents'
import { StyledLabel } from '../components/Informative'
import { AppIdLinkPair } from '../../models/model.interface'
import AppsForAdvertiserSection from '../apps/AppsComponents'
import { THING_OR_TWO_COMPANY_ID } from '../../config'

//this is the main component for editing an advertiser. Here is the logic that handles every input.

interface CompanyInfoProps {
	name?: string | null
	setName?: (value: string) => void
	legalName?: string
	setLegalName?: (value: string) => void
	address?: string
	setAddress?: (value: string) => void
	geo?: string[]
	setGeo?: (value: string[]) => void
	linkToIO?: string
	setLinkToIO?: (value: string) => void
	setOwnerEmail?: (value: string) => void
	ownerEmail?: string | null
	setSalesRep?: (value: string) => void
	salesRep?: string
	setStatus?: (value: boolean) => void
	status?: boolean
	setApps?: (value: string[]) => void
	apps?: string[]
	setPlatforms?: (value: string[] | null) => void
	platforms?: string[] | null
	setFraudTool?: (value: string[]) => void
	fraudTool?: string[]
	linkToAdvFile?: string
	setLinkToAdvFile?: (value: string) => void
	linkToCreative?: string
	setLinkToCreative?: (value: string) => void
	team?: string
	setTeam?: (value: string) => void
	communicationChannel?: string[]
	setCommunicationChannel?: (value: string[]) => void
	advertiserType?: string
	setAdvertiserType?: (value: string) => void
	vertical?: string[]
	setVertical?: (value: string[]) => void
	isValid?: boolean
	settings?: any
	loginRole?: string
	createdBy?: string
	loginUser?: string
	appList?: string[]
	setMainHeightChange?: (value: any) => void
	pauseReason?: string
	setPauseReason?: (value: string) => void
	pauseExplanation?: string
	setPauseExplanation?: (value: string) => void
	ViewPauseBox?: boolean
	setViewPauseBox?: (value: boolean) => void
	setStatusChanged?: (value: boolean) => void
	website?: string
	setWebsite?: (value: string) => void
	linkedin?: string
	setLinkedin?: (value: string) => void
	brief?: string
	setBrief?: (value: string) => void
	specialRequest?: string
	setSpecialRequest?: (value: string) => void
	pastOwner?: Owner[]
	setPastOwner?: (value: Owner[]) => void
	pastOwnerFinance?: any
	setPastOwnerFinance?: (value: any) => void
	parametersTemplate?: string
	setParametersTemplate?: (value: string) => void
	users?: any
	mandatoryFields?: any
	fieldValidations?: any
	permissions: any
	handlePastOwner?: any
	handlePastOwnerFinance?: any
	agencyAccess?: any
	setAgencyAccess?: (value: any) => void
	appsData?: AppIdLinkPair[]
	setAppsData?: (value: AppIdLinkPair[]) => void
	notes?: string
	setNotes?: (value: string) => void
	dataSource?: string[]
	setDataSource?: (value: string[]) => void
}

export const CompanyInfoComponent = (props: CompanyInfoProps) => {
	const { settings, login } = useTypedSelector((state) => state)
	const [nameTouched, setNameTouched] = useState(false)

	const permissions = props.permissions
	const { appsData = [], setAppsData } = props

	const validateDirectType = (value: string) => {
		return false
	}
	const IS_THING_OR_TWO = login.company?.id === THING_OR_TWO_COMPANY_ID

	const getGeoValue = (geo: string[] | undefined) => {
		return geo && geo.length > 0 ? geo : ['GLOBAL']
	}

	return props.ownerEmail ? (
		<Grid
			container
			columns={12}
			columnSpacing={3}
			rowSpacing={4}
			direction='row'
			style={{ width: '98%' }}
		>
			{props.ViewPauseBox && props.status !== undefined && (
				<>
					<div
						style={{
							background: 'rgba(0,0,0,0.2)',
							width: '120vw',
							height: '200vh',
							display: 'flex',
							top: '-10%',
							left: '-10%',
							position: 'absolute',
							zIndex: '1',
						}}
					></div>
					<div
						style={{
							background: 'white',
							position: 'absolute',
							display: 'flex',
							top: '40%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							border: '1px solid black',
							borderRadius: '30px',
							flexDirection: 'column',
							zIndex: '10',
							height: '24%',
							width: '32%',
							padding: '2rem 4rem 2rem 4rem',
						}}
					>
						<p style={{ color: 'black', width: '110%', marginLeft: '-12px' }}>
							Please, note that disabling the advertiser will also disable all
							the campaigns connected to it.
						</p>
						<SubmitButton
							disabled={!permissions.edit}
							style={{ margin: 'auto' }}
							onClick={() => {
								props.setViewPauseBox?.(false)
							}}
						>
							Close
						</SubmitButton>
					</div>
				</>
			)}
			<Grid item xs={3}>
				<InputFieldText
					label='Company Name'
					placeholder='Company Name'
					type='text'
					value={props.name || ''}
					onChange={(e: string) => {
						setNameTouched(true)
						props.setName?.(e)
					}}
					disabled={!permissions.edit}
					errormessage={
						nameTouched && !stringIsValidName(props.name || '')
							? "Company name is mandatory. Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Legal Name'
					placeholder='Legal Name'
					type='text'
					value={props.legalName || ''}
					onChange={(e: string) => props.setLegalName?.(e)}
					disabled={!permissions.edit}
					mandatory={!props.fieldValidations.legalName}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Company Address'
					type='text'
					value={props.address || ''}
					onChange={(e: string) => props.setAddress?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={3 / 2}>
				<CountryListSelector
					label='Head Office Geo'
					options={countryList}
					value={props.geo || []}
					singular
					onChange={(e: any) => props.setGeo?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={3 / 2}>
				<DropList
					onChange={(e: any) => {
						props.setAdvertiserType?.(e)
					}}
					options={['Direct', 'Network', 'Agency']}
					label={'Type'}
					value={props.advertiserType || ''}
					disabled={!permissions.edit}
					noNull={true}
					mandatory={!props.fieldValidations.advertiserType}
				/>
			</Grid>

			<Grid item xs={3}>
				<OwnerSelect
					onChange={(e: any) => {
						props.handlePastOwner(e)
						props.handlePastOwnerFinance(e)
						props.setOwnerEmail?.(e)
					}}
					label={'Owner'}
					value={props.ownerEmail}
					disabled={!permissions.edit}
				/>
			</Grid>

			{props.setSalesRep && (
				<Grid item xs={3}>
					<DropList
						onChange={(e: any) => props.setSalesRep?.(e)}
						options={props.settings?.settings?.salesRepresentative || []}
						label={'Sales Representative'}
						value={props.salesRep || ''}
						disabled={!permissions.edit}
						noNull={true}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setCommunicationChannel?.(e)}
					options={props.settings?.settings?.communicationChannel?.sort() || []}
					label={'Communication channel'}
					openWithClick
					value={props.communicationChannel || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>
			{props.agencyAccess !== null && !IS_THING_OR_TWO && (
				<Grid item xs={3 / 2}>
					<DropList
						onChange={(e: any) => {
							props.setAgencyAccess?.(e === 'Allowed')
						}}
						options={['Allowed', 'Denied']}
						label={'Agency Access'}
						value={props.agencyAccess === true ? 'Allowed' : 'Denied'}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}

			<Grid item xs={3 / 2}>
				<DropList
					onChange={(e: any) => {
						props.setStatus?.(e === 'Active')
						props.setStatusChanged?.(true)
					}}
					options={['Active', 'Disabled']}
					label={'Status'}
					value={props.status === true ? 'Active' : 'Disabled'}
					disabled={!permissions.edit}
				/>
			</Grid>

			<Grid item xs={3}>
				<InputFieldText
					label='LinkedIn'
					type='text'
					value={props.linkedin || ''}
					onChange={(e: string) => props.setLinkedin?.(e)}
					color={
						props.linkedin &&
						!checkInputStartsWithHttp(props.linkedin) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.linkedin
							? !checkInputStartsWithHttp(props.linkedin)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>

			{props.setLinkToIO && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to Google Drive'
						type='text'
						value={props.linkToIO || ''}
						onChange={(e: string) => props.setLinkToIO?.(e)}
						color={
							props.linkToIO &&
							!checkInputStartsWithHttp(props.linkToIO) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!permissions.edit}
						errormessage={
							props.linkToIO
								? !checkInputStartsWithHttp(props.linkToIO)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{props.setLinkToCreative && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to Creative'
						type='text'
						value={props.linkToCreative || ''}
						onChange={(e: string) => props.setLinkToCreative?.(e)}
						color={
							props.linkToCreative &&
							!checkInputStartsWithHttp(props.linkToCreative) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!permissions.edit}
						errormessage={
							props.linkToCreative
								? !checkInputStartsWithHttp(props.linkToCreative)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<InputFieldText
					label='Website'
					type='text'
					value={props.website || ''}
					onChange={(e: string) => props.setWebsite?.(e)}
					color={
						props.website &&
						!checkInputStartsWithHttp(props.website) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.website
							? !checkInputStartsWithHttp(props.website)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setPlatforms?.(e)}
					options={props.settings?.settings?.platforms?.sort() || []}
					label={'Platforms'}
					errormessage={
						validateDirectType(props.advertiserType || '')
							? 'Direct-type advertisers must have only one platform'
							: undefined
					}
					openWithClick
					value={props.platforms || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setFraudTool?.(e)}
					options={props.settings?.settings?.fraudTools?.sort() || []}
					label={'Fraud Tool'}
					openWithClick
					value={props.fraudTool || []}
					disabled={!permissions.edit || props.advertiserType === 'Network'}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setVertical?.(e)}
					options={props.settings?.settings?.vertical?.sort() || []}
					label={'Vertical'}
					openWithClick
					value={props.vertical || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
					errored={
						props.advertiserType === 'Direct' &&
						props.vertical &&
						props.vertical.length === 0
					}
				/>
			</Grid>

			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setDataSource?.(e)}
					options={['Aggregated Data', 'Raw Data']}
					label={'Data Source'}
					openWithClick
					value={props.dataSource || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>
			{props.status === false && (
				<>
					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => props.setPauseReason?.(e)}
							options={props.settings?.settings?.pauseReason?.sort() || []}
							label={'Pause Reason'}
							value={props.pauseReason || ''}
							multiple={false}
							disabled={!permissions.edit}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Explanation'
							type='text'
							value={props.pauseExplanation || ''}
							onChange={(e: string) => props.setPauseExplanation?.(e)}
							disabled={!permissions.edit}
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12} style={{ width: '100%' }}>
				<AppsForAdvertiserSection
					appsData={appsData}
					setAppsData={setAppsData!}
					apps={props.apps}
					advertiserType={props.advertiserType}
					permissions={permissions}
				/>
			</Grid>

			<Grid item xs={6}>
				<StyledTextAreaField
					label='Brief'
					value={props.brief || ''}
					onChange={(e: string) => props.setBrief?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={6}>
				<StyledTextAreaField
					label='Special Request'
					value={props.specialRequest || ''}
					onChange={(e: string) => props.setSpecialRequest?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={6}>
				<StyledTextAreaField
					label='Parameters Template'
					value={props.parametersTemplate || ''}
					onChange={(e: string) => props.setParametersTemplate?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={6}>
				<StyledTextAreaField
					label='Notes'
					value={props.notes || ''}
					onChange={(e: string) => props.setNotes?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
		</Grid>
	) : (
		<Grid
			container
			columns={12}
			columnSpacing={3}
			rowSpacing={4}
			direction='row'
			style={{ width: '98%' }}
		>
			{props.ViewPauseBox && props.status !== undefined && (
				<>
					<div
						style={{
							background: 'rgba(0,0,0,0.2)',
							width: '120vw',
							height: '200vh',
							display: 'flex',
							top: '-10%',
							left: '-10%',
							position: 'absolute',
							zIndex: '1',
						}}
					></div>
					<div
						style={{
							background: 'white',
							position: 'absolute',
							display: 'flex',
							top: '40%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							border: '1px solid black',
							borderRadius: '30px',
							flexDirection: 'column',
							zIndex: '10',
							height: '24%',
							width: '32%',
							padding: '2rem 4rem 2rem 4rem',
						}}
					>
						<p style={{ color: 'black', width: '110%', marginLeft: '-12px' }}>
							Please, note that disabling the advertiser will also disable all
							the campaigns connected to it.
						</p>
						<SubmitButton
							disabled={!permissions.edit}
							style={{ margin: 'auto' }}
							onClick={() => {
								props.setViewPauseBox?.(false)
							}}
						>
							Close
						</SubmitButton>
					</div>
				</>
			)}
			<Grid item xs={3}>
				<InputFieldText
					label='Company Name'
					placeholder='Company Name'
					type='text'
					value={props.name || ''}
					onChange={(e: string) => {
						setNameTouched(true)
						props.setName?.(e)
					}}
					disabled={!permissions.edit}
					errormessage={
						nameTouched && !stringIsValidName(props.name || '')
							? "Company name is mandatory. Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Legal Name'
					placeholder='Legal Name'
					type='text'
					value={props.legalName || ''}
					onChange={(e: string) => props.setLegalName?.(e)}
					disabled={!permissions.edit}
					mandatory={!props.fieldValidations.legalName}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Company Address'
					type='text'
					value={props.address || ''}
					onChange={(e: string) => props.setAddress?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={3 / 2}>
				<CountryListSelector
					label='Head Office Geo'
					options={countryList}
					value={getGeoValue(props.geo)}
					singular
					onChange={(e: any) => props.setGeo?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={3 / 2}>
				<DropList
					onChange={(e: any) => {
						props.setAdvertiserType?.(e)
					}}
					options={['Direct', 'Network', 'Agency']}
					label={'Type'}
					value={props.advertiserType || ''}
					disabled={!permissions.edit}
					noNull={true}
					mandatory={props.fieldValidations.advertiserType}
				/>
			</Grid>

			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setCommunicationChannel?.(e)}
					options={props.settings?.settings?.communicationChannel?.sort() || []}
					label={'Communication channel'}
					openWithClick
					value={props.communicationChannel || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>

			<Grid item xs={3}>
				<InputFieldText
					label='LinkedIn'
					type='text'
					value={props.linkedin || ''}
					onChange={(e: string) => props.setLinkedin?.(e)}
					color={
						props.linkedin &&
						!checkInputStartsWithHttp(props.linkedin) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.linkedin
							? !checkInputStartsWithHttp(props.linkedin)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>

			<Grid item xs={3}>
				<InputFieldText
					label='Website'
					type='text'
					value={props.website || ''}
					onChange={(e: string) => props.setWebsite?.(e)}
					color={
						props.website &&
						!checkInputStartsWithHttp(props.website) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.website
							? !checkInputStartsWithHttp(props.website)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setPlatforms?.(e)}
					options={props.settings?.settings?.platforms?.sort() || []}
					label={'Platforms'}
					errormessage={
						validateDirectType(props.advertiserType || '')
							? 'Direct-type advertisers must have only one platform'
							: undefined
					}
					openWithClick
					value={props.platforms || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setFraudTool?.(e)}
					options={props.settings?.settings?.fraudTools?.sort() || []}
					label={'Fraud Tool'}
					openWithClick
					value={props.fraudTool || []}
					disabled={!permissions.edit || props.advertiserType === 'Network'}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setVertical?.(e)}
					options={props.settings?.settings?.vertical?.sort() || []}
					label={'Vertical'}
					openWithClick
					value={props.vertical || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
					errored={
						props.advertiserType === 'Direct' &&
						props.vertical &&
						props.vertical.length === 0
					}
				/>
			</Grid>

			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setDataSource?.(e)}
					options={['Aggregated Data', 'Raw Data']}
					label={'Data Source'}
					openWithClick
					value={props.dataSource || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>

			{props.agencyAccess !== null && !IS_THING_OR_TWO && (
				<Grid item xs={3 / 2}>
					<DropList
						onChange={(e: any) => {
							props.setAgencyAccess?.(e === 'Allowed')
						}}
						options={['Allowed', 'Denied']}
						label={'Agency Access'}
						value={props.agencyAccess === true ? 'Allowed' : 'Denied'}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}
			{props.status === false && (
				<>
					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => props.setPauseReason?.(e)}
							options={props.settings?.settings?.pauseReason?.sort() || []}
							label={'Pause Reason'}
							value={props.pauseReason || ''}
							multiple={false}
							disabled={!permissions.edit}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Explanation'
							type='text'
							value={props.pauseExplanation || ''}
							onChange={(e: string) => props.setPauseExplanation?.(e)}
							disabled={!permissions.edit}
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12} style={{ width: '100%' }}>
				<AppsForAdvertiserSection
					appsData={appsData}
					setAppsData={setAppsData!}
					apps={props.apps}
					advertiserType={props.advertiserType}
					permissions={permissions}
				/>
			</Grid>

			<Grid item xs={6}>
				<StyledTextAreaField
					label='Brief'
					value={props.brief || ''}
					onChange={(e: string) => props.setBrief?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={6}>
				<StyledTextAreaField
					label='Special Request'
					value={props.specialRequest || ''}
					onChange={(e: string) => props.setSpecialRequest?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={6}>
				<StyledTextAreaField
					label='Parameters Template'
					value={props.parametersTemplate || ''}
					onChange={(e: string) => props.setParametersTemplate?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={6}>
				<StyledTextAreaField
					label='Notes'
					value={props.notes || ''}
					onChange={(e: string) => props.setNotes?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
		</Grid>
	)
}
