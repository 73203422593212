import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import {
	Autocomplete,
	Chip,
	MenuItem,
	TextField,
	Paper,
	useTheme,
	IconButton,
} from '@mui/material'
import { ErrorTag, StyledLabel } from './Informative'
import ReactCountryFlag from 'react-country-flag'
import React from 'react'
import { Public, Style } from '@mui/icons-material'
import {
	StyledAutocomplete,
	StyledInputBase,
	StyledTextInputField,
} from './Inputs'
import ClearIcon from '@mui/icons-material/Clear'
import { SubmitButton, UnborderedButton } from './Buttons'
import { ChipInputList } from './ChipInput'
import {
	DateRange,
	DateRangePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { UseSingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals'
import CloseIcon from '@mui/icons-material/Close'

export const OwnerSelect = (props: {
	onChange: any
	disabled?: boolean
	float?: 'right' | 'left'
	label: string
	value: any
	fontSize?: string
	multiple?: boolean
	placeholder?: string
	style?: any
}) => {
	const theme = useTheme()
	const { users } = useTypedSelector((state: any) => state)
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)
	const usersList: JSX.Element[] = users.users
		.filter((user: UserInterface) => !user.blocked)
		.map((user: UserInterface) => user.name)
	const getNameFromUserEmail = (email: string) => {
		const user = users.users.filter((el: UserInterface) => el.email === email)
		if (user[0]) {
			return user[0].name
		} else {
			return ''
		}
	}
	const handleChange = (event: any, value: any) => {
		const user = users.users.filter((el: UserInterface) => el.name === value)

		if (user.length > 0) props.onChange(user[0].email)
	}
	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
			color: theme.colors.text.titles,
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}

	return (
		<div
			style={{
				float: props.float,
				marginTop: '0px',
				color: theme.colors.text.titles,
			}}
		>
			<StyledLabel focused={focused}>{props.label}</StyledLabel>
			<Autocomplete
				style={{
					minHeight: '34px',
					width: '100%',
					background: 'transparent',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
					borderRadius: theme.dimentions.smallerRadius,
					color: theme.colors.text.general,
					...props.style,
					...responsiveStyle(),
				}}
				sx={{
					'& .MuiOutlinedInput-root': {
						border: 'none',
						padding: '0px 0px 0px 8px',
						minHeight: '34px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
						color: theme.colors.text.titles,
					},
					'& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded': {
						backgroundColor: 'red',
					},
					'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
						color: theme.colors.text.titles,
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
						color: theme.colors.text.titles,
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						minHeight: '34px',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
						color: theme.colors.text.titles,
					},
				}}
				options={usersList}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={props.placeholder}
						style={{ height: 'inherit', color: theme.colors.text.titles }}
					/>
				)}
				PaperComponent={({ children, ...paperProps }) => (
					<Paper
						{...paperProps}
						sx={{
							border: `1px solid ${theme.colors.base.grey300}`,
							padding: 1,
							color: theme.colors.text.titles,
							background: theme.colors.base.white,
						}}
					>
						{children}
					</Paper>
				)}
				onChange={handleChange}
				value={getNameFromUserEmail(props.value)}
				disabled={props.disabled}
				// onFocus={() => {
				// 	setFocused(true)
				// }}
				// onBlur={() => {
				// 	setFocused(false)
				// }}
				// onMouseEnter={() => {
				// 	setHovered(true)
				// }}
				// onMouseLeave={() => {
				// 	setHovered(false)
				// }}
			/>
		</div>
	)
}

export const DropList = React.memo(
	(props: {
		onChange: any
		disabled?: boolean
		float?: 'right' | 'left'
		options: string[]
		label: string
		value: any
		fontSize?: string
		multiple?: boolean
		noNull?: boolean
		noBackground?: boolean
		customColor?: string
		fontWeight?: string
		errored?: boolean
		style?: any
		autoCompleteStyle?: any
		errormessage?: any
		noClearOption?: boolean
		mandatory?: boolean
		placeholder?: string
	}) => {
		const theme = useTheme()
		const [inputValue, setInputValue] = useState(props.value || '')
		const [focused, setFocused] = useState(false)
		const [firstFocus, setFirstFocus] = useState(false)
		const [hovered, setHovered] = useState(false)
		const [isFocused, setIsFocused] = useState(false)
		const [interacted, setInteracted] = useState(false)

		useEffect(() => {
			if (props.value || props.value === null) {
				setInputValue(props.value)
			} else if (!props.value && props.noNull) {
				setInputValue('')
			}
		}, [props.value])
		const responsiveStyle = () => {
			const style: any = {
				border: '1px solid ' + theme.colors.base.grey300,
				borderRight: '6px solid ' + theme.colors.base.grey300,
			}
			if (props.disabled) {
				style.background = theme.colors.base.grey50
				return style
			}
			if (focused) {
				style.borderRight = '6px solid ' + theme.colors.base.green200
			}
			if (!props.value && hovered) {
				style.borderRight = '6px solid ' + theme.colors.base.green200
			}
			if (props.value && !props.errormessage) {
				style.borderRight = '6px solid ' + theme.colors.base.green200
			}
			if (props.errormessage && firstFocus) {
				style.borderRight = '6px solid ' + theme.colors.base.red300
			}
			return style
		}

		const handleInputChange = (e: any) => {
			const newValue = e.target.value
			setInputValue(newValue)
			props.onChange(newValue)
		}

		// Memoized renderInput to avoid re-renders
		const renderInput = useCallback(
			(params: any) => (
				<TextField
					{...params}
					value={inputValue}
					onChange={handleInputChange}
					style={{
						height: 'inherit',
						color: theme.colors.text.titles,
					}}
					placeholder={props.placeholder}
					InputProps={{
						...params.InputProps,
					}}
					onMouseOver={(e) => {
						e.preventDefault()
					}}
					onMouseOut={(e) => {
						e.preventDefault()
					}}
				/>
			),
			[inputValue],
		)

		return (
			<div
				style={{
					width: '100%',
					marginTop: '0px',
					position: 'relative',
					color: theme.colors.text.titles,
					...props.style,
				}}
			>
				<StyledLabel
					disabled={props.disabled}
					focused={focused}
					error={!!props.errormessage}
					style={{ display: 'flex', flexDirection: 'row' }}
				>
					<span>{props.label}</span>
					{props.mandatory && (
						<span
							style={{
								color: `${theme.colors.base.red300}`,
								fontSize: '0.8rem',
								fontWeight: 'bold',
							}}
						>
							*
						</span>
					)}
				</StyledLabel>
				{props.errormessage && firstFocus ? (
					<div
						style={{
							position: 'absolute',
							top: 29,
							right: 50,
							border: 'none',
							cursor: 'pointer',
							zIndex: '2',
						}}
					>
						<ErrorTag title={props.errormessage} />
					</div>
				) : null}
				<Autocomplete
					disableClearable={props.noClearOption}
					disabled={props.disabled}
					value={inputValue}
					onChange={(e, value) => {
						setInputValue(value)
						setInteracted(true)
						if (props.noNull && !value) {
							props.onChange('')
						} else {
							props.onChange(value)
						}
					}}
					style={{
						marginTop: '0px',
						minHeight: '34px',
						width: '100%',
						background: theme.colors.base.white,
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
						borderRadius: theme.dimentions.smallerRadius,
						color: theme.colors.text.general,
						...responsiveStyle(),
						...props.autoCompleteStyle,
					}}
					options={props.options}
					renderInput={renderInput}
					PaperComponent={({ children, ...paperProps }) => (
						<Paper
							{...paperProps}
							sx={{
								border: `1px solid ${theme.colors.base.grey300}`,
								padding: 1,
								zIndex: '30',
								color: theme.colors.text.titles,
								backgroundColor: theme.colors.base.white,
							}}
						>
							{children}
						</Paper>
					)}
					getOptionLabel={(option) => option?.toString() ?? ''}
					isOptionEqualToValue={(option, value) => {
						if (typeof option === 'object') {
							return (
								value === '' ||
								option.email.toLowerCase() ===
									(value && typeof value === 'string'
										? value.toLowerCase()
										: '')
							)
						} else {
							return (
								value === '' ||
								option.toLowerCase() ===
									(value && typeof value === 'string'
										? value.toLowerCase()
										: '')
							)
						}
					}}
					sx={{
						'& .MuiAutocomplete-endAdornment [title="Clear"]': {
							opacity: props.errormessage ? 0 : 1,
							color: theme.colors.text.titles,
						},
						'& .MuiOutlinedInput-root': {
							border: 'none',
							padding: '0px 0px 0px 8px',
							minHeight: '34px',
							fontWeight: theme.font.weight.normal,
							fontSize: theme.font.size.caption,
							color: theme.colors.text.titles,
						},
					}}
					onFocus={() => {
						setFirstFocus(true)
						setIsFocused(true)
					}}
					onBlur={() => setFocused(false)}
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
				/>
			</div>
		)
	},
)
const MemoizedPaperComponent = React.memo(
	({ children, ...paperProps }: any) => {
		const theme = useTheme()
		return (
			<Paper
				{...paperProps}
				sx={{
					border: `1px solid ${theme.colors.base.grey300}`,
					padding: 1,
					color: theme.colors.text.titles,
					backgroundColor: theme.colors.base.white,
				}}
			>
				{children}
			</Paper>
		)
	},
)
export const CountryListSelector = (props: {
	onChange?: any
	label?: string
	options?: any[]
	disabled?: boolean
	value?: string[]
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	float?: 'left' | 'right'
	style?: React.CSSProperties
	onBlur?: any
	loginRole?: string
	chipStyle?: React.CSSProperties
	noGlobal?: boolean
	mandatory?: boolean
}) => {
	const theme = useTheme()
	const [open, setOpen] = useState(false)
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (
			(list.length === 0 && value === 'GLOBAL') ||
			list.indexOf(value.substring(0, 2)) !== -1 ||
			list.indexOf(value.substring(0, 2).toLowerCase()) !== -1
		) {
			return {
				color: theme.colors.base.green100,
				fontSize: theme.font.size.body,
				width: '100%',
			}
		}
	}
	useEffect(() => {
		// Do nothing while typing, only reset inputValue when selection is made
		if (props.value && !open) {
			setInputValue('') // Reset filter input only after selection or menu close
		}
	}, [props.value, open])

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (props.disabled) {
			style.background = theme.colors.base.grey50
			return style
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value && props.value.length > 0) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (
			props.value?.includes(el.substring(0, 2)) ||
			props.value?.includes(el.substring(0, 2).toLowerCase())
		) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, 'GLOBAL', ...notSelectedOptions]

	const renderValueIfEmpty = useMemo(() => {
		// Ensure props.value is an array by defaulting to an empty array if it's not
		const valueArray = Array.isArray(props.value) ? props.value : []

		// Check if valueArray is empty or contains only an empty, null, or undefined value
		if (
			valueArray.length === 0 ||
			(valueArray.length === 1 && ['', null, undefined].includes(valueArray[0]))
		) {
			if (props.noGlobal) {
				return []
			}
			return ['GLOBAL'] // Return 'GLOBAL' if value is empty and noGlobal is not true
		}

		// Return the uppercase values of the array
		return valueArray.map((el: string) => el?.toUpperCase())
	}, [props.value, props.noGlobal])

	const optionsCovertor = (options: string) => {
		let option
		if (options === 'GLOBAL') option = options
		else option = options.length > 2 ? options.substring(0, 2) : options
		const checkedOptions = option === 'UK' ? 'GB' : option
		return checkedOptions
	}
	const renderOptionsWhenOpening = (propsd: any, options: any) => {
		const checkedOption = optionsCovertor(options)
		return checkedOption !== 'GLOBAL' ? (
			<li
				{...propsd}
				key={propsd.id}
				style={{ color: theme.colors.text.titles + ' !important' }}
			>
				<ReactCountryFlag
					style={{
						width: '2em',
						height: '2em',
						borderRadius: '30px',
						display: 'block',
					}}
					key={options + '_flags'}
					countryCode={checkedOption}
					svg
				/>
				<MenuItem
					key={options}
					value={options}
					sx={{
						fontSize: theme.font.size.body,
						color: theme.colors.text.titles,
						...StyleForSelectedOption(options, props.value || []),
						'&:hover': {
							color: theme.colors.base.green300,
						},
					}}
					disabled={props.disabled}
				>
					{options}
				</MenuItem>
			</li>
		) : (
			<></>
		)
	}
	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((options: any, index: number) => {
			const checkedOption = optionsCovertor(options)
			if (!open) {
				if (index <= 5) {
					return (
						<React.Fragment key={index + '-fragment-option'}>
							{index !== 5 ? (
								<Chip
									style={{
										maxHeight: '22px',
										color: theme.colors.text.titles,
										...props.chipStyle,
									}}
									icon={
										checkedOption !== 'GLOBAL' ? (
											<ReactCountryFlag
												style={{
													width: '1rem',
													height: '1rem',
													borderRadius: '30px',
												}}
												countryCode={checkedOption}
												svg
											/>
										) : (
											<Public style={{ scale: '0.7' }} />
										)
									}
									variant='outlined'
									label={options}
									deleteIcon={
										// checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>
										<ClearIcon
											style={{ scale: '0.7', color: theme.colors.text.titles }}
										/>
									}
									sx={{
										'& .MuiChip-label': {
											fontSize: theme.font.size.caption,
										},
									}}
									{...getTagProps({ index })}
									key={index + '-chip'}
								/>
							) : (
								<Chip
									key={index + '-chip'}
									style={{
										marginLeft: '18px',
										color: theme.colors.text.titles,
									}}
									onClick={() => {
										!props.disabled && setOpen(!open)
									}}
									variant='outlined'
									label={` + ${(value?.length || 5) - 5}`}
								/>
							)}
						</React.Fragment>
					)
				}
			} else {
				return (
					<Chip
						key={index + '-chip'}
						icon={
							checkedOption !== 'GLOBAL' ? (
								<ReactCountryFlag
									style={{
										width: '1rem',
										height: '1rem',
										borderRadius: '30px',
										color: theme.colors.text.titles,
									}}
									countryCode={checkedOption}
									svg
								/>
							) : (
								<Public />
							)
						}
						style={{
							maxHeight: '22px',
							color: theme.colors.text.titles,
						}}
						variant='outlined'
						label={options}
						// deleteIcon={checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>}
						deleteIcon={
							<ClearIcon
								style={{ scale: '0.7', color: theme.colors.text.titles }}
							/>
						}
						sx={{
							'& .MuiChip-label': {
								fontSize: theme.font.size.caption,
							},
						}}
						{...getTagProps({ index })}
					/>
				)
			}
		})
		return chips
	}
	const handleInputChange = useCallback((e: any) => {
		setInputValue(e.target.value)
	}, [])
	const renderInputField = (params: any) => (
		<>
			<StyledLabel style={{ display: 'flex' }}>
				{props.label}
				{props.mandatory && (
					<span
						style={{
							color: 'red',
							fontSize: '0.8rem',
							fontWeight: 'bold',
						}}
					>
						*
					</span>
				)}
			</StyledLabel>
			<StyledTextInputField
				{...params}
				focused
				label={undefined}
				margin='dense'
				fullWidth
				value={inputValue}
				onChange={handleInputChange}
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
					fontSize: theme.font.size.caption,
					...responsiveStyle(),
				}}
				InputProps={{
					...params.InputProps,
					style: {
						paddingLeft: '2px',
					},
				}}
				disabled={props.disabled}
				onClick={() => !props.disabled && setOpen(!open)}
			/>
		</>
	)
	const customFilter = (options: any, state: any) => {
		const geoCodeResult =
			state.inputValue.length <= 2
				? options.filter((el: any) => {
						return el
							.substring(0, 2)
							.toLowerCase()
							.includes(state.inputValue.toLowerCase())
				  })
				: []
		const countryResult = options.filter((el: any) => {
			return (
				el.toLowerCase().includes(state.inputValue.toLowerCase()) &&
				el.substring(0, 2).toLowerCase() !== state.inputValue.toLowerCase()
			)
		})
		return [...geoCodeResult, ...countryResult]
	}

	return (
		<StyledAutocomplete
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={open}
			options={orderedOptions || []}
			style={props.style}
			id='tags-filled'
			value={renderValueIfEmpty}
			onChange={(a: any, n: any) => {
				if (n[0] === 'GLOBAL') n.shift()
				if (n.includes('GLOBAL')) props.onChange([])
				else {
					let result: string[]
					if (props.singular && n.at(-1)) {
						result = [n.at(-1).substring(0, 2)]
					} else {
						result = n.map((el: string) => {
							return el.substring(0, 2) === 'GB' ? 'UK' : el.substring(0, 2)
						})
					}
					props.onChange(result)
					// setInputValue('')
				}
			}}
			PaperComponent={MemoizedPaperComponent}
			isOptionEqualToValue={(option: any, value: any) => {
				if (value === 'GLOBAL') return option === value
				return option.substring(0, 2) === value.substring(0, 2)
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onBlur={() => {
				setOpen(false)
				setFocused(false)
				return props.onBlur
			}}
			sx={{
				'& .MuiOutlinedInput-root': {
					border: 'none',
					padding: '0px 0px 0px 8px',
					minHeight: '34px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
					paddingRight: '1px !important',
					height: '34px',
					display: 'flex',
					overflowY: 'auto',
				},
				'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
					border: 'none',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					border: 'none',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
					minHeight: '34px',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiAutocomplete-tag': {
					maxWidth: '100%',
				},
				'& .MuiOutlinedInput-root .MuiAutocomplete-clearIndicator': {
					visibility: 'hidden',
				},
			}}
			filterOptions={customFilter}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
			// onFocus={() => {
			// 	setFocused(true)
			// }}
			// onMouseEnter={() => {
			// 	setHovered(true)
			// }}
			// onMouseLeave={() => {
			// 	setHovered(false)
			// }}
		/>
	)
}

export const FilterSearchComponent = (props: {
	setSearch: any
	search: string[]
	style?: any
	applyFilter?: any
	filterLabel?: any
	currentFilters?: any
	clearFilter?: any
	optionsFilters?: any
	setFilterHander?: any
	filtersKey?: any
	customFilter?: any
}) => {
	const theme = useTheme()
	let options: any = []
	for (const key in props.optionsFilters) {
		if (key === props.currentFilters) {
			if (typeof [...props.optionsFilters[key]][0] === 'string') {
				options = [...props.optionsFilters[key]].sort()
			} else if (
				typeof [...props.optionsFilters[key]][0] === 'object' &&
				!Array.isArray([...props.optionsFilters[key]][0])
			) {
				for (const element of props.optionsFilters[key]) {
					if (key === 'email' || key === 'publisher_manager') {
						options.push(element.name)
					} else {
						options.push(element[key])
					}
				}
				options.sort()
			} else if (Array.isArray([...props.optionsFilters[key]][0])) {
				for (const element of props.optionsFilters[key]) {
					for (const option of element) {
						if (!options.includes(option)) {
							options.push(option)
						}
					}
				}
				options = options.sort().filter((el: string) => el !== '')
			}
			// if (options)
			break
		}
	}

	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		}
	}, [props.currentFilters])
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		} else {
			props.setSearch([])
		}
	}, [])

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'inherit',
					width: '100%',
				}}
			>
				{options.length === 0 ? (
					<StyledInputBase
						placeholder={'Filter for ' + props.filterLabel}
						inputProps={{
							'aria-label': 'search',
						}}
						autoFocus
						onChange={(e: any) => {
							props.setSearch(e.target.value)
						}}
						value={props.search}
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								props.applyFilter()
								props.setFilterHander(false)
							}
						}}
					/>
				) : (
					<ChipInputList
						onChange={props.setSearch}
						options={options}
						value={props.search}
						openWithClick={true}
						style={
							{
								height: 'inherit',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '8px',
								width: 'inherit',
								// marginTop: '-25px',
							} as any
						}
						onlyOptionsAllowed
						noColoredBorder
					/>
				)}
			</div>
			<SubmitButton
				style={{
					fontSize: theme.font.size.caption,
					width: 60,
					height: 30,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					padding: '10px 34px',
				}}
				onClick={() => props.applyFilter()}
			>
				Apply
			</SubmitButton>
			<UnborderedButton
				style={{
					fontSize: '12px',
					border: 'none',
					boxShadow: 'none',
					textDecoration: 'solid underline 2px',
					textUnderlineOffset: '6px',
					marginLeft: '4px',
					paddingLeft: '0px',
					cursor: 'pointer',
				}}
				onClick={props.clearFilter}
				disabled={props.search.length === 0}
			>
				Clear
			</UnborderedButton>
		</>
	)
}

export const DateFilterSearchComponent = (props: {
	setSearch: any
	search: string[]
	style?: any
	applyFilter?: any
	filterLabel?: any
	currentFilters?: any
	clearFilter?: any
	setFilterHander?: any
	filtersKey?: any
	customFilter?: any
}) => {
	const theme = useTheme()
	const [value, setValue] = useState<any>(props.search ? props.search : null)

	// console.log(props.customFilter, props.filtersKey, props.search)
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		}
	}, [props.currentFilters])
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		} else {
			props.setSearch([])
		}
	}, [])
	// useEffect(() => {
	// 	if (value[0] && value[1] && props.customFilter) {
	// 		props.setSearch(value)
	// 	}
	// }, [value])

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'inherit',
					width: '100%',
				}}
			>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DateRangePicker
						value={value}
						onChange={(newValue) => {
							if (!newValue[1]) {
								const today = new Date() // Set today's date as end date
								newValue[1] = new Date(
									today.getFullYear(),
									today.getMonth(),
									today.getDate(),
								)
							}
							setValue(newValue)
							if (newValue[0] && newValue[1]) {
								props.setSearch(newValue)
							}
						}}
						renderInput={(startProps, endProps) => {
							const { value: startValue, ...restStartInputProps } =
								startProps.inputProps || {}
							const endValue = endProps?.inputProps?.value

							return (
								<StyledTextInputField
									{...startProps}
									label={null}
									inputProps={restStartInputProps}
									value={`${startValue || 'From'} - ${endValue || 'To'}`}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											props.applyFilter()
											props.setFilterHander(false)
										}
									}}
								/>
							)
						}}
					/>
				</LocalizationProvider>
			</div>
			<SubmitButton
				style={{
					fontSize: theme.font.size.caption,
					width: 60,
					height: 30,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					padding: '10px 34px',
				}}
				onClick={() => props.applyFilter()}
			>
				Apply
			</SubmitButton>
			<UnborderedButton
				style={{
					fontSize: '12px',
					border: 'none',
					boxShadow: 'none',
					textDecoration: 'solid underline 2px',
					textUnderlineOffset: '6px',
					marginLeft: '4px',
					paddingLeft: '0px',
					cursor: 'pointer',
				}}
				onClick={() => {
					setValue([])
					props.clearFilter()
				}}
				disabled={props.search && props.search.length === 0}
			>
				Clear
			</UnborderedButton>
		</>
	)
}
