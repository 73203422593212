import React from 'react'
import CompanyInformationHeader from '../CompanyInformationHeader'
import { StatusTitleTag } from '../Informative'
import defaultIcon from '../../../assets/default-icon.webp'
import SocialLinks from '../SocialLinksHeader'
import { PreviewIconsComponent } from '../PreviewComponents'

interface PreviewTitleInterface {
	name: string
	iconUrl: string
	id?: string | number
	status: boolean
	links?: any
	badges?: any
	easterEggShow?: boolean
}
const PreviewTitle = ({
	name,
	iconUrl,
	id,
	status,
	links,
	badges,
	easterEggShow = false,
}: PreviewTitleInterface) => {
	const showSocial = links
		? !Object.values(links).every((el) => el === undefined)
		: false

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				width: '95%',
				padding: '0rem 0rem',
				gap: 28,
			}}
		>
			<div
				style={{
					display: 'flex',
					marginLeft: '1.9rem',
					gap: 20,
				}}
			>
				<CompanyInformationHeader
					name={name}
					iconUrl={iconUrl || defaultIcon}
					id={id || ''}
					easterEggShow={easterEggShow}
				/>
				<StatusTitleTag status={status} />
			</div>
			<div
				style={{
					marginLeft: 'auto',
				}}
			>
				{showSocial && <SocialLinks links={links} />}
			</div>
			{badges && <PreviewIconsComponent icons={badges} label={'Badges'} />}
		</div>
	)
}

export default PreviewTitle
