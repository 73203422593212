import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { checkInputStartsWithHttp } from '../../../utils/helpers/tableHelper'
import { countryList } from '../../components/countryList'
import {
	formatDisplayValue,
	getApplicableRules,
	stringIsValidName,
} from '../../../utils/helpers/helperFuncs'
import moment from 'moment'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { InputFieldText } from '../../components/Inputs'
import {
	CountryListSelector,
	DropList,
	OwnerSelect,
} from '../../components/SelectableInputs'
import { ChipInputList } from '../../components/ChipInput'
import { Grid, IconButton, Typography, styled, useTheme } from '@mui/material'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { SwitchComponent } from '../../components/ToggleComponents'
import { ReadMoreInlineButton } from '../../components/Buttons'
import { CustomActionsMenu } from '../../components/Menus'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { THING_OR_TWO_COMPANY_ID } from '../../../config'

//this component will render and allow to edit / save a new publisher. It's the main file for all the inputs.
export const PublisherCompanyInfoComponent = (props: {
	handlePastOwnerFinance?: any
	name: string | null
	setName: any
	legalName: string
	setLegalName: any
	address: string
	setAddress: any
	geo: string[]
	setGeo: any
	linkToIO?: string
	setLinkToIO?: any
	setOwnerEmail?: any
	ownerEmail?: string | null
	setSalesRep?: any
	salesRep?: string
	setStatus?: any
	status?: boolean
	setPIDs: any
	pids: string[]
	notes: string
	setNotes: any
	skypeGroup: string
	setSkypeGroup: any
	freeText?: any
	setFreeText?: any
	linkToPubFile?: any
	setLinkToPubFile?: any
	isValid: boolean
	settings?: any
	loginRole?: string
	externalId?: any
	setExternalId?: any
	externalIdInUse: boolean
	setMainHeightChange?: any
	linkToLinkedin: any
	setLinkToLinkedin: any
	linkToWebsite: any
	setLinkToWebsite: any
	publicSkype: boolean
	setPublicSkype: any
	pastOwnerFinance?: any
	setPastOwnerFinance?: any
	users?: any
	mainCategory: string
	setMainCategory: Dispatch<SetStateAction<string>>
	pauseReason?: string
	setPauseReason?: Dispatch<SetStateAction<string>>
	pauseExplanation?: string
	setPauseExplanation?: Dispatch<SetStateAction<string>>
	setHandShakeEditorOpen?: Dispatch<SetStateAction<boolean>>
	setEditablePid?: Dispatch<SetStateAction<string>>
	mandatoryFields?: any
	permissions: any
	agencyAccess?: any
	setAgencyAccess?: any
	isCompanyOwner?: boolean
}) => {
	const { users, login, settings } = useTypedSelector((state) => state)
	//TODO: move permissions to parent
	const permissions = props.permissions
	const [filteredPIDs, setFilteredPIDs] = useState<any>([])
	const [dummiesPids, setDummiesPids] = useState<any>([])
	const [nameTouched, setNameTouched] = useState(false)

	const IS_THING_OR_TWO = login.company?.id === THING_OR_TWO_COMPANY_ID

	//we want to hide the dummiesPIDs from the user, that's why we filter them.
	useEffect(() => {
		if (props.externalId && props.externalId !== '') {
			const arr = []
			const dummiesArr = []
			for (const pid of props.pids) {
				if (!pid.endsWith(props.externalId.toString())) {
					arr.push(pid)
				} else {
					dummiesArr.push(pid)
				}
			}

			setFilteredPIDs(arr)
			setDummiesPids(dummiesArr)
		}
	}, [props.externalId])
	useEffect(() => {
		if (props.externalId) {
			const newArr = [...filteredPIDs, ...dummiesPids]
			props.setPIDs(newArr)
		}
	}, [filteredPIDs])

	const pidUpdateDeal = (e: any) => {
		setFilteredPIDs(e)
		props.setPIDs(e)
	}

	return (
		<Grid
			container
			columnSpacing={3}
			rowSpacing={4}
			direction='row'
			style={{
				display: 'flex',
				width: '98%',
			}}
			columns={12}
		>
			{/* Row 1 */}
			{props.isCompanyOwner ? (
				<>
					<Grid item xs={3}>
						<InputFieldText
							label='Company Name'
							type='text'
							value={props.name}
							color={
								nameTouched && props.name && !stringIsValidName(props.name)
									? 'error'
									: 'primary'
							}
							onChange={(e: any) => {
								setNameTouched(true)
								props.setName(e)
							}}
							disabled={!permissions.edit}
							errormessage={
								nameTouched && props.name && !stringIsValidName(props.name)
									? "Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
									: undefined
							}
							mandatory={props.mandatoryFields.name}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Legal Name'
							type='text'
							value={props.legalName}
							onChange={props.setLegalName}
							disabled={!permissions.edit}
							mandatory={props.mandatoryFields.name}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Address'
							type='text'
							value={props.address}
							onChange={props.setAddress}
							disabled={!permissions.edit}
						/>
					</Grid>
					<Grid item xs={1.5}>
						<CountryListSelector
							label='Head Office Geo'
							options={countryList}
							value={props.geo}
							singular
							onChange={(e: any) => props.setGeo(e)}
							disabled={!permissions.edit}
						/>
					</Grid>
					{!IS_THING_OR_TWO && (
						<Grid item xs={1.5}>
							<DropList
								label='Agency Access'
								options={['Allowed', 'Denied']}
								value={props.agencyAccess ? 'Allowed' : 'Denied'}
								onChange={(e: any) => props.setAgencyAccess(e === 'Allowed')}
								disabled={!permissions.edit}
							/>
						</Grid>
					)}
					<Grid item xs={1.5}>
						<DropList
							onChange={(e: any) =>
								props.setStatus(e === 'Active' ? true : false)
							}
							options={['Active', 'Disabled']}
							label='Status'
							placeholder='Select Status'
							value={props.status === true ? 'Active' : 'Disabled'}
							disabled={!permissions.edit}
						/>
					</Grid>

					{props.setOwnerEmail && (
						<Grid item xs={IS_THING_OR_TWO ? 3 : 1.5}>
							<OwnerSelect
								onChange={(e: any) => {
									if (props.handlePastOwnerFinance) {
										props.handlePastOwnerFinance(e)
									}
									props.setOwnerEmail(e)
								}}
								label='Owner'
								placeholder='Select an owner'
								value={props.ownerEmail}
								disabled={!permissions.edit}
							/>
						</Grid>
					)}
					{props.setSalesRep && (
						<Grid item xs={3}>
							<DropList
								onChange={(e: any) => props.setSalesRep(e)}
								options={props.settings.settings.salesRepresentative}
								label='Sales Representative'
								value={formatDisplayValue(props?.salesRep)}
								disabled={!permissions.edit}
							/>
						</Grid>
					)}
					<Grid item xs={3}>
						<InputFieldText
							label='Skype Group Link'
							type='text'
							value={props.skypeGroup}
							onChange={props.setSkypeGroup}
							disabled={!permissions.edit}
						/>
					</Grid>
					<Grid item xs={1.5}>
						<DropList
							onChange={(e: any) =>
								props.setPublicSkype(e === 'Open' ? true : false)
							}
							options={['Open', 'Private']}
							label='Skype Group'
							placeholder='Select Skype group'
							value={props.publicSkype === true ? 'Open' : 'Private'}
							disabled={!permissions.edit}
						/>
					</Grid>
					{props.setExternalId && (
						<Grid item xs={1.5}>
							<InputFieldText
								disabled={!permissions.edit}
								label='External ID'
								type='number'
								value={props.externalId}
								onChange={props.setExternalId}
								errormessage={
									props.externalIdInUse
										? 'This ID is already in use'
										: undefined
								}
							/>
						</Grid>
					)}

					{/* Row 3 */}
					{permissions.viewLinks && props.setLinkToPubFile && (
						<Grid item xs={3}>
							<InputFieldText
								label='Link to Publisher File'
								type='text'
								value={formatDisplayValue(props?.linkToPubFile, true)}
								onChange={props.setLinkToPubFile}
								disabled={!permissions.edit}
								showUrlIcon={true}
							/>
						</Grid>
					)}
					{permissions.viewLinks && (
						<Grid item xs={3}>
							<InputFieldText
								label='LinkedIn'
								type='text'
								value={props.linkToLinkedin}
								onChange={props.setLinkToLinkedin}
								disabled={!permissions.edit}
								showUrlIcon={true}
							/>
						</Grid>
					)}
					{permissions.viewLinks && (
						<Grid item xs={3}>
							<InputFieldText
								label='Website'
								type='text'
								value={props.linkToWebsite}
								onChange={props.setLinkToWebsite}
								disabled={!permissions.edit}
								showUrlIcon={true}
							/>
						</Grid>
					)}
					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => props.setMainCategory(e)}
							options={[
								'Direct App/Website',
								'Social Platform',
								'DSP',
								'In App Self-Serve',
								'Incent Self-Serve',
								'Incent Offerwall',
								'Native Ads Platform',
								'Phone Operator',
								'Ad Network',
								'External Media Buying',
							]}
							label='Main Category'
							value={props.mainCategory}
							disabled={!permissions.edit}
							placeholder='Select a category'
						/>
					</Grid>

					<Grid item xs={12}>
						<PublisherPIDsWrapper>
							<Grid
								item
								xs={12}
								style={{
									paddingLeft: '2rem',
									paddingRight: '1rem',
									width: '99%',
								}}
							>
								<ChipInputOverrideWrapper>
									<ChipInputList
										onChange={(newPids: string[]) => pidUpdateDeal(newPids)}
										label='PIDs'
										placeholder='Type PID'
										options={[]}
										value={filteredPIDs.length > 0 ? filteredPIDs : props.pids}
										disabled={!permissions.edit}
										forceTrim={true}
										setMainHeightChange={props.setMainHeightChange}
										clickableFunction={(option) => {
											if (
												props.setHandShakeEditorOpen &&
												props.setEditablePid
											) {
												props.setHandShakeEditorOpen(true)
												props.setEditablePid(option.toLowerCase().trim())
											}
										}}
									/>
								</ChipInputOverrideWrapper>
							</Grid>
						</PublisherPIDsWrapper>
					</Grid>
				</>
			) : (
				<>
					<Grid item xs={3}>
						<InputFieldText
							label='Company Name'
							type='text'
							value={props.name}
							color={
								nameTouched && props.name && !stringIsValidName(props.name)
									? 'error'
									: 'primary'
							}
							onChange={(e: any) => {
								setNameTouched(true)
								props.setName(e)
							}}
							disabled={!permissions.edit}
							errormessage={
								nameTouched && props.name && !stringIsValidName(props.name)
									? "Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
									: undefined
							}
							mandatory={props.mandatoryFields.name}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Legal Name'
							type='text'
							value={props.legalName}
							onChange={props.setLegalName}
							disabled={!permissions.edit}
							mandatory={props.mandatoryFields.name}
						/>
					</Grid>
					<Grid item xs={4}>
						<InputFieldText
							label='Address'
							type='text'
							value={props.address}
							onChange={props.setAddress}
							disabled={!permissions.edit}
						/>
					</Grid>
					<Grid item xs={2}>
						<CountryListSelector
							label='Head Office Geo'
							options={countryList}
							value={props.geo}
							singular
							onChange={(e: any) => props.setGeo(e)}
							disabled={!permissions.edit}
						/>
					</Grid>
					{permissions.viewLinks && (
						<Grid item xs={3}>
							<InputFieldText
								label='LinkedIn'
								type='text'
								value={props.linkToLinkedin}
								onChange={props.setLinkToLinkedin}
								disabled={!permissions.edit}
								showUrlIcon={true}
							/>
						</Grid>
					)}
					{permissions.viewLinks && (
						<Grid item xs={3}>
							<InputFieldText
								label='Website'
								type='text'
								value={props.linkToWebsite}
								onChange={props.setLinkToWebsite}
								disabled={!permissions.edit}
								showUrlIcon={true}
							/>
						</Grid>
					)}
					<Grid item xs={3}>
						<InputFieldText
							label='Skype Group Link'
							type='text'
							value={props.skypeGroup}
							onChange={props.setSkypeGroup}
							disabled={!permissions.edit}
						/>
					</Grid>
					<Grid item xs={1}>
						<DropList
							onChange={(e: any) =>
								props.setPublicSkype(e === 'Open' ? true : false)
							}
							options={['Open', 'Private']}
							label='Skype Group'
							placeholder='Select Skype group'
							value={props.publicSkype === true ? 'Open' : 'Private'}
							disabled={!permissions.edit}
						/>
					</Grid>

					<Grid item xs={2}>
						<DropList
							onChange={(e: any) => props.setMainCategory(e)}
							options={[
								'Direct App/Website',
								'Social Platform',
								'DSP',
								'In App Self-Serve',
								'Incent Self-Serve',
								'Incent Offerwall',
								'Native Ads Platform',
								'Phone Operator',
								'Ad Network',
								'External Media Buying',
							]}
							label='Main Category'
							value={props.mainCategory}
							disabled={!permissions.edit}
							placeholder='Select a category'
						/>
					</Grid>

					<Grid item xs={12}>
						<PublisherPIDsWrapper>
							<Grid
								item
								xs={12}
								style={{
									paddingLeft: '2rem',
									paddingRight: '1rem',
									width: '99%',
								}}
							>
								<ChipInputOverrideWrapper>
									<ChipInputList
										onChange={(newPids: string[]) => pidUpdateDeal(newPids)}
										label='PIDs'
										placeholder='Type PID'
										options={[]}
										value={filteredPIDs.length > 0 ? filteredPIDs : props.pids}
										disabled={!permissions.edit}
										forceTrim={true}
										setMainHeightChange={props.setMainHeightChange}
										clickableFunction={(option) => {
											if (
												props.setHandShakeEditorOpen &&
												props.setEditablePid
											) {
												props.setHandShakeEditorOpen(true)
												props.setEditablePid(option.toLowerCase().trim())
											}
										}}
									/>
								</ChipInputOverrideWrapper>
							</Grid>
						</PublisherPIDsWrapper>
					</Grid>
				</>
			)}
		</Grid>
	)
}
interface PublisherPIDsWrapperProps {
	children: React.ReactNode
	additionalStyle?: React.CSSProperties
}

const PublisherPIDsWrapper: React.FC<PublisherPIDsWrapperProps> = ({
	children,
	additionalStyle,
}) => {
	const theme = useTheme()

	return (
		<Grid
			container
			columns={12}
			rowSpacing={0}
			direction='column'
			style={{
				backgroundColor: theme.colors.preview.cardBackground,
				borderRadius: '15px',
				paddingBottom: '1.7rem',
				paddingTop: '1rem',

				marginTop: 0,
				marginLeft: 0,

				width: '100%',
				border: `1px solid ${theme.colors.preview.border}`,

				...additionalStyle,
			}}
		>
			{children}
		</Grid>
	)
}

const ChipInputOverrideWrapper = styled('div')(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		// Force the input container to allow multiple lines
		height: 'auto !important',
		minHeight: 'auto !important',
		overflow: 'visible !important',
		// Let the chips wrap to new lines rather than scroll horizontally
		flexWrap: 'wrap !important',
		display: 'flex !important',
	},
}))
