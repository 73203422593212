import React from 'react'
import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import driveIcon from '../../assets/drive-icon.svg'
import { Tooltip, useTheme } from '@mui/material'

interface SocialLinksProps {
	links: {
		website?: string
		linkedin?: string
		io?: string
		pub?: string
	}
}

const SocialLinks: React.FC<SocialLinksProps> = ({ links }) => {
	const theme = useTheme()
	const handleClickedLink = (url: string) => {
		window.open(url, '_blank')
	}
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '10px',
				background: theme.colors.base.white,
				border: '1px solid ' + theme.colors.preview.border,
				borderRadius: 40,
				textTransform: 'none',
				fontSize: theme.font.size.buttons,
				color: theme.colors.text.titles,
				padding: '0px 18px',
				height: 40,
			}}
		>
			<span>Social:</span>
			{links.website && (
				<Tooltip title={'Website'} arrow>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '0px',
							border: 'none',
							cursor: 'pointer',
							color: theme.colors.text.titles,
						}}
						onClick={() => links.website && handleClickedLink(links.website)}
					>
						<LanguageIcon
							style={{ borderBottom: `1px solid ${theme.colors.text.titles}` }}
						/>
					</button>
				</Tooltip>
			)}
			{links.linkedin && (
				<Tooltip title={'LinkedIn'} arrow>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '0px',
							border: 'none',
							cursor: 'pointer',
							color: theme.colors.text.titles,
						}}
						onClick={() => links.linkedin && handleClickedLink(links.linkedin)}
					>
						<LinkedInIcon
							style={{ borderBottom: `1px solid ${theme.colors.text.titles}` }}
						/>
					</button>
				</Tooltip>
			)}
			{/* {links.io && (
				<Tooltip title={'IO'} arrow>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '0px',
							border: 'none',
							cursor: 'pointer',
							color: theme.colors.text.titles,
						}}
						onClick={() => links.io && handleClickedLink(links.io)}
					>
						<FolderOpenIcon
							style={{ borderBottom: `1px solid ${theme.colors.text.titles}` }}
						/>
					</button>
				</Tooltip>
			)} */}
			{links.pub && (
				<Tooltip title={'Drive'} arrow>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '0px',
							border: 'none',
							cursor: 'pointer',
							color: theme.colors.text.titles,
							width: 21,
							height: 21,
						}}
						onClick={() => links.pub && handleClickedLink(links.pub)}
					>
						<img
							src={driveIcon}
							style={{
								marginTop: '3px',
								borderBottom: `1px solid ${theme.colors.text.titles}`,
							}}
							alt={'drive'}
						></img>
					</button>
				</Tooltip>
			)}
		</div>
	)
}

export default SocialLinks
