import { ThemeProvider } from '@emotion/react'
import {
	GridActionsCellItem,
	GridColDef,
	GridEditInputCell,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowModes,
	GridRowModesModel,
} from '@mui/x-data-grid-pro'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import '.././finance.css'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'

import { Typography, useTheme } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'

import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	extractPaymentLimit,
	formatDateToMonth,
	formatFinancePaymentDate,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../../utils/helpers/helperFuncs'
import {
	generateRowsWithIds,
	searchFor,
} from '../../../utils/helpers/tableHelper'
import { emptyFinance } from '../../../state/actions'
import {
	PageWrapper,
	StyledTextAreaField,
	TableWrapper,
} from '../../components/reusableComponents'
import { SubmitButton } from '../../components/Buttons'
import { TableComponent } from '../../components/TableComponents'
import Guidelines from '../Guidelines'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useActions, useFinanceActions } from '../../../hooks/useActions'
import AddCampaign from './AddCampaign'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { HeaderFilterComponent } from '../../components/TableHelperComponents'

const CreatorFinancePage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, advertiser, users, settings } = useTypedSelector(
		(state) => state,
	)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		delete: useIsAuthorized(permissionNames.DELETE_FINANCE),
		editDemand: useIsAuthorized(permissionNames.EDIT_DEMAND),
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const {
		getFinanceData,
		uploadFinanceDemand,
		downloadFinance,
		deleteFinanceCreatorCampaignRow,
	} = useFinanceActions()
	const sessionKey = 'filters-finance-demand'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const [showInvoices, setShowInvoices] = useState(false)
	const rowCount = showInvoices ? 15 : 6
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()
	const [proofToView, setProofToView] = useState<any>()
	const [openImagePreview, setOpenImagePreview] = useState(false)
	const [invoiceInstructions, setInvoiceInstructions] = useState<any>('')
	const [openInstructionsPreview, setOpenInstructionsPreview] = useState(false)
	const [uploadInvoiceTriggered, setUploadInvoiceTriggered] = useState(false)

	const [tableRows, setTableRows] = useState<any>(generateRowsWithIds([]))
	const [filterParams, setFilterParams] = useSearchParams()
	const param = filterParams.get('filter')
	const advertiserList = advertiser.advertiser.map(
		(el: any) => el.advertiser_name,
	)

	const [openFilter, setOpenFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [addOpen, setAddOpen] = useState(false)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					advertiser: [],
					account_manager: [],
					branch: [],
			  },
	)

	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		advertiser: [],
		account_manager: [],
		branch: [],
	})
	const [downloadTableRows, setDownloadTableRows] = useState(
		generateRowsWithIds(
			generateRowsByAdvertiser(filteredFinance, advertiser.advertiser),
		),
	)
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray ? sessionFilters.monthFilterArray : [],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [dataFetched, setDataFetched] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async (signal: AbortSignal) => {
		dispatch(emptyFinance())
		let i = 0
		let result
		const year = yearFilterArray
		const month = monthFilterArray

		do {
			if (signal.aborted) return
			result = await getFinanceData(
				'creator',
				props.errorMessage,
				login.user.email,
				200,
				i,
				undefined,
				{ year: year, month },
			)
			i++
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('demand')
		)
	}
	useEffect(() => {
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				financeFilterStatus,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter, monthFilterArray, yearFilterArray, financeFilterStatus])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		fetchData(signal)

		return () => controller.abort() // Cleanup previous requests
	}, [monthFilterArray, yearFilterArray])

	useEffect(() => {
		setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
		setTableRows(
			generateRowsWithIds(
				generateRowsByAdvertiser(finance.finance, advertiser.advertiser),
			),
		)
	}, [finance.finance])

	useEffect(() => {
		if (param) {
			const advertiserName = advertiserList.find(
				(el: string) => el.toLowerCase() === param,
			)
			customFilter.advertiser = [advertiserName]
			setApplyFilter(true)
		}
	}, [param])

	useEffect(() => {
		let stillFiltering = false
		for (const key in customFilter) {
			if (customFilter[key].length !== 0) {
				stillFiltering = true
				break
			}
		}
		if (!stillFiltering && window.location.search) {
			navigate('/finance/demand')
		}
	}, [applyFilter])
	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el: any) => el.length > 0),
				),
				account_manager: new Set(
					tableRows
						.map((element: any) => element.account_manager)
						.filter((el: any) => el && el.length > 0),
				),
				branch: new Set(
					tableRows
						.map((element: any) => element.branch)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])

	const possibleFinanceFilter = useMemo(
		() => ['All', 'Yes', 'No', 'On Hold'],
		[],
	)

	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered = finance.finance
		if (props.search.length > 0) {
			filtered = searchFor(props.search, finance.finance, columns)
		}
		//if the selected is "All" then our filter is already done
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, columns)
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'na',
				)
			}
		} else {
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'on hold',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'na',
				)
			}
		}

		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					if (key === 'branch') {
						foundByKey = customFilter[key].includes(
							data['internal_legal_entity'],
						)
					} else {
						foundByKey = customFilter[key].includes(data[key])
					}
					if (key === 'publisher_manager' || key === 'account_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				// console.log(year, yearFilterArray)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		setDownloadTableRows(
			generateRowsWithIds(generateRowsByAdvertiser(arr, advertiser.advertiser)),
		)
		let newRows = generateRowsByAdvertiser(arr, advertiser.advertiser)
		if (showInvoices) {
			newRows = generateRowsByInvoice(newRows)
		}
		arr = generateRowsWithIds(newRows)
		setTableRows(arr)
		setUnfiltered(arr)
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		props.search,
		applyFilter,
		financeFilterStatus,
		filteredFinance,
		showInvoices,
	])

	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const handlePreviewClick = (id: any) => async () => {
		props.setLoading(true)
		navigate('/finance/creator/' + id)
	}

	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const handleDeleteClick = (id: any) => async () => {
		const deleted = window.confirm(
			'Deleting this will remove the entire campaign from the database, including any rows within it. Are you sure?',
		)
		if (deleted) {
			let rowToRemove

			const arr = tableRows.filter((element: any) => {
				if (element.uuid !== id) {
					return true
				} else {
					rowToRemove = element
					return false
				}
			})
			setTableRows(arr)
			// console.log('to remove', rowToRemove)
			await deleteFinanceCreatorCampaignRow(
				rowToRemove,
				login.user.email,
				props.setErrorMessage,
			)
		}
	}
	useEffect(() => {
		setRowWidth(
			Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
		)
	}, [showInvoices])

	// const handleDownloadFinance = () => {
	// 	downloadFinance(
	// 		headers,
	// 		downloadTableRows,
	// 		'creator',
	// 		setFileDownload,
	// 		props.setErrorMessage,
	// 	)
	// }
	const handleRowStyle = (params: any) => {
		const row = params.row
		// console.log(row)
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		//if account manager wasn't found it means that the advertiser is not registered in Varys.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		if (row.account_manager === '' && !isInEditMode) {
			return 'demand-row-no-advertiser'
		}
		return ''
	}
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}
	let columns: GridColDef[] = [
		{
			field: 'campaign',
			headerName: 'Campaign',
			width: rowWidth,
			editable: false,
			type: 'singleSelect',
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Campaign'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'campaign'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderCell: (row) => {
				return (
					<Typography sx={{ fontSize: 14, fontWeight: '600' }}>
						{row.value}
					</Typography>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'advertiser',
			headerName: 'Advertiser',
			width: rowWidth,
			editable: false,
			type: 'singleSelect',
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Advertiser'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'advertiser'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderCell: (row) => {
				return (
					<Typography sx={{ fontSize: 14, fontWeight: '600' }}>
						{row.value}
					</Typography>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		// {
		// 	field: 'account_manager',
		// 	headerName: 'AM',
		// 	width: rowWidth * 1.3,
		// 	editable: false,
		// 	renderHeader: () => {
		// 		return (
		// 			<HeaderFilterComponent
		// 				label={'AM'}
		// 				width={rowWidth * 1.4}
		// 				openFilter={openFilter}
		// 				setOpenFilter={setOpenFilter}
		// 				field={'account_manager'}
		// 				customFilter={customFilter}
		// 				currentFilters={currentFilters}
		// 				setCurrentFilters={setCurrentFilters}
		// 				setFilterLabel={setFilterLabel}
		// 				optionsFilters={optionsFilters}
		// 			/>
		// 		)
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			editable: false,
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue',
			headerName: 'Total Revenue',
			headerAlign: 'center',
			width: rowWidth * 1.2,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			editable: true,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Edit',
			width: 80,
			cellClassName: 'actions',
			disableColumnMenu: true,
			getActions: (row) => {
				return [
					<GridActionsCellItem
						icon={
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									border: 'none',
									height: '28px',
									width: '28px',
									fontSize: '16px',
									color: theme.colors.text.titles,
									cursor: 'pointer',
								}}
								className={'finance-action-button'}
							>
								<VisibilityOutlinedIcon
									fontSize={'inherit'}
									style={{ color: 'inherit' }}
								/>
							</div>
						}
						label='Preview'
						className={'finance-action-button'}
						onClick={handlePreviewClick(row.row.uuid)}
						color='inherit'
					/>,
					permissions.delete ? (
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										height: '28px',
										width: '28px',
										fontSize: '16px',
										color: theme.colors.text.titles,
									}}
									className={'action-button'}
								>
									<DeleteIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={'action-button'}
									/>
								</div>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleDeleteClick(row.row.uuid)}
							color='inherit'
						/>
					) : (
						<></>
					),
				]
			},
		},
	]

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		if (
			newRow.invoice_number_advertiser &&
			!newRow.invoice_number_advertiser_added_time
		) {
			const formattedDate = moment().format('YYYY-MM-DDT00:00:00.000[Z]')
			newRow.invoice_number_advertiser_added_time = formattedDate
		} else if (
			!newRow.invoice_number_advertiser &&
			newRow.invoice_number_advertiser_added_time
		) {
			newRow.invoice_number_advertiser_added_time = ''
		}
		if (
			!newRow.final_confirmation_advertiser &&
			newRow.final_confirmation_advertiser !== 0
		) {
			newRow.final_confirmation_advertiser = ''
		}
		const updatedRow = { ...newRow, isNew: false }

		const failed = await uploadFinanceDemand(
			newRow,
			login.user.email,
			props.setErrorMessage,
		)
		if (updatedRow.payment_advertiser && updatedRow.proof) {
			updatedRow.proof = ''
		}
		if (
			updatedRow.final_confirmation_advertiser &&
			updatedRow.final_confirmation_advertiser_sent
		) {
			const element = tableRows.find((el: any) => el.uuid === updatedRow.uuid)
			if (element?.final_confirmation_advertiser_sent === true) {
				updatedRow.final_confirmation_advertiser_sent = false
			}
		}
		if (!failed) {
			setTableRows(
				tableRows.map((row: any) =>
					row.uuid === newRow.uuid ? updatedRow : row,
				),
			)
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
		}
		return updatedRow
	}
	const handleDoubleCellClick = React.useCallback((params: any, event: any) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params: any, event: any) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params: any, event: any) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])

	const guidelines = [
		{
			title: 'Campaign',
			explanation: 'Name of the Campaign. \nExample: Azar UGC.',
		},
		{
			title: 'Advertiser',
			explanation:
				'Name of your advertiser written in the same way that is written in Varys - Demand - Advertiser.',
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Total Revenue',
			explanation: 'Total payment from the advertiser for the entire campaign.',
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{openImagePreview && (
				<div
					style={{
						width: '75%',
						height: '75%',
						background: theme.colors.base.white,
						position: 'absolute',
						zIndex: '14',
						left: '10%',
						border: '1px solid rgba(0,0,0,0.2)',
						borderRadius: '12px',
						fontSize: '16px',
						color: 'black',
						padding: '40px',
						display: 'flex',
						overflowY: 'auto',
						boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
					key={'testdocviewer'}
				>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							borderRadius: '100px',
							height: '28px',
							width: '28px',
							fontSize: '16px',
							position: 'absolute',
							left: '94%',
							top: '2%',
							cursor: 'pointer',
							color: theme.colors.text.titles,
						}}
						onClick={() => {
							setOpenImagePreview(false)
							setProofToView(null)
						}}
					>
						<ClearIcon fontSize={'inherit'} />
					</button>
					<div
						style={{
							height: '100%',
							width: '100%',
							display: 'flex',
							overflowY: 'auto',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<DocViewer
							documents={[{ uri: proofToView }]}
							pluginRenderers={DocViewerRenderers}
						></DocViewer>
					</div>
					<SubmitButton
						onClick={() => {
							setOpenImagePreview(false)
							setProofToView(null)
						}}
						style={{
							width: 120,
							height: 30,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						Close
					</SubmitButton>
				</div>
			)}
			{openInstructionsPreview && (
				<div
					style={{
						width: '45%',
						height: '45%',
						background: theme.colors.gray,
						position: 'absolute',
						zIndex: '11',
						left: '20%',
						border: '1px solid rgba(0,0,0,0.2)',
						borderRadius: '12px',
						fontSize: '16px',
						color: 'black',
						padding: '40px',
						display: 'flex',
						overflowY: 'auto',
						boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							borderRadius: '100px',
							height: '28px',
							width: '28px',
							fontSize: '16px',
							position: 'absolute',
							left: '94%',
							top: '2%',
							cursor: 'pointer',
						}}
						onClick={() => {
							setOpenInstructionsPreview(false)
							setInvoiceInstructions('')
						}}
					>
						<ClearIcon fontSize={'inherit'} />
					</button>
					<StyledTextAreaField
						label={'Invoice Instructions'}
						value={invoiceInstructions}
						onChange={setInvoiceInstructions}
					/>
					<div
						style={{
							display: 'flex',
							width: '50%',
							justifyContent: 'space-around',
						}}
					>
						{
							<SubmitButton
								onClick={() => {
									setOpenInstructionsPreview(false)
									setUploadInvoiceTriggered(true)
								}}
								disabled={!(permissions.editDemand && permissions.edit)}
							>
								Update
							</SubmitButton>
						}
					</div>
				</div>
			)}
			{(infoOpened || addOpen) && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '110%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<PageWrapper>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
					></Guidelines>
				)}
				{addOpen && (
					<AddCampaign
						closeFunc={() => setAddOpen(false)}
						setErrorMessage={props.setErrorMessage}
					/>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						// downloadTemplate={handleDownloadFinance}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .demand-row-no-advertiser.MuiDataGrid-row:hover': {
								backgroundColor: 'rgba(252, 234, 235)',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
						}}
						searchWithTimer={true}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
						handlePopUp={useCallback(() => setAddOpen(!addOpen), [addOpen])}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

const generateRowsByAdvertiser = (inputRows: any, advertiserList: any) => {
	// console.log(inputRows)
	// console.log(advertiserList)
	// const rows = [...inputRows]
	// const result: any = []
	// const alreadyIn: string[] = []
	// for (const row of rows) {
	// 	let newRow: any = {}
	// 	if (
	// 		!alreadyIn.includes(
	// 			row.advertiser.toLowerCase().trim() +
	// 				moment(row.month, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 	) {
	// 		const currentAdvertiser = advertiserList.filter(
	// 			(adv: any) =>
	// 				adv.advertiser_name.toLowerCase().trim() ===
	// 				row.advertiser.toLowerCase().trim(),
	// 		)
	// 		newRow.advertiser =
	// 			currentAdvertiser.length > 0
	// 				? currentAdvertiser[0].advertiser_name
	// 				: row.advertiser
	// 		newRow.account_manager = row.account_manager
	// 		newRow.branch = row.internal_legal_entity

	// 		newRow.payment_limit = currentAdvertiser[0]
	// 			? extractPaymentLimit(currentAdvertiser[0].payment_terms)
	// 			: ''

	// 		newRow.uuid =
	// 			row.uuid +
	// 			'-demand-' +
	// 			moment(row.month, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 		newRow.month = row.month
	// 		newRow.invoice_instructions = row.invoice_instructions
	// 		newRow.proof = row.proof
	// 		newRow.currency = row.currency
	// 		newRow.invoice_number_advertiser_added_time_date =
	// 			row.invoice_number_advertiser_added_time
	// 				? formatDateToDdMmYyyy(row.invoice_number_advertiser_added_time)
	// 				: ''
	// 		newRow.final_confirmation_advertiser = row.final_confirmation_advertiser
	// 		newRow.advertiser_vat = row.advertiser_vat
	// 		newRow.final_with_vat =
	// 			Number(row.final_confirmation_advertiser) +
	// 			Number(row.final_confirmation_advertiser) *
	// 				(Number(row.advertiser_vat) / 100)
	// 		newRow.final_confirmation_advertiser_sent =
	// 			row.final_confirmation_advertiser_sent
	// 		newRow.invoice_number_advertiser = row.invoice_number_advertiser
	// 		newRow.invoice_number_advertiser_added_time =
	// 			row.invoice_number_advertiser_added_time
	// 		if (row.payment_advertiser) {
	// 			newRow.payment_advertiser = new Date(row.payment_advertiser)
	// 		} else {
	// 			newRow.payment_advertiser = ''
	// 		}
	// 		if (row.invoice_number_advertiser_added_time) {
	// 			newRow.invoice_number_advertiser_added_time_date = new Date(
	// 				row.invoice_number_advertiser_added_time,
	// 			)
	// 		} else {
	// 			newRow.invoice_number_advertiser_added_time_date = ''
	// 		}
	// 		newRow.total_invoices = row.revenue_converted
	// 		newRow.received_amount_advertiser = row.received_amount_advertiser
	// 		newRow.payment_method_advertiser = row.payment_method_advertiser
	// 		if (newRow.received_amount_advertiser) {
	// 			newRow.bank_fees_advertiser =
	// 				Number(newRow.final_with_vat) -
	// 				Number(newRow.received_amount_advertiser)
	// 		}
	// 		if (row.extra_invoices) {
	// 			for (const invoice of row.extra_invoices) {
	// 				newRow.total_invoices =
	// 					Number(newRow.total_invoices) + Number(invoice.revenue_converted)
	// 				if (invoice.payment_advertiser) {
	// 					invoice.payment_advertiser = new Date(invoice.payment_advertiser)
	// 				}
	// 			}
	// 		}
	// 		newRow.extra_invoices = row.extra_invoices
	// 		alreadyIn.push(
	// 			row.advertiser.toLowerCase().trim() +
	// 				moment(row.month, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 		result.push(newRow)
	// 	}
	// }
	// return result
	return inputRows
}

const generateRowsByInvoice = (inputRows: any) => {
	const rows = [...inputRows]
	const result = []
	for (const row of rows) {
		const newObj: any = {}
		newObj.advertiser = row.advertiser
		newObj.branch = row.branch
		newObj.account_manager = row.account_manager
		newObj.month = row.month
		newObj.invoice_number_advertiser = row.invoice_number_advertiser
		newObj.invoice_number_advertiser_added_time =
			row.invoice_number_advertiser_added_time
		newObj.invoice_number_advertiser_added_time_date =
			row.invoice_number_advertiser_added_time_date
		newObj.final_with_vat = row.final_with_vat
		newObj.payment_advertiser = row.payment_advertiser
		newObj.final_confirmation_advertiser = row.final_confirmation_advertiser
		newObj.currency = row.currency
		newObj.received_amount_advertiser = row.received_amount_advertiser
		newObj.payment_method_advertiser = row.payment_method_advertiser
		newObj.payment_limit = row.payment_limit
		if (newObj.received_amount_advertiser) {
			newObj.bank_fees_advertiser =
				Number(newObj.final_with_vat) -
				Number(newObj.received_amount_advertiser)
		}
		result.push(newObj)
		if (row.extra_invoices) {
			for (const invoice of row.extra_invoices) {
				const newInvoiceObj: any = {}
				newInvoiceObj.advertiser = row.advertiser
				newInvoiceObj.branch = row.branch
				newInvoiceObj.account_manager = row.account_manager
				newInvoiceObj.month = row.month
				newInvoiceObj.invoice_number_advertiser =
					invoice.invoice_number_advertiser
				newInvoiceObj.invoice_number_advertiser_added_time =
					invoice.invoice_number_advertiser_added_time
				newInvoiceObj.invoice_number_advertiser_added_time_date =
					invoice.invoice_number_advertiser_added_time_date
				newInvoiceObj.final_with_vat = invoice.final_with_vat
				newInvoiceObj.payment_advertiser = invoice.payment_advertiser
				newInvoiceObj.final_confirmation_advertiser =
					invoice.final_confirmation_advertiser
				newInvoiceObj.currency = invoice.currency
				newInvoiceObj.received_amount_advertiser =
					invoice.received_amount_advertiser
				newInvoiceObj.payment_method_advertiser =
					invoice.payment_method_advertiser
				newInvoiceObj.payment_limit = row.payment_limit
				if (newInvoiceObj.received_amount_advertiser) {
					newInvoiceObj.bank_fees_advertiser =
						Number(newInvoiceObj.final_with_vat) -
						Number(newInvoiceObj.received_amount_advertiser)
				}
				result.push(newInvoiceObj)
			}
		}
	}

	return result
}

export default CreatorFinancePage
