import React, { useState, useEffect } from 'react'
import {
	Grid,
	Box,
	Typography,
	useTheme,
	IconButton,
	Tooltip,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { ReactComponent as LockIcon } from '../../../assets/svg/lock-password-icon.svg'
import { sha256 } from 'js-sha256'

import { EditableGridItem, PreviewInfoTag } from '../PreviewComponents'
import { UnborderedButton } from '../Buttons'
import CustomModal from '../CustomModal'
import { InputFieldText } from '../Inputs'

import { decryptMessageForDataSplit } from '../../../crypto/cryptoUtils'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { canAccessResource } from '../../../utils/helpers/helperFuncs'
import { useUserActions } from '../../../hooks/useActions'
export interface PasswordInterface {
	username: string
	url: string
	password: string
	whiteList: string[]
}

interface PasswordsPreviewProps {
	passwords: PasswordInterface[]
	ownerEmail: string
	salesRep?: string
	isReadOnly?: boolean
	setErrorMessage?: (err: string | null) => void
	additionalStyle?: React.CSSProperties
	showMoreClick?: (value: any, title: string) => void
}

export const PasswordsPreviewComponent: React.FC<PasswordsPreviewProps> = ({
	passwords,
	ownerEmail,
	salesRep,
	isReadOnly = false,
	setErrorMessage,
	additionalStyle,
	showMoreClick,
}) => {
	const theme = useTheme()
	const { login, users } = useTypedSelector((state) => state)

	const pubPasswordPermission = useIsAuthorized(
		permissionNames.VIEW_PUBLISHER_PASSWORDS,
	)
	const advPasswordsPermission = useIsAuthorized(
		permissionNames.VIEW_ADVERTISER_PASSWORDS,
	)
	const canViewContactsAndPasswords =
		canAccessResource(login.user, ownerEmail, users, salesRep) ||
		pubPasswordPermission ||
		advPasswordsPermission

	const visiblePasswords = passwords.filter((pw) => {
		return (
			canViewContactsAndPasswords || pw.whiteList?.includes(login.user.name)
		)
	})

	if (!visiblePasswords.length) {
		return null
	}

	return (
		<Grid
			container
			direction='column'
			sx={{
				backgroundColor: theme.colors.preview.cardBackground,
				borderRadius: '15px',
				padding: '1rem',
				width: '95%',
				// marginBottom: '1rem',
				border: `1px solid ${theme.colors.preview.border}`,
				...additionalStyle,
			}}
		>
			<Grid item xs={12}>
				<Box
					display='flex'
					alignItems='center'
					sx={{
						marginLeft: '1.5rem',
						marginBottom: '1rem',
						paddingTop: '1rem',
					}}
				>
					<LockIcon style={{ width: 24, height: 24 }} />
					<Typography
						variant='body1'
						sx={{
							color: theme.colors.text.titles,
							fontWeight: theme.font.weight.bold,
							fontSize: '1rem',
						}}
					>
						Passwords
					</Typography>
				</Box>
			</Grid>

			<Grid item xs={12}>
				{visiblePasswords.map((pw, idx) => (
					<PasswordPreviewRow
						key={`pw-preview-${idx}`}
						passwordItem={pw}
						setErrorMessage={setErrorMessage}
						showMoreClick={showMoreClick}
					/>
				))}
			</Grid>
		</Grid>
	)
}

interface PasswordPreviewRowProps {
	passwordItem: PasswordInterface
	setErrorMessage?: (err: string | null) => void
	showMoreClick?: (value: any, title: string) => void
}

const PasswordPreviewRow: React.FC<PasswordPreviewRowProps> = ({
	passwordItem,
	setErrorMessage,
	showMoreClick,
}) => {
	const theme = useTheme()
	const { login } = useTypedSelector((state) => state)
	const { showPasswordAction } = useUserActions()

	// local states
	const [showPassword, setShowPassword] = useState(false)
	const [decrypted, setDecrypted] = useState(false)
	const [popUpVisible, setPopUpVisible] = useState(false)
	const [codeHash, setCodeHash] = useState('')
	const [actualPassword, setActualPassword] = useState('********')
	const fixedPasswordMask = 8

	const [isHovered, setIsHovered] = useState(false)

	const [code, setCode] = useState('')
	const [isValidCode, setIsValidCode] = useState(false)

	useEffect(() => {
		if (sha256(code) === codeHash) {
			setIsValidCode(true)
		} else {
			setIsValidCode(false)
		}
	}, [code, codeHash])

	const decryptPasswordIfNeeded = async () => {
		try {
			if (!decrypted && passwordItem.password.includes(':')) {
				const dec = await decryptMessageForDataSplit(passwordItem.password)
				passwordItem.password = dec
				setDecrypted(true)
				setActualPassword(dec)
			} else {
				setActualPassword(passwordItem.password)
			}
		} catch (primaryErr) {
			console.error('Primary decrypt failed:', primaryErr)
			try {
				const fallback = await decryptMessageForDataSplit(passwordItem.password)
				passwordItem.password = fallback
				setDecrypted(true)
				setActualPassword(fallback)
			} catch (fallbackErr) {
				console.error('Fallback decrypt failed:', fallbackErr)
			}
		}
	}

	const handleSendCode = async () => {
		await showPasswordAction(login.user.email, setCodeHash)
		setPopUpVisible(true)
	}

	const handleConfirmCode = async () => {
		setPopUpVisible(false)
		setShowPassword(true)
		await decryptPasswordIfNeeded()
	}

	const handleTogglePassword = () => {
		if (showPassword) {
			setShowPassword(false)
			setActualPassword('********')
		} else {
			handleSendCode()
		}
	}
	const bulletMask = '•'.repeat(fixedPasswordMask)
	const displayVPasswordalue = showPassword ? actualPassword : bulletMask

	return (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(5, 1fr)',
					columnGap: '1rem',
					rowGap: '0.5rem',
					width: '100%',
					marginBottom: '1rem',
					padding: '1rem',
					borderRadius: 10,
					background: theme.colors.preview.cardBackground,
					gridAutoRows: 62,
					gap: '10px',
				}}
			>
				<PreviewInfoTag
					label='URL'
					value={passwordItem.url}
					showMoreClick={showMoreClick ? showMoreClick : () => {}}
					gridColumnSpan={2}
				/>

				<PreviewInfoTag
					label='Username'
					value={passwordItem.username}
					showMoreClick={showMoreClick ? showMoreClick : () => {}}
				/>

				<PreviewInfoTag
					label='Password'
					value={displayVPasswordalue}
					showMoreClick={() => {}}
				/>

				<div
					style={{
						alignItems: 'flex-end',
						display: 'flex',
					}}
				>
					<Tooltip
						title='Click to receive a verification code via email. Once entered, the password will be visible.'
						arrow
					>
						<Box
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							sx={{
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
								justifyContent: 'flex-start',
								marginBottom: '4px',
							}}
						>
							<IconButton
								onClick={handleTogglePassword}
								sx={{
									backgroundColor: 'transparent',
								}}
							>
								{showPassword ? (
									<VisibilityOffIcon
										style={{
											color: theme.colors.text.titles,
											fontSize: '1rem',
										}}
									/>
								) : (
									<VisibilityIcon
										style={{
											color: theme.colors.text.titles,
											fontSize: '1rem',
										}}
									/>
								)}
							</IconButton>

							<UnborderedButton
								style={{
									color: theme.colors.text.titles,
									whiteSpace: 'nowrap',
								}}
								onClick={handleTogglePassword}
							>
								{showPassword ? 'Hide Password' : 'Show Password'}
							</UnborderedButton>
						</Box>
					</Tooltip>
				</div>
			</div>

			{popUpVisible && (
				<CustomModal
					open={popUpVisible}
					onClose={() => setPopUpVisible(false)}
					title='Enter Code'
					maxWidth='xs'
					fullWidth
					styles={{ padding: '0rem' }}
				>
					<Box
						sx={{
							backgroundColor: theme.colors.base.white,
							color: theme.colors.text.titles,
							padding: '1rem 2rem',
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem',
						}}
					>
						<Typography>
							A verification code has been sent to your email. Please enter it
							below to reveal the password.
						</Typography>

						<InputFieldText
							label='Code'
							hint='Check your email'
							type='number'
							value={code}
							onChange={setCode}
							mandatory
						/>

						<Box
							display='flex'
							justifyContent='center'
							gap='2rem'
							sx={{ marginTop: '1rem' }}
						>
							<UnborderedButton onClick={() => setPopUpVisible(false)}>
								Cancel
							</UnborderedButton>
							<UnborderedButton
								onClick={handleConfirmCode}
								disabled={!isValidCode}
								style={{
									opacity: !isValidCode ? 0.5 : 1,
									cursor: !isValidCode ? 'not-allowed' : 'pointer',
								}}
							>
								Confirm
							</UnborderedButton>
						</Box>
					</Box>
				</CustomModal>
			)}
		</>
	)
}
