import React, { useState } from 'react'
import { Grid, Typography, Tooltip, useTheme, Box } from '@mui/material'
import { ReactComponent as LockIcon } from '../../../assets/svg/lock-password-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'
import AddIcon from '@mui/icons-material/Add'

import { InputFieldText } from '../Inputs'
import { UnborderedButton, IconCircleWrapper } from '../Buttons'
import CustomModal from '../CustomModal'
import EditPasswordContent from './EditPasswordContent'

import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { canAccessResource } from '../../../utils/helpers/helperFuncs'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { SectionTitle } from '../Informative'

export interface PasswordInterface {
	username: string
	url: string
	password: string
	whiteList: string[]
}

interface PasswordsComponentProps {
	passwords: PasswordInterface[]
	setPasswords: React.Dispatch<React.SetStateAction<PasswordInterface[]>>
	handleNewPassword: (pw: PasswordInterface) => void
	loginRole: string
	setErrorMessage: (err: string | null) => void
	setDeletedPasswordList?: (deleted: PasswordInterface[]) => void
	ownerEmail: string
	salesRep?: string
	isReadOnly?: boolean
}

export const PasswordsComponent: React.FC<PasswordsComponentProps> = ({
	passwords,
	setPasswords,
	handleNewPassword,
	loginRole,
	setErrorMessage,
	setDeletedPasswordList,
	ownerEmail,
	salesRep,
	isReadOnly = false,
}) => {
	const theme = useTheme()
	const { login, users } = useTypedSelector((state) => state)

	// Permissions checks
	const pubPasswordPermission = useIsAuthorized(
		permissionNames.VIEW_PUBLISHER_PASSWORDS,
	)
	const advPasswordsPermission = useIsAuthorized(
		permissionNames.VIEW_ADVERTISER_PASSWORDS,
	)
	const canViewContactsAndPasswords =
		canAccessResource(login.user, ownerEmail, users, salesRep) ||
		pubPasswordPermission ||
		advPasswordsPermission

	const [isEditModalOpen, setIsEditModalOpen] = useState(false)
	const [editPassword, setEditPassword] = useState<PasswordInterface | null>(
		null,
	)

	const [hoverAddPassword, setHoverAddPassword] = useState(false)

	const [showFullPassword, setShowFullPassword] = useState<boolean[]>([])

	const handleAddPassword = () => {
		const blankPw: PasswordInterface = {
			username: login.user.email || '',
			url: '',
			password: '',
			whiteList: [],
		}
		setEditPassword(blankPw)
		setIsEditModalOpen(true)
	}

	const openEditModal = (pw: PasswordInterface) => {
		setEditPassword(pw)
		setIsEditModalOpen(true)
	}

	const closeEditModal = () => {
		setIsEditModalOpen(false)
		setEditPassword(null)
	}

	const handleSavePassword = (newPassword: PasswordInterface) => {
		const updated = [...passwords]
		if (editPassword) {
			const idx = updated.indexOf(editPassword)
			if (idx > -1) {
				updated[idx] = newPassword
			} else {
				updated.push(newPassword)
			}
		} else {
			updated.push(newPassword)
		}
		setPasswords(updated)
		closeEditModal()
	}

	const handleDelete = (index: number) => {
		const updated = passwords.filter((_, i) => i !== index)
		setPasswords(updated)
		if (setDeletedPasswordList) {
			setDeletedPasswordList([passwords[index]])
		}
	}

	const toggleShowPassword = (idx: number) => {
		const copy = [...showFullPassword]
		copy[idx] = !copy[idx]
		setShowFullPassword(copy)
	}

	const PasswordList = passwords.map((p, index) => {
		const hasAccess =
			canViewContactsAndPasswords || p.whiteList?.includes(login.user.name)
		if (!hasAccess) return null

		return (
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(12, 1fr)',
					padding: 10,
					marginRight: 0,
					columnGap: 30,
				}}
			>
				<div style={{ gridColumn: 'span 3' }}>
					<InputFieldText label='URL' type='text' value={p.url} disabled />
				</div>

				<div style={{ gridColumn: 'span 3' }}>
					<InputFieldText
						label='Username'
						type='text'
						value={p.username}
						disabled
					/>
				</div>

				<div style={{ gridColumn: 'span 3' }}>
					<InputFieldText
						label='Password'
						type='password'
						value={showFullPassword[index] ? p.password : '********'}
						onFocus={() => toggleShowPassword(index)}
						onBlur={() => toggleShowPassword(index)}
						disabled
					/>
				</div>
				<div
					style={{
						gridColumn: 'span 2',
						display: 'flex',
						justifyContent: 'flex-end',
						flexDirection: 'row',
						alignItems: 'flex-end',
						gap: 12,
					}}
				>
					<Tooltip title='Edit'>
						<>
							<IconCircleWrapper
								size={32}
								circleColor={theme.colors.base.white}
								iconColor={theme.colors.text.primary}
								hoverCircleColor={theme.colors.base.grey300}
								hoverIconColor={theme.colors.text.secondary}
							>
								<EditIcon
									style={{
										cursor: isReadOnly ? 'not-allowed' : 'pointer',
										width: '16px',
										height: '16px',
									}}
									onClick={() => {
										if (!isReadOnly) openEditModal(p)
									}}
								/>
							</IconCircleWrapper>
						</>
					</Tooltip>

					<Tooltip title='Delete'>
						<>
							<IconCircleWrapper
								size={32}
								circleColor={theme.colors.base.white}
								iconColor={theme.colors.text.primary}
								hoverCircleColor={theme.colors.base.grey300}
								hoverIconColor={theme.colors.text.titles}
							>
								<DeleteIcon
									style={{
										cursor: isReadOnly ? 'not-allowed' : 'pointer',
										width: '16px',
										height: '16px',
									}}
									onClick={() => {
										if (!isReadOnly) handleDelete(index)
									}}
								/>
							</IconCircleWrapper>
						</>
					</Tooltip>
				</div>
			</div>
		)
	})

	return (
		<>
			<Grid
				container
				direction='column'
				sx={{
					backgroundColor: theme.colors.preview.cardBackground,
					borderRadius: '15px',
					paddingLeft: '1rem',
					width: 'calc(99% - 2.5rem)',
					paddingBottom: '2rem',
					paddingTop: '1rem',
					marginTop: '1rem',
					border: `1px solid ${theme.colors.preview.border}`,
				}}
			>
				<Grid item xs={12}>
					<Box
						display='flex'
						alignItems='center'
						sx={{ mb: 1, ml: 1.5, mt: 0.8 }}
					>
						<LockIcon
							style={{
								width: 24,
								height: 24,
								marginRight: '0.2rem',
								marginBottom: '0.1rem',
							}}
						/>
						<SectionTitle text='Create Password' marginTop='0.1rem' />
					</Box>
				</Grid>

				<Grid item xs={12} sx={{ ml: 2 }}>
					{PasswordList}
				</Grid>

				<Grid item xs={12} sx={{ mt: 1, ml: 1.5 }}>
					<Box display='flex' alignItems='center'>
						<UnborderedButton
							onClick={handleAddPassword}
							onMouseEnter={() => setHoverAddPassword(true)}
							onMouseLeave={() => setHoverAddPassword(false)}
							icon={
								<AddIcon
									style={{
										border: `1px solid ${theme.colors.base.grey900}`,
										borderRadius: '100px',
										width: '16px',
										height: '16px',
										backgroundColor: hoverAddPassword
											? theme.colors.base.grey200
											: 'transparent',
									}}
								/>
							}
							label='Add Password'
							style={{
								textDecoration: 'underline',
								color: hoverAddPassword
									? theme.colors.base.green300
									: theme.colors.text.titles,
								fontSize: theme.font.size.caption,
							}}
						/>
					</Box>
				</Grid>
			</Grid>

			{isEditModalOpen && editPassword && (
				<CustomModal
					open={isEditModalOpen}
					onClose={closeEditModal}
					title={editPassword.url ? 'Edit Password' : 'Add Password'}
					maxWidth='md'
					fullWidth
					PaperProps={{
						sx: { overflow: 'visible' },
					}}
				>
					<EditPasswordContent
						passwordData={editPassword}
						onSave={handleSavePassword}
						onClose={closeEditModal}
						setErrorMessage={setErrorMessage}
					/>
				</CustomModal>
			)}
		</>
	)
}
