// src/components/TasksPage.tsx
import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Typography, Alert } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
	PreviewContainer,
	EditableGridItem,
	PreviewInfoTag,
} from '../components/PreviewComponents'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton } from '../components/Buttons'
import { useTasksActions } from '../../hooks/useActions'

interface Task {
	id: string
	name: string
}

const TasksPage: React.FC = () => {
	const theme = useTheme()
	const { getTasksAction, executeTaskAction } = useTasksActions()
	const [tasks, setTasks] = useState<Task[]>([])
	const [selectedTask, setSelectedTask] = useState<string>('')
	const [status, setStatus] = useState<string>('')

	useEffect(() => {
		const fetchTasks = async () => {
			try {
				await getTasksAction(setTasks)
				// setTasks(data)
			} catch (err) {
				console.error('Error fetching tasks:', err)
				setStatus('Failed to fetch tasks.')
			}
		}

		fetchTasks()
	}, [])

	const handleTaskChange = (e: any) => {
		try {
			setSelectedTask(e)
			if (e) {
				setStatus('Task Selected')
			} else {
				setStatus('')
			}
		} catch (err) {
			console.error('Error in DropList onChange handler:', err)
			setStatus('Error selecting task.')
		}
	}

	const handleExecute = async () => {
		try {
			if (!selectedTask) {
				setStatus('Please select a task to execute.')
				return
			}
            await executeTaskAction(selectedTask, setStatus)
		} catch (err: any) {
			console.error('Error executing task:', err)
			setStatus('Error executing task.')
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				padding: 2,
			}}
		>
			<Box
				sx={{
					width: '70%',
					maxWidth: '90vw',
					padding: '40px 80px 0 0',
					marginLeft: '-4rem',
				}}
			>
				<Typography variant='h4' component='h1' gutterBottom>
					Tasks Page
				</Typography>
				<Card
					sx={{
						background: theme.palette.background.paper,
						borderRadius: 6,
						padding: 2,
						overflow: 'hidden',
					}}
				>
					<CardContent>
						<PreviewContainer
							gridTemplate='repeat(5, 1fr)'
							additionalStyle={{ height: '100%' }}
						>
							<EditableGridItem gridColumnSpan={2}>
								<DropList
									onChange={handleTaskChange}
									options={tasks.map((task) => task.name)}
									label='Select Task'
									value={selectedTask}
									disabled={false}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={1}>
								<SubmitButton
									onClick={handleExecute}
									style={{ marginTop: '25px', marginLeft: '35px' }}
									disabled={!selectedTask}
								>
									Execute
								</SubmitButton>
							</EditableGridItem>
							<PreviewInfoTag
								label='Task Status'
								value={status}
								gridColumnSpan={2}
								showMoreClick={() => {}}
							></PreviewInfoTag>
						</PreviewContainer>
					</CardContent>
				</Card>
			</Box>
		</Box>
	)
}

export default TasksPage
