import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Box, Grid, Modal, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageWrapper } from '../../components/reusableComponents'
import ReactCountryFlag from 'react-country-flag'
import { Property } from 'csstype'
import {
	decryptContacts,
	getApplicableRules,
	getCompanyAgencyAccess,
} from '../../../utils/helpers/helperFuncs'
import { UnborderedButton } from '../../components/Buttons'
import { StatusTitleTag, TitleTag } from '../../components/Informative'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as GoodQualityIcon } from '../../../assets/svg/medal-icon.svg'
import { ReactComponent as ChatIcon } from '../../../assets/svg/chat-icon.svg'
import { ReactComponent as TiktokIcon } from '../../../assets/svg/tiktok-logo.svg'
import { ReactComponent as YoutubeIcon } from '../../../assets/svg/youtube-logo.svg'
import { ReactComponent as LinkedinIcon } from '../../../assets/svg/linkedin-logo.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/svg/instagram-logo.svg'
import CompanyInformationHeader from '../../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { useShowMoreActions } from '../../../hooks/useActions'
import { ContactInterface } from '../../../models/contact.interface'
import CustomContactsComponent from '../../components/contacts/CustomContactsComponent'
import {
	PreviewArrayInfoTag,
	PreviewContainer,
	PreviewGridInfoTag,
	PreviewIconsComponent,
	PreviewInfoTag,
	PreviewIOExpirationTag,
} from '../../components/PreviewComponents'
import { ContractsInterface } from '../../../models/model.interface'
import DocumentPreview from '../../components/DocumentPreview'
import IOsModal from '../../components/IOsComponent'
import { parse } from 'date-fns'
import PreviewTitle from '../../components/preview/PreviewTitle'

const CreatorPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	viewRecord: any
	setViewRecord: any
	PopUpVisible: any
	setPopUpVisible: any
	isCompanyPersonalView?: boolean
	companyData?: any
}) => {
	const theme = useTheme()
	const { creator, users, settings, login } = useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_CREATOR),
		viewCreator: useIsAuthorized(permissionNames.VIEW_CREATOR),
	}
	const navigate = useNavigate()
	const params = useParams()

	const { setShowMore } = useShowMoreActions()

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}

	let row: any
	if (props.isCompanyPersonalView) {
		row = props.companyData
	} else {
		row = creator.creator.find((e: any) => {
			return String(e.id).trim() === params.id!.trim()
		})
	}
	const links = {
		tiktok: row.tiktok,
		instagram: row.instagram,
		linkedin: row.linkedin,
		youtube: row.youtube,
	}

	const [skypeAllowedUsers, setSkypeAllowedUsers] = useState<string[]>([])
	const [skypeGroupUserAllowed, setSkypeGroupUserAllowed] =
		useState<boolean>(false)
	const closeDetailedView = () => {
		if (props.isCompanyPersonalView) {
			navigate('/companySettings')
		} else {
			navigate('/creators')
		}
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/creators/' + row.id)
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				// marginTop: '20px',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<InformationContainer
				creator={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				skypeGroupUserAllowed={skypeGroupUserAllowed}
				permissionContacts={permissions.viewCreator}
				permissionsGeneral={permissions}
				handleShowMoreClick={handleShowMoreClick}
			/>
			{/* <div
				style={{
					paddingBottom: '10px',
					marginTop: -20,
					marginBottom: 20,
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '95%',
				}}
			></div> */}

			{row &&
				row.capabilities &&
				row.capabilities.length > 0 &&
				row.capabilities.map((capability: any) => {
					return (
						<CapabilitiesInfoContainer
							capabilities={capability}
							links={links}
							handleShowMoreClick={handleShowMoreClick}
						/>
					)
				})}
		</PageWrapper>
	)
}

const InformationContainer = (props: {
	creator: any
	closeDetailedView: any
	openEdit: any
	skypeGroupUserAllowed: boolean
	permissionContacts: boolean
	permissionsGeneral: any
	handleShowMoreClick: any
}) => {
	const theme = useTheme()
	const { users, login, settings } = useTypedSelector((state) => state)
	const { setShowMore, clearShowMore } = useShowMoreActions()
	const [decryptedContacts, setDecryptedContacts] = useState<
		ContactInterface[]
	>([])

	const handleClickedLink = (link: string) => {
		if (!link.startsWith('http')) {
			return window.open('http://' + link)
		}
		return window.open(link)
	}

	const handleShowMoreIOs = () => {
		setIsIOSectionOpen(true)
	}
	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const [selectedFile, setSelectedFile] = useState('')
	const [isIOSectionOpen, setIsIOSectionOpen] = useState(false)
	const isAgencyTypeCompany: boolean = login.company.type === 'agency'

	const hasAgencyAccess: boolean = getCompanyAgencyAccess(
		props.creator?.company_id,
	)

	const icons = [
		{
			icon: (
				<ChatIcon
					style={{
						color: props.creator?.good_communication_badge
							? theme.colors.status.green
							: theme.colors.base.grey300,
						scale: '0.9',
						marginTop: -2,
					}}
				/>
			),
			value: props.creator?.good_communication_badge,
			name: 'Good Communication',
		},
		{
			icon: (
				<GoodQualityIcon
					style={{
						color: props.creator?.high_quality_work_badge
							? theme.colors.status.green
							: theme.colors.base.grey300,
						scale: '0.82',
					}}
				/>
			),
			value: props.creator?.high_quality_work_badge,
			name: 'High Quality Work',
		},
	]

	useEffect(() => {
		const decryptedContacts = async () => {
			if (!props.creator?.contacts || props.creator.contacts.length === 0) {
				setDecryptedContacts([]) // Ensure it is an empty array, not undefined
				return
			}

			try {
				const decryptedContacts = await decryptContacts(props.creator.contacts)

				setDecryptedContacts(decryptedContacts)
			} catch (error) {
				console.error('Error decrypting contacts:', error)
				setDecryptedContacts([]) // Prevent it from being undefined
			}
		}

		decryptedContacts()
	}, [props.creator?.contacts])
	const latestContract =
		props.creator?.contracts.reduce(
			(closest: ContractsInterface | null, contract: ContractsInterface) => {
				if (!contract.expiration) return closest

				const contractExpiration = parse(
					contract.expiration,
					'dd/MM/yyyy',
					new Date(),
				)
				if (isNaN(contractExpiration.getTime())) return closest

				return !closest ||
					contractExpiration <
						parse(closest.expiration, 'dd/MM/yyyy', new Date())
					? contract
					: closest
			},
			null,
		) || {}

	if (latestContract && latestContract.expiration) {
		latestContract.expiration = parse(
			latestContract.expiration,
			'dd/MM/yyyy',
			new Date(),
		).toLocaleDateString('en-GB')
	}
	//the grid is ordered like this because otherwise if the value is bigger it will move the height of the entire row.
	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
				<div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{ height: '16px', marginRight: '0px' }}
							/>
						}
					>
						Back
					</UnborderedButton>
					{props.permissionsGeneral.edit && (
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<PreviewTitle
					name={props.creator?.name}
					easterEggShow={isAgencyTypeCompany}
					iconUrl={props.creator?.creator_icon}
					status={props.creator?.status}
					links={{
						website: props.creator?.link_to_website,
						linkedin: props.creator?.link_to_linkedin,
						pub: props.creator?.link_to_pub,
					}}
					badges={icons}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 30,
						paddingBottom: 30,
					}}
				>
					<PreviewContainer gridTemplate='repeat(8, 1fr)'>
						<PreviewInfoTag
							label={'Full Name'}
							value={props.creator?.name}
							showMoreClick={props.handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Username'}
							value={props.creator?.username}
							showMoreClick={props.handleShowMoreClick}
							gridColumnSpan={2}
						/>
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Email'}
								value={props.creator?.email}
								showMoreClick={props.handleShowMoreClick}
								gridColumnSpan={2}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Owner'}
								value={props.creator?.owner}
								showMoreClick={props.handleShowMoreClick}
								gridColumnSpan={1}
							/>
						)}
						<PreviewInfoTag
							label={'Agency Access'}
							value={hasAgencyAccess ? 'Allowed' : 'Denied'}
							showMoreClick={props.handleShowMoreClick}
							gridColumnSpan={1}
						/>
						<PreviewInfoTag
							label={'Type'}
							value={props.creator?.type}
							showMoreClick={props.handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Creator ID'}
							value={props.creator?.creator_id}
							showMoreClick={props.handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Gender'}
							value={props.creator?.gender}
							showMoreClick={props.handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Country'}
							value={props.creator?.geo.length > 0 ? props.creator?.geo[0] : ''}
							isCountry
							showMoreClick={props.handleShowMoreClick}
						/>
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Agent'}
								value={props.creator?.has_agent === true ? 'Yes' : 'No'}
								showMoreClick={props.handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Finance ID'}
								value={props.creator?.finance_id}
								showMoreClick={props.handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Payment Terms'}
								value={props.creator?.payment_term}
								showMoreClick={props.handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Vat %'}
								value={props.creator?.vat}
								showMoreClick={props.handleShowMoreClick}
							/>
						)}
					</PreviewContainer>
					<PreviewContainer gridTemplate='repeat(5, 1fr)'>
						<PreviewInfoTag
							label={'Link to Portfolio'}
							value={props.creator?.portfolio}
							showMoreClick={props.handleShowMoreClick}
							gridColumnSpan={2}
						/>
						{/* <PreviewInfoTag
							label={'Link to IO'}
							value={props.creator?.link_to_io}
							showMoreClick={props.handleShowMoreClick}
						/> */}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label={'Link to Draft'}
								value={props.creator?.link_to_drafts}
								showMoreClick={props.handleShowMoreClick}
								gridColumnSpan={2}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewIOExpirationTag
								latestContract={latestContract}
								showMoreClick={handleShowMoreIOs}
								setSelectedFile={setSelectedFile}
								setIsPreviewOpen={setIsPreviewOpen}
							/>
						)}
						<PreviewArrayInfoTag
							label={'Campaign'}
							value={props.creator?.campaign}
							showMoreClick={props.handleShowMoreClick}
							gridColumnSpan={isAgencyTypeCompany ? 2 : 3}
						/>
					</PreviewContainer>
				</div>
				<Modal
					open={isPreviewOpen}
					onClose={() => setIsPreviewOpen(false)}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Box
						sx={{
							width: '80vw',
							height: '80vh',
							backgroundColor: theme.colors.base.white,
							padding: 2,
							borderRadius: 2,
							boxShadow: 24,
						}}
					>
						{selectedFile && <DocumentPreview doc={[{ uri: selectedFile }]} />}
					</Box>
				</Modal>
				<IOsModal
					open={isIOSectionOpen}
					onClose={() => {
						setIsIOSectionOpen(false)
					}}
					existingContracts={props.creator.contracts}
					onSave={() => {}}
					isPreview={true}
				/>
			</div>
		</>
	)
}

export const CapabilitiesInfoContainer = (props: {
	capabilities: any
	links: any
	handleShowMoreClick: any
}) => {
	const theme = useTheme()
	const { capabilities, links } = props

	const [geoCapabilities, setGeoCapabilities] = useState({})

	useEffect(() => {
		const obj: any = {}
		for (const geo of capabilities.geo) {
			obj[geo.geo[0]] = geo.value
		}
		setGeoCapabilities(obj)
	}, [capabilities.geo])

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 30,
					paddingBottom: 30,
				}}
			>
				<PreviewContainer
					gridTemplate='repeat(6, 1fr)'
					additionalStyle={{
						rowGap: '10px',
						marginTop: -30,
						paddingTop: 3,
						paddingBottom: 20,
					}}
				>
					<TypeComponent
						value={capabilities.type}
						link={links[capabilities.type.toLowerCase()]}
						gridColumn={'6'}
					/>
					<PreviewGridInfoTag
						label={'Gender'}
						value={capabilities.gender}
						gridRowSpan={2}
						objectLabelMap={{ male: 'Male', female: 'Female' }}
						extraElement='%'
					/>
					<PreviewGridInfoTag
						label={'Age'}
						value={capabilities.age}
						gridRowSpan={3}
						gridColumnSpan={2}
						gridMainRowSpan={2}
						objectLabelMap={{
							age18to24: '18-24',
							age25to34: '25-34',
							age35to44: '35-44',
							age45to54: '45-54',
							age55toInfinity: '55+',
						}}
						extraElement='%'
					/>
					<PreviewGridInfoTag
						label={'Geo'}
						value={geoCapabilities}
						gridRowSpan={4}
						gridColumnSpan={1}
						extraElement='%'
					/>
					<PreviewInfoTag
						label={'Engagement Rate'}
						value={capabilities.engagement_rate + '%'}
						showMoreClick={props.handleShowMoreClick}
					/>
					<PreviewInfoTag
						label={'Rate Per Video'}
						value={capabilities.rate_per_video + '%'}
						showMoreClick={props.handleShowMoreClick}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 8,
							gridColumn: `span 1`,
							gridRow: `span 1`,
						}}
					></div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 8,
							gridColumn: `span 1`,
							gridRow: `span 1`,
						}}
					></div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 8,
							gridColumn: `span 1`,
							gridRow: `span 1`,
						}}
					></div>
					<PreviewInfoTag
						label={'Vertical'}
						value={capabilities.vertical}
						showMoreClick={props.handleShowMoreClick}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 8,
							gridColumn: `span 1`,
							gridRow: `span 1`,
						}}
					></div>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(2, 1fr)',
							// gap: 8,
							gridColumn: `span 6`,
							gridRow: `span 2`,
						}}
					>
						<hr
							style={{
								width: '100%',
								height: '1px',
								background: theme.colors.preview.border,
								color: theme.colors.preview.border,
								border: 'none',
								gridColumn: `span 2`,
								gridRow: `span 1`,
								marginBottom: '0px',
							}}
						></hr>
						<PreviewInfoTag
							label={'Notes'}
							value={capabilities.notes}
							showMoreClick={props.handleShowMoreClick}
							gridColumnSpan={1}
						/>
					</div>
				</PreviewContainer>
			</div>
		</>
	)
}

const TypeComponent = (props: {
	value: string
	link?: string
	gridColumn?: string
}) => {
	const theme = useTheme()
	const iconMap: any = {
		tiktok: <TiktokIcon />,
		instagram: <InstagramIcon style={{ scale: '1.2' }} />,
		linkedin: <LinkedinIcon style={{ scale: '1.2' }} />,
		youtube: <YoutubeIcon />,
	}
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				alignItems: 'center',
				flexWrap: 'wrap',
				flexDirection: 'row',
				gridColumn: `${
					props.gridColumn ? 'span ' + props.gridColumn : 'span 4'
				}`,
				gridRow: 'span 1',
				marginTop: 4,
			}}
		>
			{iconMap[props.value.toLowerCase()]}
			{
				<div
					style={{
						position: 'relative',
						display: 'inline-block',
					}}
				>
					<span
						style={{
							textDecoration: 'underline',
							color: theme.colors.base.grey900,
							lineHeight: 'normal',
							verticalAlign: 'top',
						}}
					>
						{props.value}
					</span>
					<div
						onClick={() =>
							window.open(props.link, '_blank', 'noopener noreferrer')
						}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							cursor: 'pointer',
							backgroundColor: 'transparent',
						}}
					></div>
				</div>
			}
		</div>
	)
}

export default CreatorPreviewPage
