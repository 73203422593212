import React, { useEffect, useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import ReactCountryFlag from 'react-country-flag'
import { PageWrapper } from '../../components/reusableComponents'
import { UnborderedButton } from '../../components/Buttons'
import {
	ContactField,
	ExpandableInfoField,
	PasswordField,
	StatusTitleTag,
	TitleComponent,
} from '../../components/Informative'
import CompanyInformationHeader from '../../components/CompanyInformationHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import defaultIcon from '../../assets/default-icon.webp'
import { ContactInterface } from '../../../models/contact.interface'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import EditIcon from '@mui/icons-material/Edit'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import { ReactComponent as DriveIcon } from '../../assets/svg/driveIcon.svg'
import {
	canAccessResource,
	decryptContacts,
	getCompanyAgencyAccess,
} from '../../../utils/helpers/helperFuncs'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import PreviewTitle from '../../components/preview/PreviewTitle'
import {
	PreviewContainer,
	PreviewInfoTag,
} from '../../components/PreviewComponents'
import { useShowMoreActions } from '../../../hooks/useActions'
import CustomContactsComponent from '../../components/contacts/CustomContactsComponent'
import { CapabilitiesInfoContainer } from './PublisherPreviewPage'

const CapabilityContainer = (props: {
	value: any
	label: string
	isTrafficIndicator?: boolean
}) => {
	const theme = useTheme()
	const calculateAmountOfRows = (values: any[]) => {
		let result = 0
		if (!values || values.length === 0) {
			return result
		}
		for (const element of values) {
			//since inside the container, if a value is bigger than 12 length it's going to use 2 grid columns we use this to know the final amount of rows.
			if (element.value.length > 12) {
				result += 2
			} else {
				result++
			}
		}
		return Math.ceil(result / 3)
	}
	//we assume that in 1 row-grid we can have at max something between 3 and 4 rows of values:
	const gridMaxAmountOfRows = 4
	const gridRowHeightFrames = Math.ceil(
		calculateAmountOfRows(props.value) / gridMaxAmountOfRows,
	)
	return (
		props.value && (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
				<span
					style={{
						fontSize: theme.font.size.body,
						fontWeight: theme.font.weight.normal,
						color: theme.colors.base.grey900,
						paddingInlineStart: '1rem',
					}}
				>
					{props.label}
				</span>
				<div
					style={{
						gridRowStart: `span ${gridRowHeightFrames}`,
						// border: '1px solid rgb(141,22,108)',
						border: '1px solid ' + theme.colors.base.grey200,
						borderRadius: '20px',
						padding: '20px 20px 30px 20px',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
						height: 'calc(100% - 50px)',
					}}
				>
					<div
						style={{
							display: 'grid',
							gap: '12px',
							gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
							gridAutoFlow: 'dense',
						}}
					>
						{props.value.map((element: any) => {
							if (props.isTrafficIndicator) {
								if (element.value.includes('CR')) {
									return (
										<div
											style={{
												// border: '1px solid ' + theme.colors.lightGray,
												//background: theme.colors.lightGray,
												color: theme.colors.text.titles,
												border: '2px solid ' + theme.colors.status.green,
												// border: '1px solid ' + 'rgb(175,24,98)',
												padding: '6px',
												borderRadius: '10px',
												whiteSpace: 'nowrap',
												textAlign: 'center',
												fontWeight: '500',
												fontSize: theme.font.size.caption,
												gridColumnStart: 'span 2',
											}}
											key={element.value}
										>
											CR
											<span
												style={{
													color: theme.colors.base.white,
													background: theme.colors.status.green,
													padding: '2px 4px 2px 4px',
													borderRadius: '4px',
													whiteSpace: 'nowrap',
													textAlign: 'center',
													fontWeight: '500',
													fontSize: '9px',
													marginLeft: '8px',
												}}
											>
												{element.value}
											</span>
										</div>
									)
								} else {
									return (
										<span
											style={{
												// border: '1px solid ' + theme.colors.lightGray,
												//background: theme.colors.lightGray,
												color: theme.colors.text.titles,
												border: '2px solid ' + theme.colors.status.green,

												// border: '1px solid ' + 'rgb(175,24,98)',
												padding: '6px',
												borderRadius: '10px',
												whiteSpace: 'nowrap',
												textAlign: 'center',
												fontWeight: '500',
												fontSize: theme.font.size.caption,
												gridColumnStart: 'span 2',
											}}
											key={element.value}
										>
											Quality
											<span
												style={{
													color: theme.colors.base.white,
													background: theme.colors.status.green,
													padding: '2px 4px 2px 4px',
													borderRadius: '4px',
													whiteSpace: 'nowrap',
													textAlign: 'center',
													fontWeight: '500',
													fontSize: '9px',
													marginLeft: '8px',
													// color: theme.colors.black,
												}}
											>
												{element.value}
											</span>
										</span>
									)
								}
							} else
								return (
									<span
										style={{
											// border: '1px solid ' + theme.colors.lightGray,
											//background: theme.colors.lightGray,
											color: theme.colors.text.titles,
											border: '2px solid ' + theme.colors.status.green,
											// border: '1px solid ' + 'rgb(175,24,98)',
											padding: '6px',
											borderRadius: '10px',
											whiteSpace: 'nowrap',
											textAlign: 'center',
											fontWeight: '500',
											fontSize: theme.font.size.caption,
											gridColumnStart:
												element.value.length < 12 ? 'span 1' : 'span 2',
										}}
										key={element.value}
									>
										{element.value}
									</span>
								)
						})}
					</div>
				</div>
			</div>
		)
	)
}

const PublisherCompanyInformationContainer = (props: {
	publisher: any
	closeDetailedView: any
	openEdit: any
	permissionContacts: boolean
	permissionsGeneral: any
	setPopUpVisible: any
}) => {
	const theme = useTheme()

	const permissions = props.permissionsGeneral
	const { login } = useTypedSelector((state) => state)
	const [filteredPids, setFilteredPids] = useState<any>([])
	const [dummyPids, setDummyPids] = useState<any>([])
	const [contacts, setContacts] = useState<any>([])
	const { setShowMore } = useShowMoreActions()

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}
	const canView = true
	useEffect(() => {
		const arr = []
		const dummies = []
		if (props.publisher.external_id) {
			for (const pid of props.publisher.media_source_pid) {
				if (!pid.endsWith(props.publisher.external_id)) {
					arr.push(pid)
				} else {
					dummies.push(pid)
				}
			}
		}
		setFilteredPids(arr)
		setDummyPids(dummies)
	}, [props.publisher])

	useEffect(() => {
		const decryptContactsData = async () => {
			if (
				props.publisher &&
				props.publisher.contacts &&
				(canView || permissions.viewPublisherContact)
			) {
				try {
					const decryptedContacts = await decryptContacts(
						props.publisher.contacts,
					)
					console.log(decryptedContacts)
					setContacts(decryptedContacts)
				} catch (error) {
					console.error('Failed to decrypt contact information:', error)
				}
			}
		}
		decryptContactsData()
	}, [props.publisher.contacts, canView])

	const hasAgencyAccess = getCompanyAgencyAccess(props.publisher?.company_id)

	const badges = props.publisher.media_buying_capabilities?.badges
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%', // full width
					gap: 16,
				}}
			>
				{/* Row 1: Back & Edit Buttons */}
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 16,
					}}
				>
					<UnborderedButton
						style={{ display: 'flex', color: theme.colors.text.titles }}
						onClick={props.closeDetailedView}
						icon={<ArrowBackIosIcon style={{ height: '16px' }} />}
					>
						Back
					</UnborderedButton>

					<UnborderedButton
						style={{
							display: 'flex',
							color: theme.colors.text.titles,
							marginLeft: 'auto',
							marginRight: '7rem',
						}}
						onClick={props.openEdit}
						icon={<EditIcon />}
					>
						Edit
					</UnborderedButton>
				</div>

				{/* Row 2: PreviewTitle */}
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<PreviewTitle
						name={props.publisher.publisher_name}
						iconUrl={props.publisher.publisher_icon}
						status={true}
						id={props.publisher.external_id}
						links={{
							website: props.publisher.link_to_website,
							linkedin: props.publisher.link_to_linkedin,
							io: props.publisher.link_to_io,
						}}
					/>
				</div>

				{/* Main content */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 30,
						paddingBottom: 18,
					}}
				>
					<PreviewContainer gridTemplate='repeat(12, 1fr)'>
						<PreviewInfoTag
							label='Legal Name'
							value={props.publisher?.legal_name}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={3}
						/>
						<PreviewInfoTag
							label='Address'
							value={props.publisher?.address}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={5}
						/>
						<PreviewInfoTag
							label='Head Office Geo'
							value={props.publisher?.geo}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
							isCountry
						/>
						<PreviewInfoTag
							label='Agency Access'
							value={hasAgencyAccess ? 'Allowed' : 'Denied'}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
					</PreviewContainer>

					<PreviewContainer gridTemplate='repeat(4, 1fr)'>
						<PreviewInfoTag
							label='Skype Group'
							value={
								props.publisher?.public_skype === true ? 'Open' : 'Private'
							}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label='Skype Link'
							value={
								props.publisher.public_skype
									? props.publisher?.skype_group
									: '******'
							}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label='PIDs'
							value={
								filteredPids.length > 0
									? filteredPids
									: props.publisher?.media_source_pid
							}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
					</PreviewContainer>

					<PreviewContainer gridTemplate='repeat(2, 1fr)'>
						<PreviewInfoTag
							label='Brief'
							value={props.publisher?.notes}
							gridRowSpan={2}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label='Special Requests'
							value={
								props.publisher?.media_buying_capabilities
									?.special_request_list || 'No Special Request At This Moment'
							}
							gridRowSpan={2}
							showMoreClick={handleShowMoreClick}
						/>
					</PreviewContainer>

					<CustomContactsComponent
						contacts={contacts}
						setContacts={() => {}}
						p360Active
						canEdit={false}
						roleOptions={[]}
						mode='preview'
					/>

					{dummyPids.length > 0 && (
						<Grid item xs={12}>
							<ExpandableInfoField label='Dummy PIDs' value={dummyPids} />
						</Grid>
					)}
				</div>
			</div>
		</>
	)
}

const PublisherCapabilitiesInfoContainer = (props: { capabilities: any }) => {
	return (
		<div>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr',
					gridAutoRows: 'auto',
					gap: '20px',
					width: '92%',
					alignItems: 'flex-start',
				}}
			>
				<CapabilityContainer
					value={
						props.capabilities.type_publisher &&
						props.capabilities.type_publisher.length > 0
							? props.capabilities.type_publisher
							: [{ value: 'Not Specified' }]
					}
					label={'Type of Publisher'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.devices && props.capabilities.devices.length > 0
							? props.capabilities.devices
							: [{ value: 'Not Specified' }]
					}
					label={'Devices'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.available_platforms &&
						props.capabilities.available_platforms.length > 0
							? props.capabilities.available_platforms
							: [{ value: 'Not Specified' }]
					}
					label={'Available Platforms'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.fraud_tools &&
						props.capabilities.fraud_tools.length > 0
							? props.capabilities.fraud_tools
							: [{ value: 'Not Specified' }]
					}
					label={'Fraud Tool'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.ad_type && props.capabilities.ad_type.length > 0
							? props.capabilities.ad_type
							: [{ value: 'Not Specified' }]
					}
					label={'Ad Type'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.format_types &&
						props.capabilities.format_types.length > 0
							? props.capabilities.format_types
							: [{ value: 'Not Specified' }]
					}
					label={'Ad Format Type'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.payment_model &&
						props.capabilities.payment_model.length > 0
							? props.capabilities.payment_model
							: [{ value: 'Not Specified' }]
					}
					label={'Payment Model'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.traffic_indicators &&
						props.capabilities.traffic_indicators.length > 0
							? props.capabilities.traffic_indicators
							: [{ value: 'Not Specified' }]
					}
					label={'Traffic Indicators'}
					isTrafficIndicator={props.capabilities.traffic_indicators}
				/>
				<CapabilityContainer
					value={
						props.capabilities.abilities &&
						props.capabilities.abilities.length > 0
							? props.capabilities.abilities
							: [{ value: 'Not Specified' }]
					}
					label={'Abilities'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.daily_cap &&
						props.capabilities.daily_cap.length > 0
							? props.capabilities.daily_cap
							: [{ value: 'Not Specified' }]
					}
					label={'Daily Cap'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.relevant_categories &&
						props.capabilities.relevant_categories.length > 0
							? props.capabilities.relevant_categories
							: [{ value: 'Not Specified' }]
					}
					label={'Verticals'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.country && props.capabilities.country.length > 0
							? props.capabilities.country
							: [{ value: 'Not Specified' }]
					}
					label={'Country - Volume - Payout'}
				/>
			</div>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gridAutoRows: '170px',
					gap: '12px',
					width: '95%',
					marginTop: '20px',
				}}
			></div>
		</div>
	)
}

const PublisherCompanyPreviewPage = (props: {
	setLoading: (loading: boolean) => void
	setPopUpVisible: (visible: boolean) => void
	companyData: any
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const row = props.companyData

	const [contacts, setContacts] = useState<ContactInterface[]>([])

	const permissions = {
		// edit: useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER),
		viewPublisherContact: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewLinks: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
	}

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/companySettings/edit')
	}

	useEffect(() => {
		const decryptContactsData = async () => {
			if (row && row.contacts) {
				try {
					const decryptedContacts = await decryptContacts(row.contacts)

					setContacts(decryptedContacts)
				} catch (error) {
					console.error('Failed to decrypt contact information:', error)
				}
			}
		}
		decryptContactsData()
	}, [row.contacts])

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				paddingBottom: '30px',
			}}
		>
			<PublisherCompanyInformationContainer
				publisher={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				permissionContacts={permissions.viewPublisherContact}
				permissionsGeneral={permissions}
				setPopUpVisible={props.setPopUpVisible}
			/>
			{row?.media_buying_capabilities && (
				<CapabilitiesInfoContainer
					capabilities={row.media_buying_capabilities}
				/>
			)}
		</PageWrapper>
	)
}

export default PublisherCompanyPreviewPage
