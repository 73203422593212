import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { InputFieldText } from '.././Inputs'
import { DropList, CountryListSelector } from '.././SelectableInputs'
import { ContactInterface } from '../../../models/contact.interface'
import { countryList } from '../countryList'
import {
	EditableGridItem,
	PreviewContainer,
	PreviewInfoTag,
} from '../PreviewComponents'
import { IconCircleWrapper, ReadMoreInlineButton } from '../Buttons'

interface ContactRowItemProps {
	contact: ContactInterface
	isReadOnly: boolean
	canEdit: boolean
	roleOptions?: string[]
	p360Active?: boolean
	handleFieldChange: (
		newValue: string,
		contactUUID: string,
		fieldName: keyof ContactInterface,
	) => void
	handleGeoChange?: (contactUUID: string, newGeoArray: string[]) => void
	handleMenuOpen: (e: React.MouseEvent<HTMLElement>, uuid: string) => void
	setContacts: React.Dispatch<React.SetStateAction<ContactInterface[]>>
	getSafeValue: (value: any, defaultValue?: any) => any
	showMoreButton?: boolean
	onShowMore?: () => void
	previewContainerStyle?: React.CSSProperties //override styles inside modal
}

export const ContactRowItem: React.FC<ContactRowItemProps> = ({
	contact,
	isReadOnly,
	canEdit,
	roleOptions,
	p360Active,
	handleFieldChange,
	handleMenuOpen,
	setContacts,
	getSafeValue,
	handleGeoChange,
	showMoreButton = false,
	onShowMore,
	previewContainerStyle, // override styles inside modal
}) => {
	const theme = useTheme()

	// ================= PREVIEW MODE ================
	if (isReadOnly) {
		const geoValue = Array.isArray(contact.geo)
			? contact.geo.join(', ')
			: getSafeValue(contact.geo, '-')

		return (
			<Box
				display='grid'
				gridTemplateColumns='repeat(5, 1fr)'
				columnGap='1rem'
				rowGap='0.5rem'
				sx={{
					width: '100%',
					backgroundColor: 'transparent',
					padding: '1rem 0',
					position: 'relative',
					...previewContainerStyle,
				}}
			>
				<EditableGridItem>
					<PreviewInfoTag
						label='Name'
						value={getSafeValue(contact.name)}
						showMoreClick={() => {}}
					/>
				</EditableGridItem>

				<EditableGridItem>
					<PreviewInfoTag
						label='Email'
						value={getSafeValue(contact.email)}
						showMoreClick={() => {}}
					/>
				</EditableGridItem>

				<EditableGridItem>
					<PreviewInfoTag
						label='Role'
						value={getSafeValue(contact.role)}
						showMoreClick={() => {}}
					/>
				</EditableGridItem>

				<EditableGridItem>
					<PreviewInfoTag
						label='Skype'
						value={getSafeValue(contact.skype)}
						showMoreClick={() => {}}
					/>
				</EditableGridItem>

				<EditableGridItem>
					<PreviewInfoTag
						label='Geo'
						value={geoValue}
						showMoreClick={() => {}}
						isCountry
					/>
				</EditableGridItem>

				{/* If you want a "Show More" link below these five columns */}
				{showMoreButton && onShowMore && (
					<div
						style={{
							display: 'flex',
							position: 'absolute',
							justifyContent: 'flex-start',
							bottom: -10,
							left: 80,
						}}
					>
						<ReadMoreInlineButton
							label='More'
							onClick={() => onShowMore()}
							style={{ backgroundColor: 'transparent' }}
							showIcon
						/>
					</div>
				)}
			</Box>
		)
	}

	// ================= EDIT MODE =================

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(12, 1fr)',
				padding: 10,
				columnGap: 30,
			}}
		>
			{/* Name */}
			<div style={{ gridColumn: 'span 2' }}>
				<InputFieldText
					label='Name'
					type='text'
					value={getSafeValue(contact.name)}
					onChange={(val: string) =>
						handleFieldChange(val, contact.uuid, 'name')
					}
					disabled={isReadOnly}
				/>
			</div>

			{/* Email */}
			<div style={{ gridColumn: 'span 2' }}>
				<InputFieldText
					label='Email'
					type='text'
					value={getSafeValue(contact.email)}
					onChange={(val: string) =>
						handleFieldChange(val, contact.uuid, 'email')
					}
					disabled={isReadOnly}
				/>
			</div>

			{/* Role */}
			<div style={{ gridColumn: 'span 2' }}>
				<DropList
					label='Role'
					options={roleOptions || []}
					value={getSafeValue(contact.role)}
					onChange={(newVal: string) =>
						handleFieldChange(newVal, contact.uuid, 'role')
					}
					disabled={isReadOnly}
				/>
			</div>

			{/* Skype */}
			<div style={{ gridColumn: 'span 2' }}>
				<InputFieldText
					label='Skype'
					type='text'
					value={getSafeValue(contact.skype)}
					onChange={(val: string) =>
						handleFieldChange(val, contact.uuid, 'skype')
					}
					disabled={isReadOnly}
				/>
			</div>

			{/* Geo */}
			<div style={{ gridColumn: 'span 2' }}>
				<CountryListSelector
					label='Geo'
					options={countryList || []}
					value={Array.isArray(contact.geo) ? contact.geo : []}
					onChange={(newGeo: string[]) => {
						if (handleGeoChange) {
							handleGeoChange(contact.uuid, newGeo)
						}
					}}
					disabled={isReadOnly}
				/>
			</div>

			<div
				style={{
					gridColumn: 'span 1',
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'row',
					alignItems: 'flex-end',
					gap: 20,
				}}
			>
				<IconButton
					onClick={(e) => handleMenuOpen(e, contact.uuid)}
					disabled={isReadOnly}
					style={{ padding: '0px' }}
				>
					<IconCircleWrapper
						size={32}
						circleColor={theme.colors.base.white}
						iconColor={theme.colors.text.primary}
						hoverCircleColor={theme.colors.base.grey300}
						hoverIconColor={theme.colors.text.secondary}
					>
						<MoreVertIcon />
					</IconCircleWrapper>
				</IconButton>
			</div>
		</div>
	)
}
