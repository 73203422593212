import { Box, useTheme } from '@mui/material'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useState } from 'react'
import { decryptMessageForDataSplit } from '../../../crypto/cryptoUtils'
import CustomModal from '../CustomModal'
import { InputFieldText } from '../Inputs'
import { SubmitButton, UnborderedButton } from '../Buttons'
import { ChipInputList } from '../ChipInput'
import { PasswordInterface } from './PasswordComponent'

interface EditPasswordContentProps {
	passwordData: PasswordInterface
	onSave: (pw: PasswordInterface) => void
	onClose: () => void
	setErrorMessage: (err: string | null) => void
}

const EditPasswordContent: React.FC<EditPasswordContentProps> = ({
	passwordData,
	onSave,
	onClose,
	setErrorMessage,
}) => {
	const theme = useTheme()
	const { users, login } = useTypedSelector((state) => state)

	const userList = users.users
		.filter((u: any) => !u.blocked)
		.map((u: any) => u.name)
		.sort()

	const [tempUrl, setTempUrl] = useState(passwordData.url)
	const [tempUsername, setTempUsername] = useState(
		passwordData.username || login.user.email,
	)
	const [tempPassword, setTempPassword] = useState(passwordData.password || '')
	const [tempWhiteList, setTempWhiteList] = useState<string[]>(
		passwordData.whiteList,
	)

	const [showFullPasswordModal, setShowFullPasswordModal] = useState(false)
	const [passwordEntered, setPasswordEntered] = useState(
		tempPassword.length > 0,
	)
	const [isDecrypted, setIsDecrypted] = useState(false)

	const isNewPw = !passwordData.url
	const isSubmitDisabled =
		!tempUrl.trim() || !tempUsername.trim() || !tempPassword.trim()

	const handleDecryptPassword = async () => {
		try {
			if (isDecrypted || !tempPassword.includes(':')) {
				setShowFullPasswordModal(true)
				return
			}
			// Attempt primary decryption
			const decrypted = await decryptMessageForDataSplit(tempPassword)
			setTempPassword(decrypted)
			setShowFullPasswordModal(true)
			setIsDecrypted(true)
		} catch (error) {
			console.error('Primary decryption failed:', error)
			try {
				// Fallback decryption
				const fallbackDecrypted = await decryptMessageForDataSplit(tempPassword)
				setTempPassword(fallbackDecrypted)
				setShowFullPasswordModal(true)
				setIsDecrypted(true)
			} catch (fallbackError) {
				console.error('Fallback decryption failed:', fallbackError)
				setErrorMessage('Failed to decrypt password.')
			}
		}
	}

	const toggleShowPasswordInModal = () => {
		if (!showFullPasswordModal) {
			handleDecryptPassword()
		} else {
			setShowFullPasswordModal(false)
		}
	}

	const handlePasswordChange = (newPassword: string) => {
		setTempPassword(newPassword)
		setPasswordEntered(newPassword.length > 0)
	}

	const handleSubmit = () => {
		if (isSubmitDisabled) return
		onSave({
			url: tempUrl,
			username: tempUsername,
			password: tempPassword,
			whiteList: tempWhiteList,
		})
	}

	return (
		<CustomModal
			open={true}
			onClose={onClose}
			title={isNewPw ? 'Add Password' : 'Edit Password'}
			maxWidth='md'
			fullWidth
			styles={{ padding: '0rem' }}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				<Box
					sx={{
						backgroundColor: theme.colors.preview.cardBackground,
						borderRadius: '6px',
						padding: '1rem',
					}}
				>
					<InputFieldText
						label='URL'
						placeholder='Write or paste the URL'
						type='text'
						value={tempUrl}
						onChange={setTempUrl}
						mandatory
					/>
				</Box>

				<Box
					sx={{
						backgroundColor: theme.colors.base.white,
						borderRadius: '6px',
						padding: '1rem',
					}}
				>
					<Box display='flex' flexDirection='row' gap='1rem'>
						<Box flex={1}>
							<InputFieldText
								label='Username'
								type='text'
								value={tempUsername}
								onChange={setTempUsername}
								mandatory
							/>
						</Box>
						<Box flex={1}>
							<InputFieldText
								label='Password'
								type={showFullPasswordModal ? 'text' : 'password'}
								value={tempPassword}
								onChange={handlePasswordChange}
								placeholder={isNewPw ? '' : '********'}
								mandatory
							/>
							{passwordEntered && (
								<UnborderedButton
									onClick={toggleShowPasswordInModal}
									style={{
										marginTop: '0.5rem',
										textDecoration: 'underline',
										color: theme.colors.text.titles,
										fontSize: theme.font.size.caption,
									}}
								>
									{showFullPasswordModal ? 'Hide Password' : 'Show Password'}
								</UnborderedButton>
							)}
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						backgroundColor: theme.colors.preview.cardBackground,
						borderRadius: '6px',
						padding: '1rem',
					}}
				>
					<ChipInputList
						disabled={false}
						label='Users'
						value={tempWhiteList}
						options={userList}
						onChange={setTempWhiteList}
						openWithClick
						onlyOptionsAllowed
					/>
				</Box>

				<Box
					display='flex'
					justifyContent='center'
					gap='2rem'
					sx={{ marginTop: '1rem', paddingBottom: '1rem' }}
				>
					<UnborderedButton onClick={onClose}>Cancel</UnborderedButton>
					<SubmitButton
						onClick={handleSubmit}
						disabled={isSubmitDisabled}
						style={{
							opacity: isSubmitDisabled ? 0.5 : 1,
							cursor: isSubmitDisabled ? 'not-allowed' : 'pointer',
						}}
					>
						{isNewPw ? 'Add' : 'Update'}
					</SubmitButton>
				</Box>
			</Box>
		</CustomModal>
	)
}

export default EditPasswordContent
