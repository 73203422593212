import { useState, useEffect, useRef } from 'react'
import { styled, Card, useTheme } from '@mui/material'
import search from '../../assets/search-varys.svg'
import { StyledInputBase } from './reusableComponents'

export const TableSearch = styled(Card)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	float: 'left',
	margin: '10px',
	borderRadius: '4px',
	height: '100%',
	border: '1px solid rgba(233, 236, 239, 1) !important',
	backgroundColor: 'rgba(242, 242, 242, 1)',
}))

export const SearchIconWrapper = styled('div')(() => {
	const theme = useTheme()
	return {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: '20',
		'& .MuiSvgIcon-root': {
			fill: theme.colors.text.grey,
			color: theme.colors.text.grey,
		},
	}
})

export const TableSearchComponent = (props: {
	setSearch: (value: string) => void
	search: string
	style?: React.CSSProperties
	filterHandler?: () => void
	searchWithTimer?: boolean
	presetSearch?: string
	placeholder?: string
	autoFocus?: boolean
	fixedPlaceholder?: boolean
}) => {
	const theme = useTheme()
	const [value, setValue] = useState(props.search)
	const timer = useRef<NodeJS.Timeout | null>(null)

	useEffect(() => {
		setValue(props.search)
	}, [props.search])
	useEffect(() => {
		if (!props.placeholder) {
			props.setSearch('')
		}
	}, [props.placeholder])

	useEffect(() => {
		if (timer.current) clearTimeout(timer.current)
		timer.current = setTimeout(() => {
			if (value !== props.search) {
				props.setSearch(value)
			}
		}, 200)

		return () => {
			if (timer.current) clearTimeout(timer.current)
		}
	}, [value, props])

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
	}

	// Ensure the placeholder is a string
	const getPlaceholder = () => {
		if (typeof props.placeholder === 'string') {
			return `Search in ${props.placeholder}...`
		}
		return 'Search...'
	}

	return (
		<TableSearch style={{ ...props.style }}>
			<SearchIconWrapper>
				<img
					src={search}
					alt='search-icon'
					style={{
						zIndex: 10,
						width: '1rem',
						height: '1rem',
					}}
				/>
			</SearchIconWrapper>
			<StyledInputBase
				placeholder={
					props.fixedPlaceholder ? props.placeholder : getPlaceholder()
				}
				inputProps={{ 'aria-label': 'search' }}
				autoFocus={props.autoFocus ?? !props.filterHandler}
				onChange={handleSearch}
				value={value}
				style={{ width: '100%', color: theme.colors.chip.fill }}
				disabled={!props.fixedPlaceholder && !props.placeholder}
			/>
		</TableSearch>
	)
}
