import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
	Box,
	DialogActions,
	ThemeProvider,
	Typography,
	useTheme,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
	useActions,
	useAdvertiserActions,
	useLogActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	AdvertiserInterface,
	AppIdLinkPair,
	AppInterface,
	ContractsInterface,
} from '../../models/model.interface'
import { fetchInChunks } from '../../utils/helpers/fetchInChunks'
import {
	canAccessResource,
	checkValidBudgets,
	decryptContacts,
	getApplicableRules,
	handleIconUrl,
	isValidImageType,
	stringIsValidName,
	hasOwnerAccess,
	formatAppIdPairs,
	getCompanyAgencyAccess,
} from '../../utils/helpers/helperFuncs'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	capitalizeFirstLetter,
	cellGenerator,
	checkInputStartsWithHttp,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import { ContactInterface } from '../../models/contact.interface'
import { PageWrapper } from '../components/reusableComponents'
import { CompanyInfoComponent } from './AdvertiserCompanyInfoComponent'
import { StyledTab, StyledTabs } from './AdvertiserStyled'
import { TrafficBudgetComponent } from './TrafficBudgetComponent'
import { AdvertiserFinanceInfo } from './AdvertiserFinanceInfoComponent'
import { TabContainer } from '../components/TabComponent'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { FileUploadOutlined } from '@mui/icons-material'
import CustomModal from '../components/CustomModal'
import { CheckCircle } from '@mui/icons-material'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import {
	ACCEPTED_FILES,
	THING_OR_TWO_COMPANY_ID,
	endpoints,
} from '../../config'
import CustomFileUploader from '../components/CustomFileUploader'
import CustomDialog from '../components/CustomDialog'
import EditPageHeader from '../components/EditPageHeader'
import { editPageTopPanelStyle } from '../../utils/helpers/commonStyles'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import AdvertiserMetaEdit from './AdvertiserMetaEdit'
import moment from 'moment'
import CustomContactsComponent from '../components/contacts/CustomContactsComponent'
import {
	PasswordsComponent,
	PasswordInterface,
} from '../components/passwords/PasswordComponent'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}
export interface Owner {
	owner: string
	finishDate: number
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	)
}
export interface AllowedPlatform {
	title: string
	value: boolean
}
const AdvertiserPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: AdvertiserInterface
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isNewAdvertiser?: boolean
	setIsNewAdvertiser: any
}): JSX.Element => {
	const { uploadIcon, deleteIcon, getAppsAction, updateCompanyData } =
		useActions()
	const {
		insertAdvertiserAction,
		updateAdvertiserAction,
		updateAdvertiserMetaAction,
		getAdvertiserAction,
		getPasswordsByAdvertiserId,
	} = useAdvertiserActions()
	const { insertLog } = useLogActions()
	const { settings, login, app, advertiser, publisher, users, company } =
		useTypedSelector((state) => state)
	const theme = useTheme()
	const [name, setName] = useState<string>('')
	const [uploadedId, setUploadedId] = useState<string>('')
	const [legalName, setLegalName] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [website, setWebsite] = useState<string>('')
	const [linkedin, setLinkedin] = useState<string>('')
	const [brief, setBrief] = useState<string>('')
	const [parametersTemplate, setParametersTemplate] = useState<string>('')
	const [specialRequest, setSpecialRequest] = useState<string>('')
	const [geo, setGeo] = useState<string[]>([])
	const [appList, setAppList] = useState<any[]>([])
	const [linkToIO, setLinkToIO] = useState('')
	const [salesRep, setSalesRep] = useState('')
	const [pauseReason, setPauseReason] = useState('')
	const [pauseExplanation, setPauseExplanation] = useState('')
	const [paymentTerms, setPaymentTerms] = useState('')
	const [status, setStatus] = useState(true)
	const [statusChanged, setStatusChanged] = useState(false)
	const [ViewPauseBox, setViewPauseBox] = useState(false)
	const [fraudTool, setFraudTool] = useState<any>([])
	const [linkToAdvFile, setLinkToAdvFile] = useState('')
	const [linkToCreative, setLinkToCreative] = useState('')
	const [team, setTeam] = useState<string>('')
	const [invoiceInstructions, setInvoiceInstructions] = useState<string>('')
	const [createdBy, setCreatedBy] = useState<string>('')
	const [communicationChannel, setCommunicationChannel] = useState<string[]>([])
	const [advertiserType, setAdvertiserType] = useState<string>('')
	const [vertical, setVertical] = useState<string[]>([])
	const [trafficRestrictions, setTrafficRestrictions] = useState<string[]>([])
	const [existingChannels, setExistingChannels] = useState<string[]>([])
	const [platforms, setPlatforms] = useState<string[] | null>([])
	const [ownerEmail, setOwnerEmail] = useState<string | null>(login.user.email)
	const [apps, setApps] = useState<string[]>([])
	const [pastOwner, setPastOwner] = useState<any>([])
	const [pastOwnerFinance, setPastOwnerFinance] = useState<any>([])
	const [contacts, setContacts] = useState<ContactInterface[]>([])
	const [isValid, setIsValid] = useState<boolean>(false)
	const [prevState, setPrevState] = useState(null)
	const [usedPublishers, setUsedPublishers] = useState<string[]>([])
	const [dailyBudgetLimit, setDailyBudgetLimit] = useState<number>(0)
	const [monthlyBudgetLimit, setMonthlyBudgetLimit] = useState<number>(0)
	const [totalBudgetLimit, setTotalBudgetLimit] = useState<number>(0)
	const [dailyRevenue, setDailyRevenue] = useState<number>(0)
	const [monthlyRevenue, setMonthlyRevenue] = useState<number>(0)
	const [totalRevenue, setTotalRevenue] = useState<number>(0)
	const [notUsedPublishers, setNotUsedPublishers] = useState<string[]>([])
	const [mainHeightChange, setMainHeightChange] = useState(false)
	const [allowedPlatforms, setAllowedPlatforms] = useState<AllowedPlatform[]>(
		[],
	)
	const [branch, setBranch] = useState<string>('')
	const [currentPasswords, setCurrentPasswords] = useState<PasswordInterface[]>(
		[],
	)
	const [uploadStatus, setUploadStatus] = useState<{
		message: string
		success: boolean
	} | null>(null)

	const [vat, setVat] = useState<string>('')
	const [tab, setTab] = useState(0)
	const [appIdPairs, setAppIdPairs] = useState<AppIdLinkPair[]>([
		{ app_id: '', link: '' },
		{ app_id: '', link: '' },
	])

	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
	const [contactsChanged, setContactsChanged] = useState(false)
	const [contracts, setContracts] = useState<ContractsInterface[]>([])
	const [notes, setNotes] = useState<string>('')
	const [dataSource, setDataSource] = useState<string[]>([])
	const [agencyAccess, setAgencyAccess] = useState<Boolean>(false)
	const navigate = useNavigate()
	const params = useParams()
	//we need to select all the data for the row that we opened (if it was already created, meaning that it has params.id)
	const row = useTypedSelector((state) => {
		return state.advertiser.advertiser.find(
			(ad: AdvertiserInterface) => ad.id === Number(params.id),
		)
	})
	const [iconUrl, setIconUrl] = useState<string>(row?.advertiser_icon)

	const editOwnAdvertiser =
		useIsAuthorized(permissionNames.EDIT_ADVERTISER_OWNER_ONLY) &&
		ownerEmail === login.user.email
	const permissions = {
		edit:
			useIsAuthorized(permissionNames.ADD_EDIT_ADVERTISER) || editOwnAdvertiser,
		viewFinancePersonal: useIsAuthorized(permissionNames.VIEW_PERSONAL_FINANCE),
		viewFinanceMediaBuying: useIsAuthorized(permissionNames.VIEW_MEDIA_BUYING),
		viewAllContacts:
			useIsAuthorized(permissionNames.VIEW_ADVERTISER_CONTACTS) ||
			editOwnAdvertiser,
		viewPasswords:
			useIsAuthorized(permissionNames.VIEW_ADVERTISER_PASSWORDS) ||
			editOwnAdvertiser,
		preview: useIsAuthorized(permissionNames.PREVIEW_ADVERTISERS),
	}
	const isCompanyOwner = props.isNewAdvertiser
		? true
		: hasOwnerAccess(row, permissions, 'edit')

	const [mandatoryFields, setMandatoryFields] = useState({
		name: true,
		legalName: true,
		branch: true,
		paymentTerms: true,
		advertiserType: true,
	})

	const [fieldValidations, setFieldValidations] = useState({
		name: false,
		legalName: false,
		branch: false,
		paymentTerms: false,
		advertiserType: false,
	})

	const advertiserCompanyId = props.isNewAdvertiser
		? login.company.id
		: row?.company_id || 0

	const hasAgencyAccess =
		props.isNewAdvertiser ||
		row?.company_id === login.company.id ||
		getCompanyAgencyAccess(advertiserCompanyId)

	const publisherList = publisher.publisher
		.map((el: any) => el.publisher_name)
		.sort((a: any, b: any) => a.localeCompare(b))

	//we use prevState and newState to compare the changes done by the user. We then upload the logs based on the differences.
	const preparePrevState = (prevState: any) => {
		try {
			const copiedState = _.cloneDeep(prevState)
			const { id, count, createdAt, status, platform, ...result } = copiedState
			return result
		} catch (error) {
			return null
		}
	}

	const handleContactsChange = (e: any) => {
		const equal = _.isEqual(e, contacts)
		if (!equal) {
			setContactsChanged(true)
			setContacts(e)
		}
	}
	const prepareNewState = (prevState: any) => {
		const { id, user_login, ...result } = prevState
		return result
	}

	const selectFraudTools = (selectedValues: string[]) => {
		const lastSelectedValue = selectedValues[selectedValues.length - 1]
		if (lastSelectedValue === 'No Fraud Tool') {
			// If the last selected value is "No Fraud Tool", remove all other values and keep "No Fraud Tool"
			setFraudTool(['No Fraud Tool'])
		} else {
			// If any other value is selected, remove "No Fraud Tool" and set the remaining selected values
			setFraudTool(
				selectedValues.filter((item: string) => item !== 'No Fraud Tool'),
			)
		}
	}

	const validateFields = (): boolean => {
		if (!name?.trim()) return false
		if (!legalName?.trim()) return false
		if (!paymentTerms?.trim()) return false
		if (!advertiserType?.trim()) return false

		if (advertiserType === 'Direct') {
			const hasAtLeastOneApp = appIdPairs.some(
				(pair) => pair.app_id.trim() !== '',
			)
			if (!hasAtLeastOneApp) return false
		}

		if (!branch?.trim()) {
			if (advertiserType === 'Direct' && !apps.length) return false
			if (advertiserType !== 'Direct') return false
		}

		if (linkToAdvFile && !checkInputStartsWithHttp(linkToAdvFile)) return false
		if (linkToCreative && !checkInputStartsWithHttp(linkToCreative))
			return false
		if (linkToIO && !checkInputStartsWithHttp(linkToIO)) return false
		if (linkedin && !checkInputStartsWithHttp(linkedin)) return false
		if (website && !checkInputStartsWithHttp(website)) return false

		// Validate "name" format
		if (!stringIsValidName(name)) return false

		const trimmedAppIds = appIdPairs?.filter((p) => p.app_id.trim() !== '')
		const uniqueAppIds = new Set(trimmedAppIds.map((el) => el.app_id.trim()))
		if (uniqueAppIds.size !== trimmedAppIds.length) {
			return false
		}

		if (dailyBudgetLimit && monthlyBudgetLimit) {
			if (
				checkValidBudgets(
					parseInt(dailyBudgetLimit.toString()),
					parseInt(monthlyBudgetLimit.toString()),
				)
			) {
				return false
			}
		}

		return true
	}

	const weekCheckerBetweenDates = (date1: number, date2: number) => {
		const WEEK_IN_MS = 604800000
		return date1 + WEEK_IN_MS < date2
	}

	const handlePastOwner = (newOwner: string) => {
		if (newOwner !== ownerEmail && setPastOwner && pastOwner) {
			const currentDate = Date.now()
			if (
				!pastOwner.length ||
				weekCheckerBetweenDates(
					pastOwner[pastOwner.length - 1]?.finishDate,
					currentDate,
				)
			) {
				const lastOwner: Owner = {
					owner: ownerEmail!,
					finishDate: currentDate,
				}
				// Instead of passing a function, update the state directly
				const updatedPastOwners = [...(pastOwner || []), lastOwner]
				setPastOwner(updatedPastOwners)
			}
		}
	}

	const handlePastOwnerFinance = (newOwner: string) => {
		if (newOwner !== ownerEmail && setPastOwnerFinance) {
			const currentFinanceDate = moment().add(5, 'days')
			const currentFinancePeriod = currentFinanceDate.format('01/MM/YYYY')
			const userName =
				users?.users?.find(
					(el: any) =>
						el.email.toLowerCase() === (ownerEmail as string).toLowerCase(),
				)?.name || 'Not Selected'

			const financePastOwner = {
				owner: ownerEmail,
				ownerName: userName,
				financePeriod: currentFinancePeriod,
			}

			if (
				permissions.viewFinancePersonal ||
				permissions.viewFinanceMediaBuying
			) {
				const updatedArr = pastOwnerFinance?.length
					? pastOwnerFinance.map((oldOwner: any) =>
							oldOwner.financePeriod === currentFinancePeriod
								? { ...oldOwner, owner: ownerEmail, ownerName: userName }
								: oldOwner,
					  )
					: [financePastOwner]

				setPastOwnerFinance(updatedArr)
			} else {
				console.warn('No permission to view personal or media buying')
			}
		}
	}

	//we use this in order to generate the different tabs.
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	//function to clear up the entire component in case we go back.
	const nullifyFields = () => {
		setPlatforms([])
		setOwnerEmail(null)
		setApps([])
		setName('')
		setLegalName('')
		setAddress('')
		setGeo([])
		setLinkToIO('')
		setSalesRep('')
		setFraudTool([])
		setLinkToAdvFile('')
		setLinkToCreative('')
		setStatus(false)
		setTeam('')
		setCommunicationChannel([])
		setAdvertiserType('')
		setVertical([])
		setCreatedBy('')
		setPauseReason('')
		setPauseExplanation('')
		setWebsite('')
		setLinkedin('')
		setBrief('')
		setSpecialRequest('')
		setTrafficRestrictions([])
		setExistingChannels([])
		setUsedPublishers([])
		setPastOwner([])
		setPastOwnerFinance([])
		setParametersTemplate('')
		setDailyRevenue(0)
		setMonthlyRevenue(0)
		setTotalRevenue(0)
		setDailyBudgetLimit(0)
		setMonthlyBudgetLimit(0)
		setTotalBudgetLimit(0)
		setBranch('')
		setVat('')
		setContracts([])
		setNotes('')
		setDataSource([])
		setAgencyAccess(false)
	}

	const handleLog = async (payload: any) => {
		const shallow = shallowEqual(prevState, prepareNewState(payload))
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: name! + ' in Advertisers',
				change: JSON.stringify(shallow),
			}
			await insertLog(log)
		}
	}

	const handleUploadSuccess = (filename: string) => {
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 100)
	}

	const handleUploadError = (filename: string) => {
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 100)
	}

	const handleOpenUploadModal = () => setIsUploadModalOpen(true)

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setDeleteModalOpen(true)
		}
	}

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			// const headers = { Authorization: `Bearer ${login.user.token}` }
			deleteIcon('ADVERTISER', row.advertiser_name, row.id)
			setDeleteModalOpen(false)
			setIconUrl(defaultIcon)
		}
	}

	const handleFileUpload = async (files: any) => {
		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				props.setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}
			const id = row.id
			// const headers = {
			// 	Authorization: `Bearer ${login.user.token}`,
			// }

			const uploadResult = await uploadIcon(
				'ADVERTISER',
				file,
				row.advertiser_name,
				id,
			)
			setIconUrl(uploadResult.toString())
		}
	}

	const handleAdvertiserCreated = (id: number) => {
		setTimeout(() => navigate('/advertisers/' + id), 100)
		props.setIsNewAdvertiser(false)
	}

	//function to save the advertiser.
	const trySubmit = async (saveContinue?: boolean) => {
		if (isValid) {
			let payload: any = {
				advertiser_name: name!,
				user_login: login.user.email,
				email: ownerEmail,
				platform: platforms!,
				apps: apps!,
				legal_name: legalName,
				address: address,
				geo: geo,
				link_to_io: linkToIO,
				sales_rep: salesRep,
				status: status,
				fraud_tool: fraudTool,
				link_to_adv_file: linkToAdvFile,
				link_to_creative: linkToCreative,
				contacts: _.cloneDeep(contacts),
				allowed_platforms: allowedPlatforms,
				team: team,
				communication_channel: communicationChannel,
				advertiser_type: advertiserType,
				vertical: vertical,
				created_by: login.user.email,
				payment_terms: paymentTerms,
				pause_reason: pauseReason,
				pause_explanation: pauseExplanation,
				website,
				linkedin,
				brief,
				parameters_template: parametersTemplate,
				special_request: specialRequest,
				traffic_restrictions: JSON.stringify(trafficRestrictions),
				existing_channels: JSON.stringify(existingChannels),
				past_owner: JSON.stringify(pastOwner),
				past_owner_finance: JSON.stringify(pastOwnerFinance),
				daily_budget_limit: dailyBudgetLimit,
				monthly_budget_limit: monthlyBudgetLimit,
				total_budget_limit: totalBudgetLimit,
				branch: branch,
				vat,
				invoice_instructions_finance: invoiceInstructions,
				company_id: login.company.id,
				passwords: currentPasswords,
			}

			payload.apps = formatAppIdPairs(appIdPairs) // Use the helper function

			// const finalAppsArray = appIdPairs
			// 	.filter((p) => p.app_id.trim() !== '') // only keep those with an actual app_id
			// 	.map((p) => ({
			// 		app_id: p.app_id.trim(),
			// 		link: p.link.trim(),
			// 	}))
			const finalAppsArray = Array.from(
				new Map(
					appIdPairs
						.filter((p) => p.app_id.trim() !== '')
						.map((p) => [
							p.app_id.trim(),
							{ app_id: p.app_id.trim(), link: p.link.trim() },
						]),
				).values(),
			)

			payload.apps = finalAppsArray
			if (row) {
				payload.advertiser_name = row.advertiser_name
				payload['new_name'] = name!
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			props.setLoading(true)
			const failed = await insertAdvertiserAction(
				payload,
				props.setErrorMessage,
				handleAdvertiserCreated,
			)
			await updateCompanyData({
				id: payload.company_id,
				agency_access: agencyAccess,
			})
			getAppsAction()
			//if the save is successful then we will handle the logs and navigate to the advertiser page
			//(in case it was a new advertiser, in order to allow the user to edit from now on if he wants)
			if (!failed) {
				// await handleLog(payload)
				if (!saveContinue) {
					nullifyFields()
					navigate('/advertisers')
					props.setPopUpVisible(false)
					props.setIsNewAdvertiser(false)
				} else {
					payload.contacts = contacts
					const obj = _.cloneDeep(payload)
					const prev = preparePrevState(obj)
					setPrevState({ ...prev })
					// props.setIsSuccessPromptOpened(true)
				}
			}
			props.setLoading(false)
		}
	}
	//main function to fill all the data from the row to render it.
	const fillFields = (
		row: AdvertiserInterface,
		appList: string[],
		rawApps: any[],
	) => {
		setPlatforms(row!.platform)
		setOwnerEmail(row!.email)
		setApps(row.apps ? row.apps : appList)
		setName(row!.advertiser_name)
		setLegalName(row!.legal_name)
		setAddress(row!.address)
		setGeo(row!.geo)
		setLinkToIO(row!.link_to_io)
		setSalesRep(row!.sales_rep)
		setFraudTool(row!.fraud_tool)
		setLinkToAdvFile(row!.link_to_adv_file)
		setLinkToCreative(row!.link_to_creative)
		setStatus(row!.status)
		setContacts(row!.contacts)
		setTeam(row!.team)
		setCreatedBy(row!.created_by)
		setCommunicationChannel(
			row.communication_channel ? row.communication_channel : [],
		)
		setPaymentTerms(row!.payment_terms)
		setAdvertiserType(row!.advertiser_type)
		setVertical(row.vertical ? row.vertical : [])
		setAllowedPlatforms(row!.allowed_platforms)
		setPauseReason(row!.pause_reason)
		setPauseExplanation(row!.pause_explanation)
		setWebsite(row!.website)
		setLinkedin(row!.linkedin)
		setBrief(row!.brief)
		setSpecialRequest(row!.special_request)
		setParametersTemplate(row!.parameters_template)
		setUsedPublishers(row!.used_publisher)
		setDailyRevenue(row!.daily_revenue)
		setMonthlyRevenue(row!.monthly_revenue)
		setTotalRevenue(row!.total_revenue)
		setDailyBudgetLimit(row!.daily_budget_limit)
		setMonthlyBudgetLimit(row!.monthly_budget_limit)
		setTotalBudgetLimit(row!.total_budget_limit)
		row!.traffic_restrictions &&
			setTrafficRestrictions(JSON.parse(row!.traffic_restrictions))
		row!.existing_channels &&
			setExistingChannels(JSON.parse(row!.existing_channels))
		row!.past_owner && setPastOwner(JSON.parse(row!.past_owner))
		row!.past_owner_finance &&
			setPastOwnerFinance(JSON.parse(row!.past_owner_finance))
		setBranch(row!.branch)
		setVat(row!.vat)
		setInvoiceInstructions(row!.invoice_instructions)
		setCurrentPasswords(row!.passwords ? row!.passwords : [])
		setContracts(row!.contracts ? row!.contracts : [])
		setNotes(row!.notes)
		setDataSource(row!.data_source)
		setAgencyAccess(hasAgencyAccess)

		const transformed: AppIdLinkPair[] = rawApps.map((item) => {
			if (typeof item === 'string') {
				// old format: just 'app_id'
				return { app_id: item, link: '' }
			} else {
				// new format: { app_id, link }
				return {
					app_id: item.app_id,
					link: item.link || '',
				}
			}
		})

		// If we have fewer than 2, fill up to 2 empty pairs
		while (transformed.length < 2) {
			transformed.push({ app_id: '', link: '' })
		}

		setAppIdPairs(transformed)
	}
	const canView = canAccessResource(
		login?.user,
		row?.email,
		users,
		row?.sales_rep,
	)

	useEffect(() => {
		const fetchAndDecryptContacts = async () => {
			if (row && row?.contacts) {
				try {
					const decryptedContacts = await decryptContacts(row.contacts)

					setContacts(decryptedContacts)
				} catch (error) {
					props.setErrorMessage('Failed to decrypt contact information.')
				}
			}
		}
		fetchAndDecryptContacts()
	}, [row, canView])

	useEffect(() => {
		if (advertiserType === 'Network') {
			setFraudTool(['No Fraud Tool'])
		}
	}, [advertiserType])

	const handleRemoveContact = (contact: ContactInterface) => {
		setContacts((prev) => {
			const updatedContacts = prev.filter((c) => c.uuid !== contact.uuid)
			setContactsChanged(true)
			return updatedContacts
		})
	}

	//function to UPDATE an advertise that already exists.
	const tryUpdate = async (saveContinue?: boolean) => {
		if (isValid) {
			let payload: any = {
				id: uploadedId ? uploadedId : row!.id,
				advertiser_name: name!,
				user_login: login.user.email,
				email: ownerEmail,
				platform: platforms!,
				apps: apps!,
				legal_name: legalName,
				address: address,
				geo: geo,
				link_to_io: linkToIO,
				sales_rep: salesRep,
				status: status,
				fraud_tool: fraudTool,
				link_to_adv_file: linkToAdvFile,
				link_to_creative: linkToCreative,
				contacts: contactsChanged ? _.cloneDeep(contacts) : row.contacts,
				allowed_platforms: allowedPlatforms,
				team: team,
				communication_channel: communicationChannel,
				advertiser_type: advertiserType,
				vertical: vertical,
				created_by: createdBy,
				payment_terms: paymentTerms,
				pause_reason: pauseReason,
				pause_explanation: pauseExplanation,
				website,
				linkedin,
				brief,
				parameters_template: parametersTemplate,
				special_request: specialRequest,
				traffic_restrictions: JSON.stringify(trafficRestrictions),
				existing_channels: JSON.stringify(existingChannels),
				past_owner: JSON.stringify(pastOwner),
				past_owner_finance: JSON.stringify(pastOwnerFinance),
				daily_budget_limit: dailyBudgetLimit,
				monthly_budget_limit: monthlyBudgetLimit,
				total_budget_limit: totalBudgetLimit,
				branch: branch,
				vat,
				invoice_instructions: invoiceInstructions,
				company_id: row.company_id,
				passwords: currentPasswords,
				contracts: contracts,
				notes,
				data_source: dataSource,
			}

			payload.apps = formatAppIdPairs(appIdPairs)
			const finalAppsArray = Array.from(
				new Map(
					appIdPairs
						.filter((p) => p.app_id.trim() !== '')
						.map((p) => [
							p.app_id.trim(),
							{ app_id: p.app_id.trim(), link: p.link.trim() },
						]),
				).values(),
			)
			payload.apps = finalAppsArray

			if (!isCompanyOwner) {
				payload = {
					id: uploadedId ? uploadedId : row!.id,
					user_login: login.user.email,
					email: ownerEmail,
					sales_rep: salesRep,
					status: status,
					pause_reason: pauseReason,
					pause_explanation: pauseExplanation,
					past_owner: JSON.stringify(pastOwner),
					past_owner_finance: JSON.stringify(pastOwnerFinance),
					branch: branch,
					vat,
					invoice_instructions_finance: invoiceInstructions,
					payment_terms: paymentTerms,
					company_id: row.company_id,
					passwords: currentPasswords,
					contracts: contracts,
					notes,
					data_source: dataSource,
				}

				// return
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			props.setLoading(true)

			const failed = isCompanyOwner
				? await updateAdvertiserAction(
						payload,
						//TODO: change the funcionality of this
						{},
						props.setErrorMessage,
				  )
				: await updateAdvertiserMetaAction(payload, props.setErrorMessage)
			if (hasAgencyAccess !== agencyAccess) {
				await updateCompanyData({
					id: row.company_id,
					agency_access: agencyAccess,
				})
			}
			props.setLoading(false)
			getAppsAction()
			if (!failed) {
				try {
					getPasswordsByAdvertiserId(payload.id, setCurrentPasswords)
					// setCurrentPasswords(updatedPasswords)
					payload.contacts = contacts
					const obj = _.cloneDeep(payload)
					const prev = preparePrevState(obj)
					setPrevState({ ...prev })
					navigate('/advertisers/' + row!.id)
					props.setIsNewAdvertiser(false)
					// props.setIsSuccessPromptOpened(true)
					setUploadedId(row!.id)
				} catch (error) {
					console.error(error)
				}
			}
			// await fetchData(getAdvertiserAction, advertiser.advertiser)

			//if everything went correct we want to refill all the data with the updated one
			// fillFields(
			// 	payload,
			// 	app.app
			// 		.filter(
			// 			(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
			// 		)
			// 		.map((a: AppInterface) => a.app_id),
			// 	payload.apps,
			// )
		}
	}

	//this is legacy code. Could probably be removed because we don't use this on the edit page. TODO: refactor ?
	useEffect(() => {
		if (usedPublishers && usedPublishers.length > 0) {
			const arr = publisherList.filter(
				(pub: any) => !usedPublishers.includes(pub),
			)
			setNotUsedPublishers(arr)
		}
	}, [usedPublishers])
	//basically triggering the fill for the selected row.
	useEffect(() => {
		if (row) {
			const updatedAppList = app.app.filter(
				(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
			)
			setAppList(updatedAppList)
			fillFields(
				row,
				updatedAppList.map((a: AppInterface) => a.app_id),
				updatedAppList,
			)
		}
	}, [row, app.app, advertiser.advertiser])

	useEffect(() => {
		if (row) {
			const obj = _.cloneDeep(row)
			const apps = []
			const updatedAppList = app.app.filter(
				(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
			)
			for (const app of updatedAppList) {
				apps.push(app.app_id)
			}
			obj.apps = apps
			const prev = preparePrevState(obj)
			if (!prevState) {
				setPrevState({ ...prev })
			}
		}
	}, [appList])
	//function we use to update the tab we are in.
	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}

	//this use Effect will check if some sensitive data is correct and will allow or not to update/create the advertiser.
	useEffect(() => {
		setIsValid(validateFields())
	}, [
		linkToAdvFile,
		linkToCreative,
		linkToIO,
		linkedin,
		website,
		name,
		dailyBudgetLimit,
		monthlyBudgetLimit,
		prevState,
		advertiserType,
		vertical,
		apps,
		branch,
		legalName,
		paymentTerms,
		appIdPairs,
	])

	//this is handled in order to show the pause reasons (or remove them) based on the status.
	useEffect(() => {
		if (status === false && statusChanged) {
			setViewPauseBox(true)
		} else {
			setViewPauseBox(false)
			setPauseExplanation('')
			setPauseReason('')
		}
	}, [status])

	//this might be legacy code. Team is actually taken from the user now. We should refactor. TODO: refactor
	useEffect(() => {
		for (const user of users.users) {
			if (user.email === ownerEmail) {
				setTeam(user.team)
			}
		}
	}, [ownerEmail])
	//we use this to go back to the main table.
	const closeDetailedView = () => {
		nullifyFields()
		navigate('/advertisers')
		props.setPopUpVisible(false)
		props.setIsNewAdvertiser(false)
	}
	const openPreview = () => {
		navigate('/advertisers/' + params.id + '/preview')
		props.setIsNewAdvertiser(false)
	}
	const tabOptions = [
		{ label: 'Company Information', tabIndex: 0 },
		{ label: 'Traffic & Budget', tabIndex: 1 },
		{ label: 'Finance', tabIndex: 2 },
	]

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				onPreview={openPreview}
				onUpload={isCompanyOwner ? handleOpenUploadModal : undefined}
				tabValue={tab}
				handleChangeTab={handleChangeTab}
				hasPreview={!!(row && permissions.preview && params.id)}
				options={tabOptions}
				customStyles={editPageTopPanelStyle}
				arialabel='basic tabs'
				canEditMasterData={hasAgencyAccess}
			/>

			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={() => setIsUploadModalOpen(false)}
			>
				{uploadStatus ? (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
					>
						<Typography variant='subtitle1'>{uploadStatus.message}</Typography>
						<CheckCircle style={{ color: 'green', fontSize: 40 }} />
					</Box>
				) : (
					<CustomFileUploader
						onSetFiles={handleFileUpload}
						onSuccess={handleUploadSuccess}
						onError={handleUploadError}
						acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
					/>
				)}
			</CustomModal>
			<PageWrapper
				style={{
					marginTop: '2vh',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<CompanyInformationHeader
					name={name}
					iconUrl={handleIconUrl(iconUrl, defaultIcon)}
					onClickIcon={
						iconUrl !== defaultIcon && iconUrl ? handleIconClick : undefined
					}
					tooltipText={
						iconUrl === defaultIcon || !iconUrl
							? 'No icon to delete'
							: 'Click to delete icon'
					}
					cursorStyle={
						iconUrl === defaultIcon || !iconUrl ? 'default' : 'pointer'
					}
					style={{ marginLeft: 21 }}
				/>

				<CustomDialog
					open={isDeleteModalOpen}
					onClose={handleCloseDeleteModal}
					onConfirm={handleDeleteIcon}
					imageUrl={handleIconUrl(iconUrl, defaultIcon)}
					message='Click delete to remove the icon.'
				/>

				{hasAgencyAccess ? (
					<>
						<TabPanel value={tab} index={0}>
							<CompanyInfoComponent
								handlePastOwner={handlePastOwner}
								handlePastOwnerFinance={handlePastOwnerFinance}
								name={name}
								setName={setName}
								legalName={legalName}
								setLegalName={setLegalName}
								address={address}
								setAddress={setAddress}
								geo={geo}
								setGeo={setGeo}
								linkToIO={linkToIO}
								setLinkToIO={setLinkToIO}
								setOwnerEmail={setOwnerEmail}
								ownerEmail={ownerEmail}
								setSalesRep={setSalesRep}
								salesRep={salesRep}
								setStatus={setStatus}
								status={status}
								setApps={setApps}
								apps={apps}
								appList={apps}
								appsData={appIdPairs}
								setAppsData={setAppIdPairs}
								setPlatforms={setPlatforms}
								platforms={platforms}
								setFraudTool={selectFraudTools}
								fraudTool={fraudTool}
								linkToAdvFile={linkToAdvFile}
								setLinkToAdvFile={setLinkToAdvFile}
								linkToCreative={linkToCreative}
								setLinkToCreative={setLinkToCreative}
								team={team}
								setTeam={setTeam}
								communicationChannel={communicationChannel}
								setCommunicationChannel={setCommunicationChannel}
								advertiserType={advertiserType}
								setAdvertiserType={setAdvertiserType}
								vertical={vertical}
								setVertical={setVertical}
								isValid={isValid}
								settings={settings.settings.vertical ? settings : []}
								loginRole={login.user.role}
								createdBy={createdBy}
								loginUser={login.user.email}
								setMainHeightChange={setMainHeightChange}
								pauseReason={pauseReason}
								setPauseReason={setPauseReason}
								pauseExplanation={pauseExplanation}
								setPauseExplanation={setPauseExplanation}
								ViewPauseBox={ViewPauseBox}
								setViewPauseBox={setViewPauseBox}
								setStatusChanged={setStatusChanged}
								website={website}
								setWebsite={setWebsite}
								linkedin={linkedin}
								setLinkedin={setLinkedin}
								brief={brief}
								setBrief={setBrief}
								specialRequest={specialRequest}
								setSpecialRequest={setSpecialRequest}
								setPastOwner={setPastOwner}
								pastOwner={pastOwner}
								setPastOwnerFinance={setPastOwnerFinance}
								pastOwnerFinance={pastOwnerFinance}
								parametersTemplate={parametersTemplate}
								setParametersTemplate={setParametersTemplate}
								users={users}
								mandatoryFields={mandatoryFields}
								fieldValidations={fieldValidations}
								permissions={permissions}
								notes={notes}
								setNotes={setNotes}
								dataSource={dataSource}
								setDataSource={setDataSource}
								agencyAccess={agencyAccess}
								setAgencyAccess={setAgencyAccess}
							/>

							{(canView || permissions.viewPasswords) && (
								<PasswordsComponent
									passwords={currentPasswords || []}
									setPasswords={setCurrentPasswords}
									handleNewPassword={(data: any) => {
										console.log(data)
									}}
									loginRole='User'
									setErrorMessage={() => {
										console.log('error message 1')
									}}
									setDeletedPasswordList={() => {
										console.log('delete passwords')
									}}
									ownerEmail={row?.email}
									salesRep={row?.sales_rep}
								/>
							)}

							{(canView || permissions.viewAllContacts) && (
								<CustomContactsComponent
									contacts={contacts}
									setContacts={handleContactsChange}
									canEdit={permissions.edit}
									roleOptions={settings?.settings?.contactRole || []}
									p360Active={false}
									mode={isCompanyOwner ? 'edit' : 'preview'}
									onRemoveContact={handleRemoveContact}
								/>
							)}
						</TabPanel>
						<TabPanel value={tab} index={1}>
							<TrafficBudgetComponent
								loginRole={login.user.role}
								settings={settings}
								trafficRestrictions={trafficRestrictions}
								setTrafficRestrictions={setTrafficRestrictions}
								existingChannels={existingChannels}
								setExistingChannels={setExistingChannels}
								dailyBudgetLimit={dailyBudgetLimit}
								setDailyBudgetLimit={setDailyBudgetLimit}
								monthlyBudgetLimit={monthlyBudgetLimit}
								setMonthlyBudgetLimit={setMonthlyBudgetLimit}
								totalBudgetLimit={totalBudgetLimit}
								setTotalBudgetLimit={setTotalBudgetLimit}
								permissions={permissions}
							/>
						</TabPanel>
						<TabPanel value={tab} index={2}>
							<AdvertiserFinanceInfo
								loginRole={login.user.role}
								settings={settings}
								branch={branch}
								setBranch={setBranch}
								vat={vat}
								setVat={setVat}
								paymentTerms={paymentTerms}
								setPaymentTerms={setPaymentTerms}
								invoiceInstructions={invoiceInstructions}
								setInvoiceInstructions={setInvoiceInstructions}
								mandatoryFields={mandatoryFields}
								fieldValidations={fieldValidations}
								permissions={permissions}
								contracts={contracts}
								setContracts={setContracts}
							/>
						</TabPanel>
					</>
				) : (
					<AdvertiserMetaEdit
						permissions={permissions}
						handlePastOwner={handlePastOwner}
						handlePastOwnerFinance={handlePastOwnerFinance}
						settings={settings.settings.vertical ? settings : []}
						ownerEmail={ownerEmail}
						setOwnerEmail={setOwnerEmail}
						setSalesRep={setSalesRep}
						salesRep={salesRep}
						setStatus={setStatus}
						status={status}
						setStatusChanged={setStatusChanged}
						pauseReason={pauseReason}
						setPauseReason={setPauseReason}
						pauseExplanation={pauseExplanation}
						setPauseExplanation={setPauseExplanation}
						ViewPauseBox={ViewPauseBox}
						setViewPauseBox={setViewPauseBox}
						branch={branch}
						setBranch={setBranch}
						vat={vat}
						setVat={setVat}
						paymentTerms={paymentTerms}
						setPaymentTerms={setPaymentTerms}
						invoiceInstructions={invoiceInstructions}
						setInvoiceInstructions={setInvoiceInstructions}
						fieldValidations={fieldValidations}
					/>
				)}
				<DialogActions
					style={{
						paddingBottom: '80px',
						display: 'flex',
						alignSelf: 'flex-end',
						justifyContent: 'center',
						width: '100%',
						gap: 80,
					}}
				>
					<UnborderedButton
						// style={{ margin: 'auto' }}
						onClick={() => {
							nullifyFields()
							navigate('/advertisers')
							props.setPopUpVisible(false)
							props.setIsNewAdvertiser(false)
						}}
						label={'Cancel'}
					></UnborderedButton>
					{permissions.edit && (
						<SubmitButton
							// style={{ margin: 'auto' }}
							disabled={!isValid}
							onClick={() => {
								if (!!row) {
									tryUpdate(true)
								} else {
									trySubmit(true)
								}
							}}
						>
							{row ? 'Update' : 'Save'}
						</SubmitButton>
					)}
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}
export default AdvertiserPopUp
