import { ActionType } from '../action-types'

export const setShowMore = (payload: {
	showMore: boolean
	showMoreValue: any
	showMoreTitle: string
	searchBar: boolean
}) => {
	return {
		type: ActionType.SET_SHOW_MORE,
		payload: payload,
	}
}

export const clearShowMore = () => {
	return {
		type: ActionType.CLEAR_SHOW_MORE,
	}
}
