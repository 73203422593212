import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Box, Grid, Modal, Typography, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageWrapper } from '../../components/reusableComponents'
import ReactCountryFlag from 'react-country-flag'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Public } from '@mui/icons-material'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LanguageIcon from '@mui/icons-material/Language'
import { ReactComponent as DriveIcon } from '../../assets/svg/driveIcon.svg'
import { Property } from 'csstype'
import {
	ContactField,
	ExpandableInfoField,
	TitleComponent,
	PasswordField,
	SectionTitle,
} from '../../components/Informative'
import {
	canAccessResource,
	decryptContacts,
	formatDisplayValue,
	getApplicableRules,
	getCompanyAgencyAccess,
} from '../../../utils/helpers/helperFuncs'
import { SubmitButton, UnborderedButton } from '../../components/Buttons'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as MedalIcon } from '../../../assets/svg/medal-icon.svg'
import { ReactComponent as GraphBarsIcon } from '../../../assets/svg/graph-bars-icon.svg'
import { ReactComponent as LockerIcon } from '../../../assets/svg/locker-icon.svg'
import { ReactComponent as CartIcon } from '../../../assets/svg/cart-icon.svg'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { ContactInterface } from '../../../models/contact.interface'
import CustomContactsComponent from '../../components/contacts/CustomContactsComponent'
import {
	CountryPayoutTag,
	PreviewArrayInfoTag,
	PreviewContainer,
	PreviewIconsComponent,
	PreviewInfoTag,
	PreviewIOExpirationTag,
} from '../../components/PreviewComponents'
import { ContractsInterface } from '../../../models/model.interface'
import { useShowMoreActions } from '../../../hooks/useActions'
import DocumentPreview from '../../components/DocumentPreview'
import IOsModal from '../../components/IOsComponent'
import { parse } from 'date-fns'
import { PasswordsPreviewComponent } from '../../components/passwords/PasswordsPreviewComponent'
import SocialLinks from '../../components/SocialLinksHeader'
import PreviewTitle from '../../components/preview/PreviewTitle'

interface Password {
	password: string
	username: string
	url: string
	whiteList: string[]
}
const InformationContainer = (props: {
	publisher: any
	closeDetailedView: any
	openEdit: any
	skypeGroupUserAllowed: boolean
	permissionContacts: boolean
	permissionsGeneral: any
	PopUpVisible: any
	setPopUpVisible: any
	setSuccessMessage: any
	contacts: any
}) => {
	const theme = useTheme()
	const { users, login, settings } = useTypedSelector((state) => state)
	const isAdveriserTypeCompany = login.company.type === 'advertiser'
	const isAgencyTypeCompany = login.company.type === 'agency'
	const permissions = {
		edit:
			useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER) &&
			isAgencyTypeCompany,
		viewPublisherContact: useIsAuthorized(
			permissionNames.VIEW_PUBLISHER_CONTACTS,
		),
		viewPasswords: useIsAuthorized(permissionNames.VIEW_PUBLISHER_PASSWORDS),
	}
	const [filteredPids, setFilteredPids] = useState<any>([])
	const [dummyPids, setDummyPids] = useState<any>([])
	const [passwords, setPasswords] = useState<Password[]>(
		props.publisher.passwords || [],
	)

	const { setShowMore } = useShowMoreActions()

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}

	const handleShowMoreIOs = () => {
		setIsIOSectionOpen(true)
	}
	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const [selectedFile, setSelectedFile] = useState('')
	const [isIOSectionOpen, setIsIOSectionOpen] = useState(false)

	const canView =
		canAccessResource(
			login.user,
			props.publisher.email,
			users,
			props.publisher.sales_rep,
		) || permissions.viewPasswords
	useEffect(() => {
		const arr = []
		const dummies = []
		if (props.publisher.external_id) {
			for (const pid of props.publisher.media_source_pid) {
				if (!pid.endsWith(props.publisher.external_id)) {
					arr.push(pid)
				} else {
					dummies.push(pid)
				}
			}
		}
		setFilteredPids(arr)
		setDummyPids(dummies)
	}, [props.publisher])

	const handleClickedLink = (link: string) => {
		if (!link.startsWith('http')) {
			return window.open('http://' + link)
		}
		return window.open(link)
	}
	const financialContacts: any = []
	const mainContacts: any = []
	props.permissionContacts &&
		props.contacts &&
		props.contacts.forEach((contact: any) => {
			if (contact.type === 'finance') {
				financialContacts.push(contact)
			} else {
				mainContacts.push(contact)
			}
		})
	const badges = props.publisher.media_buying_capabilities?.badges
	const updatePasswords = (oldPassword: string, newPassword: string) => {
		const updatedPasswords = passwords.map((pwd) =>
			pwd.password === oldPassword ? { ...pwd, password: newPassword } : pwd,
		)
		setPasswords(updatedPasswords)
	}

	const accessiblePasswords = passwords.filter(
		(passwordItem: Password) =>
			canView || passwordItem.whiteList?.includes(login.user.name),
	)
	const hasAgencyAccess = getCompanyAgencyAccess(props.publisher?.company_id)
	const latestContract =
		props.publisher?.contracts?.reduce(
			(closest: ContractsInterface | null, contract: ContractsInterface) => {
				if (!contract.expiration) return closest

				const contractExpiration = parse(
					contract.expiration,
					'dd/MM/yyyy',
					new Date(),
				)
				if (isNaN(contractExpiration.getTime())) return closest

				return !closest ||
					contractExpiration <
						parse(closest.expiration, 'dd/MM/yyyy', new Date())
					? contract
					: closest
			},
			null,
		) || {}

	if (latestContract && latestContract.expiration) {
		latestContract.expiration = parse(
			latestContract.expiration,
			'dd/MM/yyyy',
			new Date(),
		).toLocaleDateString('en-GB')
	}
	const pastOwners = props.publisher?.past_owner_finance
		? JSON.parse(props.publisher?.past_owner_finance).join(', ')
		: ''
	const pids = props.publisher?.media_source_pid
		? props.publisher?.media_source_pid.join(', ')
		: ''
	const badgesIcons = [
		{
			icon: (
				<MedalIcon
					style={{
						color: badges?.good_quality
							? theme.colors.status.green
							: theme.colors.base.grey300,
						scale: '0.8',
						marginTop: 2,
					}}
				/>
			),
			name: 'Good Quality',
		},
		{
			icon: (
				<LockerIcon
					style={{
						color: badges?.low_fraud
							? theme.colors.status.green
							: theme.colors.base.grey300,
						scale: '0.8',
					}}
				/>
			),
			name: 'Low Fraud',
		},
		{
			icon: (
				<GraphBarsIcon
					style={{
						color: badges?.high_volume
							? theme.colors.status.green
							: theme.colors.base.grey300,
						scale: '0.8',
						marginTop: 0,
						marginLeft: -10,
					}}
				/>
			),
			name: 'High Volume',
		},
		{
			icon: (
				<CartIcon
					style={{
						color: badges?.high_cr
							? theme.colors.status.green
							: theme.colors.base.grey300,
						scale: '0.8',
						marginTop: 5,
						marginLeft: -12,
					}}
				/>
			),
			name: 'High CR',
		},
	]
	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
				<div style={{ display: 'flex', alignItems: 'center', width: '95%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							marginLeft: '-0.5rem',
						}}
					>
						<UnborderedButton
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								color: theme.colors.text.titles,
							}}
							onClick={props.closeDetailedView}
							icon={<ArrowBackIosIcon style={{ height: '16px' }} />}
						>
							Back
						</UnborderedButton>
					</div>
					{permissions.edit && (
						<UnborderedButton
							style={{
								display: 'flex',
								color: theme.colors.text.titles,
								marginLeft: 'auto',
								justifyContent: 'flex-end',
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<PreviewTitle
					name={props.publisher.publisher_name}
					iconUrl={props.publisher.publisher_icon}
					status={props.publisher.status}
					id={props.publisher.external_id}
					links={{
						website: props.publisher.link_to_website,
						linkedin: props.publisher.link_to_linkedin,
						io: props.publisher.link_to_io,
						pub: props.publisher.link_to_pub,
					}}
					badges={badgesIcons}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 30,
						paddingBottom: 18,
					}}
				>
					<PreviewContainer
						gridTemplate='repeat(12, 1fr)'
						additionalStyle={{ paddingBottom: 48 }}
					>
						{/* First Row (1 : 2 : 1) → (3, 6, 3) */}
						<PreviewInfoTag
							label='Legal Name'
							value={props.publisher?.legal_name}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={3}
						/>
						<PreviewInfoTag
							label='Address'
							value={props.publisher?.address}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={3}
						/>
						<PreviewInfoTag
							label='Head Office Geo'
							value={props.publisher?.geo}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
							isCountry={true}
						/>
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Internal Legal Entity'
								value={props.publisher?.branch}
								showMoreClick={handleShowMoreClick}
								gridColumnSpan={2}
							/>
						)}
						<PreviewInfoTag
							label={'Agency Access'}
							value={hasAgencyAccess ? 'Allowed' : 'Denied'}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>

						{/* Second Row (1 : ⅓ of 2 : ⅓ of 2 : ⅓ of 2 : 1) → (3, 2, 2, 2, 3) */}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Finance ID'
								value={props.publisher?.finance_id}
								showMoreClick={handleShowMoreClick}
								gridColumnSpan={3}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Payment Term'
								value={props.publisher?.payment_term}
								showMoreClick={handleShowMoreClick}
								gridColumnSpan={3}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='VAT'
								value={props.publisher?.vat}
								showMoreClick={handleShowMoreClick}
								gridColumnSpan={2}
							/>
						)}

						{isAgencyTypeCompany && (
							<PreviewIOExpirationTag
								latestContract={latestContract}
								showMoreClick={handleShowMoreIOs}
								setSelectedFile={setSelectedFile}
								setIsPreviewOpen={setIsPreviewOpen}
								gridColumnSpan={4}
							/>
						)}
					</PreviewContainer>
					<PreviewContainer gridTemplate='repeat(4, 1fr)'>
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='External ID'
								value={props.publisher?.external_id}
								showMoreClick={handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Skype Group'
								value={
									props.publisher?.public_skype === true ? 'Open' : 'Private'
								}
								showMoreClick={handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Skype Link'
								value={
									props.publisher.public_skype
										? props.publisher?.skype_group
										: props?.skypeGroupUserAllowed
										? props.publisher?.skype_group
										: '******'
								}
								showMoreClick={handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Owner'
								value={props.publisher?.email}
								showMoreClick={handleShowMoreClick}
							/>
						)}
						{isAgencyTypeCompany && (
							<PreviewInfoTag
								label='Past Owner'
								value={pastOwners}
								showMoreClick={handleShowMoreClick}
							/>
						)}
						<PreviewInfoTag
							label='Sales Representative'
							value={props.publisher?.sales_rep}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label='PIDs'
							value={pids}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={isAgencyTypeCompany ? 2 : 3}
						/>
					</PreviewContainer>
					<PreviewContainer gridTemplate='repeat(2, 1fr)'>
						<PreviewInfoTag
							label={'Brief'}
							value={props.publisher?.notes}
							gridRowSpan={2}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Special Requests'}
							value={
								props.publisher?.media_buying_capabilities
									?.special_request_list || 'No Special Request At This Moment'
							}
							gridRowSpan={2}
							showMoreClick={handleShowMoreClick}
						/>
					</PreviewContainer>
				</div>
				<Modal
					open={isPreviewOpen}
					onClose={() => setIsPreviewOpen(false)}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Box
						sx={{
							width: '80vw',
							height: '80vh',
							backgroundColor: theme.colors.base.white,
							padding: 2,
							borderRadius: 2,
							boxShadow: 24,
						}}
					>
						{selectedFile && <DocumentPreview doc={[{ uri: selectedFile }]} />}
					</Box>
				</Modal>
				<IOsModal
					open={isIOSectionOpen}
					onClose={() => {
						setIsIOSectionOpen(false)
					}}
					existingContracts={props.publisher?.contracts || []}
					onSave={() => {}}
					isPreview={true}
				/>

				{dummyPids.length > 0 && (
					<Grid item xs={12}>
						<ExpandableInfoField label={'Dummy PIDs'} value={dummyPids} />
					</Grid>
				)}

				{props.contacts.length > 0 &&
					(canView || permissions.viewPublisherContact) && (
						<CustomContactsComponent
							contacts={props.contacts}
							setContacts={() => {}}
							p360Active={true}
							canEdit={false} // Read-only mode
							roleOptions={settings?.settings?.contactRole || []}
							mode='preview'
						/>
					)}

				{isAgencyTypeCompany && accessiblePasswords.length > 0 && (
					<PasswordsPreviewComponent
						passwords={accessiblePasswords}
						ownerEmail={props.publisher.email}
						additionalStyle={{ marginTop: '1.2rem' }}
						showMoreClick={handleShowMoreClick}
					/>
				)}
			</div>
		</>
	)
}
export const CapabilitiesInfoContainer = (props: { capabilities: any }) => {
	const { setShowMore } = useShowMoreActions()

	const theme = useTheme()

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}
	const stringifyArray = (array: { label: string; value: any }[]): string => {
		if (!Array.isArray(array) || array.length === 0) return ''

		return array.map((item) => item.label).join(' - ')
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				paddingBottom: 30,
			}}
		>
			<Box
				display='flex'
				alignItems='center'
				sx={{
					marginLeft: '2.5rem',
					marginTop: '1rem',
				}}
			>
				<SectionTitle
					text='Capabilities'
					marginBottom='0rem'
					marginTop='0rem'
				/>
			</Box>
			<PreviewContainer
				gridTemplate='repeat(4, 1fr)'
				additionalStyle={{ background: 'none', border: 'none', paddingTop: 16 }}
			>
				<PreviewInfoTag
					label='Type Of Publisher'
					value={stringifyArray(props.capabilities.type_publisher)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={4}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Devices'
					value={stringifyArray(props.capabilities.devices)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Available Platforms'
					value={stringifyArray(props.capabilities.available_platforms)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Fraud Tools'
					value={stringifyArray(props.capabilities.fraud_tools)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Payment Modal'
					value={stringifyArray(props.capabilities.payment_model)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Ad Type'
					value={stringifyArray(props.capabilities.ad_type)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={4}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Ad Format Type'
					value={stringifyArray(props.capabilities.format_types)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewArrayInfoTag
					label='Traffic Indicators'
					value={
						props.capabilities.traffic_indicators.map((el: any) => el.value) ||
						[]
					}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewArrayInfoTag>
				<PreviewInfoTag
					label='Abilities'
					value={stringifyArray(props.capabilities.abilities)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Daily Cap'
					value={stringifyArray(props.capabilities.daily_cap)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={1}
				></PreviewInfoTag>
				<PreviewInfoTag
					label='Verticals'
					value={stringifyArray(props.capabilities.relevant_categories)}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={4}
				></PreviewInfoTag>
				<CountryPayoutTag
					label='Country - Volume - Payout'
					countries={props.capabilities.country}
					showMoreClick={handleShowMoreClick}
					gridColumnSpan={4}
				></CountryPayoutTag>
			</PreviewContainer>
		</div>
	)
}
//the main preview page render.
const PublisherPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	viewRecord: any
	setViewRecord: any
	PopUpVisible: any
	setPopUpVisible: any
	isCompanyPersonalView?: boolean
	companyData?: any
	setSuccessMessage: any
}) => {
	const theme = useTheme()
	const { publisher, users, settings, login } = useTypedSelector(
		(state) => state,
	)

	const [contacts, setContacts] = useState<ContactInterface[]>([])

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER),
		viewPublisherContact: useIsAuthorized(
			permissionNames.VIEW_PUBLISHER_CONTACTS,
		),
		viewLinks: useIsAuthorized(permissionNames.VIEW_LINKS),
	}

	const navigate = useNavigate()
	const params = useParams()
	let row: any
	if (props.isCompanyPersonalView) {
		row = props.companyData
	} else {
		row =
			publisher.publisher.find((e: any) => {
				return e.id.toString() === params.id!.trim()
			}) || {}
	}

	const canView = canAccessResource(login.user, row.email, users, row.sales_rep)

	useEffect(() => {
		const decryptContactsData = async () => {
			if (
				row &&
				row.contacts &&
				(canView || permissions.viewPublisherContact)
			) {
				try {
					const decryptedContacts = await decryptContacts(row.contacts)

					setContacts(decryptedContacts)
				} catch (error) {
					console.error('Failed to decrypt contact information:', error)
				}
			}
		}
		decryptContactsData()
	}, [row.contacts, canView])

	const [skypeAllowedUsers, setSkypeAllowedUsers] = useState<string[]>([])
	const [skypeGroupUserAllowed, setSkypeGroupUserAllowed] =
		useState<boolean>(false)
	const closeDetailedView = () => {
		if (props.isCompanyPersonalView) {
			navigate('/companySettings')
		} else {
			navigate('/publishers')
		}
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/publishers/' + row.id)
	}

	useEffect(() => {
		const allowedList = []
		if (settings.settings && users.users) {
			for (const teamLeader of settings.settings.teams) {
				for (const user of users.users) {
					if (
						(teamLeader === user.name ||
							user.role === 'super' ||
							user.position?.toLowerCase() === 'publisher manager' ||
							user.position?.toLowerCase() ===
								'global publisher managers team lead') &&
						allowedList.indexOf(user.email) === -1
					) {
						allowedList.push(user.email)
					}
				}
			}
			setSkypeAllowedUsers(allowedList)
		}
	}, [settings, users])

	useEffect(() => {
		if (skypeAllowedUsers.length > 0) {
			if (skypeAllowedUsers.indexOf(login.user.email) >= 0) {
				setSkypeGroupUserAllowed(true)
			}
		}
	}, [skypeAllowedUsers])

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				gap: '0px',
				paddingBottom: '10px',
			}}
		>
			<InformationContainer
				publisher={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				skypeGroupUserAllowed={skypeGroupUserAllowed}
				permissionContacts={permissions.viewPublisherContact || canView}
				permissionsGeneral={permissions}
				PopUpVisible={props.PopUpVisible}
				setPopUpVisible={props.setPopUpVisible}
				setSuccessMessage={props.setSuccessMessage}
				contacts={contacts}
			/>
			{row?.media_buying_capabilities && (
				<CapabilitiesInfoContainer
					capabilities={row.media_buying_capabilities}
				/>
			)}
		</PageWrapper>
	)
}

export default PublisherPreviewPage
