import {
	DesktopDatePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import AdapterDateFns from '@date-io/date-fns'
import {
	Dispatch,
	SetStateAction,
	useState,
	useRef,
	useCallback,
	memo,
	useEffect,
} from 'react'
import {
	Button,
	Box,
	ClickAwayListener,
	Typography,
	IconButton,
	useTheme,
} from '@mui/material'
import {
	startOfMonth,
	subMonths,
	addMonths,
	format,
	isSameDay,
	isWithinInterval,
	isSameMonth,
	startOfWeek,
	addDays,
	endOfMonth,
	endOfWeek,
	isAfter,
	subDays,
	startOfDay,
	endOfDay,
} from 'date-fns'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
	DatePickerContainer,
	CalendarContainer,
	Header,
	DaysRow,
	DayContainer,
	WeekdayHeader,
	DatePickerInputBoxContainer,
	StyledTextField,
	containerStyle,
	innerPlaceholderInputStyle,
	dropdownWrapperStyle,
	rangeSelectorStyle,
	activeRangeButtonStyle,
	rangeButtonStyle,
} from './DateInputsStyles'
import { StyledLabel } from '../Informative'
import { useDebounce } from '../../../utils/helpers/hooks'

const TIME_PERIODS = {
	'24 Hours': 1,
	'7 Days': 7,
	'30 Days': 30,
	Custom: null,
} as const

type TimePeriodKeys = keyof typeof TIME_PERIODS

export const DatePicker = memo(
	(props: {
		onChange: Dispatch<SetStateAction<any>>
		value: Date | string | null
		disabled: boolean
		label: string
		format: string
		sx?: any
		mandatory?: boolean
	}) => {
		const theme = useTheme()
		const { onChange, value, disabled, label, format, sx } = props
		const [focused, setFocused] = useState(false)
		const [hovered, setHovered] = useState(false)
		const inputRef = useRef<any>(null)
		const [position, setPosition] = useState({ top: 0, left: 0 })

		useEffect(() => {
			if (inputRef.current) {
				const inputPosition = inputRef.current.getBoundingClientRect()
				const newPosition = { top: inputPosition.top, left: inputPosition.left }
				setPosition(newPosition)
			}
		}, [])
		const responsiveStyle = () => {
			const style: any = {
				border: '1px solid ' + theme.colors.base.grey300,
				borderRight: '6px solid ' + theme.colors.base.grey300,
				borderRadius: '5px',
				...(sx || {}),
			}
			if (disabled) {
				style.background = theme.colors.base.grey50
				return style
			}
			if (focused) {
				style.borderRight = '6px solid ' + theme.colors.base.green200
			}
			if (!value && hovered) {
				style.borderRight = '6px solid ' + theme.colors.base.green200
			}
			if (value) {
				style.borderRight = '6px solid ' + theme.colors.base.green200
			}

			return style
		}
		return (
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<div
					style={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						position: 'relative',
					}}
					ref={inputRef}
				>
					<StyledLabel>
						{label}
						{props.mandatory && (
							<span
								style={{
									color: `${theme.colors.base.red300}`,
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}
							>
								*
							</span>
						)}
					</StyledLabel>
					<DesktopDatePicker
						onChange={(e: any) => {
							onChange(e)
						}}
						value={value}
						inputFormat={format}
						disabled={disabled}
						OpenPickerButtonProps={{}}
						renderInput={(params: any) => (
							<StyledTextField
								{...params}
								focused
								style={responsiveStyle()}
								disabled={disabled}
							/>
						)}
						PaperProps={{
							sx: {
								display: 'flex',
								position: 'absolute',
								top: position.top,
								left: position.left,
							},
						}}
						PopperProps={{
							sx: {
								transform: 'none !important', // Stops the calendar from moving X when scrolling
							},
						}}
					/>
				</div>
			</LocalizationProvider>
		)
	},
)

interface CustomDatePickerProps {
	numberOfCalendars: number
	startDate: Date | null
	endDate: Date | null
	onDateChange: (date: Date) => void
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = memo(
	({ numberOfCalendars, startDate, endDate, onDateChange }) => {
		const theme = useTheme()
		const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()))
		const maxDate = startOfMonth(new Date())
		const minDate = subMonths(new Date(), 5)

		const handlePrevMonth = useCallback(() => {
			if (currentMonth > minDate) {
				setCurrentMonth(subMonths(currentMonth, 1))
			}
		}, [currentMonth, minDate])

		const handleNextMonth = useCallback(() => {
			if (currentMonth < maxDate) {
				setCurrentMonth(addMonths(currentMonth, 1))
			}
		}, [currentMonth, maxDate])

		const renderHeader = useCallback((date: Date) => {
			return (
				<Header>
					<Typography variant='h6' style={{ fontSize: theme.font.size.small }}>
						{format(date, 'MMMM yyyy')}
					</Typography>
				</Header>
			)
		}, [])

		const renderDays = useCallback(() => {
			const days = []
			const startDate = startOfWeek(new Date(), { weekStartsOn: 1 })

			for (let i = 0; i < 7; i++) {
				days.push(
					<WeekdayHeader key={i}>
						{format(addDays(startDate, i), 'EEE')}
					</WeekdayHeader>,
				)
			}

			return <DaysRow>{days}</DaysRow>
		}, [])

		const renderCells = useCallback(
			(date: Date) => {
				const monthStart = startOfMonth(date)
				const monthEnd = endOfMonth(monthStart)
				const startDateOfMonth = startOfWeek(monthStart, { weekStartsOn: 1 })
				const endDateOfMonth = endOfWeek(monthEnd, { weekStartsOn: 1 })
				const rows = []
				let days = []
				let day = startDateOfMonth

				while (day <= endDateOfMonth) {
					const weekStart = startOfWeek(day, { weekStartsOn: 1 })
					days = []
					for (let i = 0; i < 7; i++) {
						const currentDay = addDays(weekStart, i)
						const isCurrentMonth = isSameMonth(currentDay, monthStart)
						const isSelected = !!(
							(startDate && isSameDay(currentDay, startDate)) ||
							(endDate && isSameDay(currentDay, endDate))
						)
						const isInRange = !!(
							startDate &&
							endDate &&
							isWithinInterval(currentDay, { start: startDate, end: endDate })
						)
						const isStartDate = !!startDate && isSameDay(currentDay, startDate)
						const isEndDate = !!endDate && isSameDay(currentDay, endDate)
						const isdisabled = isAfter(currentDay, new Date())

						days.push(
							<DayContainer
								key={currentDay.toString()}
								iscurrentmonth={isCurrentMonth ? 1 : 0}
								isselected={isSelected ? 1 : 0}
								isinrange={isInRange ? 1 : 0}
								isstartdate={isStartDate ? 1 : 0}
								isenddate={isEndDate ? 1 : 0}
								isdisabled={isdisabled ? 1 : 0}
								onClick={() => {
									if (!isdisabled) {
										onDateChange(new Date(currentDay))
									}
								}}
							>
								{format(currentDay, 'd')}
							</DayContainer>,
						)
					}
					rows.push(<DaysRow key={day.toString()}>{days}</DaysRow>)
					day = addDays(day, 7)
				}
				return <div>{rows}</div>
			},
			[startDate, endDate, onDateChange],
		)

		const renderCalendars = useCallback(() => {
			const calendars = []
			for (let i = -1; i < numberOfCalendars - 1; i++) {
				const month = addMonths(currentMonth, i)
				if (month > maxDate) break // Stop rendering if the month is beyond the current month
				calendars.push(
					<CalendarContainer key={i}>
						{renderHeader(month)}
						{renderDays()}
						{renderCells(month)}
					</CalendarContainer>,
				)
			}
			return calendars
		}, [
			currentMonth,
			numberOfCalendars,
			renderHeader,
			renderDays,
			renderCells,
			maxDate,
		])

		return (
			<DatePickerContainer>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						position: 'absolute',
						top: 0,
					}}
				>
					<IconButton
						onClick={handlePrevMonth}
						size='small'
						disabled={currentMonth <= minDate}
					>
						<ArrowBackIcon fontSize='small' />
					</IconButton>
					<IconButton
						onClick={handleNextMonth}
						size='small'
						disabled={currentMonth >= maxDate}
					>
						<ArrowForwardIcon fontSize='small' />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						width: '100%',
						marginTop: '2rem',
					}}
				>
					{renderCalendars()}
				</Box>
			</DatePickerContainer>
		)
	},
)

interface DatePickerComponentProps {
	onDateChange?: (startDate: Date | null, endDate: Date | null) => void
	checked: boolean
	label: string
	placeholder: string
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
	onDateChange,
	label,
	placeholder,
	checked,
}) => {
	const theme = useTheme()
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)
	const [range, setRange] = useState<string>('Custom')
	const [open, setOpen] = useState<boolean>(false)
	const containerRef = useRef<HTMLDivElement | null>(null)

	const timePeriodsRanges = Object.keys(TIME_PERIODS)

	const handleClickAway = useCallback((event: MouseEvent | TouchEvent) => {
		const target = event.target as Node

		if (containerRef.current && !containerRef.current.contains(target)) {
			setOpen(false)
		}
	}, [])

	const handleButtonClick = useCallback(() => {
		setOpen((prevOpen) => !prevOpen)
	}, [])

	const formatDate = (date: Date | null): string => {
		return date ? format(date, 'dd/MM/yyyy') : ''
	}

	const debouncedOnDateChange = useDebounce(onDateChange ?? (() => {}), 150)
	const handleDateChange = useCallback(
		(date: Date) => {
			let normalizedDate = startOfDay(date) // Default to start of the day (00:00:00)

			if (!startDate) {
				setStartDate(normalizedDate)
				if (endDate && isAfter(normalizedDate, endDate)) {
					setEndDate(null)
				}
				debouncedOnDateChange(normalizedDate, endDate)
			} else if (!endDate) {
				if (
					isAfter(normalizedDate, startDate) ||
					isSameDay(normalizedDate, startDate)
				) {
					const endOfDayDate = endOfDay(normalizedDate) // Set time to 23:59:59.999
					setEndDate(endOfDayDate)
					debouncedOnDateChange(startDate, endOfDayDate)
				} else {
					setEndDate(null)
				}
			} else {
				if (
					isSameDay(normalizedDate, startDate) ||
					isSameDay(normalizedDate, endDate)
				) {
					setStartDate(null)
					setEndDate(null)
					debouncedOnDateChange(null, null)
				} else {
					setStartDate(normalizedDate)
					setEndDate(null)
					debouncedOnDateChange(normalizedDate, null)
				}
			}
		},
		[startDate, endDate, debouncedOnDateChange],
	)

	const handleRangeChange = useCallback(
		(range: TimePeriodKeys) => {
			setRange(range)
			const endDate = new Date()
			const days = TIME_PERIODS[range]
			const startDate = days !== null ? subDays(endDate, days) : null
			setStartDate(startDate)
			setEndDate(endDate)
			if (startDate) {
				debouncedOnDateChange(startDate, endDate)
			}
		},
		[debouncedOnDateChange],
	)
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ClickAwayListener onClickAway={handleClickAway}>
				<Box style={containerStyle} ref={containerRef}>
					<StyledLabel>{label}</StyledLabel>
					<DatePickerInputBoxContainer onClick={handleButtonClick}>
						<CalendarMonthIcon style={{ color: theme.colors.text.titles }} />
						{startDate && endDate ? (
							<span
								style={{
									color: theme.colors.text.titles,
									fontSize: theme.font.size.caption,
								}}
							>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</span>
						) : (
							<span style={innerPlaceholderInputStyle(theme)}>
								{placeholder}
							</span>
						)}
						<ArrowDropDownIcon />
					</DatePickerInputBoxContainer>
					{open && (
						<Box id='date-range-picker' sx={dropdownWrapperStyle(theme)}>
							<Box>
								<Box style={rangeSelectorStyle(theme)}>
									{timePeriodsRanges.map((option) => (
										<Button
											key={option}
											onClick={() =>
												handleRangeChange(option as TimePeriodKeys)
											}
											style={
												range === option
													? activeRangeButtonStyle(theme)
													: rangeButtonStyle(theme)
											}
										>
											{option}
										</Button>
									))}
								</Box>
								<CustomDatePicker
									numberOfCalendars={2}
									startDate={startDate}
									endDate={endDate}
									onDateChange={handleDateChange}
								/>
							</Box>
						</Box>
					)}
				</Box>
			</ClickAwayListener>
		</LocalizationProvider>
	)
}
