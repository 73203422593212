import { Public } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Box, Grid, Modal, Tooltip, useTheme } from '@mui/material'
import { Property } from 'csstype'
import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useNavigate, useParams } from 'react-router'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	canAccessResource,
	decryptContacts,
	decryptIfNeeded,
	getApplicableRules,
	getCompanyAgencyAccess,
} from '../../utils/helpers/helperFuncs'
import { PageWrapper } from '../components/reusableComponents'
import LinkIcon from '@mui/icons-material/Link'
import { PasswordField } from '../components/Informative'
import {
	ReadMoreInlineButton,
	SubmitButton,
	UnborderedButton,
} from '../components/Buttons'
import {
	ContactField,
	ExpandableInfoField,
	StatusTitleTag,
	TitleComponent,
	TitleTag,
} from '../components/Informative'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import SocialLinks from '../components/SocialLinksHeader'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import {
	CategoryDivider,
	PreviewArrayInfoTag,
	PreviewContainer,
	PreviewInfoTag,
	PreviewIOExpirationTag,
	PreviewLabel,
	TruncatedTextWithReadMore,
} from '../components/PreviewComponents'
import CustomContactsComponent from '../components/contacts/CustomContactsComponent'
import { useDispatch } from 'react-redux'
import { useShowMoreActions, useSuccessActions } from '../../hooks/useActions'
import { ContactInterface } from '../../models/contact.interface'
import { de } from 'date-fns/locale'
import { ReactComponent as CopyIcon } from '../../assets/svg/copy-icon.svg'
import { ContractsInterface } from '../../models/model.interface'
import DocumentPreview from '../components/DocumentPreview'
import IOsModal from '../components/IOsComponent'
import CustomModal from '../components/CustomModal'
import { parse } from 'date-fns'
import { PasswordsPreviewComponent } from '../components/passwords/PasswordsPreviewComponent'
import PreviewTitle from '../components/preview/PreviewTitle'
import { Company } from '../../state/actions'

export const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: Property.WhiteSpace
	isLink?: boolean
	link?: string
}) => {
	const theme = useTheme()
	const goToLink = () => {
		window.open(props.link, '_blank')
	}
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				fontWeight: theme.font.weight.normal,
				// whiteSpace: props.whiteSpace ? props.whiteSpace : 'nowrap',
				// alignItems: props.isCountry ? 'center' : 'flex-start',
				alignItems: 'center',
				// flexWrap: 'wrap',
			}}
			key={props.label + '_key'}
		>
			<span
				style={{
					fontSize: theme.font.size.body,
					color: theme.colors.base.grey600,
					fontWeight: theme.font.weight.normal,
				}}
			>
				{props.label ? props.label + ':' : ''}
			</span>
			{props.isCountry ? (
				Array.isArray(props.value) ? (
					props.value.map((country: string) => {
						return (
							<React.Fragment key={country + 'key' + props.label}>
								<ReactCountryFlag
									style={{
										height: '9px',
										borderRadius: '100px',
										border: `1px solid ${theme.colors.black}`,
									}}
									key={'flags'}
									className='roundedFlag'
									countryCode={country === 'UK' ? 'GB' : country}
									svg
								/>
								<span
									style={{
										fontWeight: theme.font.weight.normal,
										overflowWrap: 'anywhere',
									}}
								>
									{country}
								</span>
							</React.Fragment>
						)
					})
				) : (
					<>
						<ReactCountryFlag
							style={{
								height: '9px',
								borderRadius: '100px',
								border: `1px solid ${theme.colors.black}`,
							}}
							key={'flags'}
							className='roundedFlag'
							countryCode={props.value === 'UK' ? 'GB' : props.value}
							svg
						/>
						<span
							style={{
								fontWeight: theme.font.weight.normal,
								overflowWrap: 'anywhere',
							}}
						>
							{props.value}
						</span>
					</>
				)
			) : props.isArray ? (
				props.value.length > 0 && (
					<div
						style={{
							width: '200%',
							display: 'grid',
							gap: '4px',
							gridTemplateColumns:
								props.value.length > 4
									? 'repeat(auto-fit, minmax(120px, 1fr)'
									: 'repeat(4, 1fr)',
						}}
					>
						{(props.value as string[]).map((element: string) => {
							return (
								<span
									key={element + 'key' + props.label}
									style={{
										padding: '6px',
										// border: `1px solid ${theme.colors.lightGray}`,
										// border: '1px solid ' + 'rgb(175,24,98)',
										background: theme.colors.base.green200,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										gridColumnStart: element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: theme.font.weight.normal,
									}}
								>
									{element}
								</span>
							)
						})}
					</div>
				)
			) : props.isLink ? (
				props.link ? (
					<span
						onClick={goToLink}
						style={{ cursor: 'pointer', marginBlock: '-3px' }}
					>
						<LinkIcon />
					</span>
				) : (
					<span style={{ marginBlock: '-3px' }}>
						<LinkIcon />
					</span>
				)
			) : (
				<span
					style={{
						fontWeight: theme.font.weight.normal,
						overflowWrap: 'anywhere',
						fontSize: theme.font.size.body,
						color: theme.colors.text.general,
					}}
				>
					{props.value}
				</span>
			)}
		</div>
	)
}

interface Password {
	password: string
	username: string
	url: string
	whiteList: string[]
}
const InformationContainer = (props: {
	advertiser: any
	closeDetailedView: any
	openEdit: any
	PopUpVisible: any
	setPopUpVisible: any
	setSuccessMessage: any
}) => {
	const theme = useTheme()
	const { publisher, app, users, login, settings, company } = useTypedSelector(
		(state) => state,
	)
	const editOwnAdvertiser =
		useIsAuthorized(permissionNames.EDIT_ADVERTISER_OWNER_ONLY) &&
		props.advertiser.email === login.user.email
	const permissions = {
		edit:
			useIsAuthorized(permissionNames.ADD_EDIT_ADVERTISER) || editOwnAdvertiser,
		viewAdvertiserContacts:
			useIsAuthorized(permissionNames.VIEW_ADVERTISER_CONTACTS) ||
			editOwnAdvertiser,
		preview: useIsAuthorized(permissionNames.PREVIEW_ADVERTISERS),
		viewPasswords: useIsAuthorized(permissionNames.VIEW_ADVERTISER_PASSWORDS),
	}
	const { setShowMore } = useShowMoreActions()

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: Array.isArray(value) ? value.join('  -  ') : value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}
	const handleShowMoreIOs = () => {
		setIsIOSectionOpen(true)
	}
	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const [selectedFile, setSelectedFile] = useState('')
	const [isIOSectionOpen, setIsIOSectionOpen] = useState(false)
	const [usedPublishers, setUsedPublishers] = useState<string[]>(
		props.advertiser?.used_publisher || [],
	)
	const [passwords, setPasswords] = useState<Password[]>(
		props.advertiser.passwords || [],
	)
	const [financialContacts, setFinancialContacts] = useState<
		ContactInterface[]
	>([])
	const [mainContacts, setMainContacts] = useState<ContactInterface[]>([])
	const [notUsedPublishers, setNotUsedPublishers] = useState<string[]>([])
	const appIdList = app.app
		.filter(
			(element: any) =>
				element.advertiser_name === props.advertiser?.advertiser_name,
		)
		.map((el: any) => {
			return { app_id: el.app_id, link: el.link }
		})

	const [decryptedContacts, setDecryptedContacts] = useState<
		ContactInterface[]
	>([])

	const hasAgencyAccess = getCompanyAgencyAccess(props.advertiser?.company_id)

	const pastOwner = props.advertiser?.past_owner
		? JSON.parse(props.advertiser.past_owner)
				.map((element: any) => element.owner)
				.join(', ')
		: []
	const publisherList = publisher.publisher
		.filter((el: any) => el.status === true)
		.map((el: any) => el.publisher_name)
		.sort((a: any, b: any) => a.localeCompare(b))

	const uniqueRestrictions: string[] = Array.from(
		new Set(settings.settings.trafficRestrictions),
	)
	const uniqueExistingChannels: string[] = Array.from(
		new Set(settings.settings.existingChannels),
	)

	let trafficRestrictions = []
	let existingChannels = []
	try {
		trafficRestrictions =
			props.advertiser?.traffic_restrictions?.length > 0
				? JSON.parse(props.advertiser.traffic_restrictions)
				: []
		existingChannels =
			props.advertiser?.existing_channels?.length > 0
				? JSON.parse(props.advertiser.existing_channels)
				: []
	} catch (error) {
		console.error(error)
	}
	const canView =
		canAccessResource(
			login.user,
			props.advertiser.email,
			users,
			props.advertiser.sales_rep,
		) || permissions.viewPasswords

	//if we found used publishers saved, then we are going to create a list of unused to show.
	useEffect(() => {
		if (usedPublishers) {
			const arr = publisherList.filter(
				(pub: any) => !usedPublishers.includes(pub),
			)
			setNotUsedPublishers(arr)
		}
	}, [usedPublishers])

	useEffect(() => {
		const decryptedContacts = async () => {
			if (
				!props.advertiser?.contacts ||
				props.advertiser.contacts.length === 0
			) {
				setDecryptedContacts([]) // Ensure it is an empty array, not undefined
				return
			}

			try {
				const decryptedContacts = await decryptContacts(
					props.advertiser.contacts,
				)

				setDecryptedContacts(decryptedContacts)
			} catch (error) {
				console.error('Error decrypting contacts:', error)
				setDecryptedContacts([]) // Prevent it from being undefined
			}
		}

		decryptedContacts()
	}, [props.advertiser?.contacts])

	//we want to filter the contacts between finance and non finance.

	useEffect(() => {
		if (!Array.isArray(decryptedContacts) || decryptedContacts.length === 0) {
			console.warn('No decrypted contacts to process.')
			setFinancialContacts([])
			setMainContacts([])
			return
		}

		const financial: ContactInterface[] = []
		const main: ContactInterface[] = []

		decryptedContacts.forEach((contact: ContactInterface) => {
			if (contact.type === 'finance') {
				financial.push(contact)
			} else {
				main.push(contact)
			}
		})

		setFinancialContacts(financial)
		setMainContacts(main)
	}, [decryptedContacts])

	// const isBudgetLimit =
	// 	props.advertiser.total_budget_limit > 0 ||
	// 	props.advertiser.monthly_budget_limit > 0 ||
	// 	props.advertiser.daily_budget_limit > 0

	// const updatePasswords = (oldPassword: string, newPassword: string) => {
	// 	const updatedPasswords = passwords.map((pwd) =>
	// 		pwd.password === oldPassword ? { ...pwd, password: newPassword } : pwd,
	// 	)
	// 	setPasswords(updatedPasswords)
	// }

	const accessiblePasswords = passwords.filter(
		(passwordItem: Password) =>
			canView || passwordItem.whiteList?.includes(login.user.name),
	)

	if (!permissions.edit && !permissions.preview) {
		return <></>
	}
	const latestContract =
		props.advertiser.contracts?.reduce(
			(closest: ContractsInterface | null, contract: ContractsInterface) => {
				if (!contract.expiration) return closest

				const contractExpiration = parse(
					contract.expiration,
					'dd/MM/yyyy',
					new Date(),
				)
				if (isNaN(contractExpiration.getTime())) return closest

				return !closest ||
					contractExpiration <
						parse(closest.expiration, 'dd/MM/yyyy', new Date())
					? contract
					: closest
			},
			null,
		) || {}

	if (latestContract && latestContract.expiration) {
		latestContract.expiration = parse(
			latestContract.expiration,
			'dd/MM/yyyy',
			new Date(),
		).toLocaleDateString('en-GB')
	}
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<div style={{ display: 'flex', width: '95%' }}>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
							color: theme.colors.text.titles,
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{ height: '16px', marginRight: '0px', marginLeft: -10 }}
							/>
						}
					>
						Back
					</UnborderedButton>
					{permissions.edit && (
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
								color: theme.colors.text.titles,
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<div style={{ padding: '1rem 0rem' }}>
					<PreviewTitle
						name={props.advertiser.advertiser_name}
						iconUrl={props.advertiser.advertiser_icon}
						id={props.advertiser.id}
						status={props.advertiser.status}
						links={{
							website: props.advertiser.website,
							linkedin: props.advertiser.linkedin,
							io: props.advertiser.link_to_io,
							pub: props.advertiser.link_to_pub,
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 30,
						paddingBottom: 30,
					}}
				>
					<PreviewContainer
						gridTemplate='repeat(8, 1fr)'
						additionalStyle={{ paddingBottom: '3.6rem' }}
					>
						<PreviewInfoTag
							label={'Legal Name'}
							value={props.advertiser.legal_name}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Address'}
							value={props.advertiser.address}
							gridColumnSpan={3}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Head Office Geo'}
							value={props.advertiser.geo}
							isCountry={true}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Type'}
							value={props.advertiser.advertiser_type}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Agency Access'}
							value={hasAgencyAccess ? 'Allowed' : 'Denied'}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Owner'}
							value={props.advertiser.email}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Past Owner'}
							value={pastOwner}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={3}
						/>
						<PreviewInfoTag
							label={'Branch'}
							value={props.advertiser.branch}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Sales Representative'}
							value={props.advertiser.sales_rep}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewArrayInfoTag
							label={'Communication Channel'}
							value={props.advertiser.communication_channel}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewArrayInfoTag
							label={'Tracking System'}
							value={props.advertiser.platform}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={3}
						/>
						<PreviewIOExpirationTag
							latestContract={latestContract}
							showMoreClick={handleShowMoreIOs}
							setSelectedFile={setSelectedFile}
							setIsPreviewOpen={setIsPreviewOpen}
							gridColumnSpan={2}
						/>
					</PreviewContainer>
					<div
						style={{
							// here's the explanation to the calc:
							// since the second box is 90% width we need to calculate the width of the 10%
							// taking into account its 2 columns of the 5 of the grid
							// -5px because gap = 10px
							// sorry :)
							width: 'calc(100% - ((3/5) * 5%) - 5px)',
							padding: '0rem',
							display: 'grid',
							gridTemplateColumns: 'repeat(5, 1fr)',
							gridAutoRows: 62,
							gap: '10px',
							rowGap: '20px',
							columnGap: '0px',
						}}
					>
						<PreviewContainer
							additionalStyle={{
								gridColumn: 'span 3',
								gridRow: 'span 3',
								width: '98%',
							}}
						>
							<PreviewInfoTag
								label={'Payment Terms'}
								value={props.advertiser.payment_terms}
								showMoreClick={handleShowMoreClick}
							/>
							<PreviewInfoTag
								label={'Invoice Instructions'}
								value={props.advertiser.invoice_instructions}
								gridRowSpan={2}
								gridColumnSpan={3}
								showMoreClick={handleShowMoreClick}
							/>
							<PreviewInfoTag
								label={'VAT'}
								value={props.advertiser.vat}
								showMoreClick={handleShowMoreClick}
							/>
						</PreviewContainer>
						<PreviewContainer
							additionalStyle={{ gridColumn: 'span 2', gridRow: 'span 3' }}
						>
							<PreviewInfoTag
								label={'Notes'}
								value={props.advertiser.notes || ''}
								gridRowSpan={2}
								gridColumnSpan={4}
								showMoreClick={handleShowMoreClick}
							/>
						</PreviewContainer>
					</div>
					<PreviewContainer
						gridTemplate='repeat(6, 1fr)'
						additionalStyle={{ paddingBottom: '3.6rem' }}
					>
						<PreviewArrayInfoTag
							label={'Fraud Tool'}
							value={props.advertiser.fraud_tool}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewArrayInfoTag
							label={'Vertical'}
							value={props.advertiser.vertical}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewArrayInfoTag
							label={'Data Source'}
							value={props.advertiser.data_source}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewAppIdComponent
							appList={appIdList}
							label={"App's ID"}
							handleShowMoreClick={handleShowMoreClick}
						/>
					</PreviewContainer>
					<PreviewContainer gridTemplate={'repeat(3, 1fr)'}>
						<PreviewInfoTag
							label={'Brief'}
							value={props.advertiser.brief}
							gridRowSpan={2}
							gridColumnSpan={1}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Special Requests'}
							value={props.advertiser.special_request}
							gridRowSpan={2}
							gridColumnSpan={1}
							showMoreClick={handleShowMoreClick}
						/>
						<PreviewInfoTag
							label={'Parameters template'}
							value={props.advertiser.parameters_template}
							gridRowSpan={2}
							gridColumnSpan={1}
							showMoreClick={handleShowMoreClick}
						/>
					</PreviewContainer>

					{(canView || permissions.viewAdvertiserContacts) &&
						decryptedContacts.length > 0 && (
							<CustomContactsComponent
								contacts={decryptedContacts.length > 0 ? decryptedContacts : []}
								setContacts={() => {}} // No need to modify contacts in preview mode
								p360Active={true}
								canEdit={permissions.edit}
								roleOptions={[]}
								mode='preview'
							/>
						)}
					{accessiblePasswords.length > 0 && (
						<PasswordsPreviewComponent
							passwords={accessiblePasswords}
							ownerEmail={props.advertiser.email}
							salesRep={props.advertiser.sales_rep}
							isReadOnly={!canView}
							showMoreClick={handleShowMoreClick}
						/>
					)}
					{props.advertiser.used_publisher && (
						<ExpandableInfoField
							label={'Publishers never used'}
							value={notUsedPublishers}
						/>
					)}
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
					>
						<CategoryDivider
							mainTitle={'Traffic Restrictions'}
							selected={trafficRestrictions.map((el: any) => el.value)}
							selectedLabel={'Restrictions'}
							total={uniqueRestrictions}
							totalLabel={'Allowed'}
							showMoreClick={handleShowMoreClick}
						/>
						<CategoryDivider
							mainTitle={'Existing Channels'}
							selected={existingChannels.map((el: any) => el.value)}
							selectedLabel={'Existing'}
							total={uniqueExistingChannels}
							totalLabel={'Non Existing'}
							showMoreClick={handleShowMoreClick}
						/>
					</div>
				</div>
			</div>
			<Modal
				open={isPreviewOpen}
				onClose={() => setIsPreviewOpen(false)}
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				<Box
					sx={{
						width: '80vw',
						height: '80vh',
						backgroundColor: theme.colors.base.white,
						padding: 2,
						borderRadius: 2,
						boxShadow: 24,
					}}
				>
					{selectedFile && <DocumentPreview doc={[{ uri: selectedFile }]} />}
				</Box>
			</Modal>
			<IOsModal
				open={isIOSectionOpen}
				onClose={() => {
					setIsIOSectionOpen(false)
				}}
				existingContracts={props.advertiser?.contracts || []}
				onSave={() => {}}
				isPreview={true}
			/>
		</>
	)
}

export const PreviewAppIdComponent = ({
	appList,
	label,
	gridMainRowSpan = 6,
	handleShowMoreClick,
}: {
	appList: any
	label: string
	gridMainRowSpan?: number
	handleShowMoreClick: any
}) => {
	const theme = useTheme()
	const [showModal, setShowModal] = useState<boolean>(false)
	if (!appList || appList.length === 0) {
		appList = [
			{ app_id: '', link: '' },
			{ app_id: '', link: '' },
		]
	}

	const displayedApps = appList.length > 2 ? appList.slice(0, 2) : appList

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(1, 1fr)`,
				flexDirection: 'column',
				gap: 6,
				gridColumn: `span ${gridMainRowSpan}`,
				gridRow: `span ${displayedApps.length / 2}`,
				gridAutoRows: 'min-content',
			}}
		>
			<PreviewLabel text={label} additionalStyle={{ height: '20px' }} />

			<div
				style={{
					position: 'relative',
					gridTemplateColumns: `repeat(2, 1fr)`,
					display: 'grid',
					gridAutoRows: 55,
					columnGap: '10px',
				}}
			>
				{displayedApps.map((el: any, idx: number) => {
					const isLast = idx === displayedApps.length - 1
					return (
						<PreviewAppIdElement
							key={idx}
							app={el.app_id}
							link={el.link}
							isLastListElement={isLast}
							handleShowMoreClick={handleShowMoreClick}
						/>
					)
				})}

				{appList.length > 2 && (
					<ReadMoreInlineButton
						label='More'
						onClick={() => setShowModal(true)}
						showIcon={true}
						style={{
							position: 'static',
							marginLeft: -8,
							backgroundColor: 'transparent',
							height: 30,
							width: 90,
						}}
					/>
				)}
			</div>

			<CustomModal
				title='All App IDs'
				open={showModal}
				onClose={() => setShowModal(false)}
				maxWidth='lg'
				fullWidth={true}
				PaperProps={{ sx: { width: '60%', overflow: 'visible' } }}
			>
				{/* Render all app IDs in the modal */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
						padding: '1rem',
					}}
				>
					{appList.map((el: any, idx: number) => (
						<PreviewAppIdElement
							key={idx}
							app={el.app_id}
							link={el.link}
							handleShowMoreClick={handleShowMoreClick}
						/>
					))}
				</div>
			</CustomModal>
		</div>
	)
}
const handleCopy = (link: string, setCopied: any) => {
	navigator.clipboard.writeText(link).then(() => {
		setCopied(true)
	})
}
const PreviewAppIdElement = ({
	app,
	link,
	isLastListElement,
	handleShowMoreClick,
}: {
	app: string
	link: string
	isLastListElement?: boolean
	handleShowMoreClick: any
}) => {
	const theme = useTheme()
	const [copied, setCopied] = useState(false)
	const hasAvailableLink = link && link.trim() !== ''

	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false)
			}, 1500)
			return () => clearTimeout(timeout)
		}
	}, [copied])

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(12, 1fr)`,
				gap: 8,
				gridRow: `span 1`,
				gridAutoRows: isLastListElement ? 'auto' : '4.3rem',
				columnGap: '20px',
				position: 'relative',
				gridColumn: 'span 1',
			}}
		>
			<div
				style={{
					background: theme.colors.base.white,
					width: '100%',
					border: `1px solid ${theme.colors.preview.border}`,
					padding: 10,
					borderRadius: 4,
					fontSize: theme.font.size.caption,
					display: 'flex',
					alignItems: 'center',
					gap: 8,
					gridColumn: 'span 4',
					justifyContent: 'space-between',
					height: '37px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
					wordBreak: 'break-word',
					overflowWrap: 'break-word',
				}}
			>
				<TruncatedTextWithReadMore
					text={app}
					expandedModalTitle='App ID'
					handleShowMoreClick={handleShowMoreClick}
				/>
			</div>

			<div
				style={{
					background: theme.colors.base.white,
					width: '107%',
					border: `1px solid ${theme.colors.preview.border}`,
					padding: 10,
					borderRadius: 4,
					fontSize: theme.font.size.caption,
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					gridColumn: 'span 7',
					justifyContent: 'space-between',
					height: '37px',
					color: theme.colors.text.titles,
				}}
			>
				<TruncatedTextWithReadMore
					text={link}
					maxWidth={200}
					handleShowMoreClick={handleShowMoreClick}
				/>
			</div>

			<div
				style={{
					width: '105%',
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<Tooltip
					title={hasAvailableLink ? (copied ? 'Copied!' : 'Copy') : ''}
					arrow
				>
					<button
						style={{
							border: 'none',
							display: 'flex',
							alignItems: 'center',
							cursor: hasAvailableLink ? 'pointer' : 'not-allowed',
							color: hasAvailableLink
								? theme.colors.preview.blue300
								: theme.colors.base.grey600,
							height: '37px',
							gridColumn: 'span 1',
							justifyContent: 'center',
						}}
						onClick={() => handleCopy(link, setCopied)}
						disabled={hasAvailableLink ? false : true}
					>
						<CopyIcon style={{ scale: '1.2' }} />
					</button>
				</Tooltip>
			</div>
		</div>
	)
}

const AdvertiserPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	viewRecord: any
	setViewRecord: any
	PopUpVisible: any
	setPopUpVisible: any
	isCompanyPersonalView?: boolean
	companyData?: any
	setSuccessMessage?: any
}) => {
	const { advertiser, users, login, settings } = useTypedSelector(
		(state) => state,
	)
	const theme = useTheme()
	const navigate = useNavigate()
	const params = useParams()
	let row: any
	if (props.isCompanyPersonalView) {
		row = props.companyData
	} else {
		row =
			advertiser.advertiser.find((e: any) => e.id === Number(params.id)) || []
	}
	const closeDetailedView = () => {
		if (props.isCompanyPersonalView) {
			navigate('/companySettings')
		} else {
			navigate('/advertisers')
		}
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/advertisers/' + row.id)
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				// marginTop: '8vh',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<InformationContainer
				advertiser={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				PopUpVisible={props.PopUpVisible}
				setPopUpVisible={props.setPopUpVisible}
				setSuccessMessage={props.setSuccessMessage}
			/>
		</PageWrapper>
	)
}

export default AdvertiserPreviewPage
