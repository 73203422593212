import { Grid, ThemeProvider, Typography, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	useActions,
	usePublisherActions,
	useAdvertiserActions,
	useNewsActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { PageWrapper, PlusButton } from '../components/reusableComponents'
import NewsDashboardContainer from './NewsDashboardContainer'
import NewsletterContainer from './NewsletterContainer'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { SubmitButton } from '../components/Buttons'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useLocation } from 'react-router'

const NewsPage = (props: {
	children?: any
	errorMessage?: null | string
	setErrorMessage?: any
	isErrorPromptOpened?: boolean
	setIsErrorPromptOpened?: any
}) => {
	const theme = useTheme()
	const location = useLocation()
	const { login, news, publisher, advertiser, settings, users } =
		useTypedSelector((state) => state)
	const [topics, setTopics] = useState<any>(null)
	const [editMode, setEditMode] = useState(false)
	const [deletedElements, setDeleted] = useState<any[]>([])
	const [newsAdvertiserList, setNewsAdvertiserList] = useState([])
	const [newsPublisherList, setNewsPublisherList] = useState([])
	const [mainHeight, setMainHeight] = useState(0)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_NEWS),
		view: useIsAuthorized(permissionNames.VIEW_NEWS),
		viewNewsPublishers: useIsAuthorized(permissionNames.VIEW_NEWS_PUBLISHERS),
		viewNewsAdvertisers: useIsAuthorized(permissionNames.VIEW_NEWS_ADVERTISERS),
	}
	// const [cardHoveredIndex, setCardHoveredIndex] = useState(0)
	const { postNewsImageAction, postNewsAction, getNewsAction } =
		useNewsActions()
	// const { getAdvertiserAction } = useAdvertiserActions()
	// const { getPublisherAction } = usePublisherActions()
	const ref = useRef()
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])

	// useEffect(() => {
	// 	const downloadNewsData = async () => {
	// 		await fetchData(getPublisherAction, publisher.publisher)
	// 		await fetchData(getAdvertiserAction, advertiser.advertiser)
	// 	}
	// 	getNewsAction()
	// 	downloadNewsData()

	// 	// Wait for the news data to be updated in the Redux store
	// 	// Then process the topics
	// }, [location.pathname])

	useEffect(() => {
		if (news.news.topics) {
			for (const el of news.news.topics) {
				if (typeof el.elements === 'string') {
					el.elements = JSON.parse(el.elements)
				}
				if (typeof el.titles === 'string') {
					el.titles = JSON.parse(el.titles)
				}
			}
			// console.log('news', news.news.topics)
			setTopics([...news.news.topics])
			setNewsAdvertiserList(news.news.newsAdvertisers)
			setNewsPublisherList(news.news.newsPublishers)
			setTimeout(() => getCurrentHeight(), 800)
		}
	}, [news.news.topics])

	const addTitle = () => {
		const newTopic = {
			title: 'Default title',
			elements: [],
			titles: [],
			id: uuidv4(),
		}
		setTopics((prev: any) => (prev ? [...prev, newTopic] : [newTopic]))
	}
	const deleteTitle = (id: string) => {
		const deletedTopic = topics.filter((el: any) => el.id === id)[0]
		const filteredTopics = topics.filter((el: any) => el.id !== id)
		setTopics(filteredTopics)
		const imagesToDelete: any = []
		for (const el of deletedTopic.elements) {
			if (el.image === 'uploaded') {
				imagesToDelete.push(el.id)
			}
		}
		setDeleted((prev: any) =>
			prev ? [...prev, ...imagesToDelete] : imagesToDelete,
		)
	}

	const encodeImageFileAsURL = async (img: any, id: string) => {
		const reader = new FileReader()
		const file = img
		reader.onloadend = function () {
			if (reader.result !== null) {
				const payload = {
					image: reader.result.toString().split(',')[1],
					name: id,
				}
				postNewsImageAction(payload)
			}
		}
		reader.readAsDataURL(file)
	}
	const uploadData = async () => {
		for (const topic of topics) {
			for (const element of topic.elements) {
				if (element.image && element.image !== 'uploaded') {
					await encodeImageFileAsURL(element.image, element.id)
					element.image = 'uploaded'
				}
			}
			// console.log(topic.elements)
			if (typeof topic.elements !== 'string') {
				topic.elements = JSON.stringify(topic.elements)
			}
			if (typeof topic.titles !== 'string') {
				topic.titles = JSON.stringify(topic.titles)
			}
		}
		const dataToSave = {
			topics: topics,
			deleted: deletedElements,
		}
		// console.log(dataToSave)
		postNewsAction(dataToSave)
	}

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			if (mainHeight === 0) setMainHeight(current.clientHeight)
		}
	}
	const calculatedWidth = 2
	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'flex-start'}
					width={'95%'}
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						height: permissions.edit ? '50px' : '0px',
					}}
				>
					{permissions.edit ? (
						editMode ? (
							<SubmitButton
								style={{ marginTop: '0px', width: '160px' }}
								onClick={() => {
									setEditMode(!editMode)
									uploadData()
								}}
							>
								SAVE
							</SubmitButton>
						) : (
							<SubmitButton
								style={{ marginTop: '0px', width: '160px' }}
								onClick={() => setEditMode(!editMode)}
							>
								EDIT
							</SubmitButton>
						)
					) : (
						<></>
					)}
				</Grid>
				<Grid
					container
					spacing={0}
					width={'100%'}
					style={{
						display: 'flex',
						marginTop: '1rem',
						paddingBottom: '60px',
					}}
				>
					<Grid
						item
						xs={7}
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginTop: '0px',
							marginLeft: '0px',
							padding: '40px',
							paddingTop: '10px',
							gap: '12px',
						}}
						ref={ref as unknown as any}
					>
						<div
							style={{
								display: 'flex',
								position: 'relative',
								flexDirection: 'column',
							}}
						>
							{permissions.view &&
								topics?.map((el: any, index: number) => {
									return (
										<React.Fragment key={el.id + 'main-fragment'}>
											<NewsletterContainer
												mainObj={el}
												login={login}
												editMode={editMode}
												setDeleted={setDeleted}
												deleteTitle={deleteTitle}
												key={el.id + 'main'}
												setErrorMessage={props.setErrorMessage}
												index={index}
												length={topics.length}
												// cardHoveredIndex={cardHoveredIndex}
												// setCardHoveredIndex={setCardHoveredIndex}
											/>
										</React.Fragment>
									)
								})}
						</div>
						{editMode && (
							<React.Fragment>
								<PlusButton style={{ top: 24, left: '45%' }} onClick={addTitle}>
									<span
										style={{
											marginTop: 1,
											marginLeft: 1,
										}}
									>
										+
									</span>
								</PlusButton>
								<Typography
									component={'span'}
									onClick={addTitle}
									style={{
										color: theme.colors.base.green300,
										position: 'relative',
										width: '90px',
										left: '49%',
										top: '-10px',
										cursor: 'pointer',
									}}
								>
									Add Group
								</Typography>
							</React.Fragment>
						)}
					</Grid>
					{permissions.viewNewsAdvertisers && (
						<Grid
							item
							xs={calculatedWidth}
							style={{
								marginTop: '0px',
								height: mainHeight > 0 ? `${mainHeight - 120 + 'px'}` : '50vh',
								// border: '1px solid rgba(0,0,0,0.1)',
								borderRadius: '20px',
								boxShadow: `-2px 2px 8px 2px ${theme.colors.base.grey100}`,
								position: 'relative',
								zIndex: '11',
							}}
						>
							<NewsDashboardContainer
								list={newsAdvertiserList}
								title='Advertisers'
								mainHeight={mainHeight}
							/>
						</Grid>
					)}
					{permissions.viewNewsPublishers && (
						<Grid
							item
							xs={calculatedWidth}
							style={{
								marginTop: '0px',
								height: mainHeight > 0 ? `${mainHeight - 120 + 'px'}` : '50vh',
								// border: '1px solid rgba(0,0,0,0.1)',
								borderRadius: '20px',
								boxShadow: `-2px 2px 8px 2px ${theme.colors.base.grey100}`,
								marginLeft: '60px',
								position: 'relative',
								zIndex: '11',
							}}
						>
							<NewsDashboardContainer
								list={newsPublisherList}
								title='Publishers'
								mainHeight={mainHeight}
							/>
						</Grid>
					)}
				</Grid>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default NewsPage

// const calculateWidthByPermissions = (
// 	permissions: any,
// 	isNewsletter: boolean = false,
// ) => {
// 	return 10
// }
