import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { MenuItem, Tooltip, Typography, useTheme } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { GridColDef } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import saveAs from 'file-saver'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
	useActions,
	useCreatorActions,
	useLogActions,
	usePublisherActions,
	useSuccessActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import FileUploadModal from '../../utils/notifications/FileUploadPrompt'
import {
	findUsernameByEmail,
	generateRowsWithIds,
	searchFor,
	generateRowsWithIdsAndSort,
} from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ActiveChip, DisabledChip } from './publishers/PublisherComponents'
import PublisherPopUp from './publishers/PublisherPopUp'
import {
	canAccessResource,
	decryptContacts,
	getApplicableRules,
	hasOwnerAccess,
} from '../../utils/helpers/helperFuncs'
import { TableComponent } from '../components/TableComponents'
import { ActionsButton, SimpleActionsButton } from '../components/Buttons'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import { ReactComponent as CopyIcon } from '../../assets/svg/copy-icon.svg'
import { ReactComponent as GoodQualityIcon } from '../../assets/svg/medal-icon.svg'
import { ReactComponent as ChatIcon } from '../../assets/svg/chat-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as MedalIcon } from '../../assets/svg/medal-icon.svg'
import { ReactComponent as GraphBarsIcon } from '../../assets/svg/graph-bars-icon.svg'
import { ReactComponent as LockerIcon } from '../../assets/svg/locker-icon.svg'
import { ReactComponent as CartIcon } from '../../assets/svg/cart-icon.svg'
import { ReactComponent as HighConversionIcon } from '../../assets/svg/high-conversion-icon-3.svg'
import { v4 as uuidv4 } from 'uuid'
import { fetchInChunks } from '../../utils/helpers/fetchInChunks'

import { TabContainer } from '../components/TabComponent'
import CreatorPopUp from './creators/CreatorPopUp'
import { ColoredTag, StatusTag } from '../components/Informative'
import defaultIcon from '../../assets/default-icon.webp'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'
import { THING_OR_TWO_COMPANY_ID } from '../../config'

const PublisherPage = (props: {
	children?: any
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	viewRecord: any
	setViewRecord: any
	PopUpVisible: boolean
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
	successMessage: null | string
	setSuccessMessage: any
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const params = useParams()

	const { publisher, login, users, settings, creator, handshake, company } =
		useTypedSelector((state) => state)
	const { insertPidHandshakeAction, getHandshakeAction } = useActions()
	const { getCreatorsAction, deleteCreator, insertCreatorsAction } =
		useCreatorActions()
	const { insertLog } = useLogActions()
	const {
		getPublisherReportPid,
		getPublisherAction,
		deletePublisher,
		getPublisherReport,
		getPublisherContacts,
	} = usePublisherActions()
	const { setSuccess } = useSuccessActions()
	const sessionKey = 'filters--publisher-table'
	const rowCount =
		login.company.type === 'agency'
			? 8
			: window.location.pathname.startsWith('/creators')
			? 7
			: 6
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const [pageSize, setPageSize] = useState(15)
	const [update, setUpdate] = useState(false)
	const [row, setRow] = useState()
	const [tab, setTab] = useState(
		window.location.pathname.startsWith('/publishers') ? 0 : 1,
	)
	const [downloadFile, setDownloadFile] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [publisherFilterStatus, setPublisherFilterStatus] = useState(1)
	const [skypeAllowedUsers, setSkypeAllowedUsers] = useState<string[]>([])
	const [skypeGroupUserAllowed, setSkypeGroupUserAllowed] =
		useState<boolean>(false)
	const [sortModel, setSortModel] = useState()
	const [applyFilter, setApplyFilter] = useState(false)
	const [filteredPublishers, setFilteredPublishers] = useState(
		Array.isArray(publisher.publisher)
			? publisher.publisher.filter((el: any) => el.status === true)
			: [],
	)
	const [filteredCreators, setFilteredCreators] = useState(
		Array.isArray(creator.creator)
			? creator.creator.filter((el: any) => el.status === true)
			: [],
	)

	const [tableRows, setTableRows] = useState(
		generateRowsWithIdsAndSort(
			tab === 0 ? filteredPublishers : filteredCreators,
		),
	)
	const [isNewPublisher, setIsNewPublisher] = useState(false)
	const [isNewCreator, setIsNewCreator] = useState(false)
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					publisher_name: [],
					email: [],
			  },
	)
	const activeUsersEmails = new Set(
		tableRows.map((element: any) => element.email),
	)
	const getActiveUsers = (emailsSet: any) => {
		const result = []
		for (const email of emailsSet) {
			for (const user of users.users) {
				if (email === user.email) {
					result.push(user)
					break
				}
			}
		}
		return result
	}
	const [optionsFilters, setOptionsFilter] = useState({
		email: getActiveUsers(activeUsersEmails),
	})

	const isCreatorSelected = () => {
		if (tab === 0) {
			return false
		}
		return true
	}
	const isCreator = isCreatorSelected()

	const [currentFilters, setCurrentFilters] = useState('')
	const [filterLabel, setFilterLabel] = useState('')
	const [openFilter, setOpenFilter] = useState(false)
	const permissions = {
		view: useIsAuthorized(
			isCreator ? permissionNames.VIEW_CREATOR : permissionNames.VIEW_PUBLISHER,
		),
		edit: useIsAuthorized(
			isCreator
				? permissionNames.ADD_EDIT_CREATOR
				: permissionNames.ADD_EDIT_PUBLISHER,
		),
		addNew: useIsAuthorized(
			isCreator
				? permissionNames.ADD_NEW_CREATORS
				: permissionNames.ADD_NEW_PUBLISHERS,
		),

		preview: useIsAuthorized(
			isCreator
				? permissionNames.PREVIEW_CREATORS
				: permissionNames.PREVIEW_PUBLISHERS,
		),
		delete: useIsAuthorized(
			isCreator
				? permissionNames.DELETE_CREATOR
				: permissionNames.DELETE_PUBLISHER,
		),
		accessMediaPlan: useIsAuthorized(permissionNames.ACCESS_TO_MEDIA_PLAN),
		downloadPublisherList: useIsAuthorized(
			permissionNames.DOWNLOAD_PUBLISHERS_LIST,
		),
		viewPublisherContact: useIsAuthorized(
			permissionNames.VIEW_PUBLISHER_CONTACTS,
		),
		deleteOwnerOnly: useIsAuthorized(
			isCreator
				? permissionNames.DELETE_CREATOR_OWNER_ONLY
				: permissionNames.DELETE_PUBLISHER_OWNER_ONLY,
		),
		viewTabsButton: [
			useIsAuthorized(permissionNames.VIEW_CREATOR),
			useIsAuthorized(permissionNames.VIEW_PUBLISHER),
		].every(Boolean),
		uploadCreatorFile: useIsAuthorized(permissionNames.UPLOAD_CREATORS_FILE),
	}

	const possiblePublisherFilter = ['both', 'active', 'disabled']

	const [chunksLoadedPub, setChunksLoadedPub] = useState(0)
	const [chunksLoadedCreator, setChunksLoadedCreator] = useState(0)
	const prevLengthPub = useRef(publisher.publisher.length)
	const prevLengthCreator = useRef(creator.creator.length)
	useEffect(() => {
		if (publisher.publisher.length !== prevLengthPub.current) {
			setChunksLoadedPub((prev) => prev + 1)
			prevLengthPub.current = publisher.publisher.length
		}
	}, [publisher.publisher.length])

	useEffect(() => {
		if (creator.creator.length !== prevLengthCreator.current) {
			setChunksLoadedCreator((prev) => prev + 1)
			prevLengthCreator.current = creator.creator.length
		}
	}, [creator.creator.length])

	const handleModalOpen = () => setIsModalOpen(true)
	const handleModalClose = () => setIsModalOpen(false)

	useEffect(() => {
		if (window.location.pathname.startsWith('/publishers')) {
			setTab(0)
		} else if (window.location.pathname.startsWith('/creators')) {
			setTab(1)
		} else {
			setTab(0)
		}
		setRowWidth(
			Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
		)
	}, [window.location.pathname])
	// there's the possibility of the skype link to be private for a publisher.
	// if that's the case, here we check if the user can see it or not.
	// The main idea is that only publisher managers, super roles or teamleaders can see it
	useEffect(() => {
		const allowedList = []
		if (settings.settings && users.users) {
			for (const teamLeader of settings.settings.teams) {
				for (const user of users.users) {
					if (
						(teamLeader === user.name ||
							user.role === 'super' ||
							user.position === 'Publisher Manager') &&
						allowedList.indexOf(user.email) === -1
					) {
						allowedList.push(user.email)
					}
				}
			}
			setSkypeAllowedUsers(allowedList)
		}
	}, [settings, users])
	useEffect(() => {
		if (skypeAllowedUsers.length > 0) {
			if (skypeAllowedUsers.indexOf(login.user.email) >= 0) {
				setSkypeGroupUserAllowed(true)
			}
		}
	}, [skypeAllowedUsers])

	useEffect(() => {
		props.setLoading(true)
		let filtered: any
		let mainData: any
		if (!isCreatorSelected()) {
			mainData = publisher.publisher
		} else {
			mainData = creator.creator
		}
		if (props.search.length > 0) {
			let searched = searchFor(props.search, mainData, [
				...columns,
				{ field: 'media_source_pid' },
			])
			if (possiblePublisherFilter[publisherFilterStatus] === 'both') {
				filtered = searched
			} else if (possiblePublisherFilter[publisherFilterStatus] === 'active') {
				filtered = searched.filter((el: any) => el.status === true)
			} else if (
				possiblePublisherFilter[publisherFilterStatus] === 'disabled'
			) {
				filtered = searched.filter((el: any) => el.status === false)
			}
		} else {
			if (possiblePublisherFilter[publisherFilterStatus] === 'both') {
				filtered = mainData
			} else if (possiblePublisherFilter[publisherFilterStatus] === 'active') {
				filtered = mainData.filter((el: any) => el.status === true)
			} else if (
				possiblePublisherFilter[publisherFilterStatus] === 'disabled'
			) {
				filtered = mainData.filter((el: any) => el.status === false)
			}
		}
		let anyfilters = false
		let additionalFiltersApplied = []

		for (let [index, data] of filtered.entries()) {
			let found = false
			let foundWrong = false

			for (const key in customFilter) {
				let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true

					foundByKey = customFilter[key].includes(data[key])
					//if key is email then we want to show name but use emails.
					if (key === 'email') {
						const userFound = users.users.find(
							(e: any) =>
								customFilter[key].includes(e.name) && data.email === e.email,
						)
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		let arr = []
		if (!anyfilters) {
			arr = generateRowsWithIdsAndSort(filtered)
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = generateRowsWithIdsAndSort(additionalFiltersApplied)
		} else {
			arr = generateRowsWithIdsAndSort([])
		}
		// console.log(arr)
		setTableRows(arr)
		props.setLoading(false)
	}, [
		publisherFilterStatus,
		props.search,
		applyFilter,
		tab,
		chunksLoadedPub,
		chunksLoadedCreator,
	])

	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}

	const mediaPlanHandler = () => {
		navigate('/mediaplan')
	}
	// const handshakeHandler = () => {
	// 	navigate('/pidhandshake')
	// }
	const columns: GridColDef[] = []

	if (!isCreatorSelected()) {
		columns.push(
			{
				field: 'external_id',
				headerName: 'ID',
				width: rowWidth * 0.3,
				disableColumnMenu: true,
				renderCell: ({ value }) => <span>{value}</span>,
			},
			{
				field: 'publisher_name',
				headerName: 'Name',
				width: rowWidth,
				disableColumnMenu: true,
				renderCell: (params) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img
							src={params.row.publisher_icon || defaultIcon}
							alt='Icon'
							style={{
								width: 28,
								height: 28,
								borderRadius: '50%',
								marginRight: 8,
							}}
						/>
						<span>{params?.value}</span>
					</div>
				),
			},
			{
				field: 'status',
				headerName: 'Status',
				width: rowWidth * 0.5,
				disableColumnMenu: true,
				renderCell: ({ value }) =>
					value ? (
						// <ActiveChip label='Active' />
						<StatusTag
							label={'Active'}
							style={{
								color: theme.colors.text.colored,
								fontWeight: theme.font.weight.normal,
							}}
						/>
					) : (
						// <DisabledChip label='Disabled' style={{ marginLeft: '10px' }} />
						<StatusTag label={'Disabled'} />
					),
			},

			{
				field: 'main_category',
				headerName: 'Category',
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: ({ value }) => <span>{value}</span>,
			},
			{
				field: 'email',
				headerName: 'Owner',
				width: rowWidth,
				disableColumnMenu: true,
				renderHeader: () => {
					return (
						<HeaderFilterComponent
							label={'Owner'}
							width={rowWidth * 1.2}
							openFilter={openFilter}
							setOpenFilter={setOpenFilter}
							field={'email'}
							customFilter={customFilter}
							currentFilters={currentFilters}
							setCurrentFilters={setCurrentFilters}
							setFilterLabel={setFilterLabel}
							optionsFilters={optionsFilters}
						/>
					)
				},
				renderCell: ({ value }) =>
					value ? findUsernameByEmail(value.toString(), users.users) : 'User',
			},
			{
				field: 'abilities_resume',
				headerName: 'Abilities',
				width: rowWidth * 2,
				disableColumnMenu: true,
				renderCell: (row) => {
					// console.log(row.row.media_buying_capabilities.abilities)
					const capabilities = row.row.media_buying_capabilities
					const importantValues = [
						'Fully Transparency',
						'SKAD',
						'VTA',
						'Hard KPI',
					]

					return (
						<div style={{ display: 'flex', gap: 10 }}>
							{capabilities?.abilities && capabilities.abilities.length > 0
								? importantValues.map((el: any) => {
										let checked = false
										for (const saved of capabilities.abilities) {
											if (saved.value.toLowerCase() === el.toLowerCase()) {
												checked = true
												break
											}
										}
										return <ColoredTag value={el} checked={checked} key={el} />
								  })
								: importantValues.map((el: string) => (
										<ColoredTag value={el} key={el} checked={false} />
								  ))}
						</div>
					)
				},
			},
			{
				field: 'badges',
				headerName: 'Badges',
				width: rowWidth * 1.2,
				disableColumnMenu: true,
				renderCell: (row) => {
					const badges = row.row.media_buying_capabilities?.badges

					return (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								gap: 6,
								marginTop: 10,
							}}
						>
							<Tooltip title='Good quality'>
								<MedalIcon
									style={{
										color: badges?.good_quality
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.85',
										marginTop: 2,
									}}
								/>
							</Tooltip>
							<Tooltip title='High volume'>
								<GraphBarsIcon
									style={{
										color: badges?.high_volume
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.8',
										marginTop: -11,
										marginLeft: -12,
									}}
								/>
							</Tooltip>
							<Tooltip title='Low fraud'>
								<LockerIcon
									style={{
										color: badges?.low_fraud
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.8',
										marginLeft: -10,
									}}
								/>
							</Tooltip>

							<Tooltip title='High conversion rate'>
								<HighConversionIcon
									style={{
										color: badges?.high_cr
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '1.8',
										marginTop: 6,
										marginLeft: 4,
									}}
								/>
							</Tooltip>
						</div>
					)
				},
			},
			// {
			// 	field: 'status',
			// 	headerName: 'Status',
			// 	width: rowWidth,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	renderCell: ({ value }) =>
			// 		value ? (
			// 			<ActiveChip label='Active' />
			// 		) : (
			// 			<DisabledChip label='Disabled' style={{ marginLeft: '10px' }} />
			// 		),
			// },
			// {
			// 	field: 'email',
			// 	headerName: 'Owner',
			// 	width: rowWidth,
			// 	renderHeader: () => {
			// 		return (
			// 			<HeaderFilterComponent
			// 				label={'Owner'}
			// 				width={rowWidth * 1.2}
			// 				openFilter={openFilter}
			// 				setOpenFilter={setOpenFilter}
			// 				field={'email'}
			// 				customFilter={customFilter}
			// 				currentFilters={currentFilters}
			// 				setCurrentFilters={setCurrentFilters}
			// 				setFilterLabel={setFilterLabel}
			// 				optionsFilters={optionsFilters}
			// 			/>
			// 		)
			// 	},
			// 	renderCell: ({ value }) =>
			// 		value ? findUsernameByEmail(value.toString(), users.users) : 'User',
			// },

			// {
			// 	field: 'createdAt',
			// 	headerName: 'Created at',
			// 	width: rowWidth * 0.9,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	renderCell: ({ value }) =>
			// 		value ? format(new Date(value.toString()), 'MM.dd.yyyy') : 'Now',
			// },
			// {
			// 	field: 'progress',
			// 	headerName: 'Progress',
			// 	width: rowWidth * 0.7,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	valueGetter: (row) => getProgress(row),
			// 	sortComparator: sortingCustomModel,
			// 	renderCell: (row) => <RenderProgress row={row} />,
			// },
			{
				field: 'actions',
				headerName: 'Actions',
				resizable: false,
				sortable: false,
				width: rowWidth * 1.2,
				disableColumnMenu: true,
				renderCell: (row) => <ActionsButton options={actionOptions(row)} />,
				// <SimpleActionsButton
				// 	label={'Actions'}
				// 	// width={rowWidth}
				// 	// row={row}
				// 	options={actionOptions(row)}
				// 	// style={{ width: '40px', height: '22px', fontSize: '30px' }}
				// 	// arrowColor={'rgba(0,0,0,0.75)'}
				// />
			},
		)
	}
	if (isCreatorSelected()) {
		columns.push(
			{
				field: 'name',
				headerName: 'Name',
				width: rowWidth * 1,
				disableColumnMenu: true,
				renderCell: (params) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img
							src={params.row.creator_icon || defaultIcon}
							alt='Icon'
							style={{
								width: 28,
								height: 28,
								borderRadius: '50%',
								marginRight: 8,
							}}
						/>
						<span style={{ fontSize: theme.font.size.caption }}>
							{params.row.name || ''}
						</span>{' '}
					</div>
				),
			},
			{
				field: 'username',
				headerName: 'Username',
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: ({ value }) => (
					<span style={{ fontSize: theme.font.size.caption }}>{value}</span>
				),
			},
			{
				field: 'type',
				headerName: 'Category',
				width: rowWidth * 0.7,
				disableColumnMenu: true,
				renderCell: ({ value }) => <span>{value}</span>,
			},
			{
				field: 'status',
				headerName: 'Status',
				width: rowWidth * 0.5,
				disableColumnMenu: true,
				renderCell: ({ value }) =>
					value ? (
						// <ActiveChip label='Active' />
						<StatusTag
							label={'Active'}
							style={{
								color: theme.colors.text.colored,
								fontWeight: theme.font.weight.normal,
							}}
						/>
					) : (
						// <DisabledChip label='Disabled' style={{ marginLeft: '10px' }} />
						<StatusTag label={'Disabled'} />
					),
			},
			{
				field: 'geo',
				headerName: 'Country',
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					if (
						value === null ||
						value === undefined ||
						value.length === 0 ||
						value[0] === null
					)
						return <></>
					return <span>{value[0].substring(0, 2)}</span>
				},
			},
			{
				field: 'country_cap',
				headerName: 'Audience',
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: (row) => {
					if (row.row.publisher_name) {
						return <></>
					}
					if (row.row.capabilities.length > 0) {
						let geos: any = []
						for (const capability of row.row.capabilities) {
							for (const geo of capability.geo) {
								if (geo.geo[0] && !geos.includes(geo.geo[0].substring(0, 2))) {
									geos.push(geo.geo[0].substring(0, 2))
								}
							}
						}
						const value = geos.join(' - ')
						return (
							<span style={{ fontSize: theme.font.size.caption }}>{value}</span>
						)
					} else {
						return <></>
					}
				},
			},
			{
				field: 'vertical',
				headerName: 'Vertical',
				width: rowWidth * 1,
				disableColumnMenu: true,
				renderCell: (row) => {
					if (row.row.publisher_name) {
						return <></>
					}
					if (row.row.capabilities.length > 0) {
						let verticals: any = []
						for (const capability of row.row.capabilities) {
							for (const ver of capability.vertical) {
								// console.log(ver)
								if (ver && !verticals.includes(ver)) {
									verticals.push(ver)
								}
							}
						}
						const value = verticals.join(' - ')
						return (
							<span style={{ fontSize: theme.font.size.caption }}>
								{verticals.length > 0 ? value : ''}
							</span>
						)
					} else {
						return <></>
					}
				},
			},
			{
				field: 'has_agent',
				headerName: 'Contact',
				width: rowWidth * 0.7,
				disableColumnMenu: true,
				renderCell: ({ value }) => (
					<span style={{ fontSize: theme.font.size.caption }}>
						{value === true ? 'Agency' : 'Direct'}
					</span>
				),
			},
			{
				field: 'badges',
				headerName: 'Badges',
				width: rowWidth * 0.7,
				disableColumnMenu: true,
				renderCell: (row) => {
					return (
						<div style={{ display: 'flex', justifyContent: 'center', gap: 6 }}>
							<Tooltip title='High quality work'>
								<GoodQualityIcon
									style={{
										color: row.row.high_quality_work_badge
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.82',
									}}
								/>
							</Tooltip>
							<Tooltip title='Good communication'>
								<ChatIcon
									style={{
										color: row.row.good_communication_badge
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.9',
										marginTop: -2,
									}}
								/>
							</Tooltip>
						</div>
					)
				},
			},
			{
				field: 'actions',
				headerName: 'Actions',
				resizable: false,
				sortable: false,
				width: rowWidth * 1,
				disableColumnMenu: true,
				renderCell: (row) => <ActionsButton options={actionOptions(row)} />,
				// <SimpleActionsButton
				// 	label={'Actions'}
				// 	// width={rowWidth}
				// 	// row={row}
				// 	options={actionOptions(row)}
				// 	// style={{ width: '40px', height: '22px', fontSize: '30px' }}
				// 	// arrowColor={'rgba(0,0,0,0.75)'}
				// />
			},
		)
	}

	if (login.company.type !== 'agency') {
		if (isCreatorSelected()) {
			columns.splice(3, 1)
		} else {
			columns.splice(2, 1)
			columns.splice(3, 1)
		}
	}

	const handlePreview = (id: any) => {
		if (!isCreatorSelected()) {
			navigate('/publishers/' + id + '/preview')
		} else {
			navigate('/creators/' + id + '/preview')
		}
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleEdit = (id: any) => {
		if (!isCreatorSelected()) {
			navigate('/publishers/' + id)
		} else {
			navigate('/creators/' + id)
		}
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleCellClick = (params: any, ev: React.MouseEvent) => {
		// Check that the clicked cell is not the "actions" field
		if (params.field !== 'actions') {
			// If a creator is selected, use the creator's ID
			let id
			if (isCreatorSelected()) {
				id = params.row.id
			} else {
				// For publishers, use the publisher's name
				id = params.row.id || params.row.publisher_name
			}

			// Handle opening in a new tab with Ctrl or Meta key
			if (ev.ctrlKey || ev.metaKey) {
				const endpoint = isCreatorSelected() ? '/creators/' : '/publishers/'
				window.open(endpoint + id + '/preview', '_blank')
				return
			}

			// Use the preview permission check
			if (permissions.preview) {
				handlePreview(id)
			}
		}
	}

	// const handleLogDelete = async (publisher: any) => {
	// 	const log = {
	// 		email: login.user.email,
	// 		object: publisher! + ' in Publisher',
	// 		change: 'removed publisher',
	// 	}
	// 	await insertLog(log)
	// }
	const handleDelete = async (row: any) => {
		if (!isCreatorSelected()) {
			const payload = {
				publisher_name: row.publisher_name,
				user_login: login.user.email,
				id: row.id,
				//TODO: fix this
				// rule_set: ruleSet,
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			props.setLoading(true)
			await deletePublisher(payload, props.setErrorMessage)
			await fetchInChunks(
				getPublisherAction,
				50,
				'publishers',
				props.setLoading,
			)
			setTableRows((prev) =>
				prev.filter((el) => el.publisher_name !== payload.publisher_name),
			)
			// await fetchData(getHandshakeAction, handshake.handshake)
		} else {
			const payload = {
				id: row.id,
				user_login: login.user.email,
				//TODO: fix this
				rule_set: {},
			}
			props.setLoading(true)
			await deleteCreator(payload.id, props.setErrorMessage)
			await fetchInChunks(getCreatorsAction, 50, 'creators', props.setLoading)
		}
		setUpdate(!update)
		// handleLogDelete(row.publisher_name)
		props.setLoading(false)
	}
	// const permissionsCheck = (row: any) => {
	// 	return (
	// 		login.user.role.toLowerCase() === 'super' ||
	// 		login.user.role.toLowerCase() === 'admin' ||
	// 		row.row.email === login.user.email ||
	// 		row.row.created_by === login.user.email
	// 	)
	// }
	//we use this in order to copy the inside emails that are not finance related of the publisher. That way the user doesn't need
	//to join inside the publisher to copy them.
	const copyEmailsToClipboard = async (row: any) => {
		let cpstr: string = ''

		const decryptedContacts = await decryptContacts(row.contacts)
		if (decryptedContacts && Array.isArray(decryptedContacts)) {
			const emails = []
			for (let email of decryptedContacts) {
				if (email.type === 'main') {
					emails.push(email.email)
				}
			}
			cpstr = emails.join(', ')
			navigator.clipboard
				.writeText(cpstr)
				.then(() => {
					setSuccess({
						title: 'Copy to clipboard',
						message: 'Copied ' + cpstr + ' to clipboard',
					})
				})
				.catch((err) => {
					console.error('Failed to copy: ', err)
					props.setErrorMessage('Failed to copy to clipboard')
					// props.setIsErrorPromptOpened(true)
					// props.setIsSuccessPromptOpened(false)
				})
		} else {
			console.error('Contacts is not an array or is null:', row.contacts)
			props.setErrorMessage('No valid contacts to copy')
			// props.setIsErrorPromptOpened(true)
			// props.setIsSuccessPromptOpened(false)
		}
	}

	const checkPermission = (row: any) => {
		return (
			(row.row.email === login.user.email ||
				row.row.created_by === login.user.email) &&
			permissions.deleteOwnerOnly
		)
	}
	const isAgencyTypeCompany = login.company.type === 'agency'

	const actionOptions = (row: any) => {
		const id = row.row.id
		const sameCompanyId = row.row.company_id === login.company.id
		const ownerEmail = row.row.email
		const salesRep = row.row.sales_rep

		// const canDelete =
		// 	(canAccessResource(login.user, ownerEmail, users, salesRep) &&
		// 		sameCompanyId) ||
		// 	permissions.delete
		const canEdit = hasOwnerAccess(row.row, permissions, 'edit')
		const canDelete = hasOwnerAccess(row.row, permissions, 'delete')
		// console.log(canDelete)

		const options = [
			{
				label: '',
				icon: (
					<PreviewIcon
						style={{ color: theme.colors.text.titles, scale: '10' }}
						className={'action-button'}
					/>
				),
				onClick: () => handlePreview(id),
				rule: permissions.preview,
				key: 'preview' + id,
			},
		]

		if (login.company.type === 'agency') {
			options.push({
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '7.2' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleEdit(id),
				rule: permissions.edit && isAgencyTypeCompany,
				key: 'edit',
			})

			// Conditionally add the delete option if the user has permission
			if (canDelete) {
				options.push({
					label: '',
					icon: (
						<DeleteIcon
							style={{ color: theme.colors.text.titles, scale: '8' }}
							className={'action-button'}
						/>
					),
					onClick: () => {
						setRow(row.row)
						setIsWarningPromptOpened(true)
					},
					rule: canDelete,
					key: 'delete',
				})
			}
			if (isCreatorSelected() === false) {
				options.splice(2, 0, {
					label: '',
					icon: (
						<CopyIcon
							style={{ color: theme.colors.text.titles, scale: '7.2' }}
							className={'action-button'}
						/>
					),
					onClick: () => copyEmailsToClipboard(row.row),
					rule:
						permissions.viewPublisherContact ||
						canAccessResource(login.user, ownerEmail, users, salesRep),
					key: 'copy',
				})
			}
		}
		return options
	}
	const downloadButtonoptions = (
		<div>
			<MenuItem onClick={() => handleDownloadReport()}>
				Publishers list
			</MenuItem>
			<MenuItem onClick={() => handleDownloadReportPid()}>
				Publishers with PID list
			</MenuItem>
			{login.user.position.toLowerCase() === 'full stack developer' && (
				<MenuItem onClick={() => handleDownloadContacts()}>
					Contacts list (Only devs)
				</MenuItem>
			)}
		</div>
	)
	const uploadButtonoptions = (
		<div>
			{/* {login.user.position.toLowerCase() === 'full stack developer' &&
				!isCreatorSelected() && (
					<MenuItem onClick={handleModalOpen}>
						Upload PID Handshake (Only devs)
					</MenuItem>
				)} */}
			{login.user.position.toLowerCase() === 'full stack developer' &&
				isCreatorSelected() && (
					<MenuItem onClick={() => {}}>
						{/* ----In case we want to re use upload crreators again ---- */}
						{/* <MenuItem onClick={handleModalOpen}> */}
						Upload Creators File (Only devs)
					</MenuItem>
				)}
		</div>
	)
	const handlePopUp = async () => {
		props.setPopUpVisible(!props.PopUpVisible)
		setIsNewPublisher(true)
		setIsNewCreator(true)

		if (props.PopUpVisible) {
			props.setLoading(true)
			setUpdate(!update)
			setRow(undefined)
			setIsNewPublisher(false)
			setIsNewCreator(false)
			props.setLoading(false)
		}
	}
	const getAdvertiserNameArray = (rows: any) => {
		return rows.map((el: any) => "'" + el.publisher_name + "'")
	}
	const handleDownloadReport = async () => {
		try {
			await getPublisherReport(
				setDownloadFile,
				getAdvertiserNameArray(tableRows),
				skypeGroupUserAllowed,
			)
		} catch (error) {
			console.error(error)
		}
	}
	const handleDownloadReportPid = async () => {
		try {
			await getPublisherReportPid(
				setDownloadFile,
				getAdvertiserNameArray(tableRows),
				skypeGroupUserAllowed,
			)
		} catch (error) {
			console.error(error)
		}
	}
	//this function is not used now but we still have the feature of allowing a download of all the contacts for every publisher.
	const handleDownloadContacts = async () => {
		try {
			await getPublisherContacts(setDownloadFile)
		} catch (error) {
			console.error(error)
		}
	}

	const reloadRow = async () => {
		try {
			await fetchInChunks(
				getPublisherAction,
				50,
				'publishers',
				props.setLoading,
			)
			// await fetchData(getHandshakeAction, handshake.handshake)
			await fetchInChunks(getCreatorsAction, 50, 'creators', props.setLoading)
		} catch (error) {
			console.error(error)
		}
	}

	//this triggers the download when we download the list of publishers.
	useEffect(() => {
		if (downloadFile) {
			saveAs(downloadFile, '*.csv')
		}
	}, [downloadFile])
	const handleLoading = async () => {
		// props.setLoading(true)
		// await fetchData(getPublisherAction, publisher.publisher)
		// await fetchData(getHandshakeAction, handshake.handshake)
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
		}
		// props.setLoading(false)
	}

	useEffect(() => {
		handleLoading()
		if (!props.PopUpVisible && params.id) {
			handleEdit(params.id)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter])

	const tabOptions = [
		{ label: 'Publishers', tabIndex: 0 },
		{ label: 'Creators', tabIndex: 1 },
	]
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		// setTab(newValue)
		//We do use this approach in order to change the url so whenever you are in creators table you are not seeing "publishers" endpoint
		//wich might be confusing for the users.
		if (newValue === 1) {
			navigate('/creators')
		} else {
			navigate('/publishers')
		}
	}

	const handleUpload = async (file: any) => {
		const formData = new FormData()
		formData.append('file', file)
		if (isCreatorSelected()) {
			await insertCreatorsAction(formData)
		} else {
			// await insertPidHandshakeAction(formData)
		}
		handleModalClose() // close modal after upload
	}

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				{permissions.viewTabsButton && (
					<TabContainer
						tab={tab}
						handleChangeTab={handleChangeTab}
						tabProps={a11yProps}
						options={tabOptions}
						tabStyle={{
							textTransform: 'none',
							minWidth: '8vw',
							fontSize: theme.font.size.buttons,
						}}
						mainStyle={{
							display: 'flex',
							position: 'absolute',
							left: '42%',
							zIndex: '9',
							top: '20%',
						}}
					/>
				)}
				{permissions.view && (
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '90%', marginTop: 60 }}
					>
						<TableComponent
							columns={columns}
							rows={tableRows}
							setPageSize={setPageSize}
							pageSize={pageSize}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							filterStatus={
								login.company.type === 'agency'
									? publisherFilterStatus
									: undefined
							}
							setFilterStatus={setPublisherFilterStatus}
							handlePopUp={
								permissions.edit && permissions.addNew ? handlePopUp : undefined
							}
							downloadButtonOptions={
								!isCreatorSelected() && permissions.downloadPublisherList
									? downloadButtonoptions
									: undefined
							}
							mediaPlanHandler={
								!isCreatorSelected() && permissions.accessMediaPlan
									? mediaPlanHandler
									: undefined
							}
							// handshakeHandler={
							// 	!isCreatorSelected() && permissions.accessMediaPlan
							// 		? handshakeHandler
							// 		: undefined
							// }
							onCellClick={permissions.preview ? handleCellClick : undefined}
							sortModel={sortModel}
							onSortModelChange={(model: any) => setSortModel(model)}
							filterHandler={openFilter}
							setFilterHander={setOpenFilter}
							customFilter={customFilter}
							setCustomFilter={setCustomFilter}
							currentFilters={currentFilters}
							applyFilter={handleApplyFilter}
							filterLabel={filterLabel}
							optionsFilters={optionsFilters}
							uploadButtonOptions={
								['super', 'developer'].includes(login.user.role?.toLowerCase())
									? uploadButtonoptions
									: undefined
							}
							// allowUpload={
							// 	isCreatorSelected() && ["developer"].includes(login.user.role?.toLowerCase())
							// }
							// handleUpload={handleModalOpen}
						/>
					</TableWrapper>
				)}
				<FileUploadModal
					open={isModalOpen}
					handleClose={handleModalClose}
					onUpload={handleUpload}
				></FileUploadModal>

				<WarningPrompt
					message={'Deleting the row now?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => handleDelete(row)}
					onCancel={() => props.setLoading(false)}
					obj={row}
				/>
			</PageWrapper>
		</ThemeProvider>
	) : !isCreatorSelected() ? (
		<PublisherPopUp
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={handlePopUp}
			row={row}
			loginRole={login.user.role}
			setLoading={props.setLoading}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			reloadRow={reloadRow}
			isNewPublisher={isNewPublisher}
			setIsNewPublisher={setIsNewPublisher}
		/>
	) : (
		<CreatorPopUp
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={handlePopUp}
			row={row}
			loginRole={login.user.role}
			setLoading={props.setLoading}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			reloadRow={reloadRow}
			isNewCreator={isNewCreator}
			setIsNewCreator={setIsNewCreator}
		/>
	)
}

export default PublisherPage
