import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	actionCreators,
	publisherActionCreators,
	campaignsActionCreators,
	advertiserActionCreators,
	userActionCreators,
	roleActionCreators,
	settingsActionCreators,
	financeActionCreators,
	reportActionCreators,
	newsActionCreators,
	logActionCreators,
	learningActionCreators,
	creatorActionCreators,
	alertActionCreators,
	supportActionCreators,
	notificationActionCreators,
	errorActionsCreators,
	successActionsCreators,
	showMoreActionsCreators,
	tasksActionCreators,
} from '../state'

export const useActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(actionCreators, dispatch)
}

export const usePublisherActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(publisherActionCreators, dispatch)
}

export const useCampaignsActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(campaignsActionCreators, dispatch)
}

export const useAdvertiserActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(advertiserActionCreators, dispatch)
}

export const useUserActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(userActionCreators, dispatch)
}

export const useRoleActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(roleActionCreators, dispatch)
}

export const useSettingsActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(settingsActionCreators, dispatch)
}

export const useFinanceActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(financeActionCreators, dispatch)
}

export const useReportActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(reportActionCreators, dispatch)
}

export const useNewsActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(newsActionCreators, dispatch)
}

export const useLogActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(logActionCreators, dispatch)
}

export const useLearningActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(learningActionCreators, dispatch)
}

export const useCreatorActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(creatorActionCreators, dispatch)
}

export const useAlertsActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(alertActionCreators, dispatch)
}

export const useSupportActions = () => {
	const disaptch = useDispatch()

	return bindActionCreators(supportActionCreators, disaptch)
}

export const useNotificationActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(notificationActionCreators, dispatch)
}

export const useErrorActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(errorActionsCreators, dispatch)
}

export const useSuccessActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(successActionsCreators, dispatch)
}
export const useShowMoreActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(showMoreActionsCreators, dispatch)
}

export const useTasksActions = () => {
	const dispatch = useDispatch()

    return bindActionCreators(tasksActionCreators, dispatch)
}
