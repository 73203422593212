import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ContactInterface } from '../../../models/contact.interface'
import { ContactRowItem } from './ContactRowItem'
import { CustomActionsMenu } from '../Menus'
import CustomModal from '../CustomModal'
import { SubmitButton } from '../Buttons'

interface PreviewContactsProps {
	contactDetails: ContactInterface[]
	financeDetails: ContactInterface[]
	isReadOnly: boolean
	canEdit: boolean
	p360Active: boolean
	roleOptions?: string[]
	handleFieldChange: (
		newValue: string,
		contactUUID: string,
		fieldName: keyof ContactInterface,
	) => void
	handleGeoChange: (contactUUID: string, newGeoArray: string[]) => void
	handleMenuOpen: (e: React.MouseEvent<HTMLElement>, uuid: string) => void
	handleMenuClose: () => void
	anchorEl: null | HTMLElement
	selectedContact: string | null
	menuItems: {
		label: string
		icon: React.ReactNode
		onClick: () => void
		hoverColor?: string
	}[]
	setContacts: React.Dispatch<React.SetStateAction<ContactInterface[]>>
	getSafeValue: (value: any, defaultValue?: any) => any
	addtionalStyle?: React.CSSProperties
}

export const PreviewContactsComponent: React.FC<PreviewContactsProps> = ({
	contactDetails,
	financeDetails,
	isReadOnly,
	canEdit,
	p360Active,
	roleOptions,
	handleFieldChange,
	handleGeoChange,
	handleMenuOpen,
	handleMenuClose,
	anchorEl,
	selectedContact,
	menuItems,
	setContacts,
	getSafeValue,
	addtionalStyle,
}) => {
	const theme = useTheme()

	const visibleContacts = contactDetails.slice(0, 2)
	const hiddenContacts = contactDetails.slice(2)

	const visibleFinance = financeDetails.slice(0, 2)
	const hiddenFinance = financeDetails.slice(2)

	const [showContactsModalOpen, setShowContactsModalOpen] = useState(false)
	const [showFinanceModalOpen, setShowFinanceModalOpen] = useState(false)

	// 4) Handlers to open/close modals
	const handleShowContactsModal = () => setShowContactsModalOpen(true)
	const handleShowFinanceModal = () => setShowFinanceModalOpen(true)
	const handleCloseContactsModal = () => setShowContactsModalOpen(false)
	const handleCloseFinanceModal = () => setShowFinanceModalOpen(false)

	return (
		<>
			{/* CONTACT DETAILS in its own container */}
			{contactDetails.length > 0 && (
				<Box
					sx={{
						backgroundColor: theme.colors.preview.cardBackground,
						borderRadius: '15px',
						padding: '2rem 2.2rem',
						width: '95%',
						border: `1px solid ${theme.colors.preview.border}`,
						...addtionalStyle,
					}}
				>
					<Typography
						variant='body1'
						sx={{
							marginLeft: '0.6rem',
							color: theme.colors.text.titles,
							fontWeight: theme.font.weight.bold,
							fontSize: '1rem',
							marginBottom: '1rem',
						}}
					>
						Contact Details
					</Typography>

					{/* Visible Contacts */}
					{visibleContacts.map((contact, index) => {
						const showMoreButton =
							index === visibleContacts.length - 1 && hiddenContacts.length > 0

						return (
							<ContactRowItem
								key={contact.uuid}
								contact={contact}
								isReadOnly={isReadOnly}
								canEdit={canEdit}
								roleOptions={roleOptions}
								p360Active={p360Active}
								handleFieldChange={handleFieldChange}
								handleGeoChange={handleGeoChange}
								handleMenuOpen={handleMenuOpen}
								setContacts={setContacts}
								getSafeValue={getSafeValue}
								// NEW PROPS
								showMoreButton={showMoreButton}
								onShowMore={handleShowContactsModal}
							/>
						)
					})}

					<CustomActionsMenu
						anchorEl={anchorEl}
						isOpen={Boolean(anchorEl) && selectedContact !== null}
						handleClose={handleMenuClose}
						menuItems={menuItems}
					/>
				</Box>
			)}

			{financeDetails.length > 0 && (
				<Box
					sx={{
						backgroundColor: theme.colors.preview.cardBackground,
						borderRadius: '15px',
						padding: '2rem 2.2rem',
						width: '95%',
						marginTop: '0rem',
						border: `1px solid ${theme.colors.preview.border}`,
						...addtionalStyle,
					}}
				>
					{/* SECTION TITLE */}
					<Typography
						variant='body1'
						sx={{
							marginLeft: '0.6rem',
							color: theme.colors.text.titles,
							fontWeight: theme.font.weight.bold,
							fontSize: '1rem',
							marginBottom: '1rem',
						}}
					>
						Finance Contacts
					</Typography>

					{/* Visible Finance Rows */}
					{visibleFinance.map((contact, index) => {
						const showMoreButton =
							index === visibleFinance.length - 1 && hiddenFinance.length > 0

						return (
							<ContactRowItem
								key={contact.uuid}
								contact={contact}
								isReadOnly={isReadOnly}
								canEdit={canEdit}
								roleOptions={roleOptions}
								p360Active={p360Active}
								handleFieldChange={handleFieldChange}
								handleGeoChange={handleGeoChange}
								handleMenuOpen={handleMenuOpen}
								setContacts={setContacts}
								getSafeValue={getSafeValue}
								// NEW PROPS
								showMoreButton={showMoreButton}
								onShowMore={handleShowFinanceModal}
							/>
						)
					})}

					<CustomActionsMenu
						anchorEl={anchorEl}
						isOpen={Boolean(anchorEl) && selectedContact !== null}
						handleClose={handleMenuClose}
						menuItems={menuItems}
					/>
				</Box>
			)}

			{/* ========== CONTACTS MODAL ========== */}
			<CustomModal
				title='All Contact Details'
				open={showContactsModalOpen}
				onClose={handleCloseContactsModal}
				maxWidth='xl' // make it as wide as possible
				actions={
					<SubmitButton onClick={handleCloseContactsModal}>OK</SubmitButton>
				}
				PaperProps={{
					style: {
						width: '70vw',
						padding: '3rem',
					},
				}}
			>
				<div
					style={{
						maxHeight: '600px',
						overflowY: 'auto',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
					}}
				>
					{hiddenContacts.map((contact) => (
						<ContactRowItem
							key={contact.uuid}
							contact={contact}
							isReadOnly={isReadOnly}
							canEdit={canEdit}
							roleOptions={roleOptions}
							p360Active={p360Active}
							handleFieldChange={handleFieldChange}
							handleGeoChange={handleGeoChange}
							handleMenuOpen={handleMenuOpen}
							setContacts={setContacts}
							getSafeValue={getSafeValue}
							previewContainerStyle={{
								padding: '2rem 1rem',
								width: '100%',
							}}
							showMoreButton={false}
						/>
					))}
				</div>
			</CustomModal>

			{/* ========== FINANCE MODAL ========== */}
			<CustomModal
				title='All Finance Details'
				open={showFinanceModalOpen}
				onClose={handleCloseFinanceModal}
				maxWidth='xl'
				actions={
					<SubmitButton onClick={handleCloseFinanceModal}>OK</SubmitButton>
				}
				PaperProps={{
					style: {
						width: '60vw',
						maxWidth: '700px',
						padding: '2rem',
					},
				}}
			>
				<div
					style={{
						maxHeight: '600px',
						overflowY: 'auto',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
					}}
				>
					{hiddenFinance.map((contact) => (
						<ContactRowItem
							key={contact.uuid}
							contact={contact}
							isReadOnly={isReadOnly}
							canEdit={canEdit}
							roleOptions={roleOptions}
							p360Active={p360Active}
							handleFieldChange={handleFieldChange}
							handleGeoChange={handleGeoChange}
							handleMenuOpen={handleMenuOpen}
							setContacts={setContacts}
							getSafeValue={getSafeValue}
							previewContainerStyle={{
								padding: '1rem 1rem',
								width: '100%',
							}}
							showMoreButton={false}
						/>
					))}
				</div>
			</CustomModal>
		</>
	)
}
