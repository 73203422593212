import { PublisherInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface PublisherReducerInterface {
	publisher: PublisherInterface[] | any
}

const initialState = {
	publisher: [],
}

const reducer = (
	state: PublisherReducerInterface = initialState,
	action: Action,
): PublisherReducerInterface => {
	switch (action.type) {
		case ActionType.GET_PUBLISER:
			const alreadyIn = state.publisher
			let updated = []
			//we check the index because if it's 0 it means we are pulling the data again so we force a clean up just in case.
			if (action.index === 0) {
				updated = [...action.payload]
			} else {
				updated = [...alreadyIn, ...action.payload]
			}
			return {
				...state,
				publisher: updated,
			}
		case ActionType.GET_PUBLISHER_CONTACTS:
			return {
				...state,
				publisher: action.payload,
			}

		case ActionType.INSERT_PUBLIHSER: {
			let arr = []
			let changed = false
			if (state.publisher.length > 0) {
				arr = state.publisher.map((pub: any) => {
					if (pub.publisher_name === action.payload.publisher_name) {
						changed = true
						return action.payload
					}
					return pub
				})
			} else {
				state.publisher.unshift(action.payload)
			}
			if (!changed) {
				state.publisher.unshift(action.payload)
			}

			return {
				publisher: changed ? arr : state.publisher,
			}
		}
		case ActionType.INSERT_PUBLIHSER_MASTER: {
			let arr = []

			if (state.publisher.length > 0) {
				arr = state.publisher.map((pub: any) => {
					if (pub.id === action.payload.id) {
						return action.payload
					}
					return pub
				})
			}

			return {
				publisher: arr,
			}
		}
		case ActionType.INSERT_PUBLIHSER_META: {
			let arr = []

			if (state.publisher.length > 0) {
				arr = state.publisher.map((pub: any) => {
					if (pub.id === action.payload.id) {
						return action.payload
					}
					return pub
				})
			}

			return {
				publisher: arr,
			}
		}

		case ActionType.UPDATE_PUBLISHER_ICON:
			return {
				...state,
				publisher: state.publisher.map((pub: PublisherInterface) =>
					pub.publisher_name === action.payload.name
						? { ...pub, iconUrl: action.payload.iconUrl }
						: pub,
				),
			}
		case ActionType.DELETE_PUBLISHER_ICON:
			return {
				...state,
				publisher: state.publisher.map((pub: PublisherInterface) => {
					if (pub.publisher_name === action.payload.name) {
						return { ...pub, iconUrl: action.payload.iconUrl }
					}
					return pub
				}),
			}
		case ActionType.LOGOUT:
			return {
				...state,
				publisher: [],
			}
		default:
			return state
	}
}

export default reducer
