import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../state/reducers'
import { useShowMoreActions } from '../../hooks/useActions'
import ShowMoreModal from './ShowMoreModal'

const GlobalShowMoreHandler = () => {
	const dispatch = useDispatch()
	const { setShowMore, clearShowMore } = useShowMoreActions()
	const { showMore, showMoreValue, showMoreTitle, searchBar } = useSelector(
		(state: RootState) => state.showmore,
	)

	const handleClose = () => {
		dispatch(clearShowMore())
	}
	if (!showMore) return null

	return (
		<ShowMoreModal
			showMore={showMore}
			showMoreValues={showMoreValue}
			showMoreTitle={showMoreTitle}
			handleClose={handleClose}
			searchBar={searchBar}
		/>
	)
}

export default GlobalShowMoreHandler
