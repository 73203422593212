import React, { useState, useMemo, CSSProperties } from 'react'
import { useTheme } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'
import { ContactInterface } from '../../../models/contact.interface'
import { CustomActionsMenu } from '../Menus'
import { EditContactsComponent } from './EditContactsComponent'
import { PreviewContactsComponent } from './PreviewContactsComponent'

interface CustomContactsProps {
	contacts: ContactInterface[]
	setContacts: React.Dispatch<React.SetStateAction<ContactInterface[]>>
	p360Active?: boolean
	canEdit?: boolean
	roleOptions?: string[]
	mode?: 'edit' | 'preview'
	onAddToP360?: (contact: ContactInterface) => void
	onRemoveFromP360?: (contact: ContactInterface) => void
	onRemoveContact?: (contact: ContactInterface) => void
	addtionalStyle?: CSSProperties
}

const CustomContactsComponent: React.FC<CustomContactsProps> = ({
	contacts,
	setContacts,
	p360Active = true,
	canEdit = true,
	roleOptions,
	mode = 'preview',
	onAddToP360,
	onRemoveFromP360,
	onRemoveContact,
	addtionalStyle,
}) => {
	const theme = useTheme()

	const isReadOnly = useMemo(
		() => mode === 'preview' || !canEdit,
		[mode, canEdit],
	)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [selectedContact, setSelectedContact] = useState<string | null>(null)

	const [hoverContact, setHoverContact] = useState(false)
	const [hoverFinance, setHoverFinance] = useState(false)

	const isPreviewMode = mode === 'preview'
	const isEditMode = mode === 'edit'

	const handleFieldChange = (
		newValue: string,
		contactUUID: string,
		fieldName: keyof ContactInterface,
	) => {
		if (isReadOnly) return
		setContacts((prev) =>
			prev.map((c) =>
				c.uuid === contactUUID
					? {
							...c,
							[fieldName]: fieldName === 'geo' ? newValue.split(',') : newValue,
					  }
					: c,
			),
		)
	}

	const handleGeoChange = (contactUUID: string, newGeoArray: string[]) => {
		if (isReadOnly) return
		setContacts((prev) =>
			prev.map((c) =>
				c.uuid === contactUUID ? { ...c, geo: newGeoArray } : c,
			),
		)
	}

	const handleMenuOpen = (
		event: React.MouseEvent<HTMLElement>,
		uuid: string,
	) => {
		if (isReadOnly) return
		setAnchorEl(event.currentTarget)
		setSelectedContact(uuid)
	}
	const handleMenuClose = () => {
		setAnchorEl(null)
		setSelectedContact(null)
	}

	const handleAddNewContact = (contactType: 'contact' | 'finance') => {
		if (isReadOnly) return
		const newContact: ContactInterface = {
			uuid: crypto.randomUUID(),
			name: '',
			role: '',
			email: '',
			skype: '',
			geo: [],
			type: contactType,
		}
		setContacts((prev) => [...prev, newContact])
	}

	const selectedContactType = contacts.find(
		(c) => c.uuid === selectedContact,
	)?.type
	const selectedContactObj = contacts.find((c) => c.uuid === selectedContact)

	const menuItems = [
		...(selectedContactType === 'contact' && p360Active
			? [
					{
						label: 'Add to P360',
						icon: <AddCircleOutlineIcon fontSize='small' />,
						onClick: () => {
							if (selectedContactObj && onAddToP360) {
								onAddToP360(selectedContactObj)
							}
						},
					},
					{
						label: 'Remove from P360',
						icon: <DeleteIcon />,
						onClick: () => {
							if (selectedContactObj && onRemoveFromP360) {
								onRemoveFromP360(selectedContactObj)
							}
						},
						hoverColor: theme.colors.base.red300,
					},
			  ]
			: []),
		{
			label: 'Remove Contact',
			icon: <DeleteIcon />,
			onClick: () => {
				if (selectedContactObj && onRemoveContact) {
					onRemoveContact(selectedContactObj)
				}
			},
			// hoverColor: theme.colors.base.red300,
		},
	]
	// =========== Splitting contacts ============
	const contactDetails = contacts.filter(
		(c) => c.type === 'contact' || c.type === 'main',
	)
	const financeDetails = contacts.filter((c) => c.type === 'finance')

	const noContactsExist =
		isPreviewMode && contactDetails.length === 0 && financeDetails.length === 0
	if (noContactsExist) {
		return null
	}

	const getSafeValue = (value: any, defaultValue: any = '') => {
		if (Array.isArray(value)) {
			return value
		}
		return value !== undefined && value !== null ? value : defaultValue
	}

	if (isEditMode) {
		return (
			<EditContactsComponent
				contacts={contacts}
				setContacts={setContacts}
				isReadOnly={isReadOnly}
				canEdit={canEdit}
				p360Active={p360Active}
				roleOptions={roleOptions}
				addtionalStyle={addtionalStyle}
				handleFieldChange={handleFieldChange}
				handleGeoChange={handleGeoChange}
				handleAddNewContact={handleAddNewContact}
				handleMenuOpen={handleMenuOpen}
				anchorEl={anchorEl}
				selectedContact={selectedContact}
				handleMenuClose={handleMenuClose}
				menuItems={menuItems}
				isPreviewMode={isPreviewMode}
				hoverContact={hoverContact}
				setHoverContact={setHoverContact}
				hoverFinance={hoverFinance}
				setHoverFinance={setHoverFinance}
				getSafeValue={getSafeValue}
				contactDetails={contactDetails}
				financeDetails={financeDetails}
			/>
		)
	}

	return (
		<PreviewContactsComponent
			contactDetails={contactDetails}
			financeDetails={financeDetails}
			isReadOnly={isReadOnly}
			canEdit={canEdit}
			p360Active={p360Active}
			roleOptions={roleOptions}
			handleFieldChange={handleFieldChange}
			handleGeoChange={handleGeoChange}
			handleMenuOpen={handleMenuOpen}
			handleMenuClose={handleMenuClose}
			anchorEl={anchorEl}
			selectedContact={selectedContact}
			menuItems={menuItems}
			setContacts={setContacts}
			getSafeValue={getSafeValue}
			addtionalStyle={addtionalStyle}
		/>
	)
}

export default CustomContactsComponent
