import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	GridEditSingleSelectCell,
	useGridApiRef,
	GridEditInputCell,
} from '@mui/x-data-grid-pro'
import { MenuItem, Typography, useTheme } from '@mui/material'
import './finance.css'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'

import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import {
	findAdvertiserByOwner,
	findPublisherFromPid,
	formatDateToMonth,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	handleFinanceStatusColor,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import Guidelines from './Guidelines'
import { DropList } from '../components/SelectableInputs'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { SmallLoading } from '../../assets/svg/loading'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

//this is the first page of finance.
//In finance we have 4 pages:
// - Personal: Where the user is able to upload his excel
// - Global: Where the user can see all the data of the company
// - Supply: Where the user can see the data ordered by publisher
// - Demand: Where the user can see the data ordered by advertiser
// The idea of personal page is that the user can download an excel template that he will be able to fill and upload to the system
//After that, the system will show all the data with error messages if it's missing data (publisher or advertiser)
//Inside personal the user is also able to edit row by row if he wants or to upload a new excel that will UPDATE / INSERT data
// based on what he has on his file. IE: Same campaign, period and pid will update the row instead of adding a new one.
const PersonalPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, publisher, advertiser, users, settings } =
		useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		delete: useIsAuthorized(permissionNames.DELETE_FINANCE),
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const {
		downloadFinanceTemplateAction,
		downloadFinanceFilledTemplateAction,
		uploadFinanceTemplate,
		getFinanceData,
		uploadFinanceRow,
		deleteFinanceRow,
	} = useFinanceActions()
	const sessionKey = 'filters-finance-personal'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [file, setFile] = useState(null)
	const fileInputRef = useRef()
	const [pageSize, setPageSize] = useState(15)
	const [update, setUpdate] = useState(false)
	const [filteredFinance, setFilteredFinance] = useState<any>(
		Array.isArray(finance.finance) ? finance.finance : [],
	)
	const rowCount = 15
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(filteredFinance),
	)
	const [openFilter, setOpenFilter] = useState(false)
	const [errorFilter, setErrorFilter] = useState<boolean>(
		sessionFilters.errorFilter ? sessionFilters.errorFilter : false,
	)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					campaign: [],
					platform: [],
					advertiser: [],
					pid: [],
					publisher: [],
			  },
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		campaign: [],
		platform: [],
		advertiser: [],
		pid: [],
		publisher: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const apiRef = useGridApiRef()
	const previousMonth = moment().subtract(1, 'months').format('MMMM').toString()
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray?.length > 0
			? sessionFilters.monthFilterArray
			: [previousMonth],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray
			? sessionFilters.yearFilterArray
			: ['This Year'],
	)
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const advertiserList = advertiser.advertiser.map(
		(el: any) => el.advertiser_name,
	)
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)
	const [smallLoading, setSmallLoading] = useState(true)
	//this is the function we use to download the template. We open a blank page in order to not update the current page.
	const downloadTemplate = async () => {
		const url: any = await downloadFinanceTemplateAction()
		window.open(url, '_blank')
	}
	const downloadCurrentData = useCallback(async () => {
		const url: any = await downloadFinanceFilledTemplateAction(
			tableRowsRef.current,
		)
		window.open(url, '_blank')
	}, [])

	const tableRowsRef = useRef(tableRows)

	useEffect(() => {
		tableRowsRef.current = tableRows
	}, [tableRows])

	const fileSelectedHandler = (event: any) => {
		setFile(event.target.files[0])
	}
	const fileInputClickHandler = useCallback(() => {
		setFile(null)
		;(fileInputRef.current as any).value = ''
		;(fileInputRef.current as any).click()
	}, [fileInputRef])
	//function to upload data with the excel file.
	const fetchData = async (signal: AbortSignal) => {
		setSmallLoading(true)
		dispatch(emptyFinance())
		let i = 0
		let result
		const year = yearFilterArray
		const month = monthFilterArray

		do {
			if (signal.aborted) {
				setDataFetched(true)
				return
			}
			result = await getFinanceData(
				'personal',
				props.errorMessage,
				login.user.email,
				500,
				i,
				undefined,
				{ year: year, month },
			)
			i++
			setDataFetched(true)
			if (result.length === 0) {
				setSmallLoading(false)
			}
		} while (result && result.length > 0)
	}
	const uploadData = async () => {
		if (!file) {
			return
		}
		props.setLoading(true)
		const formData = new FormData()
		formData.append('finance', file)
		formData.append('email', login.user.email)
		const failed = await uploadFinanceTemplate(formData, props.setErrorMessage)
		// if (!failed) {
		// 	props.setIsSuccessPromptOpened(true)
		// }
		if (!failed) {
			const controller = new AbortController()
			const signal = controller.signal
			// props.setIsSuccessPromptOpened(true)
			fetchData(signal)
		}
		setFile(null)
		;(fileInputRef.current as any).value = ''
		props.setLoading(false)
	}

	useEffect(() => {
		if (file) {
			uploadData()
		}
	}, [file])

	useEffect(() => {
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(
					sessionFilters.monthFilterArray.length > 0
						? sessionFilters.monthFilterArray
						: [previousMonth],
				)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(
					sessionFilters.yearFilterArray.length > 0
						? sessionFilters.yearFilterArray
						: ['This Year'],
				)
			sessionFilters.errorFilter && setErrorFilter(sessionFilters.errorFilter)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				errorFilter,
				financeFilterStatus,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		errorFilter,
		financeFilterStatus,
	])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		fetchData(signal)

		return () => controller.abort() // Cleanup previous requests
	}, [monthFilterArray, yearFilterArray])

	useEffect(() => {
		setFilteredFinance(
			Array.isArray(finance.finance)
				? finance.finance.filter((el: any) => el.owner === login.user.email)
				: [],
		)
	}, [finance.finance])

	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				campaign: new Set(
					tableRows
						.map((element: any) => element.campaign)
						.filter((el) => el.length > 0),
				),
				platform: new Set(
					tableRows
						.map((element: any) => element.platform)
						.filter((el) => el.length > 0),
				),
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el) => el.length > 0),
				),
				pid: new Set(
					tableRows
						.map((element: any) => element.pid)
						.filter((el) => el.length > 0),
				),
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el) => el.length > 0),
				),
			})
		}
	}, [tableRows])

	//same filter that we always use. In this case, instead of 3 status we have 4. The logic behind relies the same.
	const possibleFinanceFilter = useMemo(
		() => ['All', 'Yes', 'No', 'On Hold', 'NA'],
		[],
	)
	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, columns)
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'na',
				)
			}
		} else {
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status?.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status?.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status?.toLowerCase() === 'on hold',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status?.toLowerCase() === 'na',
				)
			}
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					foundByKey = customFilter[key].includes(data[key])
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length === 0) {
			arr = generateRowsWithIds(arr)
		} else {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = generateRowsWithIds(filteredData)
		}
		//this handles the months
		if (monthFilterArray.length === 0) {
			arr = generateRowsWithIds(arr)
		} else {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = generateRowsWithIds(filteredData)
		}
		if (errorFilter) {
			const errorRows = arr
				.map((el: any) => {
					if (
						!el.publisher ||
						!advertiserList.includes(el.advertiser) ||
						(!el.revenue && el.revenue !== 0)
					) {
						return el
					}
					return undefined
				})
				.filter((el: any) => el !== undefined)
			// setTableRows(errorRows)
			arr = errorRows
		}
		setTableRows(arr)
		setUnfiltered(arr)
		setPinnedBottomRow(calculatePinnedTotalRow(arr))
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		financeFilterStatus,
		props.search,
		applyFilter,
		filteredFinance,
		errorFilter,
	])

	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])
	//these next functions are used to track the edit, remove, save and cancel of an specific row.
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const handleDeleteClick = (id: any) => async () => {
		const deleted = window.confirm(
			'Deleting this will remove the entire row from the database. Are you sure?',
		)
		if (deleted) {
			let rowToRemove
			const arr = tableRows.filter((element: any) => {
				if (element.id !== id) {
					return true
				} else {
					rowToRemove = element
					return false
				}
			})
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))

			await deleteFinanceRow(
				rowToRemove,
				login.user.email,
				props.setErrorMessage,
			)
		}
	}
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	const downloadOptions = useMemo(
		() => (
			<div>
				{/* <MenuItem onClick={handleClose}>Preview</MenuItem> */}
				<MenuItem key={'menuption_template'} onClick={downloadTemplate}>
					Empty Template
				</MenuItem>
				<MenuItem key={'menuption_current'} onClick={downloadCurrentData}>
					Current Data
				</MenuItem>
			</div>
		),
		[downloadCurrentData],
	)

	const columns: GridColDef[] = [
		{
			field: 'campaign',
			headerName: 'Campaign',
			width: rowWidth * 1.4,
			editable: true,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Campaign'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'campaign'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'none') {
					return <span className='is-not-editable'>{params.value}</span>
				}
				return <GridEditInputCell {...params} />
			},
			renderCell: (row) => {
				if (row.row.id === 9999999999) {
					return (
						<span
							style={{
								fontWeight: theme.font.weight.normal,
								fontSize: theme.font.size.body,
								justifyContent: row.id === 9999999999 ? 'center' : 'left',
								display: 'flex',
								width: '100%',
							}}
						>
							{row.value}
							{smallLoading && (
								<div style={{ display: 'relative' }}>
									<SmallLoading />
								</div>
							)}
						</span>
					)
				}

				return (
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: 14,
							justifyContent: row.id === 9999999999 ? 'center' : 'left',
							display: 'flex',
							width: '100%',
						}}
					>
						{row.value}
					</Typography>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'agency_account',
			headerName: 'Agency Account',
			width: rowWidth * 0.6,
			align: 'center',
			disableColumnMenu: true,
			renderCell: (row) => {
				if (row.row.agency_account === 'Other') {
					return <span>{row.row.agency_account_specification}</span>
				} else {
					return <span>{row.row.agency_account}</span>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth * 0.8,
			editable: true,
			type: 'singleSelect',
			align: 'center',
			disableColumnMenu: true,
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'none') {
					return <span className='is-not-editable'>{params.value}</span>
				}
				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'advertiser',
								value: e,
							})
						}}
						options={[
							'Affise',
							'Appsflyer',
							'Adjust',
							'Kochava',
							'Branch',
							'Singular',
							'Tenjin',
							'Appmetrica',
							'Airbridge',
							'Swaarm',
							'Everflow',
							'Hassoffers',
							'Awin',
							'Income Access',
							'Partnerstack',
							'Impact',
							'MB',
							'Other',
						]}
						label={''}
						value={params.value}
						noBackground
						style={{
							marginTop: -20,
							marginRight: 0,
							color: theme.colors.text.titles,
						}}
						autoCompleteStyle={{ border: 'none', borderRight: 'none' }}
					/>
				)
			},
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Platform'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'platform'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'advertiser',
			headerName: 'Advertiser',
			width: rowWidth,
			editable: true,
			type: 'singleSelect',
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Advertiser'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'advertiser'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				//if the advertiser exists in the database it will have account manager saved so the user shouldn't change it to another advertiser.
				if (
					params.row.account_manager ||
					(params.row.lock_state && params.row.lock_state !== 'none')
				) {
					return (
						<span className='is-not-editable'>{params.row.advertiser}</span>
					)
				}
				let options = []
				if (params && params.row && advertiser.advertiser) {
					options = findAdvertiserByOwner(
						params.row.owner,
						advertiser.advertiser,
					)
					if (!options.includes(params.row.advertiser)) {
						options.unshift(params.row.advertiser)
					}
				}
				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'advertiser',
								value: e,
							})
						}}
						options={options}
						label={''}
						value={params.value}
						noBackground
						autoCompleteStyle={{
							border: 'none',
							borderRight: 'none',
						}}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'pid',
			headerName: 'Pid',
			width: rowWidth,
			editable: true,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Pid'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'pid'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'none') {
					return <span className='is-not-editable'>{params.value}</span>
				}
				return <GridEditInputCell {...params} />
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'publisher',
			headerName: 'Publisher',
			width: rowWidth * 0.9,
			editable: true,
			type: 'singleSelect',
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Publisher'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				let options = []
				if (params && params.row && publisher.publisher) {
					options = findPublisherFromPid(params.row.pid, publisher.publisher)
				}
				if (params.row.lock_state && params.row.lock_state !== 'none') {
					return <span className='is-not-editable'>{params.value}</span>
				}

				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'publisher',
								value: e,
							})
						}}
						options={options}
						label={''}
						value={params.value}
						noBackground
						autoCompleteStyle={{
							border: 'none',
							borderRight: 'none',
						}}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue',
			headerName: 'Revenue',
			width: rowWidth * 0.85,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'approved_revenue_events',
			headerName: 'Approved Revenue Events',
			width: rowWidth * 0.85,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			width: rowWidth * 0.85,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'none') {
					return <span className='is-not-editable'>{params.value}</span>
				}
				return <GridEditInputCell {...params} />
			},
		},
		{
			field: 'total_conversions',
			headerName: 'Total Conversions',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return <span>{Number(value).toLocaleString('en-US')}</span>
				}
				return <span>{Number(value)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'none') {
					return <span className='is-not-editable'>{params.value}</span>
				}
				return <GridEditInputCell {...params} />
			},
		},
		{
			field: 'deducted_conversions',
			headerName: 'Deducted Conversions',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return <span>{Number(value).toLocaleString('en-US')}</span>
				}
				return <span>{Number(value)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'full') {
					return <GridEditInputCell {...params} />
				}
				return <span className='is-not-editable'>{params.value}</span>
			},
		},
		{
			field: 'approved_conversions',
			headerName: 'Approved Conversions',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: false,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.row.total_conversions - row.row.deducted_conversions
				if (row.id === 9999999999) {
					return <span>{Number(value).toLocaleString('en-US')}</span>
				}
				return <span>{Number(value)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'deduction_amount',
			headerName: 'Deduction',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderEditCell: (params) => {
				if (params.row.lock_state && params.row.lock_state !== 'full') {
					return <GridEditInputCell {...params} />
				}
				return <span className='is-not-editable'>{params.value}</span>
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.6,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			type: 'singleSelect',
			editable: true,
			disableColumnMenu: true,
			renderEditCell: (params) => {
				const allowNA =
					params.row.lock_state && params.row.lock_state === 'none'

				if (params.row.lock_state && params.row.lock_state === 'full') {
					return <span className='is-not-editable'>{params.value}</span>
				}
				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'status',
								value: e,
							})
						}}
						options={
							allowNA
								? ['Yes', 'No', 'On Hold', 'NA']
								: ['Yes', 'No', 'On Hold']
						}
						label={''}
						value={params.value}
						noBackground
						style={{
							marginTop: -20,
							marginRight: 0,
							color: theme.colors.text.titles,
						}}
						autoCompleteStyle={{ border: 'none', borderRight: 'none' }}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (value === '') {
					return <></>
				}
				return (
					<div
						style={{
							borderRadius: '20px',
							color: handleFinanceStatusColor(
								value === 'On Hold' ? 'on hold yellow' : value,
								theme,
							),
							width: '70px',
							height: '30px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							textTransform: 'uppercase',
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.caption,
						}}
					>
						{value}
					</div>
				)
			},
		},
		{
			field: 'final_amount',
			headerName: 'Final Number',
			headerAlign: 'center',
			width: rowWidth * 0.9,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			editable: false,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'finance-final-cell is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},
		(permissions.edit || (permissions.delete as any)) && {
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			disableColumnMenu: true,
			getActions: (row) => {
				const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
				// const editLocked = row.row.lock_state && row.row.lock_state === 'full'
				const deleteLocked = row.row.lock_state && row.row.lock_state !== 'none'
				//9999999999 this id is the hardcoded id we use for the bottom pinned row.
				if (row.id === 9999999999) {
					return []
				}
				//based on if the edit mode is triggered we will have the options to save, cancel or edit, remove
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</div>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(row.id)}
						/>,
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</div>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(row.id)}
							color='inherit'
						/>,
					]
				}

				return [
					permissions.edit ? (
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										opacity: '1',
										height: '28px',
										width: '28px',
										fontSize: '16px',
										cursor: 'pointer',
									}}
									className={'action-button'}
								>
									<EditIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={'action-button'}
									/>
								</div>
							}
							title={'Edit'}
							label='Edit'
							className='textPrimary'
							onClick={handleEditClick(row.id)}
							color='inherit'
						/>
					) : (
						<></>
					),
					permissions.delete ? (
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										opacity: deleteLocked ? '0.3' : '1',
										height: '28px',
										width: '28px',
										fontSize: '16px',
										cursor: deleteLocked ? 'not-allowed' : 'pointer',
									}}
									className={
										deleteLocked ? 'locked-action-button' : 'action-button'
									}
								>
									<DeleteIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={
											deleteLocked ? 'locked-action-button' : 'action-button'
										}
									/>
								</div>
							}
							title={deleteLocked ? 'This row is locked' : 'Delete'}
							label='Delete'
							className='textPrimary'
							onClick={deleteLocked ? undefined : handleDeleteClick(row.id)}
							color='inherit'
						/>
					) : (
						<></>
					),
				]
			},
		},
	]
	//this is the logic we use to update the rows everytime a change is done.
	//for every time the user presses "save" it will trigger the update.
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false }
		const oldRow = tableRows.find((el: any) => el.uuid === newRow.uuid)
		// console.log(oldRow)
		const data = { old_row: oldRow, new_row: newRow }
		const failed = await uploadFinanceRow(
			data,
			login.user.email,
			props.setErrorMessage,
		)
		const selectedAdvertiser = advertiser.advertiser.filter(
			(el: any) =>
				el.advertiser_name.toLowerCase() ===
				updatedRow.advertiser.toLowerCase(),
		)
		if (selectedAdvertiser.length > 0) {
			updatedRow.account_manager = selectedAdvertiser[0].email
		}
		updatedRow.final_amount = updatedRow.cost - updatedRow.deduction_amount
		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
			setPinnedBottomRow(calculatePinnedTotalRow(notUpdatedData))
		}
		return updatedRow
	}
	//we do this in order to prevent the double click to allow to enter the edit mode.
	const handleDoubleCellClick = React.useCallback((params: any, event: any) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params: any, event: any) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params: any, event: any) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	//basic function for style
	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		const advertiserRegistered = advertiserList.includes(row.advertiser)
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		if (
			(!advertiserRegistered || row.publisher === '' || row.revenue === '') &&
			!isInEditMode
		) {
			return 'personal-row-no-data'
		}
		return ''
	}
	const renderCustomMessage = (hoveredRow: any, position: any) => {
		let advertiserIsRegistered = true
		if (advertiserList) {
			advertiserIsRegistered = advertiserList.includes(hoveredRow.advertiser)
		}
		let possiblePublishers = []
		if (publisher.publisher) {
			possiblePublishers = findPublisherFromPid(
				hoveredRow.pid,
				publisher.publisher,
			)
		}
		return (
			(!advertiserIsRegistered ||
				!hoveredRow.publisher ||
				(!hoveredRow.revenue && hoveredRow.revenue !== 0)) && (
				<div
					style={{
						position: 'absolute',
						top: position.y,
						left: position.x,
						width: 'auto',
						height: 'auto',
						backgroundColor: theme.colors.base.white,
						border: '1px solid rgb(196,91,80)',
						display: 'flex',
						borderRadius: '4px',
					}}
				>
					<span
						style={{
							color: 'rgb(196,91,80)',
							fontSize: '14px',
							backgroundColor: 'rgba(240,215,217,1)',
							padding: '8px 30px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{!advertiserIsRegistered && (
							<span>
								The advertiser{' '}
								<span style={{ fontWeight: '600' }}>
									{hoveredRow.advertiser}
								</span>{' '}
								is not registered in Varys.
							</span>
						)}
						{!hoveredRow.publisher && possiblePublishers.length > 20 && (
							<span>
								The row is missing a{' '}
								<span style={{ fontWeight: '600' }}>publisher.</span> The PID
								was not found.
							</span>
						)}
						{!hoveredRow.publisher && possiblePublishers.length <= 20 && (
							<span>
								This PID is connected to{' '}
								<span style={{ fontWeight: '600' }}>more than one</span>{' '}
								publishers.
							</span>
						)}
						{!hoveredRow.revenue && hoveredRow.revenue !== 0 && (
							<span>
								The row is missing the{' '}
								<span style={{ fontWeight: '600' }}>revenue</span>.
							</span>
						)}
					</span>
				</div>
			)
		)
	}
	calculatePinnedTotalRow(tableRows)

	const guidelinesIntro = [
		{
			explanation:
				"The deadline for adding your numbers is the 10th of each month. You must finish the calculations and remove any entries marked 'On Hold' by the 20th of every month. If your advertiser's approval is delayed, there might be a possibility of extending this deadline.",
		},
	]

	const guidelines = [
		{
			title: 'Campaign',
			explanation:
				'Name of the Campaign. \nExample: William Hill - iOS - UK - CPA.',
		},
		{
			title: 'Agency Account',
			explanation:
				"Specify the agency account, choosing between: \nthing Or Two, Tatanka, smartassmeil951, and weknowil870. If 'Other' is chosen, provide details in the 'Agency Account Specification' column.",
		},
		{
			title: 'Platform',
			explanation:
				'You can choose between the following: \nAffise, Appsflyer, Adjust, Kochava, Branch, Singular, Tenjin, Appmetrica, Impact, MB (Media buying).',
		},
		{
			title: 'Advertiser',
			explanation:
				'Name of your advertiser written in the same way that is written in Varys - Demand - Advertiser.',
		},
		{ title: 'Pid', explanation: 'PID name. \nExample: pubmint_int .' },
		{
			title: 'Publisher',
			explanation:
				'Publisher name. It is not necessary to add the publisher name, it will come automatically from the system when you write the pid.',
		},
		{
			title: 'Revenue',
			explanation:
				'Explanation: The total revenue the client paid us for the installs/events brought by this specific media sourc, regardless of model or amount we are paying to the media source. \n Example: If a client is paying $50 per FTD, but does not pay for P360 Post-Attribution Events, and we generated 3 FTDs 1 of which was flagged, the revenue to add here would be $100. ',
		},
		{
			title: 'Approved Revenue Events',
			explanation: `Explanation: The number of events or conversions the client is paying us for generated by this media source, regardless of model or amount of events we pay the media source for. \n Example: If a client pays us $50 per FTD, but does not pay for P360 Post-Attribution Events, and we generated 3 FTDs 1 of which was flagged, the correct number would be 2, even if we pay this media source CPI, or we don't deduct P360 from the pubs, etc.`,
		},
		{
			title: 'Cost',
			explanation: `Explanation: The total amount the publisher is expecting to receive before any deductions, based on the payout and conditions agreed upon with the publisher. Not to be changed after it's been added. \n Example: If we owe the publisher $40 per FTD and we indicated in the brief the geo of the campaign was UK, and the publisher generated 6 FTDs, 1 of which was outside of the UK, as long as they have visibility to the geos we do not need to include that UK conversion in the total expected cost, and instead can consider $200 as the expected cost for the publisher.`,
		},
		{
			title: 'Total Conversions',
			explanation: `Explanation: The total amount of installs/events the publisher is expecting to receive before any deductions, based on the model and conditions agreed upon with the publisher. \n Example:  If we owe the publisher $40 per FTD and we indicated in the brief the geo of the campaign was UK, and the publisher generated 6 FTDs, 1 of which was outside of the UK, as long as they have visibility to the geos we do not need to include that UK conversion in the total expected conversions, and instead can consider 5 as the expected conversions for the publisher.`,
		},
		{
			title: 'Deducted Conversions',
			explanation: `Explanation: The number of installs/events deducted from the publisher's expected total amount of conversions - regardless of the amount the client deducted from us.  \n Example: If you are going to deduct 20 out of a publisher's expected 200 conversions, the deducted conversions would be 20.`,
		},
		{
			title: 'Approved Conversions',
			explanation: `Explanation: The total number of conversions or events approved for the publisher - CALCULATED AUTOMATICALLY! Do NOT try to edit or change! The total expected conversions - the deducted conversions.  \n Formula: Expected Conversions (Pub) - Deducted Conversions (Pub) \n Example: If the total expected conversions are 200 and 20 are deducted, the approved conversions are 180. `,
		},
		{
			title: 'Deducted Amount',
			explanation: `Explanation: The amount in dollars of the deduction we are passing to the publisher. It should align with the amount of deducted conversions (Deduction Amount divided by Deducted Conversions should equal the publishers PO per event). \n Example: If you are going to deduct 20 out of a publisher's expected 200 convesions, and they were receiving a PO of $5 per conversion, the deduction amount is $100. `,
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Status',
			explanation: `3 different statuses: \n   * On Hold: you add the numbers but they are yet to be confirmed by the advertiser. \n   * Yes: The line is approved and we will pay our source that specific amount indicated in the total amount approved. \n   * No: unconfirmed or fully deducted.`,
		},
		{
			title: 'Final Number',
			explanation:
				'What we will pay to the publisher for that offer on that pid. Cost-Deduction.',
		},
	]

	const guidelinesOutro = [
		{
			explanation:
				'For any revenues that a publisher has brought that we are documenting but have no associated cost for the publisher, please use the NA category in the status column and those rows will not be display in the supply pages.',
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '110%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<PageWrapper>
				<input
					type='file'
					onChange={fileSelectedHandler}
					ref={fileInputRef as any}
					style={{ display: 'none' }}
				/>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
						intro={guidelinesIntro}
						outro={guidelinesOutro}
					></Guidelines>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						// columns={[]}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						fileInputClickHandler={
							permissions.edit ? fileInputClickHandler : undefined
						}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .personal-row-no-data.MuiDataGrid-row:hover': {
								backgroundColor: 'rgba(252, 234, 235)',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& ::-webkit-scrollbar': {
								height: '7px',
								width: '10px',
							},
							'& .MuiDataGrid-columnHeaders': {
								borderBottom: `1px solid ${theme.colors.base.grey3} !important`,
								borderTop: `1px solid ${theme.colors.base.grey3} !important`,
								maxHeight: '60px !important',
								height: '60px !important',
							},
							'& .MuiDataGrid-columnHeader': {
								// maxHeight: '100px !important',
								height: '60px !important',
							},
						}}
						customMessage={renderCustomMessage}
						pinnedBottomRow={pinnedBottomRow}
						apiRef={apiRef}
						searchWithTimer={true}
						checkboxButtonValue={errorFilter}
						setCheckboxButtonValue={setErrorFilter}
						checkboxButtonLabel={'Only Errors'}
						downloadButtonFolderOptions={downloadOptions}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default PersonalPage

const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		campaign: 'TOTAL',
		platform: '',
		advertiser: '',
		pid: '',
		publisher: '',
		revenue: 0,
		approved_revenue_events: 0,
		cost: 0,
		total_conversions: 0,
		deducted_conversions: 0,
		approved_conversions: 0,
		deduction_amount: 0,
		period: '',
		status: '',
		final_amount: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.revenue) {
			pinnedRow.revenue = Number(pinnedRow.revenue) + Number(row.revenue)
		}
		if (row.approved_revenue_events) {
			pinnedRow.approved_revenue_events =
				Number(pinnedRow.approved_revenue_events) +
				Number(row.approved_revenue_events)
		}
		if (row.total_conversions) {
			pinnedRow.total_conversions =
				Number(pinnedRow.total_conversions) + Number(row.total_conversions)
		}
		if (row.deducted_conversions) {
			pinnedRow.deducted_conversions =
				Number(pinnedRow.deducted_conversions) +
				Number(row.deducted_conversions)
		}
		if (row.deduction_amount) {
			pinnedRow.deduction_amount =
				Number(pinnedRow.deduction_amount) + Number(row.deduction_amount)
		}
		if (row.final_amount) {
			pinnedRow.final_amount =
				Number(pinnedRow.final_amount) + Number(row.final_amount)
		}
	}
	pinnedRow.approved_conversions = (
		Number(pinnedRow.total_conversions) - Number(pinnedRow.deducted_conversions)
	).toFixed(2) as any
	pinnedRow.revenue = Number(pinnedRow.revenue).toFixed(2) as any
	pinnedRow.approved_revenue_events = Number(
		pinnedRow.approved_revenue_events,
	).toFixed(2) as any
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	pinnedRow.deduction_amount = Number(pinnedRow.deduction_amount).toFixed(
		2,
	) as any
	pinnedRow.final_amount = Number(pinnedRow.final_amount).toFixed(2) as any
	return [pinnedRow]
}
