import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import './finance.css'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	GridEditInputCell,
} from '@mui/x-data-grid-pro'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Checkbox, Typography, useTheme } from '@mui/material'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as MarkEmailReadIcon } from '../../assets/svg/email-history-icon.svg'
import { ReactComponent as TableRowsIcon } from '../../assets/svg/data-icon.svg'
import {
	extractPaymentLimit,
	formatDateToMonth,
	formatFinancePaymentDate,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	handleFinanceStatusColor,
	returnAbbreviatedMonth,
	sortDates,
	sortDatesStrings,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'
import moment from 'moment'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Guidelines from './Guidelines'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

//In finance we have 4 pages:
// - Personal: Where the user is able to upload his excel
// - Global: Where the user can see all the data of the company
// - Supply: Where the user can see the data ordered by publisher
// - Demand: Where the user can see the data ordered by advertiser
// The idea of supply page is that the data is ordered by publisher. The publisher manager will be able to open one publisher
// in order to see all the data related to that publisher. Then he will be able to send an email to the publisher
// it could be a status email or a final numbers email. Both will update the history.
//status will only be allowed if there's an on hold while final numbers will only be allowed if no on hold value is registered.
// user is able to edit the rows in order to add invoice numbers and payment dates.
//supply page have different components. For the email templates, the preview page and the status page.
const SupplyPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, publisher, users, settings } = useTypedSelector(
		(state) => state,
	)

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		editSupplyInvoice: useIsAuthorized(permissionNames.EDIT_SUPPLY_INVOICE),
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const { getFinanceData, uploadFinancePublisher, downloadFinance } =
		useFinanceActions()
	const sessionKey = 'filters-finance-supply'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 12
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()
	const [showEmails, setShowEmails] = useState(false)
	const [amountOfEmailRows, setAmountOfEmailRows] = useState(0)
	const [tableRows, setTableRows] = useState<any>(
		generateRowsWithIds(
			generateRowsByPublisher(
				filteredFinance,
				publisher.publisher,
				'',
				amountOfEmailRows,
				setAmountOfEmailRows,
			),
		),
	)
	const [openFilter, setOpenFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					publisher: [],
					publisher_manager: [],
			  },
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		publisher: [],
		publisher_manager: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const previousMonth = moment().subtract(1, 'months').format('MMMM').toString()
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray?.length > 0
			? sessionFilters.monthFilterArray
			: permissions.editSupplyInvoice
			? []
			: [previousMonth],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray
			? sessionFilters.yearFilterArray
			: permissions.editSupplyInvoice
			? []
			: ['This Year'],
	)
	const [columns, setColumns] = useState<GridColDef[]>([])

	const [dataFetched, setDataFetched] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async (signal: AbortSignal) => {
		dispatch(emptyFinance())
		let i = 0
		let result
		const year = yearFilterArray
		const month = monthFilterArray

		do {
			if (signal.aborted) return
			result = await getFinanceData(
				'supply',
				props.errorMessage,
				login.user.email,
				200,
				i,
				undefined,
				{ year: year, month },
			)
			i++
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('supply')
		)
	}
	useEffect(() => {
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(
					sessionFilters.monthFilterArray.length > 0
						? sessionFilters.monthFilterArray
						: permissions.editSupplyInvoice
						? []
						: [previousMonth],
				)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(
					sessionFilters.yearFilterArray.length > 0
						? sessionFilters.yearFilterArray
						: permissions.editSupplyInvoice
						? []
						: ['This Year'],
				)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])

	useEffect(() => {
		const filterData = () => {
			let filteredData = finance.finance

			// Apply custom filters
			if (customFilter.publisher.length > 0) {
				filteredData = filteredData.filter((item: any) =>
					customFilter.publisher.includes(item.publisher),
				)
			}
			if (monthFilterArray.length > 0) {
				filteredData = filteredData.filter((item: any) =>
					monthFilterArray.includes(
						moment(item.month, 'DD/MM/YYYY').format('MMM'),
					),
				)
			}
			if (yearFilterArray.length > 0) {
				filteredData = filteredData.filter((item: any) =>
					yearFilterArray.includes(
						moment(item.month, 'DD/MM/YYYY').format('YYYY'),
					),
				)
			}

			setFilteredFinance(filteredData)
		}

		filterData()
	}, [customFilter, monthFilterArray, yearFilterArray, finance.finance])

	useEffect(() => {
		// if (filterLabel) {
		const filters = {
			customFilter,
			monthFilterArray,
			yearFilterArray,
			financeFilterStatus,
		}
		const serializedFilters = JSON.stringify(filters)
		sessionStorage.setItem(sessionKey, serializedFilters)
		// }
	}, [applyFilter, monthFilterArray, yearFilterArray, financeFilterStatus])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		fetchData(signal)

		return () => controller.abort() // Cleanup previous requests
	}, [monthFilterArray, yearFilterArray])

	useEffect(() => {
		setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
	}, [finance.finance])
	useEffect(() => {
		if (tableRows[0]) {
			setAmountOfEmailRows(tableRows[0].maxAmountOfEmails)
		}
		if (dataFetched) {
			setOptionsFilter({
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el: any) => el && el.length > 0),
				),
				publisher_manager: new Set(
					tableRows
						.map((element: any) => element.publisher_manager)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])
	//same logic for filtering. The main difference is that in this table we will render the tableRow grouped by publishers.
	//we will have only 1 row for each publisher.
	//the main difference is that we check the possibleFinanceFilter INSIDE the function that generates the rows.
	const possibleFinanceFilter = useMemo(
		() =>
			showEmails
				? [
						'All',
						'Status Sent',
						'Status Missing',
						'Final Sent',
						'Final Missing',
				  ]
				: ['All', 'Yes', 'On Hold'],
		[showEmails],
	)

	let columnsToShow: GridColDef[] = []
	if (showEmails) {
		columnsToShow = [
			{
				field: 'publisher',
				headerName: 'Publisher',
				width: rowWidth * 1.4,
				editable: false,
				type: 'singleSelect',
				disableColumnMenu: true,
				renderHeader: () => {
					return (
						<HeaderFilterComponent
							label={'Publisher'}
							width={rowWidth * 1.2}
							openFilter={openFilter}
							setOpenFilter={setOpenFilter}
							field={'publisher'}
							customFilter={customFilter}
							currentFilters={currentFilters}
							setCurrentFilters={setCurrentFilters}
							setFilterLabel={setFilterLabel}
							optionsFilters={optionsFilters}
						/>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'publisher_manager',
				headerName: 'PM',
				width: rowWidth * 1,
				editable: false,
				disableColumnMenu: true,
				renderHeader: () => {
					return (
						<HeaderFilterComponent
							label={'PM'}
							width={rowWidth * 2.2}
							openFilter={openFilter}
							setOpenFilter={setOpenFilter}
							field={'publisher_manager'}
							customFilter={customFilter}
							currentFilters={currentFilters}
							setCurrentFilters={setCurrentFilters}
							setFilterLabel={setFilterLabel}
							optionsFilters={optionsFilters}
						/>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'month',
				headerName: 'Period',
				headerAlign: 'center',
				align: 'center',
				editable: false,
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					const date = formatDateToMonth(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				},
				sortComparator: (str1, str2) => sortDatesStrings(str1, str2),
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'status_percentage',
				headerName: 'Status',
				headerAlign: 'center',
				align: 'center',
				width: rowWidth * 0.8,
				editable: false,
				disableColumnMenu: true,
				renderCell: (row) => {
					let value = Math.floor(
						(Number(row.row.final_amount) /
							(Number(row.row.pending_amount) + Number(row.row.final_amount))) *
							100,
					)
					if (isNaN(value)) {
						value = 0
					}

					if (row.row.invoice_number !== '') {
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									border: 'none',
									color: theme.colors.base.green400,
									borderRadius: '100px',
									height: '28px',
									width: '28px',
									fontSize: '22px',
									// cursor: 'pointer',
								}}
							>
								<CheckIcon fontSize={'inherit'} />
							</div>
						)
					}
					return row.row.status_percentage ? (
						<div
							style={{
								position: 'relative',
								width: 100,
								maxWidth: '110px',
								height: '12px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							}}
						>
							<div
								style={{
									borderRadius: '4px',
									background: theme.colors.base.grey300,
									width: 'inherit',

									height: '12px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									color: theme.colors.base.white,
									// position: 'relative',
									overflow: 'hidden',
									margin: '4px',
									marginTop: -6,
								}}
							>
								<span
									style={{
										position: 'absolute',
										left: '36%',
										zIndex: 10,
										color: theme.colors.text.titles,
										top: 6,
									}}
								>
									{value}%
								</span>
								<div
									style={{
										background: handleFinanceStatusColor('yes', theme),
										width: value !== 100 ? `calc(${value}% + 10px)` : '100%',
										height: '100%',
										borderRadius: 'inherit',
										position: 'relative',
									}}
								></div>
							</div>
						</div>
					) : (
						<div
							style={{
								position: 'relative',
								width: 100,
								maxWidth: '110px',
								height: '12px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							}}
						>
							<div
								style={{
									borderRadius: '4px',
									background: theme.colors.base.grey300,
									width: 'inherit',
									height: '12px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									color: theme.colors.text.titles,
									// position: 'relative',
									overflow: 'hidden',
									margin: '4px',
									marginTop: -6,
								}}
							>
								<span
									style={{
										position: 'absolute',
										left: '38%',
										zIndex: 10,
										color: theme.colors.text.titles,
										top: 6,
									}}
								>
									{value}%
								</span>
							</div>
						</div>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
		]
		for (let i = 0; i < amountOfEmailRows; i++) {
			const sentNumber = i + 1
			columnsToShow.push({
				field: `last_send_${sentNumber}`,
				headerName: `Status ${sentNumber}`,
				headerAlign: 'center',
				align: 'center',
				editable: false,
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					const date = value ? moment(value).format('DD/MM/YYYY') : ''
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				},
				sortComparator: (str1, str2) => {
					return sortHardcodedDates(str1, str2)
				},
				cellClassName: (params) => {
					if (params?.colDef?.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			})
		}
		columnsToShow.push({
			field: 'final_numbers_send',
			headerName: 'Final Numbers Sent',
			headerAlign: 'center',
			align: 'center',
			editable: false,
			width: rowWidth * 1.2,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = value ? moment(value).format('DD/MM/YYYY') : ''
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				if (!str1) {
					return -1
				}
				if (!str2) {
					return 1
				}
				const date1 = moment(str1)
				const date2 = moment(str2)
				if (date1.isBefore(date2)) return -1
				if (date1.isAfter(date2)) return 1
				return 0
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		})
	} else {
		columnsToShow = [
			{
				field: 'publisher',
				headerName: 'Publisher',
				width: rowWidth * 1.4,
				editable: false,
				type: 'singleSelect',
				disableColumnMenu: true,
				renderHeader: () => {
					return (
						<HeaderFilterComponent
							label={'Publisher'}
							width={rowWidth * 1.2}
							openFilter={openFilter}
							setOpenFilter={setOpenFilter}
							field={'publisher'}
							customFilter={customFilter}
							currentFilters={currentFilters}
							setCurrentFilters={setCurrentFilters}
							setFilterLabel={setFilterLabel}
							optionsFilters={optionsFilters}
						/>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'publisher_manager',
				headerName: 'PM',
				width: rowWidth * 1,
				editable: false,
				disableColumnMenu: true,
				renderHeader: () => {
					return (
						<HeaderFilterComponent
							label={'PM'}
							width={rowWidth * 2.2}
							openFilter={openFilter}
							setOpenFilter={setOpenFilter}
							field={'publisher_manager'}
							customFilter={customFilter}
							currentFilters={currentFilters}
							setCurrentFilters={setCurrentFilters}
							setFilterLabel={setFilterLabel}
							optionsFilters={optionsFilters}
						/>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'publisher_internal_legal_entity',
				headerName: 'Legal Entity',
				width: rowWidth * 0.6,
				editable: false,
				align: 'center',
				disableColumnMenu: true,
				renderHeader: () => {
					return (
						<HeaderFilterComponent
							label={'Legal Entity'}
							width={rowWidth * 1.4}
							openFilter={openFilter}
							setOpenFilter={setOpenFilter}
							field={'publisher_internal_legal_entity'}
							customFilter={customFilter}
							currentFilters={currentFilters}
							setCurrentFilters={setCurrentFilters}
							setFilterLabel={setFilterLabel}
							optionsFilters={optionsFilters}
						/>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'month',
				headerName: 'Period',
				headerAlign: 'center',
				align: 'center',
				editable: false,
				width: rowWidth * 0.8,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					const date = formatDateToMonth(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				},
				sortComparator: (str1, str2) => {
					return sortDatesStrings(str1, str2)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			// {
			// 	field: 'approved_conversions',
			// 	headerName: 'Conversion',
			// 	width: rowWidth * 0.8,
			// 	type: 'number',
			// 	align: 'left',
			// 	headerAlign: 'left',
			// 	editable: false,
			// 	renderCell: ({ value }) => {
			// 		return <span>{Number(value).toFixed(2)}</span>
			// 	},
			// 	cellClassName: (params) => {
			// 		if (params.colDef.editable === false) {
			// 			return 'is-not-editable'
			// 		}
			// 		return 'is-editable'
			// 	},
			// },
			{
				field: 'deduction_amount',
				headerName: 'Deduction',
				width: rowWidth * 0.6,
				type: 'number',
				align: 'left',
				headerAlign: 'left',
				editable: false,
				disableColumnMenu: true,
				renderCell: (row) => {
					const value = row.value
					return <span>{Number(value).toFixed(2)}</span>
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},

			{
				field: 'pending_amount',
				headerName: 'Pending',
				headerAlign: 'center',
				width: rowWidth * 0.8,
				type: 'number',
				align: 'center',
				editable: false,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					return <span>{Number(value).toFixed(2)}</span>
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'final_amount',
				headerName: 'Approved',
				headerAlign: 'center',
				width: rowWidth * 0.8,
				type: 'number',
				align: 'center',
				headerClassName: 'finance-final-header',
				editable: false,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					return <span>{Number(value).toFixed(2)}</span>
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'finance-final-cell is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'status_percentage',
				headerName: 'Status',
				headerAlign: 'center',
				align: 'center',
				width: rowWidth * 0.8,
				editable: false,
				disableColumnMenu: true,
				renderCell: (row) => {
					let value = Math.floor(
						(Number(row.row.final_amount) /
							(Number(row.row.pending_amount) + Number(row.row.final_amount))) *
							100,
					)
					if (isNaN(value)) {
						value = 0
					}

					if (row.row.invoice_number !== '') {
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									border: 'none',
									color: theme.colors.base.green400,
									borderRadius: '100px',
									height: '28px',
									width: '28px',
									fontSize: '22px',
									// cursor: 'pointer',
								}}
							>
								<CheckIcon fontSize={'inherit'} />
							</div>
						)
					}
					return row.row.status_percentage ? (
						<div
							style={{
								position: 'relative',
								width: 100,
								maxWidth: '110px',
								height: '12px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							}}
						>
							<div
								style={{
									borderRadius: '4px',
									background: theme.colors.base.grey300,
									width: 'inherit',

									height: '12px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									color: theme.colors.base.white,
									// position: 'relative',
									overflow: 'hidden',
									margin: '4px',
									marginTop: -6,
								}}
							>
								<span
									style={{
										position: 'absolute',
										left: '36%',
										zIndex: 10,
										color: theme.colors.text.titles,
										top: 6,
									}}
								>
									{value}%
								</span>
								<div
									style={{
										background: handleFinanceStatusColor('yes', theme),
										width: value !== 100 ? `calc(${value}% + 10px)` : '100%',
										height: '100%',
										borderRadius: 'inherit',
										position: 'relative',
									}}
								></div>
							</div>
						</div>
					) : (
						<div
							style={{
								position: 'relative',
								width: 100,
								maxWidth: '110px',
								height: '12px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							}}
						>
							<div
								style={{
									borderRadius: '4px',
									background: theme.colors.base.grey300,
									width: 'inherit',
									height: '12px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									color: theme.colors.text.titles,
									// position: 'relative',
									overflow: 'hidden',
									margin: '4px',
									marginTop: -6,
								}}
							>
								<span
									style={{
										position: 'absolute',
										left: '38%',
										zIndex: 10,
										color: theme.colors.text.titles,
										top: 6,
									}}
								>
									{value}%
								</span>
							</div>
						</div>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'last_send',
				headerName: 'Last Email Sent',
				headerAlign: 'center',
				align: 'center',
				editable: false,
				width: rowWidth * 0.7,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					if (!value || value.length === 0) {
						return <></>
					}
					const date = moment(value[value.length - 1]).format('DD/MM/YYYY')
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				},
				sortComparator: (str1, str2) => {
					if (str1.length === 0) {
						return -1
					}
					if (str2.length === 0) {
						return 1
					}
					const date1 = moment(str1[str1.length - 1])
					const date2 = moment(str2[str2.length - 1])
					if (date1.isBefore(date2)) return -1
					if (date1.isAfter(date2)) return 1
					return 0
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'final_with_vat',
				headerName: 'Amount inc. VAT',
				headerAlign: 'center',
				width: rowWidth * 0.8,
				type: 'number',
				align: 'center',
				editable: false,
				disableColumnMenu: true,
				renderCell: ({ value }) => {
					return <span>{Number(value).toFixed(2)}</span>
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},

			{
				field: 'invoice_number',
				headerName: 'Invoice Number',
				width: rowWidth * 0.8,
				align: 'left',
				headerAlign: 'left',
				disableColumnMenu: true,
				editable:
					permissions.editSupplyInvoice && permissions.edit ? true : false,
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'due_date',
				headerName: 'Due Date',
				width: rowWidth * 0.8,
				type: 'date',
				align: 'left',
				headerAlign: 'left',
				editable: true,
				disableColumnMenu: true,
				valueGetter: ({ value }) => {
					if (!value) {
						return ''
					}
					if (typeof value === 'string' && value.includes('/')) {
						return moment(value, 'DD/MM/YYYY').toDate()
					} else {
						return moment(value).toDate()
					}
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
				renderCell: (row) => {
					if (row.value) {
						const date = formatFinancePaymentDate(row.value)
						return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
					} else {
						return <></>
					}
				},
				valueSetter: (params) => {
					const newValue = params.value
						? moment(params.value, ['YYYY-MM-DD', 'DD/MM/YYYY']).format(
								'DD/MM/YYYY',
						  )
						: undefined
					return { ...params.row, due_date: newValue }
				},
				sortComparator: (str1, str2) => {
					return sortDates(str2, str1)
				},
			},
			{
				field: 'payment',
				headerName: 'Payment Date',
				width: rowWidth * 0.8,
				align: 'left',
				headerAlign: 'left',
				editable: false,
				type: 'date',
				disableColumnMenu: true,
				valueGetter: ({ value }) => new Date(value),
				renderCell: ({ value }) => {
					if (value) {
						const date = formatFinancePaymentDate(value)
						return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
					} else {
						return <></>
					}
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
				sortComparator: (str1, str2) => {
					return sortDates(str1, str2)
				},
			},
			{
				field: 'roll_over_value',
				headerName: 'Roll-Over',
				width: rowWidth * 0.4,
				type: 'boolean',
				align: 'left',
				headerAlign: 'left',
				editable: false,
				disableColumnMenu: true,
				renderCell: (params) => {
					const row = params.row
					const value = params.value
					if (row.id === 9999999999) {
						return <></>
					}
					return (
						<Checkbox
							style={{ marginRight: '-10px' }}
							checked={value}
							key={'rollover' + value}
							inputProps={{
								'aria-label': 'controlled',
							}}
							icon={
								<CheckBoxOutlineBlankIcon
									style={{
										scale: '0.7',
										color: theme.colors.base.green500,
									}}
								/>
							}
							disabled
							checkedIcon={
								<CheckBoxIcon
									style={{
										scale: '0.7',
										color: theme.colors.base.green500,
									}}
								/>
							}
						/>
					)
				},
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				disableColumnMenu: true,
				getActions: (row) => {
					const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
					if (isInEditMode) {
						return [
							<GridActionsCellItem
								icon={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											border: 'none',
											cursor: 'pointer',
											height: '28px',
											width: '28px',
											fontSize: '16px',
											color: theme.colors.text.titles,
										}}
										className={'finance-action-button'}
									>
										<CheckIcon fontSize={'inherit'} />
									</div>
								}
								label='Save'
								sx={{
									color: 'primary.main',
								}}
								onClick={handleSaveClick(row.id)}
							/>,
							<GridActionsCellItem
								icon={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											border: 'none',
											cursor: 'pointer',
											height: '28px',
											width: '28px',
											fontSize: '16px',
											color: theme.colors.text.titles,
										}}
										className={'finance-action-button'}
									>
										<ClearIcon fontSize={'inherit'} />
									</div>
								}
								label='Cancel'
								className='textPrimary'
								onClick={handleCancelClick(row.id)}
								color='inherit'
							/>,
						]
					}

					return actions(row)
				},
			},
		]
		if (dataFetched) {
			props.setLoading(false)
		}
	}
	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		//we set the status to 0 (to filter by all).
		setFinanceFilterStatus(0)
		//if status is already 0 it wont trigger the useEffect of status so we are going to stop the loading that would stop inside the
		//status change triggered use effect.
		if (financeFilterStatus === 0 && dataFetched) {
			props.setLoading(false)
		}
	}, [showEmails])

	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, finance.finance, columnsToShow)
			let maximumEmails = 0
			for (const row of searched) {
				if (row.last_send && row.last_send.length > maximumEmails) {
					maximumEmails = row.last_send.length
				}
			}
			if (searched.length > 0) {
				searched[0].maxAmountOfEmails = maximumEmails
			}
			filtered = searched
		} else {
			filtered = finance.finance
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true

					foundByKey = customFilter[key].includes(data[key])
					if (key === 'publisher_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}

		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		const publisherRows = generateRowsByPublisher(
			arr,
			publisher.publisher,
			possibleFinanceFilter[financeFilterStatus],
			amountOfEmailRows,
			setAmountOfEmailRows,
		)
		arr = generateRowsWithIds(publisherRows)
		setTableRows(arr)
		setUnfiltered(arr)
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [financeFilterStatus, props.search, applyFilter, filteredFinance])
	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	//logic behind the edit for the rows.
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const handlePreviewClick = (id: any) => async () => {
		navigate('/finance/supply/' + id)
	}
	const handleDownloadFinance = useCallback(() => {
		downloadFinance(tableRows, 'supply', setFileDownload, props.setErrorMessage)
	}, [tableRows])

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	const triggerShowEmails = useCallback(() => {
		setShowEmails(!showEmails)
	}, [showEmails])

	const actions = (row: any) => {
		const result = []
		if (permissions.editSupplyInvoice && permissions.edit ? true : false) {
			result.push(
				<GridActionsCellItem
					icon={
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								border: 'none',
								height: '28px',
								width: '28px',
								fontSize: '16px',
								cursor: 'pointer',
								color: theme.colors.text.titles,
							}}
							className={'finance-action-button'}
						>
							<EditIcon
								style={{ color: theme.colors.text.titles, scale: '0.8' }}
								className={'action-button'}
							/>
						</div>
					}
					label='Edit'
					className='textPrimary'
					onClick={handleEditClick(row.id)}
					color='inherit'
				/>,
			)
		}
		result.push(
			<GridActionsCellItem
				icon={
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							color: theme.colors.text.titles,
							height: '28px',
							width: '28px',
							fontSize: '16px',
							cursor: 'pointer',
						}}
						className={'finance-action-button'}
					>
						<VisibilityOutlinedIcon
							fontSize={'inherit'}
							style={{ color: 'inherit' }}
						/>
					</div>
				}
				label='Preview'
				className={'finance-action-button'}
				onClick={handlePreviewClick(row.row.uuid)}
				color='inherit'
			/>,
		)

		return result
	}

	//we use this to handle the saving. It will trigger a save that will update all the rows that contain the same publisher and
	//period (month + year).
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		if (newRow.invoice_number && !newRow.due_date) {
			let currentDate = new Date()
			let isPrepayment = false
			const selectedPublisher = publisher.publisher.filter(
				(pub: any) =>
					pub.publisher_name.toLowerCase() === newRow.publisher.toLowerCase(),
			)
			const paymentTerm = selectedPublisher[0].payment_term
			if (paymentTerm) {
				if (paymentTerm.startsWith('Net')) {
					const numberOfDays = extractPaymentLimit(paymentTerm)
					//we add 1 because it needs to start counting from the following day of registration.
					currentDate.setDate(
						currentDate.getDate() + parseInt(numberOfDays) + 1,
					)
				} else if (paymentTerm === 'Prepayment') {
					isPrepayment = true
				}
			} else {
				//by default we use Net45 which would be 45 + 1
				currentDate.setDate(currentDate.getDate() + 46)
			}
			const year = currentDate.getFullYear()
			const month = String(currentDate.getMonth() + 1).padStart(2, '0')
			const day = String(currentDate.getDate()).padStart(2, '0')
			if (!newRow.due_date) {
				newRow.due_date = isPrepayment ? '-' : `${day}/${month}/${year}`
			}
		}
		if (!newRow.invoice_number && newRow.due_date) {
			newRow.due_date = ''
		}
		const updatedRow = { ...newRow, isNew: false }
		// console.log('updated', updatedRow)
		const failed = await uploadFinancePublisher(
			newRow,
			'performance',
			login.user.email,
			props.setErrorMessage,
		)
		if (!failed) {
			setTableRows(
				tableRows.map((row: any) =>
					row.uuid === newRow.uuid ? updatedRow : row,
				),
			)
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
		}
		return updatedRow
	}
	//prevent the editing with double click.
	const handleDoubleCellClick = React.useCallback((params: any, event: any) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params: any, event: any) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params: any, event: any) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		return ''
	}

	const guidelines = [
		{
			title: 'Publisher',
			explanation: 'Publisher name.',
		},
		{
			title: 'PM',
			explanation: 'Name of PM of that publisher. \nExample: Dan.',
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Conversion',
			explanation:
				'(After deduction) Approved number of conversions per publisher per PID after deductions.',
		},
		{
			title: 'Deduction',
			explanation:
				'How much money (in USD) we will deduct from the payout amount.',
		},
		{
			title: 'Status',
			explanation: `3 different statuses: \n   * On Hold: you add the numbers but they are yet to be confirmed by the advertiser. \n   * Yes: The line is approved and we will pay our source that specific amount indicated in the total amount approved. \n   * No: unconfirmed or fully deducted.`,
		},
		{
			title: 'Final Number',
			explanation:
				'What we will pay to the publisher for that offer on that pid. Cost-Deduction.',
		},
		{
			title: 'Invoice Number',
			explanation: 'Number of the invoice issued by the publisher.',
		},
		{
			title: 'Due Date',
			explanation:
				'Based on the payment term specified on the supply database, when that invoice will be overdue.',
		},
		{
			title: 'Payment Date',
			explanation: 'When we make the payment to the publisher of that invoice.',
		},
		{
			title: 'Roll-Over',
			explanation:
				"The publisher has some campings that can't be approved during that period.",
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '110%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<PageWrapper>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
					></Guidelines>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columnsToShow}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						downloadTemplate={handleDownloadFinance}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
						}}
						searchWithTimer={true}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
						triggerExtraBolFunction={triggerShowEmails}
						triggerExtraBolFunctionValue={showEmails}
						extraBolFunctionLabel={useMemo(
							() => ({
								true: 'Supply Data',
								false: 'Email History',
								iconTrue: <TableRowsIcon style={{ marginRight: 0 }} />,
								iconFalse: <MarkEmailReadIcon style={{ marginRight: 0 }} />,
							}),
							[],
						)}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

//this is the function that generates the rows by publishers. Basically we save the publisher name the first time it's read
//(generating a new row) and if we found the publisher then we just update its values instead of generating a new row.
const generateRowsByPublisher = (
	inputRows: any,
	publisherList: any,
	filterStatus: string = '',
	amountOfEmailRows: number,
	setAmountOfEmailRows: any,
) => {
	let result = inputRows
	// const rows = [...inputRows]
	// const result = []
	// const alreadyIn: string[] = []
	// for (const row of rows) {
	// 	let newRow: any = {}
	// 	let period = row.month
	// 	if (row.roll_over && row.roll_over_month) {
	// 		period = row.roll_over_month
	// 	}
	// 	if (
	// 		!alreadyIn.includes(
	// 			row.publisher + moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 	) {
	// 		const currentPublisher = publisherList.filter(
	// 			(pub: any) => pub.publisher_name === row.publisher,
	// 		)
	// 		if (currentPublisher[0]) {
	// 			newRow.publisher = row.publisher
	// 			newRow.publisher_manager = row.publisher_manager
	// 			newRow.platform = ''
	// 			newRow.uuid =
	// 				row.uuid +
	// 				'-supply-' +
	// 				moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 			newRow.month = period
	// 			newRow.approved_conversions = row.approved_conversions
	// 			newRow.deduction_amount = row.deduction_amount
	// 			newRow.invoice_number = row.invoice_number
	// 			let amountOfEmails = 0
	// 			for (let i = 0; i < row.last_send.length; i++) {
	// 				const sentNumber = i + 1
	// 				if (row.final_numbers_sent && i === row.last_send.length - 1) {
	// 					newRow[`final_numbers_send` as keyof typeof newRow] =
	// 						row.last_send[i]
	// 					amountOfEmails++
	// 				} else {
	// 					if (
	// 						!row.last_send[i - 1] ||
	// 						moment(row.last_send[i]).format('DD/MM/YYYY') !==
	// 							moment(row.last_send[i - 1]).format('DD/MM/YYYY')
	// 					) {
	// 						newRow[`last_send_${sentNumber}` as keyof typeof newRow] =
	// 							row.last_send[i]
	// 						amountOfEmails++
	// 					}
	// 				}
	// 			}
	// 			newRow['last_send'] = row.last_send
	// 			if (amountOfEmailRows < amountOfEmails) {
	// 				setAmountOfEmailRows(amountOfEmails)
	// 			}
	// 			newRow.due_date = row.due_date
	// 			if (row.payment) {
	// 				newRow.payment = new Date(row.payment)
	// 			} else {
	// 				newRow.payment = ''
	// 			}
	// 			newRow.number_of_rows = 0
	// 			newRow.number_of_yes = 0
	// 			newRow.number_of_no = 0
	// 			newRow.number_of_on_hold = 0
	// 			newRow.final_amount = 0
	// 			newRow.pending_amount = 0
	// 			newRow.final_with_vat = 0
	// 			newRow.number_of_rows++
	// 			if (row.status.toLowerCase() === 'yes') {
	// 				newRow.final_amount = row.final_amount
	// 				newRow.final_with_vat =
	// 					Number(row.final_amount) +
	// 					Number(row.final_amount) * (Number(row.publisher_vat) / 100)
	// 				newRow.number_of_yes++
	// 			} else if (row.status.toLowerCase() === 'no') {
	// 				newRow.number_of_no++
	// 			} else if (row.status.toLowerCase() === 'on hold') {
	// 				newRow.pending_amount = row.final_amount
	// 				newRow.number_of_on_hold++
	// 			}
	// 			newRow.status_percentage =
	// 				((newRow.number_of_yes + newRow.number_of_no) * 100) /
	// 				newRow.number_of_rows
	// 			alreadyIn.push(
	// 				row.publisher + moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 			)
	// 			result.push(newRow)
	// 		}
	// 	} else {
	// 		let period = row.month
	// 		if (row.roll_over && row.roll_over_month) {
	// 			period = row.roll_over_month
	// 		}

	// 		let rowToEdit = -1
	// 		for (let i = 0; i < result.length; i++) {
	// 			if (result[i].publisher === row.publisher) {
	// 				if (
	// 					moment(result[i].month, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
	// 					moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 				) {
	// 					rowToEdit = i
	// 					break
	// 				}
	// 			}
	// 		}
	// 		result[rowToEdit].approved_conversions =
	// 			Number(result[rowToEdit].approved_conversions) +
	// 			Number(row.approved_conversions)
	// 		result[rowToEdit].deduction_amount =
	// 			Number(result[rowToEdit].deduction_amount) +
	// 			Number(row.deduction_amount)
	// 		if (row.status.toLowerCase() !== 'na') {
	// 			result[rowToEdit].number_of_rows++
	// 		}
	// 		if (row.status.toLowerCase() === 'yes') {
	// 			result[rowToEdit].final_amount =
	// 				Number(result[rowToEdit].final_amount) + Number(row.final_amount)
	// 			result[rowToEdit].final_with_vat =
	// 				Number(result[rowToEdit].final_amount) +
	// 				Number(result[rowToEdit].final_amount) *
	// 					(Number(row.publisher_vat) / 100)
	// 			result[rowToEdit].number_of_yes++
	// 		} else if (row.status.toLowerCase() === 'no') {
	// 			result[rowToEdit].number_of_no++
	// 		} else if (row.status.toLowerCase() === 'on hold') {
	// 			result[rowToEdit].pending_amount =
	// 				Number(result[rowToEdit].pending_amount) + Number(row.final_amount)
	// 			result[rowToEdit].number_of_on_hold++
	// 		}
	// 		result[rowToEdit].status_percentage = Math.floor(
	// 			((result[rowToEdit].number_of_yes + result[rowToEdit].number_of_no) *
	// 				100) /
	// 				result[rowToEdit].number_of_rows,
	// 		)
	// 	}
	// }
	// for (const row of rows) {
	// 	for (let i = 0; i < result.length; i++) {
	// 		if (
	// 			row.publisher === result[i].publisher &&
	// 			row.roll_over &&
	// 			row.month === result[i].month
	// 		) {
	// 			result[i].roll_over_value = true
	// 		}
	// 	}
	// }
	if (filterStatus) {
		let filteredArr = []
		if (filterStatus.toLowerCase() === 'all') {
			return result
		} else if (filterStatus.toLowerCase() === 'yes') {
			filteredArr = result.filter((el: any) => el.number_of_on_hold === 0)
		} else if (filterStatus.toLowerCase() === 'on hold') {
			filteredArr = result.filter((el: any) => el.number_of_on_hold > 0)
		} else if (filterStatus.toLowerCase() === 'final sent') {
			filteredArr = result.filter((el: any) => el.final_numbers_send)
		} else if (filterStatus.toLowerCase() === 'final missing') {
			filteredArr = result.filter((el: any) => !el.final_numbers_send)
		} else if (filterStatus.toLowerCase() === 'status sent') {
			filteredArr = result.filter((el: any) => el.last_send_1)
		} else if (filterStatus.toLowerCase() === 'status missing') {
			filteredArr = result.filter(
				(el: any) => !el.last_send_1 && !el.final_numbers_send,
			)
		}

		return filteredArr
	}

	return result
}

export default SupplyPage
