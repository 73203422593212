import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { MenuItem, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import saveAs from 'file-saver'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
	useActions,
	useCampaignsActions,
	useLogActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { CampaignInterface } from '../../models/model.interface'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	findUsernameByEmail,
	generateCampaignRowsFromMap,
	generateCampaignRowsWithIds,
	generateRowsWithIds,
	searchFor,
	generateCampaignRowsWithIdsAndSort,
} from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import {
	ActiveChip,
	DisabledChip,
} from '../supply/publishers/PublisherComponents'
import CampaignAdd from './CampaignAdd'
import { hasOwnerAccess } from '../../utils/helpers/helperFuncs'
import { StatusTag } from '../components/Informative'
import { TableComponent } from '../components/TableComponents'
import { ActionsButton, SimpleActionsButton } from '../components/Buttons'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../assets/svg/duplicate-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as AndroidIcon } from '../../assets/svg/android-icon.svg'
import AppleIcon from '@mui/icons-material/Apple'
import defaultIcon from '../../assets/default-icon.webp'
import { countryList } from '../components/countryList'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { HeaderFilterComponent } from '../components/TableHelperComponents'
import { THING_OR_TWO_COMPANY_ID } from '../../config'
import { fetchInChunks } from '../../utils/helpers/fetchInChunks'

const CampaignPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	PopUpVisible: boolean
	setPopUpVisible: any
	forcedFilterStatus: number
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const navigate = useNavigate()
	const params = useParams()
	const theme = useTheme()
	const { campaign, login, users, app, settings, company } = useTypedSelector(
		(state) => state,
	)
	const { getAppsIcons } = useActions()
	const { insertLog } = useLogActions()
	const {
		getCampaignAction,
		deleteCampaign,
		getCampaignsInternalReport,
		getCampaignsExternalWithCostReport,
		getCampaignsExternalWithoutCostReport,
	} = useCampaignsActions()
	const sessionKey = 'filters-campaigns-table'
	const rowCount = login.company.type === 'agency' ? 7 : 5
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [campaignsGroup, setCampaignsGroup] = useState<any[]>([])
	// const [PopUpVisible, setPopUpVisible] = useState(props.isEditorOpened)
	const [row, setRow] = useState<CampaignInterface | undefined>(undefined)
	const [isAdding, setIsAdding] = useState(false)
	const [sortModel, setSortModel] = useState()
	const [columnVisibility, setColumnVisibility] = useState({
		date_added: false,
	})
	// const [found, setFound] = useState<any[]>(
	// 	searchFor(search, campaign.campaign),
	// )
	const [filteredCampaigns, setFilteredCampaigns] = useState<
		CampaignInterface[]
	>(
		campaign.campaign.filter(
			(el: any) => el.status === true && el.role === 'M',
		),
	)
	const [update, setUpdate] = useState(false)
	const [campaignFilterStatus, setCampaignFilterStatus] = useState(1)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [duplicating, setDuplicating] = useState<boolean>(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [uuidOfDuped, setUuidOfDuped] = useState(null)

	const [fileDownload, setFileDownload] = useState()
	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }

	const [tableRows, setTableRows] = useState<CampaignInterface[]>([])
	const [isNewCampaign, setIsNewCampaign] = useState(false)

	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					campaign_name: [],
					advertiser_name: [],
					email: [],
					country_resume: [],
			  },
	)

	const activeUsersEmails = new Set(
		tableRows.map((element: any) => element.email),
	)
	const getActiveUsers = (emailsSet: any) => {
		const result = []
		for (const email of emailsSet) {
			for (const user of users.users) {
				if (email === user.email) {
					result.push(user)
					break
				}
			}
		}
		return result
	}
	const [optionsFilters, setOptionsFilter] = useState({
		email: getActiveUsers(activeUsersEmails),
		advertiser_name: Array.from(
			new Set(
				tableRows
					.map((element: any) => element.advertiser_name)
					.filter(Boolean),
			),
		),
		campaign_name: Array.from(
			new Set(
				tableRows.map((element: any) => element.campaign_name).filter(Boolean),
			),
		),
		country_resume: countryList,
	})
	const [currentFilters, setCurrentFilters] = useState('')
	const [filterLabel, setFilterLabel] = useState('')
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [openFilter, setOpenFilter] = useState(false)
	const possibleCampaignFilter = ['both', 'active', 'disabled']

	const notCreatorCompany = login.company.type !== 'creator'
	const isDemandCompany =
		login.company.type === 'agency' || login.company.type === 'advertiser'
	// const companyType = login.company.type

	const filterBasedOnOptions = (rows: any, filters: any) => {
		let noFilter = true
		let activeFilters: (keyof typeof filterMap)[] = []

		// Define filterMap for advertiser_name, country, and email
		const filterMap = {
			advertiser_name: 'advertiser_name',
			country_resume: 'country',
			email: 'email',
		}

		// Determine which filters are active
		for (const filter in filters) {
			if (filters[filter].length !== 0) {
				noFilter = false
				activeFilters.push(filter as keyof typeof filterMap)
			}
		}

		// If no filters are applied, return the original rows
		if (noFilter) {
			return rows
		}

		const result: any = []
		for (const row of rows) {
			let add = true
			for (const key of activeFilters) {
				let filterPassed = false

				// Handle each filter key separately
				for (const filteredValue of filters[key]) {
					// Country filter logic
					if (key === 'country_resume' && Array.isArray(row[filterMap[key]])) {
						if (
							row[filterMap[key]].includes(
								filteredValue.substring(0, 2).toLowerCase(),
							)
						) {
							filterPassed = true
							break
						}
					}

					// Email filter logic (now comparing emails directly)
					else if (key === 'email') {
						const matchingEmail = findUsernameByEmail(row.email, users.users) // Change this to match email comparison
						if (matchingEmail && matchingEmail.includes(filteredValue)) {
							filterPassed = true
							break
						}
					}

					// Advertiser name and other fields
					else if (Array.isArray(row[filterMap[key]])) {
						if (row[filterMap[key]].includes(filteredValue)) {
							filterPassed = true
							break
						}
					} else if (row[filterMap[key]] === filteredValue) {
						filterPassed = true
						break
					}
				}

				// If any filter fails, exclude this row
				if (!filterPassed) {
					add = false
					break
				}
			}

			// Add the row to results if all filters passed
			if (add) {
				result.push(row)
			}
		}

		return result
	}

	useEffect(() => {
		if (props.forcedFilterStatus !== 1) {
			setCampaignFilterStatus(props.forcedFilterStatus)
		}
	}, [props.forcedFilterStatus])

	useEffect(() => {
		const removeDuplicates = (data: CampaignInterface[]) => {
			const uniqueData = new Map<string, CampaignInterface>()
			data.forEach((item) => {
				uniqueData.set(item.uuid ?? '', item)
			})
			return Array.from(uniqueData.values())
		}

		props.setLoading(true)
		let filtered: CampaignInterface[]

		if (props.search.length > 0) {
			let searched = searchFor(props.search, campaign.campaign, [
				...columns,
				{ field: 'campaign_name' },
				{ field: 'country' },
			])
			filtered = applyCampaignFilters(searched)
		} else {
			filtered = applyCampaignFilters(campaign.campaign)
		}

		filtered = removeDuplicates(filtered)
		filtered = filterBasedOnOptions(filtered, customFilter)

		const rowsWithIds = generateCampaignRowsWithIdsAndSort(filtered)

		setTableRows(rowsWithIds as CampaignInterface[])
		setOptionsFilter({
			email: getActiveUsers(activeUsersEmails),
			advertiser_name: Array.from(
				new Set(
					campaign.campaign
						.map((element: any) => element.advertiser_name)
						.filter(Boolean),
				),
			),
			campaign_name: Array.from(
				new Set(
					campaign.campaign
						.map((element: any) => element.campaign_name)
						.filter(Boolean),
				),
			),
			country_resume: countryList,
		})
		props.setLoading(false)
	}, [campaignFilterStatus, props.search, applyFilter, campaign.campaign])

	const applyCampaignFilters = (data: CampaignInterface[]) => {
		if (possibleCampaignFilter[campaignFilterStatus] === 'both') {
			return data.filter((el) => el.role === 'M')
		} else if (possibleCampaignFilter[campaignFilterStatus] === 'active') {
			return data.filter((el) => el.status === true && el.role === 'M')
		} else if (possibleCampaignFilter[campaignFilterStatus] === 'disabled') {
			return data.filter((el) => el.status === false && el.role === 'M')
		} else {
			return data
		}
	}

	// useEffect(() => {
	// 	let multipleFilter = false
	// 	for (const key in customFilter) {
	// 		if (customFilter[key as keyof typeof customFilter].length > 0) {
	// 			//the idea here is that if multipleFilter turns true ONCE we still want to have all the filters options.
	// 			//so we are only doing the setter once the multiple is true (aka two or more filters are in)
	// 			if (multipleFilter) {
	// 				setOptionsFilter({
	// 					email: getActiveUsers(activeUsersEmails),
	// 					advertiser_name: Array.from(
	// 						new Set(
	// 							tableRows
	// 								.map((element: any) => element.advertiser_name)
	// 								.filter(Boolean),
	// 						),
	// 					),
	// 					campaign_name: Array.from(
	// 						new Set(
	// 							tableRows
	// 								.map((element: any) => element.campaign_name)
	// 								.filter(Boolean),
	// 						),
	// 					),
	// 					country_resume: countryList,
	// 				})
	// 				break
	// 			}
	// 			multipleFilter = true
	// 			const newObj: any = { ...optionsFilters }
	// 			for (const newKey in newObj) {
	// 				if (newKey !== key) {
	// 					if (newKey !== 'email') {
	// 						newObj[newKey as keyof typeof newObj] = new Set(
	// 							tableRows
	// 								.map((element: any) => element[newKey])
	// 								.filter(Boolean),
	// 						)
	// 						setOptionsFilter(newObj)
	// 					} else {
	// 						newObj.email = getActiveUsers(activeUsersEmails)
	// 						setOptionsFilter(newObj)
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [tableRows])

	useEffect(() => {
		if (!props.PopUpVisible && params.id) {
			// console.log('2')

			// const found = campaign.campaign.find((e: any) => e.uuid === params.id)
			handleEdit(params?.id)
		}
	}, [])

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN),
		addNewCampaigns: useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN),
		preview: useIsAuthorized(permissionNames.PREVIEW_CAMPAIGNS),
		view: useIsAuthorized(permissionNames.VIEW_CAMPAIGN),
		delete: useIsAuthorized(permissionNames.DELETE_CAMPAIGN),
		editOtherParams: useIsAuthorized(permissionNames.EDIT_OTHER_PARAMETERS),
		editMainParams: useIsAuthorized(permissionNames.EDIT_MAIN_PARAMETERS),
		addPayouts: useIsAuthorized(permissionNames.ADD_UNIQUE_PAYOUTS),
		openHistory: useIsAuthorized(permissionNames.OPENING_HISTORY),
		downloadCampaignReport: useIsAuthorized(
			permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
		),
	}

	const handlePreview = (uuid: any) => {
		if (uuid) {
			navigate('/campaigns/' + uuid + '/preview')
			props.setPopUpVisible(!props?.PopUpVisible)
		}
	}

	const actionOptions = (row: any) => {
		const sameCompanyId = row.row.company_id === login.company.id
		const canEdit = hasOwnerAccess(row.row, permissions, 'edit')
		const canDelete = hasOwnerAccess(row.row, permissions, 'delete')
		return [
			{
				label: '',
				icon: (
					<PreviewIcon
						style={{ color: theme.colors.text.titles, scale: '10' }}
						className={'action-button'}
					/>
				),
				onClick: () => handlePreview(row.row.uuid),
				rule: permissions.preview,
				key: 'preview',
			},
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '7.2' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleEdit(row.row.uuid),
				rule: canEdit,
				key: 'edit',
			},
			{
				label: '',
				icon: (
					<DuplicateIcon
						style={{ color: theme.colors.text.titles, scale: '8' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleDuplicate(row.row),
				rule: canEdit,
				key: 'duplicate',
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '7.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					setIsWarningPromptOpened(true)
					setRow(row.row)
				},
				rule: canDelete,
				key: 'delete',
			},
		]
	}
	const dateSortComparator = (v1: string, v2: string) => {
		const date1 = new Date(v1)
		const date2 = new Date(v2)
		if (date1 < date2) return 1
		if (date1 > date2) return -1
		return 0
	}
	const columns: GridColDef[] = [
		// {
		// 	field: 'icon',
		// 	headerName: '',
		// 	align: 'center',
		// 	width: rowWidth / 5,
		// 	renderCell: (row) => (
		// 		<img
		// 			alt={''}
		// 			src={row.row.icon}
		// 			style={{
		// 				height: '28px',
		// 				borderRadius: '16px',
		// 			}}
		// 		></img>
		// 	),
		// },
		// {
		// 	field: 'campaign_name',
		// 	headerName: 'Campaign Name',
		// 	width: rowWidth * 2,
		// 	renderCell: (row) => {
		// 		console.log(row.row)
		// 		return (
		// 			<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
		// 				{row.row.campaign_name}
		// 			</Typography>
		// 		)
		// 	},
		// },
		...(notCreatorCompany
			? [
					{
						field: 'advertiser_name',
						headerName: 'Advertiser',
						flex: 1,
						disableColumnMenu: true,
						renderHeader: () => {
							return (
								<HeaderFilterComponent
									label={'Advertiser'}
									width={rowWidth * 1.2}
									openFilter={openFilter}
									setOpenFilter={setOpenFilter}
									field={'advertiser_name'}
									customFilter={customFilter}
									currentFilters={currentFilters}
									setCurrentFilters={setCurrentFilters}
									setFilterLabel={setFilterLabel}
									optionsFilters={optionsFilters}
								/>
							)
						},
					},
			  ]
			: []),
		{
			field: 'campaign_name',
			headerName: 'App Name',
			flex: 1.4,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = deconstructCampaignName(row.row.campaign_name)
				const iconSrc = row.row.icon ? row.row.icon : defaultIcon
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: 12,
						}}
					>
						<img
							alt={''}
							src={iconSrc}
							style={{
								height: '28px',
								borderRadius: '16px',
							}}
						></img>
						<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
							{value.appName}
						</Typography>
					</div>
				)
			},
		},
		{
			field: 'country_resume',
			headerName: 'Country',
			flex: 0.5,
			disableColumnMenu: true,
			renderCell: (row) => {
				let value = deconstructCampaignName(row.row.campaign_name)
				return (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{value.geo}
					</Typography>
				)
			},
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Country'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'country_resume'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			valueGetter: (row) => deconstructCampaignName(row.row.campaign_name).geo,
			// sortComparator: (str1, str2) => {
			// 	console.log(str1, str2)
			// },
		},
		{
			field: 'app_id',
			headerName: 'Operated System',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			disableColumnMenu: true,
			sortComparator: (str1) => {
				if (str1.startsWith('id')) {
					return 1
				} else {
					return -1
				}
			},
			renderCell: ({ row }) => {
				// console.log(row)
				let androidActive = false
				let iOSActive = false

				if (row.app_id.startsWith('id')) {
					iOSActive = true
				} else {
					androidActive = true
				}
				return (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{iOSActive && (
							<AppleIcon
								style={{
									color: theme.colors.status.green,
									scale: '1.3',
								}}
							/>
						)}
						{androidActive && (
							<AndroidIcon
								style={{
									color: theme.colors.status.green,
									scale: '0.9',
								}}
							/>
						)}
					</div>
				)
			},
		},
		{
			field: 'flow',
			headerName: 'Flow',
			flex: 0.4,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = deconstructCampaignName(row.row.campaign_name)
				return (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{value.flow}
					</Typography>
				)
			},
			valueGetter: (row) => deconstructCampaignName(row.row.campaign_name).flow,
		},
		{
			field: 'tag',
			headerName: 'Tag',
			flex: 0.4,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = deconstructCampaignName(row.row.campaign_name)
				return (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{value.tag}
					</Typography>
				)
			},
			valueGetter: (row) => deconstructCampaignName(row.row.campaign_name).tag,
		},

		// {
		// 	field: 'date_added',
		// 	headerName: 'date added',
		// 	width: rowWidth,
		// 	sortComparator: dateSortComparator,
		// },
		...(notCreatorCompany
			? [
					{
						field: 'status',
						headerName: 'Status',
						align: 'center',
						headerAlign: 'center',
						flex: 0.8,
						disableColumnMenu: true,
						renderCell: ({ value }: { value: boolean }) =>
							value ? (
								<StatusTag
									label={'Active'}
									style={{
										color: theme.colors.text.colored,
										fontWeight: theme.font.weight.normal,
									}}
								/>
							) : (
								<StatusTag label={'Disabled'} />
							),
					},
			  ]
			: []),
		...(notCreatorCompany
			? [
					{
						field: 'email',
						headerName: 'Owner',
						flex: 0.8,
						disableColumnMenu: true,
						renderHeader: () => {
							return (
								<HeaderFilterComponent
									label={'Owner'}
									width={rowWidth * 1.2}
									openFilter={openFilter}
									setOpenFilter={setOpenFilter}
									field={'email'}
									customFilter={customFilter}
									currentFilters={currentFilters}
									setCurrentFilters={setCurrentFilters}
									setFilterLabel={setFilterLabel}
									optionsFilters={optionsFilters}
								/>
							)
						},
						renderCell: ({ value }: { value: boolean }) =>
							value
								? findUsernameByEmail(value.toString(), users.users)
								: 'User',
					},
			  ]
			: []),

		(permissions.edit ||
			permissions.delete ||
			(permissions.preview as any)) && {
			field: 'actions',
			headerName: 'Actions',
			resizable: false,
			align: 'center',
			disableColumnMenu: true,
			headerAlign: 'center',
			// width: rowWidth * 0.1,
			sortable: false,
			renderCell: (row) => (
				<ActionsButton options={actionOptions(row)} style={{ marginTop: 6 }} />
			),
		},
	]
	if (login.company.type !== 'agency') {
		columns.splice(2, 1)
		if (login.company.type !== 'advertiser') {
			columns.splice(1, 1)
			columns.splice(2, 1)
		}
	}

	const handleCellClick = (params: any, ev: React.MouseEvent) => {
		if (params.field !== 'actions') {
			const uuid = params.row.uuid
			if (permissions.preview) {
				if (ev.ctrlKey || ev.metaKey) {
					window.open('/campaigns/' + uuid + '/preview', '_blank')
					return
				}
				handlePreview(uuid)
			}
		}
	}

	const handlePopUp = async () => {
		if (props.PopUpVisible === false) {
			setDuplicating(false)
			// await fetchInChunks(getCampaignAction, 50, 'campaigns', props.setLoading)

			//setRow(undefined)
		}
		setIsNewCampaign(true)
		setIsAdding(true)
		props.setPopUpVisible(!props.PopUpVisible)
	}

	const handleDuplicate = (rowPicked: any) => {
		setDuplicating(true)
		// console.log('rowPicked', rowPicked)

		setUuidOfDuped(rowPicked.uuid)
		setIsAdding(false)
		// setCampaignsGroup(
		// 	campaign.campaign.filter(
		// 		(c: CampaignInterface) => c.campaign_name === row.campaign_name,
		// 	),
		// )
		// setRow(row)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleLogDelete = async (campaign: any) => {
		const log = {
			email: login.user.email,
			object: campaign! + ' in Campaigns',
			change: 'removed campaign',
		}
		await insertLog(log)
	}
	const handleDelete = async (row: any) => {
		const payload = {
			uuid: row.uuid,
			user_login: login.user.email,
			campaign_field: row.campaign_name,
			only_uuid: false,
		}

		props.setLoading(true)
		await deleteCampaign(payload, props.setErrorMessage)
		await fetchInChunks(getCampaignAction, 50, 'campaigns', props.setLoading)
		setUpdate(!update)
		// handleLogDelete(row.campaign_name)
		props.setLoading(false)
	}
	for (const app_id of app.appIcons) {
		for (const camp of campaign.campaign) {
			if (app_id.app_id == camp.app_id) {
				if (app_id.icon_url && app_id.icon_url !== '') {
					camp.icon = app_id.icon_url
				}
			}
		}
	}

	const handleEdit = (row: any) => {
		let found_campaign: any = null

		if (typeof row === 'string') {
			found_campaign = campaign.campaign.find((e: any) => e?.uuid === row)
		}

		for (const app_id of app.appIcons) {
			if (!found_campaign && app_id.app_id === row.app_id) {
				row.icon = app_id.icon_url
			}
		}
		setDuplicating(false)
		setIsAdding(false)
		// setCampaignsGroup(
		// 	campaign.campaign.filter(
		// 		(c: CampaignInterface) =>
		// 			c.campaign_name === found_campaing.campaign_name,
		// 	),
		// )
		// setRow(row)

		navigate('/campaigns/' + found_campaign.uuid)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleLoading = async () => {
		// props.setLoading(true)
		// props.setLoading(false)
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
		}
		if (props.presetSearch !== '') {
			props.setSearch(props.presetSearch)
			props.setPresetSearch('')
		}
	}

	useEffect(() => {
		handleLoading()
	}, [])

	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter])

	// useEffect(() => {
	// 	if (search.length === 0 && props.presetSearch !== '') {
	// 		props.setPresetSearch('')
	// 	}
	// }, [search])
	useEffect(() => {
		setFilteredCampaigns(
			filteredCampaigns.filter((c: CampaignInterface) => c.role === 'M'),
		)
	}, [campaign.campaign])
	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}
	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const getUuidArray = (rows: any) => {
		return rows.map((el: any) => "'" + el.uuid + "'")
	}
	const downloadButtonoptions = (
		<div>
			<MenuItem
				onClick={() =>
					getCampaignsInternalReport(
						setFileDownload,
						getUuidArray(tableRows),
						props.setErrorMessage,
					)
				}
			>
				Campaign list (Internal use)
			</MenuItem>
			<MenuItem
				onClick={() =>
					getCampaignsExternalWithCostReport(
						setFileDownload,
						getUuidArray(tableRows),
						props.setErrorMessage,
					)
				}
			>
				Campaign list (External use with cost)
			</MenuItem>
			<MenuItem
				onClick={() =>
					getCampaignsExternalWithoutCostReport(
						setFileDownload,
						getUuidArray(tableRows),
						props.setErrorMessage,
					)
				}
			>
				Campaign list (External use without cost)
			</MenuItem>
		</div>
	)

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				{/* <Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
				>
					<Grid item xs={6}>
						<GradientedText>
							<TitleComponent>Campaigns</TitleComponent>
						</GradientedText>
					</Grid>
					<Grid item xs={3}>
						<TableSearchComponent setSearch={setSearch} search={search} />
					</Grid>
					<Grid item xs={1}>
						<AddButton
							onClick={() => props.setPopUpVisible(!props.PopUpVisible)}
						>
							+ Add
						</AddButton>
					</Grid>
					<Grid item xs={1}>
						<AddButton
							onClick={() =>
								getCampaignsReport(
									setFileDownload,
									headers,
									props.setErrorMessage,
								)
							}
						>
							<DownloadIcon />
						</AddButton>
					</Grid>
				</Grid> */}

				{permissions.view && (
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '80%' }}
					>
						<TableComponent
							columns={columns}
							rows={tableRows}
							setPageSize={setPageSize}
							pageSize={pageSize}
							// rowHeight={55}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							filterStatus={isDemandCompany ? campaignFilterStatus : undefined}
							setFilterStatus={setCampaignFilterStatus}
							handlePopUp={
								isDemandCompany &&
								permissions.edit &&
								permissions.addNewCampaigns
									? handlePopUp
									: undefined
							}
							downloadButtonOptions={
								isDemandCompany && permissions.downloadCampaignReport
									? downloadButtonoptions
									: undefined
							}
							sortModel={sortModel}
							onSortModelChange={(model: any) => setSortModel(model)}
							filterHandler={openFilter}
							setFilterHander={setOpenFilter}
							customFilter={customFilter}
							setCustomFilter={setCustomFilter}
							currentFilters={currentFilters}
							applyFilter={handleApplyFilter}
							filterLabel={filterLabel}
							optionsFilters={optionsFilters}
							onCellClick={permissions.view ? handleCellClick : undefined}
							columnVisibility={columnVisibility}
						></TableComponent>
					</TableWrapper>
				)}
				{/* <ReactDataGrid
					idProperty='id'
					columns={columns}
					style={{ height: '80vh', marginTop: '40px' }}
					shareSpaceOnResize
					dataSource={generateRowsWithIds(
						search.length > 0 ? found : filteredCampaigns,
					)}
				/> */}
				<WarningPrompt
					message={'Deleting the row now?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => handleDelete(row)}
					onCancel={() => props.setLoading(false)}
					obj={row}
				/>
			</PageWrapper>
		</ThemeProvider>
	) : (
		<CampaignAdd
			PopUpVisible={props.PopUpVisible}
			isAdding={isAdding}
			setPopUpVisible={handlePopUp}
			row={row}
			setRow={setRow}
			setLoading={props.setLoading}
			setDuplicating={setDuplicating}
			duplicating={duplicating}
			campaignsGroup={campaignsGroup}
			setCampaignsGroup={setCampaignsGroup}
			handleDelete={handleDelete}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			uuidOfDuped={uuidOfDuped}
			isNewCampaign={isNewCampaign}
			setIsNewCampaign={setIsNewCampaign}
		/>
	)
}

export default CampaignPage

const deconstructCampaignName = (campaignName: string) => {
	const arr = campaignName.split(' - ')
	if (arr.length <= 1) {
		return {
			appName: arr[0],
			geo: '',
			system: '',
			flow: '',
			tag: '',
		}
	}
	const geos = arr[1].split(',')
	let multiGeo = false
	if (geos.length > 3) {
		multiGeo = true
	}
	const result = {
		appName: arr[0],
		geo: multiGeo ? 'MultiGeo' : arr[1],
		system: arr[2],
		flow: arr[3],
		tag: arr[4],
	}

	return result
}

function updateCountryData(objects: any) {
	// This object will store all countries indexed by main_uuid
	const countryMapping: any = {}

	// First, gather all countries for each main_uuid, excluding the 'M' role objects
	objects.forEach((obj: any) => {
		if (!countryMapping[obj.main_uuid]) {
			countryMapping[obj.main_uuid] = new Set()
		}
		obj.country.forEach((country: any) =>
			countryMapping[obj.main_uuid].add(country),
		)
	})

	// Now, update the 'M' role objects with the collected countries
	objects.forEach((obj: any) => {
		if (obj.role === 'M' && countryMapping[obj.main_uuid]) {
			// Combine the existing countries of the 'M' role object with the collected ones
			obj.allCountries = Array.from(countryMapping[obj.main_uuid])
		}
	})

	// Optional: return the modified objects array if needed
	return objects
}
