import { Grid, useTheme } from '@mui/material'
import React from 'react'
import { DropList, OwnerSelect } from '../../components/SelectableInputs'
import { InputFieldText } from '../../components/Inputs'
import { TitleComponent } from '../../components/Informative'
import { checkInputStartsWithHttp } from '../../../utils/helpers/tableHelper'

const PublisherMetaEdit = (props: {
	permissions: any
	handlePastOwnerFinance: any
	settings: any
	ownerEmail: string | null
	setOwnerEmail: any
	pastOwnerFinance: any
	setPastOwnerFinance: any
	salesRep: any
	setSalesRep: any
	status: boolean
	setStatus: any
	linkToPubFile: any
	setLinkToPubFile: any
	pauseReason: string
	setPauseReason: (value: string) => void
	pauseExplanation: string
	setPauseExplanation: (value: string) => void
	paymentTerms: string
	setPaymentTerms: any
	vat: string
	setVat: any
	branch: any
	setBranch: any
	financeId: any
	setFinanceId: any
	fieldValidations: any
	financeIdInUse: any
}) => {
	const { permissions } = props
	const theme = useTheme()

	return (
		<Grid
			container
			columns={12}
			rowSpacing={1}
			style={{ width: '100%', marginBottom: 20 }}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: 8,
					marginLeft: '20px',
				}}
			>
				<TitleComponent
					style={{
						fontSize: theme.font.size.body,
						color: theme.colors.text.titles,
						marginTop: '2rem',
					}}
				>
					Company Info
				</TitleComponent>

				<Grid
					container
					columns={12}
					columnSpacing={6}
					rowSpacing={1}
					direction='row'
					style={{ width: '98%' }}
				>
					<Grid item xs={3}>
						<OwnerSelect
							onChange={(e: any) => {
								props.handlePastOwnerFinance(e)
								props.setOwnerEmail?.(e)
							}}
							label='Owner'
							value={props.ownerEmail}
							disabled={!permissions.edit}
							style={{ background: theme.colors.base.white }}
						/>
					</Grid>

					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => props.setSalesRep?.(e)}
							options={props.settings?.settings?.salesRepresentative || []}
							label='Sales Representative'
							value={props.salesRep || ''}
							disabled={!permissions.edit}
							noNull={true}
						/>
					</Grid>

					{permissions.viewLinks && (
						<Grid item xs={3}>
							<DropList
								onChange={(e: any) => {
									props.setStatus?.(e === 'Active')
								}}
								options={['Active', 'Disabled']}
								label='Status'
								value={props.status === true ? 'Active' : 'Disabled'}
								disabled={!permissions.edit}
							/>
						</Grid>
					)}

					{permissions.viewLinks && (
						<Grid item xs={3}>
							<InputFieldText
								label='Link to Publisher File'
								type='text'
								value={props.linkToPubFile}
								onChange={props.setLinkToPubFile}
								disabled={!permissions.edit}
								errormessage={
									props.linkToPubFile &&
									!checkInputStartsWithHttp(props.linkToPubFile)
										? "Links must start with 'http://' or 'https://'"
										: undefined
								}
								showUrlIcon={true}
							/>
						</Grid>
					)}

					{props.status === false ? (
						<>
							<Grid item xs={3}>
								<DropList
									onChange={(e: any) => props.setPauseReason?.(e)}
									options={props.settings?.settings?.pauseReason?.sort() || []}
									label='Pause Reason'
									value={props.pauseReason}
									multiple={false}
									disabled={!permissions.edit}
									noNull={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<InputFieldText
									label='Explanation'
									type='text'
									value={props.pauseExplanation}
									onChange={(e: string) => props.setPauseExplanation?.(e)}
									disabled={!permissions.edit}
									style={{ width: '100%' }}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={6} style={{ minHeight: 25 }} />
					)}
				</Grid>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: 8,
					marginLeft: '20px',
					marginBottom: '1.5rem',
				}}
			>
				<TitleComponent
					style={{
						fontSize: theme.font.size.body,
						color: theme.colors.text.titles,
					}}
				>
					Finance Info
				</TitleComponent>

				<Grid
					container
					columns={12}
					columnSpacing={6}
					rowSpacing={1}
					direction='row'
					style={{ width: '98%' }}
				>
					<Grid item xs={3}>
						<InputFieldText
							label='Finance ID'
							type='number'
							value={props.financeId}
							onChange={props.setFinanceId}
							errormessage={
								props.financeIdInUse ? 'This id is already in use' : undefined
							}
						/>
					</Grid>

					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => {
								props.setBranch(e)
							}}
							options={props.settings.settings.branches}
							label='Internal Legal Entity'
							placeholder='Internal Legal Entity'
							value={props.branch}
							errored={!props.branch}
							mandatory={!props.fieldValidations.branch}
							disabled={!permissions.edit}
						/>
					</Grid>

					<Grid item xs={3}>
						<DropList
							onChange={(e: string) => props.setPaymentTerms(e)}
							options={
								props.settings.settings.paymentTerms
									? [
											'Prepayment',
											...props.settings.settings.paymentTerms,
									  ].sort()
									: []
							}
							label='Payment Term'
							placeholder='Payment Term'
							value={props.paymentTerms}
							errored={!props.paymentTerms}
							mandatory={!props.fieldValidations.paymentTerms}
							disabled={!permissions.edit}
						/>
					</Grid>

					<Grid item xs={3}>
						<DropList
							onChange={(e: string) => props.setVat(e)}
							options={
								props.settings.settings.vatValues
									? props.settings.settings.vatValues.sort()
									: []
							}
							label='VAT (%)'
							placeholder='VAT (%)'
							value={props.vat}
							disabled={!permissions.edit}
						/>
					</Grid>
				</Grid>
			</div>
		</Grid>
	)
}

export default PublisherMetaEdit
