import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import { Property } from 'csstype'
import ReactCountryFlag from 'react-country-flag'
import { PageWrapper } from '../../components/reusableComponents'
import { UnborderedButton } from '../../components/Buttons'
import EditIcon from '@mui/icons-material/Edit'
import CompanyInformationHeader from '../../components/CompanyInformationHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import defaultIcon from '../../assets/default-icon.webp'
import PreviewTitle from '../../components/preview/PreviewTitle'
import {
	PreviewArrayInfoTag,
	PreviewContainer,
	PreviewInfoTag,
} from '../../components/PreviewComponents'
import { useShowMoreActions } from '../../../hooks/useActions'
import { CapabilitiesInfoContainer } from './CreatorPreviewPage'
import { getCompanyAgencyAccess } from '../../../utils/helpers/helperFuncs'

const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: Property.WhiteSpace
	textDecoration?: Property.TextDecoration
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				// fontWeight: '500',
				whiteSpace: props.whiteSpace ? props.whiteSpace : 'nowrap',
				alignItems: props.isCountry ? 'center' : 'flex-start',
				flexWrap: 'wrap',
				flexDirection: props.isArray ? 'column' : 'row',
			}}
		>
			<span style={{ color: theme.colors.base.grey600 }}>{props.label}:</span>
			{props.isCountry ? (
				Array.isArray(props.value) ? (
					props.value.map((country: string) => {
						return (
							<>
								<ReactCountryFlag
									style={{
										height: '9px',
										borderRadius: '100px',
										border: `1px solid ${theme.colors.base.grey900}`,
									}}
									key={'flags'}
									className='roundedFlag'
									countryCode={country === 'UK' ? 'GB' : country}
									svg
								/>
								<span style={{ overflowWrap: 'anywhere' }}>{country}</span>
							</>
						)
					})
				) : (
					<>
						<ReactCountryFlag
							style={{
								height: '9px',
								borderRadius: '100px',
								border: `1px solid ${theme.colors.base.grey900}`,
							}}
							key={'flags'}
							className='roundedFlag'
							countryCode={props.value === 'UK' ? 'GB' : props.value}
							svg
						/>
						<span style={{ overflowWrap: 'anywhere' }}>{props.value}</span>
					</>
				)
			) : props.isArray ? (
				props.value?.length > 0 && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '100%',
							maxHeight: '11rem',
							overflow: 'auto',
							gap: '4px',
							// border: '1px solid ' + theme.colors.base.grey200,
							borderRadius: '10px',
							padding: '1rem',
							marginInlineStart: -18,
							marginTop: -10,
						}}
					>
						{(props.value as string[]).map((element: string) => {
							return (
								<span
									key={element + 'key'}
									style={{
										padding: '6px',
										color: theme.colors.base.grey900,
										border: '1px solid ' + theme.colors.base.grey300,
										// background: theme.colors.base.green200,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										gridColumnStart:
											element && element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
									}}
								>
									{element}
								</span>
							)
						})}
					</div>
				)
			) : props.value &&
			  typeof props.value === 'string' &&
			  ((props.value as string).startsWith('https://') ||
					(props.value as string).startsWith('http://')) ? (
				<a
					href={props.value as string}
					target={'_blank'}
					rel='noreferrer'
					style={{
						overflowWrap: 'anywhere',
						textDecoration: props.textDecoration,
					}}
				>
					Click to Open
				</a>
			) : (
				<span
					style={{
						overflowWrap: 'anywhere',
						textDecoration: props.textDecoration,
					}}
				>
					{props.value}
				</span>
			)}
		</div>
	)
}

const CreatorInformationContainer = (props: {
	creator: any
	closeDetailedView: any
	handleEdit: any
	handleShowMoreClick: any
}) => {
	const theme = useTheme()
	const handleShowMoreClick = props.handleShowMoreClick
	const hasAgencyAccess = getCompanyAgencyAccess(props.creator?.company_id)

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
				<div style={{ display: 'flex', alignItems: 'center', width: '95%' }}>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{ height: '16px', marginRight: '0px' }}
							/>
						}
					>
						Back
					</UnborderedButton>
					{
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
							}}
							onClick={props.handleEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					}
				</div>
				<PreviewTitle
					name={props.creator?.name}
					easterEggShow={false}
					iconUrl={props.creator?.creator_icon}
					status={true}
					links={{}}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 30,
						paddingBottom: 30,
					}}
				>
					<PreviewContainer gridTemplate='repeat(8, 1fr)'>
						<PreviewInfoTag
							label={'Full Name'}
							value={props.creator?.name}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Username'}
							value={props.creator?.username}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>

						<PreviewInfoTag
							label={'Email'}
							value={props.creator?.email}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>

						<PreviewInfoTag
							label={'Type'}
							value={props.creator?.type}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Creator ID'}
							value={props.creator?.creator_id}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Gender'}
							value={props.creator?.gender}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Country'}
							value={props.creator?.geo.length > 0 ? props.creator?.geo[0] : ''}
							isCountry={true}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewInfoTag
							label={'Agency Access'}
							value={hasAgencyAccess ? 'Allowed' : 'Denied'}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
					</PreviewContainer>
					<PreviewContainer gridTemplate='repeat(5, 1fr)'>
						<PreviewInfoTag
							label={'Link to Portfolio'}
							value={props.creator?.portfolio}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
						<PreviewArrayInfoTag
							label={'Campaign'}
							value={props.creator?.campaign}
							showMoreClick={handleShowMoreClick}
							gridColumnSpan={2}
						/>
					</PreviewContainer>
				</div>
			</div>
		</>
	)
}

const CreatorCompanyPreviewPage = (props: {
	setLoading: (loading: boolean) => void
	setPopUpVisible: (visible: boolean) => void
	companyData: any
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const row = props.companyData

	const { setShowMore } = useShowMoreActions()

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}

	const handleEdit = () => {
		// console.log('handleEdit')
		navigate('/companySettings/edit')
	}

	const links = {
		tiktok: row.tiktok,
		instagram: row.instagram,
		linkedin: row.linkedin,
		youtube: row.youtube,
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<CreatorInformationContainer
				creator={row}
				closeDetailedView={closeDetailedView}
				handleEdit={handleEdit}
				handleShowMoreClick={handleShowMoreClick}
			/>
			{row &&
				row.capabilities &&
				row.capabilities.length > 0 &&
				row.capabilities.map((capability: any) => {
					return (
						<CapabilitiesInfoContainer
							capabilities={capability}
							links={links}
							handleShowMoreClick={handleShowMoreClick}
						/>
					)
				})}
		</PageWrapper>
	)
}

export default CreatorCompanyPreviewPage
