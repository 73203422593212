import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'
import { formatDateToDdMmYyyy } from '../../utils/helpers/helperFuncs'

const arrayBufferToBase64 = (buffer: any) => {
	let binary = ''
	let bytes = [].slice.call(new Uint8Array(buffer))
	bytes.forEach((b) => (binary += String.fromCharCode(b)))
	return window.btoa(binary)
}

export const downloadFinanceTemplateAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadtemplate', // TODO: MATAN -> what permission is this?
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinanceMBTemplateAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadmediabuyingtemplate', // TODO: MATAN -> what permissions is this?
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinanceCreatorTemplateAction = (
	// headers: any,
	mainUuid: string,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadcreatortemplate/' + mainUuid,
				{
					headers: {
						'x-permission-name': permissionNames.EDIT_CREATOR_INVOICE, // TODO: MATAN -> is this the right one?
					},
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinanceFilledTemplateAction = (
	// headers: any,
	rows: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete rows.id
			delete rows.isNew
			delete rows.hierarchy
			const dataToSave = { rows: rows }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadfilledtemplate',
				obj,
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinanceFilledCreatorTemplateAction = (
	// headers: any,
	rows: any,
	uuid: string,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete rows.id
			delete rows.isNew
			delete rows.hierarchy
			const dataToSave = { rows: rows }
			const obj = { data: dataToSave, uuid: uuid }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadfilledcreatortemplate',
				obj,
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadtemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload Template',
					message: 'Template uploaded successfully',
				},
			})

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceMBTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadmediabuyingtemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload Template',
					message: 'Template uploaded successfully',
				},
			})

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceControlTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadcontroltemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload Template',
					message: 'Template uploaded successfully',
				},
			})

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceCreatorTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'x-permission-name': permissionNames.EDIT_CREATOR_INVOICE,
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadcreatortemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload Template',
					message: 'Template uploaded successfully',
				},
			})

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const saveProofFinance = (
	// headers: any,
	payload: any,
	refresh: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			// console.log(payload)
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadprooffinance',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.UPLOAD_PROOF_FINANCE,
				payload: data.payload,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Save Proof',
					message: 'Proof saved successfully',
				},
			})
			refresh()
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updaterow',
				obj,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_FINANCE,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				// dispatch({
				// 	type: ActionType.SET_SUCCESS,
				// 	payload: {
				// 		title: 'Upload Row',
				// 		message: 'Row uploaded successfully',
				// 	},
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadControlFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatecontrolrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				// dispatch({
				// 	type: ActionType.SET_SUCCESS,
				// 	payload: {
				// 		title: 'Upload Row',
				// 		message: 'Row uploaded successfully',
				// 	},
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadMBFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatemediabuyingrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				// dispatch({
				// 	type: ActionType.SET_SUCCESS,
				// 	payload: {
				// 		title: 'Upload Row',
				// 		message: 'Row uploaded successfully',
				// 	},
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceCreatorRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatecreatorrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount = payload.new_row.cost
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				// dispatch({
				// 	type: ActionType.SET_SUCCESS,
				// 	payload: {
				// 		title: 'Upload Row',
				// 		message: 'Row uploaded successfully',
				// 	},
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinancePublisher = (
	// headers: any,
	payload: any,
	type: string,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload, type }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancepublisherrow',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_PUBLISHER,
					payload: obj,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Upload Row',
						message: 'Row uploaded successfully',
					},
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadRollOverFinance = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadfinancerollover',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_ROLLOVER,
					payload: payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Upload Rollover',
						message: 'Rollover uploaded successfully',
					},
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFinanceDemand = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancedemandrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_DEMAND,
					payload: payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Upload Row',
						message: 'Row uploaded successfully',
					},
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadInvoiceDemand = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updateinvoice',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				// console.log('data from upate', data.payload)
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Upload Invoice',
						message: 'Invoice uploaded successfully',
					},
				})
				return data
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const removeInvoiceDemand = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// console.log('deleting', payload)
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/removeinvoice',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// payload.new_row.final_amount =
				// 	payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Remove Invoice',
						message: 'Invoice removed successfully',
					},
				})
			}
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateInvoiceInstructionsFinance = (
	// headers: any,
	payload: any,
	type: string,
	refreshFunc: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obj = { data: payload, type }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updateinvoiceinstructions',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_INSTRUCTIONS_FINANCE,
					payload: { payload: data.payload, type },
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Update Invoice Instructions',
						message: 'Invoice instructions updated successfully',
					},
				})
				refreshFunc()
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const getProofImageFinance = (
	// headers: any,
	payload: any,
	type: string,
	setImageSrc: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obj = { data: payload, type }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/getproofimage',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				let dataToUse = undefined
				if (type === 'demand') {
					dataToUse = data.payload.proof
				} else if (type === 'publisher') {
					dataToUse = data.payload.proof_publisher
				}
				const srcTo64 = arrayBufferToBase64(dataToUse.data.data)
				let src = ''
				if (dataToUse.ContentType.includes('pdf')) {
					src = `data:application/pdf;base64,${srcTo64}`
				} else if (dataToUse.ContentType.includes('png')) {
					src = `data:image/jpeg;base64,${srcTo64}`
				}
				setImageSrc(src)
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const removeProofImageFinance = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const obj = { data: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/removeproofimage',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// console.log(payload, data)
				dispatch({
					type: ActionType.DELETE_PROOF_FINANCE,
					payload: payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Remove Proof Image',
						message: 'Proof image removed successfully',
					},
				})
				setLoading(false)
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinance = (
	// headers: any,
	payload: any,
	type: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload, type: type }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloaddata',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
				setFileDownload(data.payload.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinanceTipalti = (
	// headers: any,
	payload: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadtipaltireport',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
				setFileDownload(data.payload.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const downloadFinanceBreakdownFile = (
	// headers: any,
	payload: any,
	month: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload, month: month }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadsupplybreakdown',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })

				setFileDownload(data.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const sendEmailAdvertiserFinance = (
	// headers: any,
	payload: any,
	mainRow: any,
	user: any,
	currentRows: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToSave = { data: payload, user: user, main_row: mainRow }
			const obj = { data: dataToSave }
			// console.log(obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/sendemailadvertiser',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SEND_EMAIL_FINANCE_ADVERTISER,
					payload: { row: payload, main_row: mainRow },
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Send Advertiser Email',
						message: 'Email sent successfully',
					},
				})
				return false
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const sendEmailFinance = (
	// headers: any,
	payload: any,
	type: any,
	setErrorMessage: any,
	setLastSend: any,
	setFinanceFinalSent?: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToSave = { data: payload, type: type }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/sendemail',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SEND_EMAIL_FINANCE,
					payload: { payload, type },
				})
				const currentTime = new Date()
				const currentTimeISO = currentTime.toISOString()
				setLastSend(formatDateToDdMmYyyy(currentTimeISO))
				if (type !== 'status') {
					setFinanceFinalSent(true)
				}
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Send Email',
						message: 'Email sent successfully',
					},
				})
				return true
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const deleteFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deleterow',
				obj,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_FINANCE,
					},
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.DELETE_FINANCE,
				payload: data.payload,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Delete Row',
					message: 'Row removed successfully',
				},
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const deleteFinanceCreatorRow = (
	// headers: any,
	payload: any,
	mainUuid: string,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload, mainUuid: mainUuid }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deletecreatorrow',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Delete Row',
					message: 'Row removed successfully',
				},
			})
			// dispatch({
			// 	type: ActionType.DELETE_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const deleteFinanceCreatorCampaignRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deletecreatorcampaignrow',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Delete Row',
					message: 'Row removed successfully',
				},
			})
			// dispatch({
			// 	type: ActionType.DELETE_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadBalanceIn = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancebalanceinrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_BALANCEIN,
					payload: payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Upload Balance In',
						message: 'Balance in uploaded successfully',
					},
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadBalanceOut = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancebalanceoutrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_BALANCEOUT,
					payload: payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Upload Balance Out',
						message: 'Balance out uploaded successfully',
					},
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const createCreatorFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/createcreatorfinancerow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.CREATE_CREATOR_CAMPAIGN,
					payload: data.payload,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Create Row',
						message: 'Row added successfully',
					},
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const editCreatorFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/editcreatorfinancerow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Update Row',
						message: 'Row Updated successfully',
					},
				})
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE_BALANCEOUT,
				// 	payload: payload,
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const getFinanceData = (
	// headers: any,
	type: string,
	setErrorMessage: any,
	email: string = '',
	chunkSize: number = 200,
	index: number = 0,
	controller: any = new AbortController().signal,
	filter?: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.FINANCE + `/getdata/`, {
				params: {
					type: type,
					chunkSize: chunkSize,
					index: index,
					email: email,
					year: filter?.year,
					month: filter?.month,
				},
				signal: controller,
				headers: {
					'x-permission-name': permissionNames.VIEW_FINANCE,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GET_FINANCE,
				payload: data.payload,
				index: index,
			})
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}

export const getInternalFinanceData = (
	// headers: any,
	type: string,
	setErrorMessage: any,
	endpoint: string,
	email: string = '',
	chunkSize: number = 200,
	index: number = 0,
	controller: any = new AbortController().signal,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + `/getinternaldata/`,
				{
					params: {
						type,
						chunkSize,
						index,
						email,
						endpoint,
					},
					signal: controller,
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GET_FINANCE,
				payload: data.payload,
				index: index,
			})
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateExchange = (user: string, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + `/updateexchange/`,
				{ user },
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Update Exchange',
					message: 'Exchange Updated successfully',
				},
			})

			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
export const collapseFinanceData = (user: string, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + `/collapsefinancedata/`,
				{ user },
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			return data
		} catch (err) {
			console.error(err)
		}
	}
}
export const fixBankFeesAdvertiser = (user: string, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + `/fixbankfeesadvertiser/`,
				{ user },
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			return data
		} catch (err) {
			console.error(err)
		}
	}
}
