import { ActionType } from '../action-types'
import { Action } from '../actions'

interface ShowMoreState {
	showMore: boolean
	showMoreValue: any
	showMoreTitle: string
	searchBar: boolean
}

const initialState: ShowMoreState = {
	showMore: false,
	showMoreValue: undefined,
	showMoreTitle: '',
	searchBar: false,
}

const showMoreReducer = (
	state: ShowMoreState = initialState,
	action: Action,
): ShowMoreState => {
	switch (action.type) {
		case ActionType.SET_SHOW_MORE:
			return {
				...state,
				showMore: true,
				showMoreValue: action.payload.showMoreValue,
				showMoreTitle: action.payload.showMoreTitle,
				searchBar: action.payload.searchBar || false,
			}
		case ActionType.CLEAR_SHOW_MORE:
			return {
				...state,
				showMore: false,
				showMoreValue: undefined,
			}
		default:
			return state
	}
}

export default showMoreReducer
