import { CSSProperties } from 'styled-components'

export const editPageTopPanelStyle: CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: '3vh',
	width: '95%',
	paddingLeft: 70,
	paddingRight: '0px',
}
