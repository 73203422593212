import React from 'react'
import { Box, Typography, IconButton, Tooltip, Checkbox } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as ReadIcon } from '../../assets/svg/read-mail.svg'
import { ReactComponent as UnreadIcon } from '../../assets/svg/unread-mail.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/notification-delete.svg'
import { useNavigate } from 'react-router'
import {
	NOTIFICATION_DATE_LOCALE,
	NOTIFICATION_DATE_OPTIONS,
} from '../../config'

interface NotificationItemProps {
	id: string
	title: string
	message: string
	isRead: boolean
	createdAt: string
	onDelete: (id: string) => void
	onRead: (id: string) => void
	action_url?: string
	isSelected: boolean
	onSelect: (id: string) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
	id,
	title,
	message,
	isRead,
	createdAt,
	onDelete,
	onRead,
	action_url,
	isSelected,
	onSelect,
}) => {
	const theme = useTheme()
	const navigate = useNavigate()

	return (
		<Box
			sx={{
				padding: '16px',
				marginBottom: '12px',
				border: `1px solid ${
					isRead ? theme.colors.border.light : theme.colors.status.green
				}`,
				borderLeft: isRead
					? `1px solid ${theme.colors.border.light}`
					: `6px solid ${theme.colors.status.green}`,
				borderRadius: theme.dimentions.smallerRadius,
				backgroundColor: theme.colors.base.white,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: '20px',
				boxShadow: `0 2px 4px ${theme.colors.base.boxShadowColor}`,
			}}
		>
			{/* Checkbox */}
			<Checkbox
				checked={isSelected}
				onChange={() => onSelect(id)}
				sx={{
					color: theme.colors.text.inactive,
					'&.Mui-checked': {
						color: theme.colors.base.white,
					},
				}}
			/>

			{/* Notification Content */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '16px',
					flex: 1,
					cursor: action_url ? 'pointer' : undefined,
				}}
				onClick={
					action_url
						? () => {
								navigate(action_url)
						  }
						: undefined
				}
			>
				<Box>
					<Typography
						sx={{
							fontWeight: theme.font.weight.mediumBold,
							color: isRead
								? `${theme.colors.text.inactive}`
								: `${theme.colors.text.general}`,
						}}
					>
						{title}
					</Typography>
					<Typography
						sx={{
							fontSize: theme.font.size.caption,
							color: theme.colors.text.inactive,
							marginTop: '4px',
						}}
					>
						{message}
					</Typography>
				</Box>
			</Box>

			{/* Actions */}
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
				<Typography
					variant='caption'
					sx={{
						color: isRead
							? `${theme.colors.text.inactive}`
							: `${theme.colors.text.general}`,
					}}
				>
					{new Date(createdAt).toLocaleDateString(
						NOTIFICATION_DATE_LOCALE,
						NOTIFICATION_DATE_OPTIONS,
					)}
				</Typography>
				<Tooltip title={isRead ? 'Mark as Unread' : 'Mark as Read'} arrow>
					<IconButton
						size='small'
						onClick={() => onRead(id)}
						sx={{
							backgroundColor: 'transparent',
							borderRadius: '50%',
							boxShadow: `0px 4px 6px ${theme.colors.base.boxShadowColor}`,
							'&:hover': {
								backgroundColor: theme.colors.base.grey150,
							},
						}}
					>
						{isRead ? (
							<ReadIcon style={{ color: theme.colors.text.general }} />
						) : (
							<UnreadIcon style={{ color: theme.colors.text.general }} />
						)}
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete' arrow>
					<IconButton
						size='small'
						onClick={() => onDelete(id)}
						sx={{
							backgroundColor: 'transparent',
							borderRadius: '50%',
							boxShadow: `0px 4px 6px ${theme.colors.base.boxShadowColor}`,
							'&:hover': {
								backgroundColor: theme.colors.base.grey150,
							},
						}}
					>
						<DeleteIcon style={{ color: theme.colors.text.general }} />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	)
}

export default NotificationItem
