import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import ReactCountryFlag from 'react-country-flag'
import { PageWrapper } from '../components/reusableComponents'
import { UnborderedButton } from '../components/Buttons'
import { ContactField, TitleComponent } from '../components/Informative'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import SocialLinks from '../components/SocialLinksHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import EditIcon from '@mui/icons-material/Edit'
import defaultIcon from '../../assets/default-icon.webp'
import PreviewTitle from '../components/preview/PreviewTitle'
import {
	CategoryDivider,
	PreviewArrayInfoTag,
	PreviewContainer,
	PreviewInfoTag,
} from '../components/PreviewComponents'
import { useShowMoreActions } from '../../hooks/useActions'
import { PreviewAppIdComponent } from './AdvertiserPreviewPage'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import CustomContactsComponent from '../components/contacts/CustomContactsComponent'
import { getCompanyAgencyAccess } from '../../utils/helpers/helperFuncs'

// const InfoField = (props: {
// 	label: string
// 	value: string | string[]
// 	isArray?: boolean
// 	isCountry?: boolean
// 	whiteSpace?: string
// 	isLink?: boolean
// 	link?: string
// }) => {
// 	const theme = useTheme()
// 	const handleClickedLink = () => {
// 		if (props.link) {
// 			if (!props.link.startsWith('http')) {
// 				window.open('http://' + props.link, '_blank')
// 			} else {
// 				window.open(props.link, '_blank')
// 			}
// 		}
// 	}

// 	return (
// 		<div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
// 			<span style={{ color: theme.colors.base.grey600 }}>
// 				{props.label ? props.label + ':' : ''}
// 			</span>
// 			{props.isCountry ? (
// 				<>
// 					<ReactCountryFlag
// 						style={{
// 							height: '9px',
// 							borderRadius: '100px',
// 							border: `1px solid ${theme.colors.black}`,
// 						}}
// 						countryCode={props.value === 'UK' ? 'GB' : (props.value as string)}
// 						svg
// 					/>
// 					<span>{props.value}</span>
// 				</>
// 			) : props.isArray ? (
// 				<div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
// 					{(props.value as string[]).map((element: string) => (
// 						<span
// 							key={element}
// 							style={{
// 								padding: '6px',
// 								background: theme.colors.base.green200,
// 								borderRadius: '10px',
// 								whiteSpace: 'nowrap',
// 							}}
// 						>
// 							{element}
// 						</span>
// 					))}
// 				</div>
// 			) : props.isLink ? (
// 				<span
// 					onClick={handleClickedLink}
// 					style={{ cursor: 'pointer', color: theme.colors.base.black }}
// 				>
// 					{props.value}
// 				</span>
// 			) : (
// 				<span style={{ overflowWrap: 'anywhere' }}>{props.value}</span>
// 			)}
// 		</div>
// 	)
// }

// const CapabilityContainer = (props: { value: any; label: string }) => {
// 	const theme = useTheme()
// 	const valueArray = Array.isArray(props.value) ? props.value : []

// 	if (valueArray.length === 0) {
// 		return null
// 	}

// 	return (
// 		<div
// 			style={{
// 				border: '1px solid ' + theme.colors.base.grey200,
// 				borderRadius: '20px',
// 				padding: '20px',
// 				display: 'flex',
// 				flexDirection: 'column',
// 				gap: '12px',
// 				height: '100%',
// 			}}
// 		>
// 			<span style={{ fontWeight: '600' }}>{props.label}</span>
// 			<div
// 				style={{
// 					display: 'grid',
// 					gap: '12px',
// 					gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
// 				}}
// 			>
// 				{valueArray.map((element: any) => (
// 					<span
// 						key={element.value}
// 						style={{
// 							border: '2px solid ' + theme.colors.base.green200,
// 							padding: '6px',
// 							borderRadius: '10px',
// 							textAlign: 'center',
// 							whiteSpace: 'nowrap',
// 							overflow: 'hidden',
// 							textOverflow: 'ellipsis',
// 						}}
// 					>
// 						{element.value}
// 					</span>
// 				))}
// 			</div>
// 		</div>
// 	)
// }

const CompanyInformationContainer = (props: {
	advertiser: any
	closeDetailedView: any
	onEdit: () => void
}) => {
	const { app, settings } = useTypedSelector((state) => state)
	const theme = useTheme()
	const trafficRestrictions =
		props.advertiser?.traffic_restrictions?.length > 0
			? JSON.parse(props.advertiser.traffic_restrictions)
			: []
	const existingChannels =
		props.advertiser?.existing_channels?.length > 0
			? JSON.parse(props.advertiser.existing_channels)
			: []

	const { setShowMore } = useShowMoreActions()
	const hasAgencyAccess = getCompanyAgencyAccess(props.advertiser?.company_id)

	const handleShowMoreClick = (
		value: any,
		title: string,
		searchBar = false,
	) => {
		setShowMore({
			showMore: true,
			showMoreValue: Array.isArray(value) ? value.join('  -  ') : value,
			showMoreTitle: title,
			searchBar: searchBar,
		})
	}
	const appIdList = app.app
		.filter(
			(element: any) =>
				element.advertiser_name === props.advertiser?.advertiser_name,
		)
		.map((el: any) => {
			return { app_id: el.app_id, link: el.link }
		})
	const uniqueRestrictions: string[] = Array.from(
		new Set(settings.settings.trafficRestrictions),
	)
	const uniqueExistingChannels: string[] = Array.from(
		new Set(settings.settings.existingChannels),
	)

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<div style={{ display: 'flex', width: '90%' }}>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
							color: theme.colors.text.titles,
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{ height: '16px', marginRight: '0px', marginLeft: -10 }}
							/>
						}
					>
						Back
					</UnborderedButton>
					{
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
								color: theme.colors.text.titles,
							}}
							onClick={props.onEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					}
				</div>
				<div style={{ padding: '1rem 0rem 0rem 0rem' }}>
					<PreviewTitle
						name={props.advertiser.advertiser_name}
						iconUrl={props.advertiser.advertiser_icon}
						id={props.advertiser.id}
						status={true}
						links={{
							website: props.advertiser.website,
							linkedin: props.advertiser.linkedin,
						}}
					/>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 30,
					paddingBottom: 30,
				}}
			>
				<PreviewContainer gridTemplate='repeat(8, 1fr)'>
					<PreviewInfoTag
						label={'Legal Name'}
						value={props.advertiser.legal_name}
						showMoreClick={handleShowMoreClick}
						gridColumnSpan={2}
					/>
					<PreviewInfoTag
						label={'Address'}
						value={props.advertiser.address}
						gridColumnSpan={3}
						showMoreClick={handleShowMoreClick}
					/>
					<PreviewInfoTag
						label={'Head Office Geo'}
						value={props.advertiser.geo}
						isCountry={true}
						showMoreClick={handleShowMoreClick}
					/>
					<PreviewInfoTag
						label={'Type'}
						value={props.advertiser.advertiser_type}
						showMoreClick={handleShowMoreClick}
					/>
					<PreviewInfoTag
						label={'Agency Access'}
						value={hasAgencyAccess ? 'Allowed' : 'Denied'}
						showMoreClick={handleShowMoreClick}
					/>

					{/* <PreviewInfoTag
						label={'Branch'}
						value={props.advertiser.branch}
						showMoreClick={handleShowMoreClick}
					/> */}

					<PreviewInfoTag
						label={'Notes'}
						value={props.advertiser.notes || ''}
						gridRowSpan={2}
						gridColumnSpan={5}
						showMoreClick={handleShowMoreClick}
					/>
					<PreviewArrayInfoTag
						label={'Communication Channel'}
						value={props.advertiser.communication_channel}
						showMoreClick={handleShowMoreClick}
						gridColumnSpan={2}
					/>
					<PreviewArrayInfoTag
						label={'Tracking System'}
						value={props.advertiser.platform}
						showMoreClick={handleShowMoreClick}
						gridColumnSpan={2}
					/>
				</PreviewContainer>
				<PreviewContainer gridTemplate='repeat(6, 1fr)'>
					<PreviewArrayInfoTag
						label={'Fraud Tool'}
						value={props.advertiser.fraud_tool}
						showMoreClick={handleShowMoreClick}
						gridColumnSpan={2}
					/>
					<PreviewArrayInfoTag
						label={'Vertical'}
						value={props.advertiser.vertical}
						showMoreClick={handleShowMoreClick}
						gridColumnSpan={2}
					/>
					<PreviewArrayInfoTag
						label={'Data Source'}
						value={props.advertiser.data_source}
						showMoreClick={handleShowMoreClick}
						gridColumnSpan={2}
					/>
					<PreviewAppIdComponent
						appList={appIdList}
						label={"App's ID"}
						handleShowMoreClick={handleShowMoreClick}
					/>
				</PreviewContainer>
				<PreviewContainer gridTemplate={'repeat(3, 1fr)'}>
					<PreviewInfoTag
						label={'Brief'}
						value={props.advertiser.brief}
						gridRowSpan={2}
						gridColumnSpan={1}
						showMoreClick={handleShowMoreClick}
					/>
					<PreviewInfoTag
						label={'Special Requests'}
						value={props.advertiser.special_request}
						gridRowSpan={2}
						gridColumnSpan={1}
						showMoreClick={handleShowMoreClick}
					/>
					<PreviewInfoTag
						label={'Parameters template'}
						value={props.advertiser.parameters_template}
						gridRowSpan={2}
						gridColumnSpan={1}
						showMoreClick={handleShowMoreClick}
					/>
				</PreviewContainer>
				<CustomContactsComponent
					contacts={
						props.advertiser?.contacts.length > 0
							? props.advertiser?.contacts
							: []
					}
					setContacts={() => {}} // No need to modify contacts in preview mode
					p360Active={true}
					canEdit={false}
					roleOptions={[]}
					mode='preview'
				/>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
					<CategoryDivider
						mainTitle={'Traffic Restrictions'}
						selected={trafficRestrictions.map((el: any) => el.value)}
						selectedLabel={'Restrictions'}
						total={uniqueRestrictions}
						totalLabel={'Allowed'}
						showMoreClick={handleShowMoreClick}
					/>
					<CategoryDivider
						mainTitle={'Existing Channels'}
						selected={existingChannels.map((el: any) => el.value)}
						selectedLabel={'Existing'}
						total={uniqueExistingChannels}
						totalLabel={'Non Existing'}
						showMoreClick={handleShowMoreClick}
					/>
				</div>
			</div>
		</>
	)
}

const AdvertiserCompanyPreviewPage = (props: {
	setLoading: any
	companyData: any
	setPopUpVisible: (visible: boolean) => void
}) => {
	const navigate = useNavigate()
	const theme = useTheme()
	const row = props.companyData

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}

	const handleEdit = () => {
		navigate(`/companySettings/edit`)
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<CompanyInformationContainer
				advertiser={row}
				closeDetailedView={closeDetailedView}
				onEdit={handleEdit}
			/>
		</PageWrapper>
	)
}

export default AdvertiserCompanyPreviewPage
