import { Grid, useTheme, Box, Card, CardContent } from '@mui/material'
import React from 'react'
import { DropList, OwnerSelect } from '../components/SelectableInputs'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import { TitleComponent } from '../components/Informative'
import {
	EditableGridItem,
	PreviewContainer,
} from '../components/PreviewComponents'

const AdvertiserMetaEdit = (props: {
	permissions: any
	handlePastOwner: any
	handlePastOwnerFinance: any
	settings: any
	ownerEmail: string | null
	setOwnerEmail: any
	salesRep: any
	setSalesRep: any
	status: boolean
	setStatus: any
	setStatusChanged: any
	pauseReason?: string
	setPauseReason?: (value: string) => void
	pauseExplanation?: string
	setPauseExplanation?: (value: string) => void
	ViewPauseBox?: boolean
	setViewPauseBox?: (value: boolean) => void
	paymentTerms: string
	setPaymentTerms: any
	vat: string
	setVat: any
	branch: any
	setBranch: any
	invoiceInstructions: string
	setInvoiceInstructions: any
	fieldValidations: any
}) => {
	const permissions = props.permissions
	const theme = useTheme()

	const handleOwnerChange = (e: any) => {
		try {
			props.handlePastOwner(e)
			props.handlePastOwnerFinance(e)
			props.setOwnerEmail?.(e)
		} catch (error) {
			console.error('Error handling owner change', error)
			throw error
		}
	}

	const handleSalesRepChange = (e: any) => {
		try {
			props.setSalesRep?.(e)
		} catch (error) {
			console.error('Error handling sales rep change', error)
			throw error
		}
	}

	const handleStatusChange = (e: any) => {
		try {
			props.setStatus?.(e === 'Active')
			props.setStatusChanged?.(true)
		} catch (error) {
			console.error('Error handling status change', error)
			throw error
		}
	}

	const handleBranchChange = (e: any) => {
		try {
			props.setBranch(e)
		} catch (error) {
			console.error('Error handling branch change', error)
			throw error
		}
	}

	const handlePaymentTermsChange = (e: string) => {
		try {
			props.setPaymentTerms(e)
		} catch (error) {
			console.error('Error handling payment terms change', error)
			throw error
		}
	}

	const handleVatChange = (e: string) => {
		try {
			props.setVat(e)
		} catch (error) {
			console.error('Error handling VAT change', error)
			throw error
		}
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					gap: 4,
					width: '70%',
					maxWidth: '90vw',
					padding: '40px 80px 0 0',
					marginLeft: '-4rem',
					flexDirection: 'column',
				
				}}
			>
				<Card
					sx={{
						flex: 3,
						background: theme.colors.preview.cardBackground,
						borderRadius: 6,
						padding: 1,
						overflow: 'hidden',
					}}
				>
					<CardContent>
						<PreviewContainer
							additionalStyle={{
								display: 'grid',
								gridTemplateColumns: 'repeat(3, 1fr)',
								gap: '20px',
								height: 200, 
								overflow: 'hidden',
								padding: '20px',
								border: 'none',
								width: '98%'
							}}
						>
							{/* First row */}
							<EditableGridItem gridColumnSpan={1}>
								<OwnerSelect
									onChange={(e: any) => {
										try {
											handleOwnerChange(e)
										} catch (error) {
											console.error('Error in OwnerSelect', error)
											throw error
										}
									}}
									label='Owner'
									value={props.ownerEmail}
									disabled={!permissions.edit}
									style={{ background: theme.colors.base.white }}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={1}>
								<DropList
									onChange={(e: any) => {
										try {
											handleSalesRepChange(e)
										} catch (error) {
											console.error('Error in Sales Representative', error)
											throw error
										}
									}}
									options={props.settings?.settings?.salesRepresentative || []}
									label='Sales Representative'
									value={props.salesRep || ''}
									disabled={!permissions.edit}
									noNull={true}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={1}>
								<DropList
									onChange={(e: any) => {
										try {
											handleStatusChange(e)
										} catch (error) {
											console.error('Error in Status', error)
											throw error
										}
									}}
									options={['Active', 'Disabled']}
									label='Status'
									value={props.status === true ? 'Active' : 'Disabled'}
									disabled={!permissions.edit}
								/>
							</EditableGridItem>

							{/* Second row */}
							<EditableGridItem gridColumnSpan={1}>
								<DropList
									onChange={(e: any) => {
										try {
											handleBranchChange(e)
										} catch (error) {
											console.error('Error in Internal Legal Entity', error)
											throw error
										}
									}}
									options={props.settings.settings.branches}
									label='Internal Legal Entity'
									placeholder='Internal Legal Entity'
									value={props.branch}
									errored={!props.branch}
									mandatory={!props.fieldValidations.branch}
									disabled={!permissions.edit}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={1}>
								<DropList
									onChange={(e: string) => {
										try {
											handlePaymentTermsChange(e)
										} catch (error) {
											console.error('Error in Payment Term', error)
											throw error
										}
									}}
									options={
										props.settings.settings.paymentTerms
											? [
													'Prepayment',
													...props.settings.settings.paymentTerms,
											  ].sort()
											: []
									}
									label='Payment Term'
									placeholder='Payment Term'
									value={props.paymentTerms}
									errored={!props.paymentTerms}
									mandatory={!props.fieldValidations.paymentTerms}
									disabled={!permissions.edit}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={1}>
								<DropList
									onChange={(e: string) => {
										try {
											handleVatChange(e)
										} catch (error) {
											console.error('Error in VAT', error)
											throw error
										}
									}}
									options={
										props.settings.settings.vatValues
											? props.settings.settings.vatValues.sort()
											: []
									}
									label='VAT (%)'
									placeholder='VAT (%)'
									value={props.vat}
									disabled={!permissions.edit}
								/>
							</EditableGridItem>
						</PreviewContainer>
					</CardContent>
				</Card>

				{/* Right side: Invoice Instructions spanning full height */}
				<Card
					sx={{
						flex: 2,
						background: theme.colors.preview.cardBackground,
						borderRadius: 6,
						padding: 3.5,
						overflow: 'hidden',
						height: 250,
					}}
				>
					<CardContent style={{  height: '100%' }}>
						<StyledTextAreaField
							label='Invoice Instructions'
							value={props.invoiceInstructions}
							onChange={props.setInvoiceInstructions}
							disabled={!permissions.edit}
							rows={4}
						/>
					</CardContent>
				</Card>
			</Box>
		</Box>
	)
}

export default AdvertiserMetaEdit
