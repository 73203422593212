import { io, Socket } from 'socket.io-client'
import { devUrl } from '../config'

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
const host = window.location.hostname

const url = window.location.href.includes('stage')
? `${protocol}://stage.varys.io`
: window.location.href.includes('varys')
? `${protocol}://${host}`
: `${protocol}://localhost:4000`

class WebSocketManager {
	private static instance: WebSocketManager // Singleton instance
	private socket: Socket | null = null

	private constructor() {}

	// Singleton: Ensure only one WebSocketManager instance
	static getInstance(): WebSocketManager {
		if (!WebSocketManager.instance) {
			WebSocketManager.instance = new WebSocketManager()
		}
		return WebSocketManager.instance
	}

	// Connect to the WebSocket server
	connect(token: string): void {
		if (this.socket) {
			// console.warn('WebSocket is already connected')
			return
		}

		this.socket = io(url, {
			transports: ["websocket"],
			extraHeaders: {
				Authorization: `Bearer ${token}`,
			},
		})

		// Event listeners
		this.socket.on('connect', () => {
			console.log('WebSocket connected')
		})

		this.socket.on('disconnect', () => {
			console.log('WebSocket disconnected')
		})

		this.socket.on('connect_error', (error) => {
			console.error('WebSocket connection error:', error.message)
		})
	}

	// Register an event listener
	on(event: string, callback: (data: any) => void): void {
		if (!this.socket) {
			console.error('WebSocket is not connected')
			return
		}

		this.socket.on(event, callback)
	}

	// Emit an event
	emit(event: string, data: any): void {
		if (!this.socket) {
			console.error('WebSocket is not connected')
			return
		}

		this.socket.emit(event, data)
	}

	// Disconnect the WebSocket
	disconnect(): void {
		if (this.socket) {
			this.socket.disconnect()
			this.socket = null
			console.log('WebSocket disconnected')
		}
	}
}

export default WebSocketManager
