import React, { useState, useEffect, ChangeEvent } from 'react'
import { Modal, Box, Typography, Button, Grid, useTheme } from '@mui/material'
import { format, parse } from 'date-fns'
import { DatePicker } from '../components/dateInputs/DateInputs'
import { FileUploadButton } from './reusableComponents'
import { ContractsInterface } from '../../models/model.interface'
import { SubmitButton } from './Buttons'
import {
	convertFileToBase64,
	sortContracts,
} from '../../utils/helpers/helperFuncs'
import { InputFieldText } from './Inputs'

interface IOsModalProps {
	open: boolean
	onClose: () => void
	existingContracts?: ContractsInterface[]
	onSave: (contracts: ContractsInterface[]) => void
	isPreview?: boolean
}

const MIN_ROWS = 5

const IOsModal: React.FC<IOsModalProps> = ({
	open,
	onClose,
	existingContracts = [],
	onSave,
	isPreview = false,
}) => {
	const theme = useTheme()
	const [contracts, setContracts] = useState<ContractsInterface[]>([])

	useEffect(() => {
		try {
			if (isPreview) {
				setContracts((prevContracts) => {
					try {
						const sortedExisting = sortContracts(existingContracts)
						const prevString = JSON.stringify(prevContracts)
						const sortedString = JSON.stringify(sortedExisting)
						return prevString !== sortedString ? sortedExisting : prevContracts
					} catch (error) {
						console.error('Error sorting contracts in preview mode:', error)
						return prevContracts
					}
				})
				return
			}

			const sortedContracts = sortContracts(existingContracts)
			const updatedContracts = [...sortedContracts]

			while (updatedContracts.length < MIN_ROWS) {
				updatedContracts.push({
					fileURL: '',
					expiration: '',
					fileType: '',
					fileName: '',
				})
			}

			if (updatedContracts.every((c) => c.fileURL)) {
				updatedContracts.push({
					fileURL: '',
					expiration: '',
					fileType: '',
					fileName: '',
				})
			}

			setContracts((prevContracts) => {
				try {
					const prevString = JSON.stringify(prevContracts)
					const updatedString = JSON.stringify(updatedContracts)
					return prevString !== updatedString ? updatedContracts : prevContracts
				} catch (error) {
					console.error('Error comparing contracts:', error)
					return prevContracts
				}
			})
		} catch (error) {
			console.error('Error in useEffect for contracts:', error)
		}
	}, [existingContracts, isPreview])

	const handleDateChange = (index: number, date: Date | null) => {
		if (!date || isPreview) return

		const formattedDate = format(date, 'dd/MM/yyyy')

		const updatedContracts = [...contracts]
		updatedContracts[index] = {
			...updatedContracts[index],
			expiration: formattedDate,
		}
		setContracts(updatedContracts)
	}

	const handleFileUpload = async (
		index: number,
		event: ChangeEvent<HTMLInputElement>,
	) => {
		if (isPreview) return

		const file = event.target.files?.[0]
		if (!file) return

		const base64String = await convertFileToBase64(file)

		setContracts((prevContracts) => {
			const updatedContracts = [...prevContracts]
			updatedContracts[index] = {
				...updatedContracts[index],
				file: base64String, // Send Base64 instead of File object
				fileName: file.name,
				fileType: updatedContracts[index].fileType,
			}
			return updatedContracts
		})
	}

	const handleFileDelete = (index: number) => {
		if (isPreview) return

		setContracts((prevContracts) => {
			const updatedContracts = [...prevContracts]
			updatedContracts[index] = {
				fileURL: '',
				expiration: '',
				fileType: '',
				fileName: '',
			}
			return updatedContracts
		})
	}
	const handleFileTypeChange = (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			if (isPreview) return

			const { value } = event.target

			setContracts((prevContracts) => {
				const updatedContracts = [...prevContracts]
				updatedContracts[index] = {
					...updatedContracts[index],
					fileType: value,
				}
				return updatedContracts
			})
		} catch (error) {
			console.error('Error updating fileType:', error)
			throw error
		}
	}

	const handleClose = () => {
		if (!isPreview) {
			onSave(contracts)
		}
		onClose()
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
		>
			<Box
				sx={{
					backgroundColor: theme.colors.base.white,
					borderRadius: 3,
					width: 750,
					height: '50vh',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				}}
			>
				{/* Title */}
				<Box sx={{ padding: 3 }}>
					<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
						Documents
					</Typography>
				</Box>

				{/* Scrollable Content */}
				<Box
					sx={{
						flexGrow: 1,
						overflowY: 'auto',
						padding: '3px 0',
					}}
				>
					{contracts.length === 0 ? (
						<Typography
							variant='h6'
							sx={{ textAlign: 'center', color: theme.colors.base.grey600 }}
						>
							No Contracts Available
						</Typography>
					) : (
						contracts.map((contract, index) => (
							<Grid
								key={index}
								container
								spacing={2}
								alignItems='center'
								sx={{
									backgroundColor:
										index % 2 === 0
											? theme.colors.base.grey100
											: theme.colors.base.white,
									borderRadius: 2,
									padding: 2,
									marginBottom: 2,
								}}
							>
								{/* Contract Type Input */}
								<Grid item xs={4}>
									<Typography variant='body2'>Doc Type</Typography>
									<InputFieldText
										type='text'
										label=''
										fullWidth
										disabled={isPreview}
										value={contract.fileType ?? ''}
										onChange={(event: any) =>
											handleFileTypeChange(index, event)
										}
									/>
								</Grid>
								{/* Expiration Date */}
								<Grid item xs={4}>
									<Typography variant='body2'>Expiration</Typography>
									<DatePicker
										label=''
										value={
											contract.expiration
												? parse(contract.expiration, 'dd/MM/yyyy', new Date())
												: null
										}
										onChange={(date: Date | null) =>
											handleDateChange(index, date)
										}
										disabled={isPreview}
										format='dd/MM/yyyy'
										sx={{ background: theme.colors.base.white }}
									/>
								</Grid>

								{/* File Upload */}
								<Grid item xs={4}>
									<Typography variant='body2'>File</Typography>
									<FileUploadButton
										uploadedFile={
											contract.fileURL || contract.file ? contract : null
										}
										onUpload={(event) => handleFileUpload(index, event)}
										onDelete={() => handleFileDelete(index)}
										isPreview={isPreview}
									/>
								</Grid>
							</Grid>
						))
					)}
				</Box>

				{/* Sticky Footer Button */}
				<Box
					sx={{
						position: 'sticky',
						bottom: 0,
						backgroundColor: theme.colors.base.white,
						padding: '2px 5px',
						borderTop: `1px solid ${theme.colors.base.grey200}`,
						boxShadow: '0px -2px 5px rgba(0,0,0,0.1)',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<SubmitButton
						onClick={handleClose}
						sx={{
							'&:hover': {
								background: theme.colors.base.green20,
								border: '2px solid ' + theme.colors.base.green200,
								color: theme.colors.base.grey900,
							},
							'&.Mui-focused': {
								background: theme.colors.base.green100,
							},
						}}
					>
						OK
					</SubmitButton>
				</Box>
			</Box>
		</Modal>
	)
}

export default IOsModal
