import React, { CSSProperties } from 'react'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Box,
	Typography,
	DialogActions,
	useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PaperProps as MuiPaperProps } from '@mui/material'

interface CustomModalProps {
	title: string
	open: boolean
	onClose: () => void
	children: React.ReactNode
	actions?: React.ReactNode
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	fullWidth?: boolean
	PaperProps?: Partial<MuiPaperProps>
	styles?: CSSProperties
}

const CustomModal: React.FC<CustomModalProps> = ({
	title,
	open,
	onClose,
	children,
	actions,
	maxWidth = 'sm',
	fullWidth = false,
	styles,
	PaperProps,
}) => {
	const theme = useTheme()
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			PaperProps={{
				...PaperProps,
			}}
		>
			<DialogTitle>
				{title}
				<IconButton
					onClick={onClose}
					style={{ position: 'absolute', right: '8px', top: '8px' }}
					aria-label='close'
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ width: '100%', ...styles }}>
				{children}
			</DialogContent>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'column',
					borderTop: `1px solid ${theme.colors.preview.border}`,
					marginTop: 10,
					// padding: '1rem',
				}}
			></div>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Dialog>
	)
}

export default CustomModal
