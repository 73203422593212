import { ThemeProvider } from '@emotion/react'
import {
	DataGridProProps,
	GridColDef,
	GridEventListener,
	GridRenderCellParams,
	GridRowEditStopReasons,
	GridRowModes,
	GridRowModesModel,
	gridFilteredDescendantCountLookupSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import './finance.css'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'

import { ButtonProps, MenuItem, Typography, useTheme } from '@mui/material'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'
import {
	createHierarchy,
	extractPaymentLimit,
	filterStatusByProperty,
	formatDateToMonth,
	formatFinancePaymentDate,
	getMonthAbbreviation,
	getYear,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { HeaderFilterComponent } from '../components/TableHelperComponents'
import { SimpleActionsButton } from '../components/Buttons'
import { ReactComponent as DownloadIcon } from '../../assets/svg/download-icon.svg'
import { compareRevenuesByLayers } from './financeHelperFuncs'

const BalanceInPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, advertiser, users, settings } = useTypedSelector(
		(state) => state,
	)
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const {
		getFinanceData,
		uploadBalanceIn,
		downloadFinance,
		updateExchange,
		collapseFinanceData,
		fixBankFeesAdvertiser,
	} = useFinanceActions()
	const sessionKey = 'filters-finance-balance_in'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 17
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()
	const [discrepancyFilter, setDiscrepancyFilter] = useState<boolean>(false)
	const [tableRows, setTableRows] = useState<any>([])
	const [openFilter, setOpenFilter] = useState(false)
	const [financeFinalConfirmationFilter, setFinanceFinalConfirmationFilter] =
		useState(
			sessionFilters.financeFinalConfirmationFilter
				? sessionFilters.financeFinalConfirmationFilter
				: 0,
		)
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					advertiser: [],
					account_manager: [],
					branch: [],
			  },
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		advertiser: [],
		account_manager: [],
		branch: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray ? sessionFilters.monthFilterArray : [],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async (signal: AbortSignal) => {
		dispatch(emptyFinance())
		let i = 0
		let result
		const year = yearFilterArray
		const month = monthFilterArray

		do {
			if (signal.aborted) return
			result = await getFinanceData(
				'balancein',
				props.errorMessage,
				login.user.email,
				1000,
				i,
				undefined,
				{ year: year, month },
			)
			i++
			setDataFetched(true)
			//we need to check the pathname in order to stop fetching data if we move to a different route
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('balancein')
		)
	}

	useEffect(() => {
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.financeFinalConfirmationFilter &&
				setFinanceFinalConfirmationFilter(
					sessionFilters.financeFinalConfirmationFilter,
				)
		}
	}, [])
	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el: any) => el.length > 0),
				),
				account_manager: new Set(
					tableRows
						.map((element: any) => element.account_manager)
						.filter((el: any) => el && el.length > 0),
				),
				branch: new Set(
					tableRows
						.map((element: any) => element.branch)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				financeFinalConfirmationFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		financeFinalConfirmationFilter,
	])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		fetchData(signal)

		return () => controller.abort() // Cleanup previous requests
	}, [monthFilterArray, yearFilterArray])

	useEffect(() => {
		//we do this check because if we move from one page to another, even if finance.finance has data only for 0.1ms then the
		//gridtable will fail trying to find hierarchy.
		if (finance.finance.length > 0 && finance.finance[0].hierarchy) {
			setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
		}
	}, [finance.finance])

	const possibleFinanceFilter = useMemo(
		() => ['All', 'Missing', 'Confirmed', 'Paid', 'Unpaid'],
		[],
	)

	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, [
				...columns,
				{ field: 'advertiser' },
			])
			if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'all'
			) {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'missing'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(
						el,
						index,
						arr,
						'final_confirmation_advertiser',
						false,
					),
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'confirmed'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(
						el,
						index,
						arr,
						'final_confirmation_advertiser',
						true,
					),
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'paid'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'payment_advertiser', true),
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'unpaid'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'payment_advertiser', false),
				)
			}
		} else {
			if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'all'
			) {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'missing'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(
						el,
						index,
						arr,
						'final_confirmation_advertiser',
						false,
					),
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'confirmed'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(
						el,
						index,
						arr,
						'final_confirmation_advertiser',
						true,
					),
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'paid'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'payment_advertiser', true),
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'unpaid'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'payment_advertiser', false),
				)
			}
		}
		if (discrepancyFilter) {
			const added: any = {}
			const mainRowsFiltered = filtered.filter((el: any) => {
				if (
					el.hierarchy.length === 1 &&
					compareRevenuesByLayers(el.revenue_base, el.revenue)
				) {
					added[el.hierarchy[0]] = true
					return true
				}
				return false
			})
			const childrenRowsFiltered = filtered.filter((el: any) => {
				if (el.hierarchy.length > 1 && added[el.hierarchy[0]]) {
					return true
				}
				return false
			})
			filtered = [...mainRowsFiltered, ...childrenRowsFiltered]
		}
		//if possible due date is "All" then we do nothing at all because we won't need to filter.
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true

					foundByKey = customFilter[key].includes(data[key])

					if (key === 'publisher_manager' || key === 'account_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key] &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)
				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		const rowsByAdvertisers = generateRowsByAdvertiser(
			arr,
			advertiser.advertiser,
		)
		const hierarchyArr = createHierarchy(arr, 'advertiser')
		const recalculated = recalculateMainAfterFilter(hierarchyArr)
		arr = generateRowsWithIds(recalculated)
		setTableRows(arr)
		setPinnedBottomRow(calculatePinnedTotalRow(arr))
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		props.search,
		applyFilter,
		financeFinalConfirmationFilter,
		finance.finance,
		discrepancyFilter,
	])

	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	// const handleEditClick = (id: any) => () => {
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	// }
	// const handleSaveClick = (id: any) => () => {
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	// }
	// const handleCancelClick = (id: any) => () => {
	// 	setRowModesModel({
	// 		...rowModesModel,
	// 		[id]: { mode: GridRowModes.View, ignoreModifications: true },
	// 	})
	// }
	const handleDownloadFinance = useCallback(() => {
		const dataToDownload = tableRowsRef.current
		downloadFinance(
			dataToDownload,
			'balance-in',
			setFileDownload,
			props.setErrorMessage,
		)
	}, [])

	const tableRowsRef = useRef(tableRows)

	useEffect(() => {
		tableRowsRef.current = tableRows
	}, [tableRows])
	const handleUpdateExchangeFinance = () => {
		updateExchange(login.user, props.setErrorMessage)
	}
	const triggerCollapse = async () => {
		const checker = window.confirm(
			'Are you sure you want to collapse the finance data?',
		)
		if (checker) {
			const update: any = await collapseFinanceData(
				login.user,
				props.setErrorMessage,
			)
			// if (update.successful) {
			// 	props.setIsSuccessPromptOpened(true)
			// }
		}
	}
	const triggerBankFeesFix = async () => {
		const checker = window.confirm(
			'Are you sure you want to collapse the finance data?',
		)
		if (checker) {
			const update: any = await fixBankFeesAdvertiser(
				login.user,
				props.setErrorMessage,
			)
			// if (update.successful) {
			// 	props.setIsSuccessPromptOpened(true)
			// }
		}
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}
	const columns: GridColDef[] = [
		// {
		// 	field: 'advertiser',
		// 	headerName: 'Advertiser',
		// 	width: rowWidth,
		// 	type: 'singleSelect',
		// 	renderHeader: () => {
		// 		return (
		// 			<HeaderFilterComponent
		// 				label={'Advertiser'}
		// 				width={rowWidth * 1.2}
		// 				openFilter={openFilter}
		// 				setOpenFilter={setOpenFilter}
		// 				field={'advertiser'}
		// 				customFilter={customFilter}
		// 				currentFilters={currentFilters}
		// 				setCurrentFilters={setCurrentFilters}
		// 				setFilterLabel={setFilterLabel}
		// 				optionsFilters={optionsFilters}
		// 			/>
		// 		)
		// 	},
		// 	renderCell: (row) => {
		// 		return (
		// 			<Typography sx={{ fontSize: 14, fontWeight: '600' }}>
		// 				{row.value}
		// 			</Typography>
		// 		)
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		{
			field: 'branch',
			headerName: 'Legal Entity',
			width: rowWidth * 0.8,
			align: 'center',
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Legal Entity'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'branch'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'account_manager',
			headerName: 'Account Manager',
			width: rowWidth * 1.3,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Account Manager'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'account_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue_base',
			headerName: 'Revenue from Personal',
			headerAlign: 'center',
			width: rowWidth * 1.2,
			type: 'number',
			align: 'center',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return value ? (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				) : (
					<>-</>
				)
			},
			cellClassName: (params) => {
				if (
					params &&
					params.row &&
					compareRevenuesByLayers(params.row.revenue_base, params.row.revenue)
				) {
					return 'warning is-not-editable'
				}
				return 'is-not-editable'
			},
		},
		{
			field: 'revenue',
			headerName: 'Revenue',
			headerAlign: 'center',
			width: rowWidth,
			type: 'number',
			align: 'center',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (
					params &&
					params.row &&
					compareRevenuesByLayers(params.row.revenue_base, params.row.revenue)
				) {
					return 'warning is-not-editable'
				}
				return 'is-not-editable'
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				if (value) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'profit',
			headerName: 'Profit',
			headerAlign: 'center',
			width: rowWidth * 1.2,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				if (value) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					if (params && params.row && params.row.profit < 0) {
						return 'finance-final-cell no-profit is-not-editable'
					}
					return 'finance-final-cell is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},
		{
			field: 'margin_value',
			headerName: 'Margin',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				if (!isNaN(value)) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}{' '}
							%
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue_with_vat',
			headerName: 'Amount inc. VAT',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_number_advertiser',
			headerName: 'Invoice Number',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_number_advertiser_added_time',
			headerName: 'Overdue Days',
			width: rowWidth * 0.9,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			valueGetter: (row) => {
				// console.log(row)
				const value = row.row.invoice_number_advertiser_added_time
				let currentDate
				if (row.row.payment_advertiser) {
					currentDate = moment(row.row.payment_advertiser).startOf('day')
				} else {
					currentDate = moment().startOf('day')
				}
				const valueDate = moment(value).startOf('day')
				const difference_days = currentDate.diff(valueDate, 'days')
				// const outOfPaymentLimit = row.row.payment_limit < difference_days
				return difference_days || ''
			},
			renderCell: (row) => {
				const difference_days = row.value
				const outOfPaymentLimit = row.row.payment_limit < difference_days
				return (
					<Typography
						sx={{
							fontSize: 14,
							color: outOfPaymentLimit ? 'red' : 'inherit',
						}}
					>
						{row.value ? difference_days : ''}
					</Typography>
				)
			},
			sortComparator: (a, b) => {
				if (!a) {
					return 0
				}
				if (a > b) {
					return 1
				} else {
					return -1
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'payment_advertiser',
			headerName: 'Payment Date',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			type: 'date',
			disableColumnMenu: true,
			valueGetter: ({ value }) => new Date(value),
			renderCell: ({ value }) => {
				if (value) {
					const date = formatFinancePaymentDate(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'received_amount_advertiser_converted',
			headerName: 'Received Amount',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			// editable: true,
			type: 'number',
			disableColumnMenu: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value || 0).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'bank_fees_advertiser_converted',
			headerName: 'Bank Fees',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			// editable: true,
			type: 'number',
			disableColumnMenu: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value && value !== 0) {
					return <></>
				}
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'payment_method_advertiser',
			headerName: 'Payment Method',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			type: 'singleSelect',
			// editable: true,
			valueOptions: settings.settings.paymentMethod,
			disableColumnMenu: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'exchange_value',
			headerName: 'Exchange Rate',
			width: rowWidth * 0.9,
			align: 'left',
			headerAlign: 'left',
			editable: false,
			type: 'number',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				if (value) {
					return (
						<Typography sx={{ fontSize: 14 }}>
							{Number(value).toFixed(4)}
						</Typography>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
	]

	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) =>
		row.hierarchy
	//here we define the column that is grouping.
	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Advertiser',
		width: rowWidth * 1.2,
		// cellClassName: (params: any) =>
		// 	params.row.hierarchy && params.row.hierarchy.length > 1
		// 		? 'finance-campaign-title'
		// 		: 'finance-campaign-title',
		renderCell: (params) => {
			if (params.rowNode.type === 'group') {
				return <CustomGridTreeDataGroupingCell {...params} />
			} else {
				if (params.row.hierarchy && params.row.hierarchy.length > 1) {
					return <span></span>
				}
				return <span>{params.row.advertiser}</span>
			}
		},
	}

	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		if (compareRevenuesByLayers(row.revenue_base, row.revenue)) {
			return 'revenue-missmatch'
		}
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		return ''
	}
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false }

		const failed = await uploadBalanceIn(
			newRow,
			login.user.email,
			props.setErrorMessage,
		)

		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
			setPinnedBottomRow(calculatePinnedTotalRow(notUpdatedData))
		}
		return updatedRow
	}
	const handleDoubleCellClick = React.useCallback((params: any, event: any) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params: any, event: any) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params: any, event: any) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])

	const downloadOptions = useMemo(
		() => (
			<div>
				<MenuItem key={'menuption_data'} onClick={handleDownloadFinance}>
					Current Data Report
				</MenuItem>
			</div>
		),
		[handleDownloadFinance],
	)

	const customTableButtons = useMemo(
		() => [
			{
				component: (
					<SimpleActionsButton
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							height: 20,
							display: 'flex',
						}}
						key='extra-options'
						options={
							<div>
								<MenuItem
									key={'update-exchange-button'}
									onClick={handleUpdateExchangeFinance}
								>
									(Dev ONLY) Update exchange of invoices
								</MenuItem>
								<MenuItem key={'collapse_data'} onClick={triggerCollapse}>
									(Dev ONLY) collapse data
								</MenuItem>
								<MenuItem
									key={'fixbankfeesadvertiser'}
									onClick={triggerBankFeesFix}
								>
									(Dev ONLY) Fix bank fees advertisers
								</MenuItem>
							</div>
						}
						label={'Extra'}
					></SimpleActionsButton>
				),
			},
		],
		[],
	)

	const renderCustomMessage = (hoveredRow: any, position: any) => {
		let isMatchingRevenue = true
		if (compareRevenuesByLayers(hoveredRow.revenue_base, hoveredRow.revenue)) {
			isMatchingRevenue = false
		}

		return (
			!isMatchingRevenue && (
				<div
					style={{
						position: 'absolute',
						top: position.y,
						left: position.x,
						width: 'auto',
						height: 'auto',
						backgroundColor: theme.colors.base.white,
						border: '1px solid rgb(196, 150, 80)',
						display: 'flex',
						borderRadius: '4px',
					}}
				>
					<span
						style={{
							color: 'rgb(196, 146, 80)',
							fontSize: '14px',
							backgroundColor: 'rgb(240, 225, 215)',
							padding: '8px 30px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{!isMatchingRevenue && (
							<span>
								The revenue of the invoice is not matching the revenue from
								Personal data.
							</span>
						)}
					</span>
				</div>
			)
		)
	}

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFinalConfirmationFilter}
						setFilterStatus={setFinanceFinalConfirmationFilter}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						treeData={true}
						getTreeDataPath={getTreeDataPath}
						groupingColDef={groupingColDef}
						// downloadTemplate={handleDownloadFinance}
						downloadButtonFolderOptions={downloadOptions}
						customSx={{
							'& .revenue-missmatch.MuiDataGrid-row:hover': {
								backgroundColor: 'rgb(243, 227, 217)',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& ::-webkit-scrollbar': {
								height: '7px',
								width: '10px',
							},
						}}
						getRowClassName={handleRowStyle}
						pinnedBottomRow={pinnedBottomRow}
						customTableButtons={customTableButtons}
						customMessage={renderCustomMessage}
						checkboxButtonValue={discrepancyFilter}
						setCheckboxButtonValue={setDiscrepancyFilter}
						checkboxButtonLabel={'Only Revenue Discrepancies'}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

const generateRowsByAdvertiser = (inputRows: any, advertiserList: any) => {
	// console.log(inputRows)
	// console.log(advertiserList)
	return inputRows
}
const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		advertiser: 'TOTAL',
		branch: '',
		account_manager: '',
		month: '',
		revenue_base: 0,
		revenue: 0,
		cost: 0,
		profit: 0,
		invoice_number_advertiser: '',
		invoice_number_advertiser_added_time: '',
		payment_advertiser: '',
		bank_fees_advertiser: 0,
		received_amount_advertiser_converted: 0,
		payment_method_advertiser: '',
		revenue_with_vat: 0,
		margin_value: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost && row.hierarchy.length < 2) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.revenue_base && row.hierarchy.length < 2) {
			pinnedRow.revenue_base =
				Number(pinnedRow.revenue_base) + Number(row.revenue_base)
		}
		if (row.revenue && row.hierarchy.length < 2) {
			pinnedRow.revenue = Number(pinnedRow.revenue) + Number(row.revenue)
		}
		if (row.bank_fees_advertiser_converted && row.hierarchy.length < 2) {
			pinnedRow.bank_fees_advertiser =
				Number(pinnedRow.bank_fees_advertiser) +
				Number(row.bank_fees_advertiser_converted)
		}
		if (row.received_amount_advertiser_converted && row.hierarchy.length < 2) {
			pinnedRow.received_amount_advertiser_converted =
				Number(pinnedRow.received_amount_advertiser_converted) +
				Number(row.received_amount_advertiser_converted)
		}
		if (row.revenue_with_vat && row.hierarchy.length < 2) {
			pinnedRow.revenue_with_vat =
				Number(pinnedRow.revenue_with_vat) + Number(row.revenue_with_vat)
		}
	}
	pinnedRow.revenue_base = Number(pinnedRow.revenue_base).toFixed(2) as any
	pinnedRow.revenue = Number(pinnedRow.revenue).toFixed(2) as any
	pinnedRow.bank_fees_advertiser = Number(
		pinnedRow.bank_fees_advertiser,
	).toFixed(2) as any
	pinnedRow.received_amount_advertiser_converted = Number(
		pinnedRow.received_amount_advertiser_converted,
	).toFixed(2) as any
	pinnedRow.revenue_with_vat = Number(pinnedRow.revenue_with_vat).toFixed(
		2,
	) as any
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	pinnedRow.profit =
		Number(pinnedRow.revenue) -
		Number(pinnedRow.cost) -
		Number(pinnedRow.bank_fees_advertiser)
	pinnedRow.margin_value =
		(Number(pinnedRow.profit) / Number(pinnedRow.revenue)) * 100
	pinnedRow.profit = Number(pinnedRow.profit).toFixed(2) as any
	return [pinnedRow]
}

// const createHierarchy = (
// 	array: any,
// 	key: string = 'advertiser',
// 	key2: string | undefined = undefined,
// ) => {
// 	return array
// }

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
	const theme = useTheme()
	const { id, field, rowNode } = props
	const apiRef = useGridApiContext()
	const [expanded, setExpanded] = useState(false)
	const filteredDescendantCountLookup = useGridSelector(
		apiRef,
		gridFilteredDescendantCountLookupSelector,
	)
	const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

	const handleClick: ButtonProps['onClick'] = (event) => {
		if (rowNode.type !== 'group') {
			return
		}
		apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
		apiRef.current.setCellFocus(id, field)
		event.stopPropagation()
	}
	useEffect(() => {
		const currentRowStatus = apiRef.current.getRowNode(id)
		setExpanded((currentRowStatus as any).childrenExpanded)
	}, [apiRef.current.getRowNode(id)])

	return (
		<div>
			<div>
				{filteredDescendantCount > 0 ? (
					<button
						onClick={handleClick}
						tabIndex={-1}
						style={{
							border: 'none',
							display: 'flex',
							alignItems: 'flex-start',
							marginLeft: '-12px',
							cursor: 'pointer',
						}}
					>
						{expanded ? (
							<KeyboardArrowDownIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						) : (
							<KeyboardArrowRightIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						)}
						<span
							style={{
								fontWeight: '600',
								fontSize: '14px',
								display: 'flex',
								justifyContent: 'flex-start',
								textAlign: 'start',
								marginLeft: '2px',
								color: theme.colors.text.titles,
							}}
						>
							{props.row.advertiser}
						</span>
					</button>
				) : (
					<span />
				)}
			</div>
		</div>
	)
}

export default BalanceInPage

const recalculateMainAfterFilter = (arr: any[]) => {
	const hierarchyIndexesMap: any = {}
	const mainWithHierarchy: any = []
	for (const [index, element] of arr.entries()) {
		if (element.hierarchy.length === 1) {
			hierarchyIndexesMap[element.hierarchy[0]] = index
		} else {
			if (
				element.hierarchy.length > 1 &&
				typeof hierarchyIndexesMap[element.hierarchy[0]] === 'number'
			) {
				const mainRowIndex = hierarchyIndexesMap[element.hierarchy[0]]
				//if the row is a main but has some other rows inside we need to reset the main row values once.
				if (!mainWithHierarchy.includes(element.hierarchy[0])) {
					arr[mainRowIndex].revenue = 0
					// arr[mainRowIndex].cost = 0
					arr[mainRowIndex].approved_conversions = 0
					arr[mainRowIndex].deduction_amount = 0
					arr[mainRowIndex].final_amount = 0
					mainWithHierarchy.push(element.hierarchy[0])
				}
				if (arr[mainRowIndex].is_creator_row) {
					arr[mainRowIndex].revenue = Number(element.revenue)
				} else {
					arr[mainRowIndex].revenue += Number(element.revenue)
				}
				// arr[mainRowIndex].cost += Number(element.cost)
				arr[mainRowIndex].approved_conversions += Number(
					element.approved_conversions,
				)
				arr[mainRowIndex].deduction_amount += Number(element.deduction_amount)
				arr[mainRowIndex].final_amount += Number(element.final_amount)
			}
		}
	}
	return arr
}
