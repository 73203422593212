export const fetchInChunks = async (
	fetchFunc: any,
	chunks = 50,
	pathname: string,
	downloadHandler: any,
) => {
	console.time('fetching ' + pathname)
	downloadHandler(true)
	let i = 0
	let result
	do {
		result = await fetchFunc(chunks, chunks * i, i)
		i++
		if (i === 1) {
			downloadHandler(false)
		}
		console.timeEnd('fetching ' + pathname)
		// console.log(result)
	} while (
		result &&
		result.length > 0 &&
		window.location.pathname.endsWith(pathname)
	)
}

export const fetchInChunksUntilEnd = async (fetchFunc: any, chunks = 50) => {
	let i = 0
	let result
	do {
		result = await fetchFunc(chunks, chunks * i, i)
		i++
	} while (result && result.length > 0)
}
