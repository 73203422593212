import { CampaignInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface CampaignReducerInterface {
	campaign: CampaignInterface | any
}

const initialState: CampaignReducerInterface = {
	campaign: [],
}

const reducer = (
	state: CampaignReducerInterface = initialState,
	action: Action,
): CampaignReducerInterface => {
	switch (action.type) {
		case ActionType.GET_CAMPAIGN: {
			const alreadyIn = state.campaign
			let updated = []
			//we check the index because if it's 0 it means we are pulling the data again so we force a clean up just in case.
			if (action.index === 0) {
				updated = [...action.payload]
			} else {
				updated = [...alreadyIn, ...action.payload]
			}
			return {
				...state,
				campaign: updated,
			}
		}
		case ActionType.INSERT_CAMPAIGN: {
			state.campaign.unshift(action.payload)
			return {
				campaign: state.campaign,
			}
		}
		case ActionType.DELETE_CAMPAIGN: {
			const arr = state.campaign.filter(
				(campaign: any) => campaign.uuid !== action.payload.uuid,
			)
			return {
				campaign: arr,
			}
		}
		case ActionType.LOGOUT: {
			return {
				...state,
				campaign: [],
			}
		}
		default: {
			return state
		}
	}
}

export default reducer
