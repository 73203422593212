import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
	ButtonProps,
	MenuItem,
	ThemeProvider,
	Typography,
	useTheme,
} from '@mui/material'
import {
	DataGridProProps,
	GridColDef,
	gridFilteredDescendantCountLookupSelector,
	GridRenderCellParams,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	createHierarchy,
	filterStatusByProperty,
	formatDateToMonth,
	getApplicableRules,
	getMonth,
	getMonthAbbreviation,
	getYear,
	handleFinanceStatusColor,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import './finance.css'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import saveAs from 'file-saver'
import moment from 'moment'
import Guidelines from './Guidelines'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

//In finance we have 4 pages:
// - Personal: Where the user is able to upload his excel
// - Global: Where the user can see all the data of the company
// - Supply: Where the user can see the data ordered by publisher
// - Demand: Where the user can see the data ordered by advertiser
//In the global page we render the rows based on the campaign name. Same campaign will render together as a "grouped" row.
//the user will be able to "open" the grouped rows in order to see all of them.
const GlobalTable = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, users, settings } = useTypedSelector((state) => state)

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
	}

	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const { getFinanceData, downloadFinance } = useFinanceActions()
	const sessionKey = 'filters-finance-global'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [fileDownload, setFileDownload] = useState()
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 15
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(filteredFinance),
	)
	const [openFilter, setOpenFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					campaign: [],
					platform: [],
					publisher_manager: [],
					account_manager: [],
					advertiser: [],
					publisher: [],
					pid: [],
			  },
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		campaign: [],
		platform: [],
		publisher_manager: [],
		account_manager: [],
		advertiser: [],
		publisher: [],
		pid: [],
	})

	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const previousMonth = moment().subtract(1, 'months').format('MMMM').toString()
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray?.length > 0
			? sessionFilters.monthFilterArray
			: [previousMonth],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray
			? sessionFilters.yearFilterArray
			: ['This Year'],
	)
	const [alreadyOpened, setAlreadyOpened] = useState<any>([])
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async (signal: AbortSignal) => {
		dispatch(emptyFinance())
		let i = 0
		let result
		const year = yearFilterArray
		const month = monthFilterArray

		do {
			if (signal.aborted) return
			result = await getFinanceData(
				'global',
				props.errorMessage,
				login.user.email,
				1000,
				i,
				undefined,
				{ year: year, month },
			)
			i++
			//we need to check the pathname in order to stop fetching data if we move to a different route
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('global')
		)

		// return () => source.cancel('Component unmounting or refetch needed'); // Function to cancel the request
	}
	useEffect(() => {
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(
					sessionFilters.monthFilterArray.length > 0
						? sessionFilters.monthFilterArray
						: [previousMonth],
				)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(
					sessionFilters.yearFilterArray.length > 0
						? sessionFilters.yearFilterArray
						: ['This Year'],
				)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				financeFilterStatus,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter, monthFilterArray, yearFilterArray, financeFilterStatus])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		fetchData(signal)

		return () => controller.abort() // Cleanup previous requests
	}, [monthFilterArray, yearFilterArray])

	useEffect(() => {
		//we do this check because if we move from one page to another, even if finance.finance has data only for 0.1ms then the
		//gridtable will fail trying to find hierarchy.
		if (finance.finance.length > 0 && finance.finance[0].hierarchy) {
			setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
		}
	}, [finance.finance])

	//same filter. The only difference is that in the end we trigger the function to generate the rows based on the campaign name
	//aka generate rows with hierarchy.
	const possibleFinanceFilter = useMemo(
		() => ['All', 'Yes', 'No', 'On Hold', 'NA'],
		[],
	)
	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, [
				...columns,
				{ field: 'campaign' },
			])
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'yes'),
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'no'),
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'on hold'),
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = searched.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'na'),
				)
			}
		} else {
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'yes'),
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'no'),
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'on hold'),
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = filteredFinance.filter((el: any, index: number, arr: any) =>
					filterStatusByProperty(el, index, arr, 'status', 'na'),
				)
			}
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		let result = []
		for (const key in customFilter) {
			if (customFilter[key].length > 0) {
				anyfilters = true
				result = filtered.filter((el: any, index: number, arr: any) => {
					// let wrongKey = false
					// let foundByKey = false
					const selectedValueInFilter = customFilter[key].map((el: string) =>
						el.toLowerCase(),
					)

					for (const valueToCheck of selectedValueInFilter) {
						const checker = filterStatusByProperty(
							el,
							index,
							arr,
							key,
							valueToCheck,
						)
						if (checker) {
							return true
						}
					}
					// console.log(checker)

					return false
				})
			}
		}
		if (anyfilters) {
			arr = result
		} else {
			arr = filtered
		}
		//this handles the year
		if (yearFilterArray.length > 0) {
			let filteredData = []
			for (const year of yearFilterArray) {
				const arrToAdd = arr.filter((item: any, index: number, arr: any) => {
					// console.log(year, yearFilterArray)
					return filterStatusByProperty(item, index, arr, 'year', year)
				})
				const recalculatedData = recalculateMainAfterFilter(arrToAdd)
				filteredData.push(...recalculatedData)
			}
			arr = filteredData
		}

		//this handles the months
		if (monthFilterArray.length > 0) {
			let filteredData = []
			for (const month of monthFilterArray) {
				const arrToAdd = arr.filter((item: any, index: number, arr: any) => {
					return filterStatusByProperty(item, index, arr, 'month', month)
				})
				// console.log(arrToAdd)
				const recalculatedData = recalculateMainAfterFilter(arrToAdd)
				filteredData.push(...recalculatedData)
			}
			arr = filteredData
		}
		//in here we make sure we don't have duplicated rows
		const objectsMap = new Map()
		arr.forEach((obj: any) => {
			if (!objectsMap.has(obj.uuid)) {
				objectsMap.set(obj.uuid, obj)
			}
		})
		const uniqueObjectsSet = new Set(objectsMap.values())
		arr = Array.from(uniqueObjectsSet)
		const hierarchyArr = createHierarchy(arr)
		const recalculated = recalculateMainAfterFilter(hierarchyArr)
		arr = generateRowsWithIds(recalculated)
		setTableRows(arr)
		setUnfiltered(arr)
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [financeFilterStatus, props.search, applyFilter, filteredFinance])

	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				campaign: new Set(
					tableRows
						.map((element: any) => element.campaign)
						.filter((el) => el && el.length > 0),
				),
				platform: new Set(
					tableRows
						.map((element: any) => element.platform)
						.filter((el) => el && el.length > 0),
				),
				publisher_manager: new Set(
					tableRows
						.map((element: any) => element.publisher_manager)
						.filter((el) => el && el.length > 0),
				),
				account_manager: new Set(
					tableRows
						.map((element: any) => element.account_manager)
						.filter((el) => el && el.length > 0),
				),
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el) => el && el.length > 0),
				),
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el) => el && el.length > 0),
				),
				pid: new Set(
					tableRows
						.map((element: any) => element.pid)
						.filter((el) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])

	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])

	const columns: GridColDef[] = [
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth * 0.9,
			align: 'center',
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Platform'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'platform'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'advertiser',
			headerName: 'Advertiser',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Advertiser'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'advertiser'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'account_manager',
			headerName: 'AM',
			width: rowWidth * 0.9,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'AM'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'account_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'publisher',
			headerName: 'Publisher',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Publisher'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'pid',
			headerName: 'Pid',
			width: rowWidth * 1,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Pid'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'pid'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'publisher_manager',
			headerName: 'PM',
			width: rowWidth * 0.9,
			disableColumnMenu: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'PM'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'revenue',
			headerName: 'Revenue',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				if (row.row.is_creator_row && row.row.hierarchy.length > 1) {
					return <>0</>
				}
				return <span>{Number(row.value).toFixed(2)}</span>
			},
		},
		{
			field: 'approved_revenue_events',
			headerName: 'Approved Revenue Events',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				if (row.row.is_creator_row && row.row.hierarchy.length > 1) {
					return <>0</>
				}
				return <span>{Number(row.value).toFixed(2)}</span>
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'total_conversions',
			headerName: 'Total Conversion',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'deducted_conversions',
			headerName: 'Deducted Conversion',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'approved_conversions',
			headerName: 'Approved Conversion',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.row.total_conversions - row.row.deducted_conversions
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'deduction_amount',
			headerName: 'Deduction',
			width: rowWidth * 0.7,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'final_amount',
			headerName: 'Cost After Deduction',
			headerAlign: 'center',
			width: rowWidth * 0.7,
			type: 'number',
			align: 'center',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.6,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			type: 'singleSelect',
			valueOptions: ['Yes', 'No', 'On Hold'],
			disableColumnMenu: true,
			renderCell: (row) => {
				//here, the logic is that if status of ANY campaign is On Hold, then the entire group should be on hold.
				//if it's Yes or No then the status is Yes.

				if (row.value) {
					return (
						<div
							style={{
								borderRadius: '20px',
								color: handleFinanceStatusColor(
									row.value === 'On Hold' ? 'on hold yellow' : row.value,
									theme,
								),
								width: '70px',
								height: '30px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								textTransform: 'uppercase',
								fontWeight: theme.font.weight.bolder,
								fontSize: theme.font.size.caption,
							}}
						>
							{row.value}
						</div>
					)
				} else if (financeFilterStatus !== 0) {
					return (
						<div
							style={{
								borderRadius: '20px',
								color: handleFinanceStatusColor(
									possibleFinanceFilter[financeFilterStatus] === 'On Hold'
										? 'on hold yellow'
										: possibleFinanceFilter[financeFilterStatus],
									theme,
								),
								width: '70px',
								height: '30px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								textTransform: 'uppercase',
								fontWeight: theme.font.weight.bolder,
								fontSize: theme.font.size.caption,
							}}
						>
							{possibleFinanceFilter[financeFilterStatus]}
						</div>
					)
				} else {
					return (
						<>
							{row.row.on_hold_status_values ? (
								<div
									style={{
										borderRadius: '20px',
										color: handleFinanceStatusColor('On Hold', theme),
										width: '70px',
										height: '30px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										textTransform: 'uppercase',
										fontWeight: theme.font.weight.bolder,
										fontSize: theme.font.size.caption,
									}}
								>
									On Hold
								</div>
							) : (
								<div
									style={{
										borderRadius: '20px',
										color: handleFinanceStatusColor('Yes', theme),
										width: '70px',
										height: '30px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										textTransform: 'uppercase',
										fontWeight: theme.font.weight.bolder,
										fontSize: theme.font.size.caption,
									}}
								>
									Yes
								</div>
							)}
						</>
					)
				}
			},
		},
		{
			field: 'profit',
			headerName: 'Profit',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			cellClassName: 'finance-final-cell',
			disableColumnMenu: true,
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
			valueGetter: (params) => {
				if (!params.row || !params.row.row_status) {
					return 0
				}
				return params.row.row_status.toLowerCase() === 'no'
					? 0
					: params.row.revenue - params.row.final_amount
			},
			renderCell: (params) => {
				if (params.row.is_creator_row && params.row.hierarchy.length > 1) {
					return <>0</>
				}
				return <span>{Number(params.value).toFixed(2)}</span>
			},
		},
	]
	//saving the report for the global numbers. Generally used only for finance members.
	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const handleDownloadFinanceData = useCallback(() => {
		downloadFinance(
			tableRowsRef.current,
			'global-finance',
			setFileDownload,
			props.setErrorMessage,
		)
	}, [])
	const handleDownloadFinanceGeneral = useCallback(() => {
		const dataToDownload = []
		for (const row of tableRowsRef.current) {
			const rowToDownload: any = { ...row }
			rowToDownload.profit = rowToDownload.revenue - rowToDownload.final_amount
			dataToDownload.push(rowToDownload)
		}
		downloadFinance(
			dataToDownload,
			'global',
			setFileDownload,
			props.setErrorMessage,
		)
	}, [])
	const tableRowsRef = useRef(tableRows)

	useEffect(() => {
		tableRowsRef.current = tableRows
	}, [tableRows])

	const downloadOptions = useMemo(
		() => (
			<div>
				{permissions.edit && (
					<MenuItem
						key={'menuption_finance_data'}
						onClick={handleDownloadFinanceData}
					>
						Finance Report
					</MenuItem>
				)}
				<MenuItem
					key={'menuption_finance_general'}
					onClick={handleDownloadFinanceGeneral}
				>
					Current Data Report
				</MenuItem>
			</div>
		),
		[handleDownloadFinanceData, handleDownloadFinanceGeneral],
	)
	//we use this to apply the path that datagrid must follow in order to generate the grouped rows.
	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => {
		return row.hierarchy
	}
	//here we define the column that is grouping.
	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Campaign',
		width: rowWidth * 1.2,
		// cellClassName: (params: any) =>
		// 	params.row.hierarchy && params.row.hierarchy.length > 1
		// 		? ''
		// 		: 'finance-campaign-title',
		renderCell: (params) => {
			if (params.rowNode.type === 'group') {
				return (
					<CustomGridTreeDataGroupingCell
						{...params}
						alreadyOpened={alreadyOpened}
						setAlreadyOpened={setAlreadyOpened}
						search={props.search}
						tableRows={tableRows}
					/>
				)
			} else {
				if (params.row.hierarchy.length > 1) {
					return <span></span>
				}
				return <span>{params.row.campaign}</span>
			}
		},
	}

	const guidelines = [
		{
			title: 'Campaign',
			explanation:
				'Name of the Campaign. \nExample: William Hill - iOS - UK - CPA.',
		},
		{
			title: 'Platform',
			explanation:
				'You can choose between the following: \nAF (Affise), Appsflyer, Adjust, Kochava, Branch, Singular, Tenjin, Appmetrica, Impact, MB (Media buying).',
		},
		{
			title: 'Advertiser',
			explanation:
				'Name of your advertiser written in the same way that is written in Varys - Demand - Advertiser.',
		},
		{ title: 'AM', explanation: "Name of Campaign's AM. \nExample: Carlos." },
		{
			title: 'Publisher',
			explanation:
				'Publisher name. It is not necessary to add the publisher name, it will come automatically from the system when you write the pid.',
		},
		{ title: 'Pid', explanation: 'PID name. \nExample: pubmint_int.' },
		{
			title: 'PM',
			explanation: 'Name of PM of that publisher. \nExample: Dan.',
		},
		{
			title: 'Revenue',
			explanation:
				'Payment from the advertiser for approved conversions that the pid/publisher generated. (Number of Approved Conversions made by this Network * Payout from our Advertiser).',
		},
		{
			title: 'Cost',
			explanation:
				'(Before deduction) Amount payable to the publisher before deductions for the total conversions.',
		},
		{
			title: 'Conversion',
			explanation:
				'(After deduction) Approved number of conversions per publisher per PID after deductions.',
		},
		{
			title: 'Deduction',
			explanation:
				'How much money (in USD) we will deduct from the payout amount.',
		},
		{
			title: 'Cost after deduction',
			explanation:
				'What we will pay to the publisher for that offer on that pid. (Cost-Deduction).',
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Status',
			explanation: `3 different statuses: \n   * On Hold: you add the numbers but they are yet to be confirmed by the advertiser. \n   * Yes: The line is approved and we will pay our source that specific amount indicated in the total amount approved. \n   * No: unconfirmed or fully deducted.`,
		},
		{
			title: 'Profit',
			explanation: 'Revenue - Cost after deduction.',
		},
	]
	const timelines = [
		{
			title: 'Add Data - Personal page',
			explanation: 'From the 1st to the 10th',
		},
		{ title: 'Approve cost - Personal page', explanation: 'Before the 25th' },
		{
			title: 'Confirm revenue - Demand page',
			explanation: 'Before the 15th - up to 20th for agencies',
		},
		{
			title: 'Send Numbers Approval - Supply page',
			explanation: 'Before the 15th to the last day of the month',
		},
	]
	const timelinesTable = [
		{
			platform: 'Apple Search Ads',
			poc: 'Yoav',
			personAdding: 'Barak',
			day: '10th',
		},
		{ platform: 'Appnext', poc: 'Dan', personAdding: 'Dan', day: '10th' },
		{ platform: 'Bigo Ads', poc: 'Yoav', personAdding: 'Yoav', day: '10th' },
		{
			platform: 'Demand Scale',
			poc: 'Nissan',
			personAdding: 'Nissan',
			day: '10th',
		},
		{ platform: 'Kwai', poc: 'Yoav', personAdding: 'Yoav', day: '10th' },
		{ platform: 'Mintegral', poc: 'Alon', personAdding: 'Alon', day: '10th' },
		{ platform: 'Reddit', poc: 'Barak', personAdding: 'Barak', day: '10th' },
		{ platform: 'Two Feeds', poc: 'Gal', personAdding: 'Gal', day: '5th' },
		{ platform: 'Unity Ads', poc: 'Yoav', personAdding: 'Yoav', day: '10th' },
		{ platform: 'Xiaomi', poc: 'Barak', personAdding: 'Barak', day: '13th' },
		{ platform: 'Chartboost', poc: 'Yoav', personAdding: 'Yoav', day: '10th' },
	]
	const timelinesTableMap = {
		platform: 'Platform',
		poc: 'POC',
		personAdding: 'Person adding the numbers',
		day: 'Day of the month the numbers will be added',
	}

	return (
		<ThemeProvider theme={theme}>
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '110%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<PageWrapper>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					{infoOpened && (
						<Guidelines
							data={guidelines}
							closeFunc={() => setInfoOpened(false)}
							timelines={timelines}
							timelinesTable={timelinesTable}
							tableTitle={'MB Platforms - Media Buying'}
							timelinesTableMap={timelinesTableMap}
						></Guidelines>
					)}
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						treeData={true}
						getTreeDataPath={getTreeDataPath}
						groupingColDef={groupingColDef}
						// downloadTemplate={handleDownloadFinance}
						downloadButtonFolderOptions={downloadOptions}
						searchWithTimer={true}
						customSx={{
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-columnHeaders': {
								borderBottom: `1px solid ${theme.colors.base.grey3} !important`,
								borderTop: `1px solid ${theme.colors.base.grey3} !important`,
								maxHeight: '60px !important',
								height: '60px !important',
							},
							'& .MuiDataGrid-columnHeader': {
								// maxHeight: '100px !important',
								height: '60px !important',
							},
						}}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}
interface ExtraGridRenderProps {
	search: string
	tableRows: any
	alreadyOpened: string[]
	setAlreadyOpened: Dispatch<SetStateAction<string[]>>
}
//this is the entire component that will render instead of the default grouping cell.
export function CustomGridTreeDataGroupingCell(
	props: GridRenderCellParams & ExtraGridRenderProps,
) {
	const theme = useTheme()
	const { id, field, rowNode, value, alreadyOpened, setAlreadyOpened } = props
	const apiRef = useGridApiContext()
	const [expanded, setExpanded] = useState(false)
	const filteredDescendantCountLookup = useGridSelector(
		apiRef,
		gridFilteredDescendantCountLookupSelector,
	)
	const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

	const handleClick: ButtonProps['onClick'] = (event) => {
		if (rowNode.type !== 'group') {
			return
		}
		if (rowNode.childrenExpanded === false) {
			setAlreadyOpened((prev: string[]) =>
				prev.length > 0 ? [...prev, value] : [value],
			)
			apiRef.current.setRowChildrenExpansion(id, true)
		} else {
			setAlreadyOpened(alreadyOpened.filter((el) => el !== value))
			apiRef.current.setRowChildrenExpansion(id, false)
		}
		apiRef.current.setCellFocus(id, field)
		event.stopPropagation()
	}
	useEffect(() => {
		const currentRowStatus = apiRef.current.getRowNode(id)
		if (currentRowStatus) {
			setExpanded((currentRowStatus as any).childrenExpanded)
		}
	}, [apiRef.current.getRowNode(id)])

	useEffect(() => {
		if (alreadyOpened.includes(value)) {
			try {
				apiRef.current.setRowChildrenExpansion(id, true)
				apiRef.current.setCellFocus(id, field)
				setExpanded(true)
			} catch (error) {
				console.error(error)
			}
		}
	}, [alreadyOpened, props.tableRows])

	return (
		<div>
			<div>
				{filteredDescendantCount > 0 ? (
					<button
						onClick={handleClick}
						tabIndex={-1}
						style={{
							border: 'none',
							display: 'flex',
							alignItems: 'flex-start',
							marginLeft: '-12px',
							cursor: 'pointer',
						}}
					>
						{expanded ? (
							<KeyboardArrowDownIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						) : (
							<KeyboardArrowRightIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						)}
						<span
							style={{
								fontWeight: '600',
								fontSize: '14px',
								display: 'flex',
								justifyContent: 'flex-start',
								textAlign: 'start',
								marginLeft: '2px',
								color: theme.colors.text.titles,
							}}
						>
							{props.row.campaign}
						</span>
					</button>
				) : (
					<span />
				)}
			</div>
		</div>
	)
}

export default GlobalTable

const recalculateMainAfterFilter = (arr: any[]) => {
	const hierarchyIndexesMap: any = {}
	const mainWithHierarchy: any = []
	for (const [index, element] of arr.entries()) {
		if (element.hierarchy.length === 1) {
			hierarchyIndexesMap[element.hierarchy[0]] = index
		} else {
			if (
				element.hierarchy.length > 1 &&
				typeof hierarchyIndexesMap[element.hierarchy[0]] === 'number'
			) {
				const mainRowIndex = hierarchyIndexesMap[element.hierarchy[0]]
				//if the row is a main but has some other rows inside we need to reset the main row values once.
				if (!mainWithHierarchy.includes(element.hierarchy[0])) {
					arr[mainRowIndex].revenue = 0
					arr[mainRowIndex].cost = 0
					arr[mainRowIndex].total_conversions = 0
					arr[mainRowIndex].deducted_conversions = 0
					arr[mainRowIndex].deduction_amount = 0
					arr[mainRowIndex].final_amount = 0
					mainWithHierarchy.push(element.hierarchy[0])
				}
				if (arr[mainRowIndex].is_creator_row) {
					arr[mainRowIndex].revenue = Number(element.revenue)
				} else {
					arr[mainRowIndex].revenue += Number(element.revenue)
				}
				arr[mainRowIndex].cost += Number(element.cost)
				arr[mainRowIndex].total_conversions += Number(element.total_conversions)
				arr[mainRowIndex].deducted_conversions += Number(
					element.deducted_conversions,
				)
				arr[mainRowIndex].deduction_amount += Number(element.deduction_amount)
				arr[mainRowIndex].final_amount += Number(element.final_amount)
			}
		}
	}
	return arr
}
