import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ThemeProvider, useTheme } from '@mui/material'
import { PageWrapper } from '../components/reusableComponents'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import EditPageHeader from '../components/EditPageHeader'
import CustomDialog from '../components/CustomDialog'
import CustomModal from '../components/CustomModal'
import CustomFileUploader from '../components/CustomFileUploader'
import { CompanyInfoComponent } from './AdvertiserCompanyInfoComponent'
import { TrafficBudgetComponent } from './TrafficBudgetComponent'
import { ContactsComponent } from '../components/contacts/ContactComponent'
import { ContactInterface } from '../../models/contact.interface'
import { useActions, useAdvertiserActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import defaultIcon from '../../assets/default-icon.webp'
import { ACCEPTED_FILES } from '../../config'
import { editPageTopPanelStyle } from '../../utils/helpers/commonStyles'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { v4 as uuidv4 } from 'uuid'
import {
	decryptContacts,
	formatAppIdPairs,
	isValidImageType,
} from '../../utils/helpers/helperFuncs'
import CustomContactsComponent from '../components/contacts/CustomContactsComponent'
import _ from 'lodash'
import { AppIdLinkPair } from '../../models/model.interface'

const AdvertiserEditPage = ({
	companyData,
	setLoading,
	setErrorMessage,
	setIsErrorPromptOpened,
	setIsSuccessPromptOpened,
	setPopUpVisible,
	onSave,
}: any) => {
	const theme = useTheme()
	const [tab, setTab] = useState(0)
	const [name, setName] = useState(companyData?.advertiser_name || '')
	const [legalName, setLegalName] = useState(companyData?.legal_name || '')
	const [address, setAddress] = useState(companyData?.address || '')
	const [website, setWebsite] = useState(companyData?.website || '')
	const [linkedin, setLinkedin] = useState(companyData?.linkedin || '')
	const [brief, setBrief] = useState(companyData?.brief || '')
	const [linkToIO, setLinkToIO] = useState(companyData?.link_to_io || '')
	const [contactsChanged, setContactsChanged] = useState(false)
	const [linkToCreative, setLinkToCreative] = useState(
		companyData?.link_to_creative || '',
	)
	const [parametersTemplate, setParametersTemplate] = useState(
		companyData?.parameters_template || '',
	)
	const [specialRequest, setSpecialRequest] = useState(
		companyData?.special_request || '',
	)
	const [geo, setGeo] = useState<string[]>(companyData?.geo || [])
	const [iconUrl, setIconUrl] = useState(
		companyData?.advertiser_icon || defaultIcon,
	)
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

	// New States for TrafficBudgetComponent
	const [trafficRestrictions, setTrafficRestrictions] = useState<string[]>(
		companyData?.traffic_restrictions
			? JSON.parse(companyData.traffic_restrictions)
			: [],
	)

	const [existingChannels, setExistingChannels] = useState<string[]>(
		companyData?.existing_channels
			? JSON.parse(companyData.existing_channels)
			: [],
	)
	const [dailyBudgetLimit, setDailyBudgetLimit] = useState<number>(
		companyData?.daily_budget_limit || 0,
	)
	const [monthlyBudgetLimit, setMonthlyBudgetLimit] = useState<number>(
		companyData?.monthly_budget_limit || 0,
	)
	const [totalBudgetLimit, setTotalBudgetLimit] = useState<number>(
		companyData?.total_budget_limit || 0,
	)
	const [advertiserType, setAdvertiserType] = useState(
		companyData?.advertiser_type || '',
	)
	const [communicationChannel, setCommunicationChannel] = useState(
		companyData?.communication_channel || [],
	)

	// State for Contacts
	const [contacts, setContacts] = useState<ContactInterface[]>(
		companyData?.contacts || [],
	)

	const [vertical, setVertical] = useState<string[]>(
		companyData?.vertical || [],
	)

	const [platforms, setPlatforms] = useState<string[] | null>(
		companyData?.platform || [],
	)

	const [fraudTool, setFraudTool] = useState<string[]>(
		companyData?.fraud_tool || [],
	)
	const [apps, setApps] = useState<string[]>(companyData?.apps || [])
	const [appsData, setAppsData] = useState<AppIdLinkPair[]>([
		{ app_id: '', link: '' },
		{ app_id: '', link: '' },
	])
	const [dataSource, setDataSource] = useState<string[]>(companyData?.data_source)

	const navigate = useNavigate()
	const params = useParams()

	const { uploadIcon, deleteIcon, updateCompanyData } = useActions()
	const { insertAdvertiserAction, updateAdvertiserAction } =
		useAdvertiserActions()
	const { login, settings, company } = useTypedSelector((state) => state)

	const [fieldValidations, setFieldValidations] = useState({
		name: false,
		legalName: false,
		branch: false,
		paymentTerms: false,
	})
	const companyAgencyAccess =
		company.companies.find((item: any) => {
			return item.id === companyData.company_id
		})?.agency_access || false

	const [agencyAccess, setAgencyAccess] = useState<boolean>(companyAgencyAccess)

	const permissions = {
		edit: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewFinancePersonal: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewFinanceMediaBuying: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewAllContacts: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		preview: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setDeleteModalOpen(true)
		}
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			const id = companyData.id
			deleteIcon('ADVERTISER', name, id)
			setDeleteModalOpen(false)
			setIconUrl(defaultIcon)
		}
	}

	const handleFileUpload = async (files: any) => {
		const id = companyData.id
		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}

			const uploadResult = await uploadIcon('ADVERTISER', file, name, id)
			setIconUrl(uploadResult.toString())
		}
	}
	const handleContactsChange = (e: any) => {
		const equal = _.isEqual(e, contacts)
		if (!equal) {
			setContactsChanged(true)
			setContacts(e)
		}
	}

	const handleRemoveContact = (contact: ContactInterface) => {
		setContacts((prev) => {
			const updatedContacts = prev.filter((c) => c.uuid !== contact.uuid)
			setContactsChanged(true)
			return updatedContacts
		})
	}

	useEffect(() => {
		const fetchAndDecryptContacts = async () => {
			if (companyData && companyData.contacts) {
				try {
					const decryptedContacts = await decryptContacts(companyData.contacts)

					setContacts(decryptedContacts)
				} catch (error) {
					setErrorMessage('Failed to decrypt contact information.')
				}
			}
		}
		fetchAndDecryptContacts()
	}, [companyData])

	// somewhere in AdvertiserEditPage:
	useEffect(() => {
		if (companyData?.apps) {
			const transformed: AppIdLinkPair[] = companyData.apps.map((item: any) => {
				if (typeof item === 'string') {
					return { app_id: item, link: '' }
				} else {
					return {
						app_id: item.app_id || '',
						link: item.link || '',
					}
				}
			})

			while (transformed.length < 2) {
				transformed.push({ app_id: '', link: '' })
			}

			setAppsData(transformed)
		}
	}, [companyData])

	const handleSubmit = async () => {
		const formattedApps = formatAppIdPairs(appsData)

		const updatedAdvertiserData = {
			id: companyData?.id,
			company_id: companyData?.company_id,
			advertiser_name: name,
			legal_name: legalName,
			address: address,
			geo: geo || [],
			website: website || '',
			linkedin: linkedin || '',
			brief: brief || '',
			special_request: specialRequest || '',
			parameters_template: parametersTemplate || '',
			advertiser_icon: iconUrl || '',
			traffic_restrictions: JSON.stringify(trafficRestrictions) || '',
			existing_channels: JSON.stringify(existingChannels) || '',
			daily_budget_limit: dailyBudgetLimit || 0,
			monthly_budget_limit: monthlyBudgetLimit || 0,
			total_budget_limit: totalBudgetLimit || 0,
			contacts: contactsChanged ? _.cloneDeep(contacts) : companyData?.contacts,
			sales_rep: companyData?.salesRep || '',
			status: companyData?.status ?? true,
			created_by: companyData?.created_by || '',
			createdAt: companyData?.createdat || new Date(),
			used_publisher: companyData?.used_publisher || [],
			apps: formattedApps,
			invoice_instructions_finance:
				companyData?.invoice_instructions_finance || '',
			branch: companyData?.branch || '',
			payment_terms: companyData?.payment_terms || '',
			pause_reason: companyData?.pause_reason || '',
			pause_explanation: companyData?.pause_explanation || '',
			vat: companyData?.vat || '0',
			communication_channel: companyData?.communication_channel || [],
			platform: companyData?.platform || [],
			fraud_tool: companyData?.fraud_tool || [],
			vertical: companyData?.vertical || [],
			link_to_creative: linkToCreative || '',
			link_to_io: linkToIO || '',
			team: companyData?.team || '',
			advertiser_type: companyData?.advertiser_type || '',
			//we will asign an owner if there's no owner for the system not to break
			email: companyData.email ? companyData.email : login.user.email,
			data_source: dataSource
		}

		try {
			setLoading(true)
			await updateAdvertiserAction(updatedAdvertiserData, {}, setErrorMessage)
			await updateCompanyData({
				id: companyData.company_id,
				agency_access: agencyAccess,
			})
			setIsSuccessPromptOpened(true)
			// navigate('/companySettings')
		} catch (error) {
			setIsErrorPromptOpened(true)
		} finally {
			setLoading(false)
		}
	}

	const closeDetailedView = () => {
		navigate('/companySettings')
	}

	const openPreview = () => {
		navigate(`/companySettings`)
	}

	const tabOptions = [
		{ label: 'Company Information', tabIndex: 0 },
		{ label: 'Traffic & Budget', tabIndex: 1 },
	]

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				onPreview={openPreview}
				onUpload={() => setIsUploadModalOpen(true)}
				tabValue={tab}
				handleChangeTab={handleChangeTab}
				hasPreview={!!(params.id && permissions.preview)}
				options={tabOptions}
				customStyles={editPageTopPanelStyle}
				arialabel='basic tabs'
			/>

			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={() => setIsUploadModalOpen(false)}
			>
				<CustomFileUploader
					onSetFiles={handleFileUpload}
					acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
				/>
			</CustomModal>

			<PageWrapper
				style={{
					marginTop: '2vh',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<CompanyInformationHeader
					name={name}
					iconUrl={iconUrl || defaultIcon}
					onClickIcon={handleIconClick}
					tooltipText={
						iconUrl === defaultIcon
							? 'No icon to delete'
							: 'Click to delete icon'
					}
					cursorStyle={iconUrl === defaultIcon ? 'default' : 'pointer'}
					style={{ marginLeft: '1rem', marginBottom: '1.5rem' }}
				/>

				<CustomDialog
					open={isDeleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onConfirm={handleDeleteIcon}
					imageUrl={iconUrl || defaultIcon}
					message='Click delete to remove the icon.'
				/>

				{tab === 0 && (
					<>
						<CompanyInfoComponent
							name={name}
							setName={setName}
							legalName={legalName}
							setLegalName={setLegalName}
							address={address}
							setAddress={setAddress}
							website={website}
							setWebsite={setWebsite}
							linkedin={linkedin}
							setLinkedin={setLinkedin}
							brief={brief}
							setBrief={setBrief}
							specialRequest={specialRequest}
							setSpecialRequest={setSpecialRequest}
							parametersTemplate={parametersTemplate}
							setParametersTemplate={setParametersTemplate}
							geo={geo}
							setGeo={setGeo}
							setPastOwner={companyData?.pastOwner}
							pastOwner={companyData?.pastOwner}
							setPastOwnerFinance={companyData?.pastOwnerFinance}
							pastOwnerFinance={companyData?.pastOwnerFinance}
							settings={settings}
							loginRole={login.user.role}
							loginUser={login.user.email}
							advertiserType={advertiserType}
							setAdvertiserType={setAdvertiserType}
							communicationChannel={communicationChannel}
							setCommunicationChannel={setCommunicationChannel}
							platforms={platforms}
							setPlatforms={setPlatforms}
							vertical={vertical}
							setVertical={setVertical}
							fraudTool={fraudTool}
							setFraudTool={setFraudTool}
							fieldValidations={fieldValidations}
							permissions={permissions}
							apps={apps}
							setApps={setApps}
							appsData={appsData}
							setAppsData={setAppsData}
							// linkToIO={linkToIO}
							// setLinkToIO={setLinkToIO}
							// linkToCreative={linkToCreative}
							// setLinkToCreative={setLinkToCreative}
							agencyAccess={agencyAccess}
							setAgencyAccess={setAgencyAccess}
							dataSource={dataSource}
							setDataSource={setDataSource}
						/>
						{permissions.viewAllContacts && (
							<CustomContactsComponent
								contacts={contacts}
								setContacts={handleContactsChange}
								canEdit={permissions.edit}
								roleOptions={settings?.settings?.contactRole || []}
								p360Active={false}
								mode={'edit'}
								onRemoveContact={handleRemoveContact}
							/>
						)}
					</>
				)}

				{tab === 1 && (
					<TrafficBudgetComponent
						loginRole={login.user.role}
						settings={settings}
						trafficRestrictions={trafficRestrictions}
						setTrafficRestrictions={setTrafficRestrictions}
						existingChannels={existingChannels}
						setExistingChannels={setExistingChannels}
						dailyBudgetLimit={dailyBudgetLimit}
						setDailyBudgetLimit={setDailyBudgetLimit}
						monthlyBudgetLimit={monthlyBudgetLimit}
						setMonthlyBudgetLimit={setMonthlyBudgetLimit}
						totalBudgetLimit={totalBudgetLimit}
						setTotalBudgetLimit={setTotalBudgetLimit}
						permissions={permissions}
					/>
				)}

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '2rem',
					}}
				>
					<UnborderedButton
						onClick={closeDetailedView}
						label='Cancel'
						style={{ marginRight: '2rem', color: theme.colors.text.titles }}
					/>
					{permissions.edit && (
						<SubmitButton onClick={handleSubmit}>{'Update'}</SubmitButton>
					)}
				</div>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default AdvertiserEditPage
