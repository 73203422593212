import React from 'react'
import { Menu, MenuItem } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'
import { useTheme } from '@mui/material/styles'

export interface CustomActionsMenuProps {
	anchorEl: HTMLElement | null
	isOpen: boolean
	handleClose: () => void
	menuItems: {
		label: string
		icon?: React.ReactNode
		onClick: () => void
		hoverColor?: string
	}[]
}

/**
 * Generic Actions Menu Component
 * @param {CustomActionsMenuProps} props
 * @returns React Component
 */
export const CustomActionsMenu: React.FC<CustomActionsMenuProps> = ({
	anchorEl,
	isOpen,
	handleClose,
	menuItems,
}) => {
	const theme = useTheme()

	return (
		<Menu
			anchorEl={anchorEl}
			open={isOpen}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			{menuItems.map(({ label, icon, onClick, hoverColor }, index) => (
				<MenuItem
					key={index}
					onClick={() => {
						onClick()
						handleClose()
					}}
					sx={{
						color: theme.colors.text.primary,
						background: 'transparent',
						'&:hover': {
							color: hoverColor || theme.colors.base.green300,
						},
					}}
				>
					{icon && (
						<span
							style={{
								marginRight: '8px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{icon}
						</span>
					)}
					{label}
				</MenuItem>
			))}
		</Menu>
	)
}
