import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HTTP_STATUS_CODES, endpoints } from '../../config'


export const getTasksAction = (setTasks: any) => {
    return async () => {
        try {
            const { data } = await AxiosCustom.get(endpoints.GET_TASKS, {
            })
            if (!data.success) {
                return []
            }
            setTasks(data.payload)
          
        } catch (err) {
            console.error(err)
        }
    }
}

export const executeTaskAction = (taskName: string, setStatus: any) => {
    return async () => {
        try {
            const { data } = await AxiosCustom.post(endpoints.EXECUTE_TASK, {taskName
            })
            if (data.payload.$metadata.httpStatusCode === 200) {
                setStatus(`Task ${taskName} was successfully executed: statusCode: ${data.payload.$metadata.httpStatusCode}`)
            } else {
                setStatus(`Task ${taskName} execution failed: statusCode: ${data.payload.$metadata.httpStatusCode}`)
            }
          
        } catch (err) {
            console.error(err)
        }
    }
}