import React, { useEffect, useState } from 'react'
import { DialogContent, Box, Typography, Button, useTheme } from '@mui/material'
import { ReactComponent as CheckmarkIcon } from '../../assets/svg/checkmark-icon.svg'
import { SubmitButton } from './Buttons'
import { TableSearchComponent } from './Search'

interface ShowMoreModalProps {
	showMore: any
	showMoreValues: any
	showMoreTitle: any
	handleClose: any
	searchBar: boolean
}

const ShowMoreModal: React.FC<ShowMoreModalProps> = ({
	showMore,
	showMoreValues,
	showMoreTitle,
	handleClose,
	searchBar,
}) => {
	const theme = useTheme()
	const [search, setSearch] = useState('')
	const [values, setValues] = useState(showMoreValues)

	const mainHeight = document.documentElement.scrollHeight

	useEffect(
		function handleSearch() {
			if (search === '') {
				setValues(showMoreValues)
			} else {
				const searchLower = search.toLowerCase()
				if (
					Array.isArray(showMoreValues) &&
					typeof showMoreValues[0] === 'string'
				) {
					setValues(
						showMoreValues.filter((el: any) =>
							el.toLowerCase().includes(searchLower),
						),
					)
				} else if (
					Array.isArray(showMoreValues) &&
					typeof showMoreValues[0] === 'object'
				) {
					//filter for values that match and order based on if they start with or contain. Basically done for country tag.
					setValues(
						showMoreValues
							.filter((el: any) => el.key.toLowerCase().includes(searchLower))
							.sort((a: any, b: any) => {
								const aStartsWith = a.key.toLowerCase().startsWith(searchLower)
								const bStartsWith = b.key.toLowerCase().startsWith(searchLower)

								if (aStartsWith && !bStartsWith) return -1
								if (!aStartsWith && bStartsWith) return 1
								return 0
							}),
					)
				}
			}
		},
		[search],
	)
	return (
		showMore && (
			<div
				style={{
					background: 'rgba(0,0,0,0.1)',
					height: mainHeight,
					width: '100%',
					position: 'absolute',
					zIndex: 30,
					top: 0,
					left: 0,
				}}
			>
				<div
					style={{
						display: 'flex',
						maxHeight: '30rem',
						minHeight: '16rem',
						width: '40rem',
						background: theme.colors.base.white,
						position: 'fixed',
						top: '20%',
						left: '30%',
						flexDirection: 'column',
						justifyContent: 'space-between',
						borderRadius: 20,
						boxShadow: `0px 4px 12px 4px rgba(0,0,0,0.3)`,
					}}
				>
					<span
						style={{
							fontSize: theme.font.size.titles,
							padding: '2rem 2rem 2rem 4rem',
							fontWeight: theme.font.weight.bolder,
						}}
					>
						{showMoreTitle}
					</span>
					{searchBar && (
						<TableSearchComponent
							setSearch={setSearch}
							search={search}
							style={{
								width: '40%',
								boxShadow: 'none',
								border: '1px solid rgba(0,0,0,0.12)',
								borderRadius: theme.customComponents.input.borderRadius,
								marginTop: 0,
								marginLeft: 60,
								opacity: 1,
								transition: '0.6s ease',
								color: theme.colors.base.white,
								minHeight: 34,
							}}
							placeholder='Search'
							fixedPlaceholder={true}
						/>
					)}
					<div
						style={{
							overflowX: 'scroll',
							padding: '1rem 2rem 2rem 4rem',
							display: 'flex',
							gap: 20,
							flexDirection: 'column',
							whiteSpace: 'pre-wrap',
						}}
					>
						{values}
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'column',
							borderTop: `1px solid ${theme.colors.preview.border}`,
							marginTop: 10,
							padding: '1rem',
						}}
					>
						{/* <hr style={{ width: '100%' }}></hr> */}
						<SubmitButton
							style={{
								alignSelf: 'flex-end',
								marginRight: 30,
							}}
							onClick={handleClose}
						>
							OK
						</SubmitButton>
					</div>
				</div>
			</div>
		)
	)
}

export default ShowMoreModal
