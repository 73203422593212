import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useTheme } from '@mui/material/styles'
import { UnborderedButton } from '../Buttons'
import { ContactInterface } from '../../../models/contact.interface'
import { ContactRowItem } from './ContactRowItem'
import { CustomActionsMenu } from '../Menus'
import { SectionTitle } from '../Informative'

interface EditContactsProps {
	contacts: ContactInterface[]
	setContacts: React.Dispatch<React.SetStateAction<ContactInterface[]>>

	isReadOnly: boolean
	canEdit: boolean
	p360Active: boolean
	roleOptions?: string[]
	addtionalStyle?: React.CSSProperties
	handleFieldChange: (
		newValue: string,
		contactUUID: string,
		fieldName: keyof ContactInterface,
	) => void
	handleGeoChange: (contactUUID: string, newGeoArray: string[]) => void
	// for creating new contact
	handleAddNewContact: (contactType: 'contact' | 'finance') => void
	handleMenuOpen: (e: React.MouseEvent<HTMLElement>, uuid: string) => void
	// for actions menu
	anchorEl: null | HTMLElement
	selectedContact: string | null
	handleMenuClose: () => void
	menuItems: {
		label: string
		icon: React.ReactNode
		onClick: () => void
		hoverColor?: string
	}[]
	// from parent
	isPreviewMode: boolean
	hoverContact: boolean
	setHoverContact: React.Dispatch<React.SetStateAction<boolean>>
	hoverFinance: boolean
	setHoverFinance: React.Dispatch<React.SetStateAction<boolean>>
	getSafeValue: (value: any, defaultValue?: any) => any
	contactDetails: ContactInterface[]
	financeDetails: ContactInterface[]
}

export const EditContactsComponent: React.FC<EditContactsProps> = ({
	contacts,
	setContacts,
	isReadOnly,
	canEdit,
	p360Active,
	roleOptions,
	addtionalStyle,
	handleFieldChange,
	handleGeoChange,
	handleAddNewContact,
	anchorEl,
	selectedContact,
	handleMenuClose,
	menuItems,
	isPreviewMode,
	handleMenuOpen,
	hoverContact,
	setHoverContact,
	hoverFinance,
	setHoverFinance,
	getSafeValue,
	contactDetails,
	financeDetails,
}) => {
	const theme = useTheme()

	const gridItemStyles = {
		pr: 2,
		'& > .MuiGrid-item': {
			paddingLeft: '2rem',
		},
	}

	return (
		<Grid
			container
			columns={12}
			columnSpacing={6}
			rowSpacing={0}
			direction='column'
			style={{
				backgroundColor: theme.colors.preview.cardBackground,
				borderRadius: '15px',
				paddingBottom: '2rem',
				paddingTop: '1rem',
				width: 'calc(99% - 2.5rem)',
				marginTop: '1rem',
				marginLeft: '0rem',
				paddingRight: '0px',
				border: `1px solid ${theme.colors.preview.border}`,
				...gridItemStyles,
				...addtionalStyle,
			}}
			sx={gridItemStyles}
		>
			{/* ======================= CONTACT DETAILS ======================= */}
			{(!isPreviewMode || contactDetails.length > 0) && (
				<Grid item xs={12}>
					<SectionTitle text='Contact Details' marginTop='0.6rem' />

					{contactDetails.map((contact) => (
						<ContactRowItem
							key={contact.uuid}
							contact={contact}
							isReadOnly={isReadOnly}
							canEdit={canEdit}
							roleOptions={roleOptions}
							p360Active={p360Active}
							handleFieldChange={handleFieldChange}
							handleGeoChange={handleGeoChange}
							handleMenuOpen={handleMenuOpen}
							setContacts={setContacts}
							getSafeValue={getSafeValue}
						/>
					))}

					<Box
						display='flex'
						alignItems='center'
						marginBottom='1.7rem'
						marginTop='0.7rem'
					>
						<UnborderedButton
							onClick={() => handleAddNewContact('contact')}
							disabled={isReadOnly}
							onMouseEnter={() => setHoverContact(true)}
							onMouseLeave={() => setHoverContact(false)}
							icon={
								<AddIcon
									style={{
										border: '1px solid ' + theme.colors.base.grey900,
										borderRadius: '100px',
										width: '16px',
										height: '16px',
										backgroundColor: hoverContact
											? theme.colors.base.grey200
											: 'transparent',
										transition: 'background-color 0.3s',
									}}
								/>
							}
							label='Add Contact'
							style={{
								textDecoration: 'underline',
								color: hoverContact
									? theme.colors.base.green300
									: theme.colors.text.titles,
								fontSize: theme.font.size.caption,
								transition: 'color 0.3s',
								paddingLeft: '2px',
							}}
						/>
					</Box>
				</Grid>
			)}

			{/* ======================= FINANCE SECTION ======================= */}
			{(!isPreviewMode || financeDetails.length > 0) && (
				<Grid item xs={12}>
					<SectionTitle text='Finance Details' marginBottom='0.6rem' />

					{financeDetails.map((contact) => (
						<ContactRowItem
							key={contact.uuid}
							contact={contact}
							isReadOnly={isReadOnly}
							canEdit={canEdit}
							roleOptions={roleOptions}
							p360Active={p360Active}
							handleFieldChange={handleFieldChange}
							handleMenuOpen={handleMenuOpen}
							handleGeoChange={handleGeoChange}
							setContacts={setContacts}
							getSafeValue={getSafeValue}
						/>
					))}

					<Box display='flex' alignItems='center'>
						<UnborderedButton
							onClick={() => handleAddNewContact('finance')}
							disabled={isReadOnly}
							onMouseEnter={() => setHoverFinance(true)}
							onMouseLeave={() => setHoverFinance(false)}
							icon={
								<AddIcon
									style={{
										border: '1px solid ' + theme.colors.base.grey900,
										borderRadius: '100px',
										width: '16px',
										height: '16px',
										backgroundColor: hoverFinance
											? theme.colors.base.grey200
											: 'transparent',
										transition: 'background-color 0.3s',
									}}
								/>
							}
							label='Add Finance Contact'
							style={{
								textDecoration: 'underline',
								color: hoverFinance
									? theme.colors.base.green300
									: theme.colors.text.titles,
								fontSize: theme.font.size.caption,
								transition: 'color 0.3s',
								paddingLeft: '2px',
							}}
						/>
					</Box>
				</Grid>
			)}

			<CustomActionsMenu
				anchorEl={anchorEl}
				isOpen={Boolean(anchorEl) && selectedContact !== null}
				handleClose={handleMenuClose}
				menuItems={menuItems}
			/>
		</Grid>
	)
}
