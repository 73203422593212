import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CapabilityObj } from './CreatorPopUp'
import { v4 as uuidv4 } from 'uuid'
import { Grid, useTheme } from '@mui/material'
import { InputFieldText } from '../../components/Inputs'
import {
	CountryListSelector,
	DropList,
} from '../../components/SelectableInputs'
import { countryList } from '../../components/countryList'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'
import { UnborderedButton } from '../../components/Buttons'
import AddIcon from '@mui/icons-material/Add'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ChipInputList } from '../../components/ChipInput'

const CreatorCapabilities = (props: {
	capabilities: CapabilityObj[]
	setCapabilities: Dispatch<SetStateAction<CapabilityObj[]>>
}) => {
	const theme = useTheme()
	const { capabilities, setCapabilities } = props

	const { settings } = useTypedSelector((state) => state)

	const createNewCapability = () => {
		const newCapability = {
			gender: { male: 0, female: 0 },
			age: {
				age18to24: 0,
				age25to34: 0,
				age35to44: 0,
				age45to54: 0,
				age55toInfinity: 0,
			},
			vertical: [],
			geo: [],
			engagement_rate: 0,
			rate_per_video: 0,
			notes: '',
			uuid: uuidv4(),
			type: '',
		}
		setCapabilities((prev) =>
			prev ? [...prev, newCapability] : [newCapability],
		)
	}
	const deleteCapability = (id: string) => {
		const updatedCapabilities = capabilities.filter(
			(el: CapabilityObj) => el.uuid !== id,
		)
		setCapabilities(updatedCapabilities)
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 12,
				position: 'relative',
			}}
		>
			<hr
				style={{
					width: '104%',
					height: '1px',
					background: theme.colors.base.grey300,
					marginLeft: '-3%',
					border: 'none',
				}}
			></hr>
			{capabilities.map((el) => {
				return (
					<CreatorCapabilityBox
						key={el.uuid}
						obj={el}
						verticalOptions={settings.settings.creatorVertical}
						capabilities={capabilities}
						setCapabilities={setCapabilities}
						deleteFunction={deleteCapability}
					/>
				)
			})}
			<UnborderedButton
				onClick={createNewCapability}
				label='Add'
				icon={
					<AddIcon
						style={{
							border: '1px solid ' + theme.colors.text.titles,
							borderRadius: '100px',
							width: '14px',
							height: '14px',
						}}
					/>
				}
				style={{
					color: theme.colors.text.titles,
					zIndex: '12',
					width: 48,
					alignSelf: 'flex-start',
				}}
			/>
		</div>
	)
}

export default CreatorCapabilities

const CreatorCapabilityBox = (props: {
	obj: CapabilityObj
	verticalOptions: string[]
	capabilities: CapabilityObj[]
	setCapabilities: Dispatch<SetStateAction<any>>
	deleteFunction: Function
}) => {
	const theme = useTheme()
	const {
		obj,
		verticalOptions,
		capabilities,
		setCapabilities,
		deleteFunction,
	} = props
	const [malePercentage, setMalePercentage] = useState<number>(
		obj.gender.male ? obj.gender.male : 0,
	)
	const [femalePercentage, setFemalePercentage] = useState<number>(
		obj.gender.female ? obj.gender.female : 0,
	)
	const [age18to24, setAge18to24] = useState<number>(
		obj.age.age18to24 ? obj.age.age18to24 : 0,
	)
	const [age25to34, setAge25to34] = useState<number>(
		obj.age.age25to34 ? obj.age.age25to34 : 0,
	)
	const [age35to44, setAge35to44] = useState<number>(
		obj.age.age35to44 ? obj.age.age35to44 : 0,
	)
	const [age45to54, setAge45to54] = useState<number>(
		obj.age.age45to54 ? obj.age.age45to54 : 0,
	)
	//hahaha
	const [age55toInfinity, setAge55toInfinity] = useState<number>(
		obj.age.age55toInfinity ? obj.age.age55toInfinity : 0,
	)
	const [ratePerVideo, setRatePerVideo] = useState<number>(
		obj.rate_per_video ? obj.rate_per_video : 0,
	)
	const [engagementRate, setEngagementRate] = useState<number>(
		obj.engagement_rate ? obj.engagement_rate : 0,
	)
	const [verticals, setVerticals] = useState<string[]>(
		obj.vertical ? obj.vertical : [],
	)
	const [notes, setNotes] = useState<string>(obj.notes ? obj.notes : '')
	const [type, setType] = useState<string>(obj.type ? obj.type : '')
	const [geoRatios, setGeoRatios] = useState<any>(obj.geo ? obj.geo : [])
	//TODO: add verticals for influencers in settings
	const addNewGeo = () => {
		const newGeo = { geo: '', value: 0, uuid: uuidv4() }
		setGeoRatios((prev: any) => (prev ? [...prev, newGeo] : [newGeo]))
	}

	const removeGeo = (uuid: string) => {
		const updatedRatios = geoRatios.filter((el: any) => el.uuid !== uuid)
		setGeoRatios(updatedRatios)
	}

	useEffect(() => {
		const newObj = {
			gender: { male: malePercentage, female: femalePercentage },
			age: {
				age18to24: age18to24,
				age25to34: age25to34,
				age35to44: age35to44,
				age45to54: age45to54,
				age55toInfinity: age55toInfinity,
			},
			vertical: verticals,
			engagement_rate: engagementRate,
			rate_per_video: ratePerVideo,
			geo: geoRatios,
			notes: notes,
			uuid: obj.uuid,
			type: type,
		}
		const result = []
		for (const capObj of capabilities) {
			if (capObj.uuid === newObj.uuid) {
				result.push(newObj)
			} else {
				result.push(capObj)
			}
		}
		setCapabilities(result)
	}, [
		malePercentage,
		femalePercentage,
		age18to24,
		age25to34,
		age35to44,
		age45to54,
		age55toInfinity,
		ratePerVideo,
		verticals,
		notes,
		geoRatios,
		engagementRate,
	])

	return (
		<div
			style={{
				display: 'flex',
				gap: 34,
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<UnborderedButton
				style={{
					display: 'flex',
					position: 'absolute',
					border: 'none',
					cursor: 'pointer',
					zIndex: '10',
					top: -8,
					right: -8,
				}}
				icon={
					<DeleteIcon
						style={{
							scale: '0.8',
						}}
					/>
				}
				onClick={() => deleteFunction(obj.uuid)}
			>
				Remove
			</UnborderedButton>

			<Grid
				container
				columns={12}
				columnSpacing={6}
				rowSpacing={4}
				direction='column'
				style={{
					width: '98%',
					display: 'flex',
					minHeight: 440,
				}}
			>
				<Grid
					item
					container
					xs={3}
					direction={'row'}
					columns={12}
					columnSpacing={6}
					rowSpacing={4}
				>
					<Grid
						item
						xs={1}
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<div
							style={{
								color: theme.colors.base.grey600,
								fontSize: theme.font.size.caption,
								height: 'auto',
								alignItems: 'flex-end',
								display: 'flex',
								marginBottom: 8,
							}}
						>
							<span>Type:</span>
						</div>
					</Grid>
					<Grid item xs={2}>
						<DropList
							label='Type'
							options={['Instagram', 'LinkedIn', 'Tiktok', 'Youtube']}
							value={type}
							onChange={setType}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					container
					xs={3}
					direction={'row'}
					columns={12}
					columnSpacing={6}
					rowSpacing={4}
				>
					<Grid
						item
						xs={1}
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<div
							style={{
								color: theme.colors.base.grey600,
								fontSize: theme.font.size.caption,
								height: 'auto',
								alignItems: 'flex-end',
								display: 'flex',
							}}
						>
							<span>Gender Audience Ratio:</span>
						</div>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='Male'
							type='number'
							customInputLabel={'%'}
							value={malePercentage}
							onChange={setMalePercentage}
						/>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='Female'
							type='number'
							customInputLabel={'%'}
							value={femalePercentage}
							onChange={setFemalePercentage}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					container
					xs={3}
					direction={'row'}
					columns={12}
					columnSpacing={6}
					rowSpacing={0}
				>
					<Grid
						item
						xs={1}
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<div
							style={{
								color: theme.colors.base.grey600,
								fontSize: theme.font.size.caption,
								height: 'auto',
								alignItems: 'flex-end',
								display: 'flex',
							}}
						>
							<span>Age Audience Ratio:</span>
						</div>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='18-24'
							type='number'
							customInputLabel={'%'}
							value={age18to24}
							onChange={setAge18to24}
						/>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='25-34'
							type='number'
							customInputLabel={'%'}
							value={age25to34}
							onChange={setAge25to34}
						/>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='35-44'
							type='number'
							customInputLabel={'%'}
							value={age35to44}
							onChange={setAge35to44}
						/>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='45-54'
							type='number'
							customInputLabel={'%'}
							value={age45to54}
							onChange={setAge45to54}
						/>
					</Grid>
					<Grid item xs={1}>
						<InputFieldText
							label='55+'
							type='number'
							customInputLabel={'%'}
							value={age55toInfinity}
							onChange={setAge55toInfinity}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					container
					xs={3}
					direction={'row'}
					columns={12}
					columnSpacing={6}
					rowSpacing={3}
				>
					<Grid
						item
						xs={1}
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<div
							style={{
								color: theme.colors.base.grey600,
								fontSize: theme.font.size.caption,
								height: 'auto',
								alignItems: 'flex-start',
								display: 'flex',
							}}
						>
							<span>Geo Audience Ratio:</span>
						</div>
					</Grid>
					<Grid
						item
						xs={10}
						container
						direction={'row'}
						columns={12}
						columnSpacing={7}
						rowSpacing={3}
					>
						{geoRatios.map((el: any, indx: number) => {
							return (
								<MultipleCapabilityGeo
									el={el}
									indx={indx}
									removeFunc={removeGeo}
									geoRatios={geoRatios}
									setGeoRatios={setGeoRatios}
								/>
							)
						})}
						{geoRatios.length < 4 && (
							<Grid item xs={2} style={{ display: 'flex' }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-end',
										paddingBottom: 8,
									}}
								>
									<UnborderedButton
										onClick={addNewGeo}
										label='Add Geo'
										icon={
											<AddIcon
												style={{
													border: '1px solid ' + theme.colors.text.titles,
													borderRadius: '100px',
													width: '14px',
													height: '14px',
												}}
											/>
										}
										style={{ color: theme.colors.text.titles, zIndex: '12' }}
									/>
								</div>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				columns={12}
				columnSpacing={6}
				rowSpacing={4}
				direction='row'
				style={{
					width: '98%',
					display: 'flex',
				}}
			>
				<Grid item xs={1.5}>
					<InputFieldText
						label={'Engagement Rate'}
						type='number'
						customInputLabel={'%'}
						value={engagementRate}
						onChange={setEngagementRate}
					/>
				</Grid>
				<Grid item xs={1.5}>
					<InputFieldText
						label='Rate Per Video'
						type='number'
						customInputLabel={'%'}
						value={ratePerVideo}
						onChange={setRatePerVideo}
					/>
				</Grid>
				<Grid item xs={9}>
					<ChipInputList
						label='Verticals'
						options={verticalOptions}
						value={verticals}
						onChange={setVerticals}
						openWithClick
					/>
				</Grid>
				<Grid item xs={12}>
					<InputFieldText
						label='Notes'
						type={'text'}
						value={notes}
						onChange={setNotes}
					/>
				</Grid>
			</Grid>
			<hr
				style={{
					width: '104%',
					height: '1px',
					background: theme.colors.base.grey300,
					marginLeft: '-3%',
					border: 'none',
				}}
			></hr>
		</div>
	)
}

const MultipleCapabilityGeo = (props: {
	el: any
	indx: number
	removeFunc: Function
	geoRatios: any
	setGeoRatios: Dispatch<SetStateAction<any>>
}) => {
	const theme = useTheme()
	const { el, indx, removeFunc, geoRatios, setGeoRatios } = props
	const [geo, setGeo] = useState(el.geo ? el.geo : '')
	const [value, setValue] = useState(el.value ? el.value : '')

	useEffect(() => {
		const newObj = { geo: geo, value: value, uuid: el.uuid }
		const result = []
		for (const geoObj of geoRatios) {
			if (geoObj.uuid === newObj.uuid) {
				result.push(newObj)
			} else result.push(geoObj)
		}
		setGeoRatios(result)
	}, [geo, value])

	return (
		<Grid
			item
			xs={6}
			key={indx}
			container
			direction={'row'}
			columns={12}
			columnSpacing={2}
			rowSpacing={0}
			style={{ position: 'relative' }}
		>
			<Grid item xs={8}>
				<CountryListSelector
					label='Geo'
					options={countryList}
					value={geo}
					singular
					onChange={setGeo}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Percentage'
					type='number'
					customInputLabel={'%'}
					value={value}
					onChange={setValue}
				/>
			</Grid>
			<Grid
				item
				xs={1}
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<button
					style={{
						display: 'flex',
						marginTop: 28,
						border: 'none',
						cursor: 'pointer',
						zIndex: '10',
					}}
					onClick={() => removeFunc(el.uuid)}
				>
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '0.8' }}
						className={'action-button'}
					/>
				</button>
			</Grid>
		</Grid>
	)
}
