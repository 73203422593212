import React from 'react'
import { Grid, useTheme } from '@mui/material'
import {
	CountryListSelector,
	DropList,
} from '../../components/SelectableInputs'
import { InputFieldText } from '../../components/Inputs'
import { TitleComponent } from '../../components/Informative'
import { checkInputStartsWithHttp } from '../../../utils/helpers/tableHelper'
import { countryList } from '../../components/countryList'
import { stringIsValidType } from '../../../utils/helpers/helperFuncs'

interface FieldValidations {
	name?: boolean
	userName?: boolean
	type?: boolean
	gender?: boolean
	address?: boolean
	geo?: boolean
	linkToDrafts?: boolean
	branch?: boolean
}

interface CreatorMetaEditProps {
	permissions: { edit: boolean }
	settings: any
	name: string
	setName: (val: string) => void
	userName: string
	setUserName: (val: string) => void
	type: string
	setType: (val: string) => void
	gender: string
	setGender: (val: string) => void
	address: string
	setAddress: (val: string) => void
	geo: string[]
	setGeo: (val: string[]) => void
	instagram: string
	setInstagram: (val: string) => void
	tiktok: string
	setTiktok: (val: string) => void
	youtube: string
	setYoutube: (val: string) => void
	linkedin: string
	setLinkedin: (val: string) => void
	portfolio: string
	setPortfolio: (val: string) => void
	fiverr: string
	setFiverr: (val: string) => void
	linkToDrafts: string
	setLinkToDrafts: (val: string) => void
	branch: string
	setBranch: (val: string) => void
	fieldValidations?: FieldValidations
}

const CreatorMetaEdit: React.FC<CreatorMetaEditProps> = (props) => {
	const {
		permissions,
		settings,
		name,
		setName,
		userName,
		setUserName,
		type,
		setType,
		gender,
		setGender,
		address,
		setAddress,
		geo,
		setGeo,
		instagram,
		setInstagram,
		tiktok,
		setTiktok,
		youtube,
		setYoutube,
		linkedin,
		setLinkedin,
		portfolio,
		setPortfolio,
		fiverr,
		setFiverr,
		linkToDrafts,
		setLinkToDrafts,
		fieldValidations,
	} = props

	const theme = useTheme()

	return (
		<Grid
			container
			columns={12}
			rowSpacing={2}
			style={{ width: '100%', marginBottom: 20 }}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: 8,
					marginLeft: 20,
				}}
			>
				<TitleComponent
					style={{
						fontSize: theme.font.size.body,
						color: theme.colors.text.titles,
						marginTop: '2rem',
					}}
				>
					Creator Info
				</TitleComponent>

				<Grid
					container
					columns={12}
					columnSpacing={6}
					rowSpacing={2}
					style={{ width: '98%' }}
				>
					<Grid item xs={3}>
						<InputFieldText
							label='Full Name'
							type='text'
							value={name}
							onChange={setName}
							disabled={!permissions.edit}
							errormessage={
								fieldValidations?.name === false ? 'Required' : undefined
							}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='Username'
							type='text'
							value={userName}
							onChange={setUserName}
							disabled={!permissions.edit}
							errormessage={
								fieldValidations?.userName === false ? 'Required' : undefined
							}
						/>
					</Grid>

					<Grid item xs={1.5}>
						<DropList
							label='Type'
							options={['IM', 'UGC']}
							value={props.type}
							onChange={props.setType}
							disabled={!permissions.edit}
							errormessage={
								props.type && !stringIsValidType(props.type)
									? 'Invalid type.'
									: undefined
							}
							mandatory={!props.fieldValidations?.type}
						/>
					</Grid>

					<Grid item xs={1.5}>
						<DropList
							onChange={setGender}
							options={['Male', 'Female', 'Other']}
							label='Gender'
							value={gender}
							disabled={!permissions.edit}
						/>
					</Grid>

					<Grid item xs={3}>
						<CountryListSelector
							label='Country'
							options={countryList}
							value={props.geo}
							singular
							onChange={(e: any) => props.setGeo(e)}
							disabled={!permissions.edit}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='Address'
							type='text'
							value={address}
							onChange={setAddress}
							disabled={!permissions.edit}
							multiline
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='Instagram'
							type='text'
							value={instagram}
							onChange={setInstagram}
							disabled={!permissions.edit}
							showUrlIcon={true}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='TikTok'
							type='text'
							value={tiktok}
							onChange={setTiktok}
							disabled={!permissions.edit}
							showUrlIcon={true}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='YouTube'
							type='text'
							value={youtube}
							onChange={setYoutube}
							disabled={!permissions.edit}
							showUrlIcon={true}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='LinkedIn'
							type='text'
							value={linkedin}
							onChange={setLinkedin}
							disabled={!permissions.edit}
							showUrlIcon={true}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='Portfolio'
							type='text'
							value={portfolio}
							onChange={setPortfolio}
							disabled={!permissions.edit}
							showUrlIcon={true}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='Fiverr'
							type='text'
							value={fiverr}
							onChange={setFiverr}
							disabled={!permissions.edit}
							showUrlIcon={true}
						/>
					</Grid>

					<Grid item xs={3}>
						<InputFieldText
							label='Link to Drafts'
							type='text'
							value={linkToDrafts}
							onChange={setLinkToDrafts}
							disabled={!permissions.edit}
							errormessage={
								linkToDrafts && !checkInputStartsWithHttp(linkToDrafts)
									? "Links must start with 'http://' or 'https://'"
									: undefined
							}
							showUrlIcon={true}
						/>
					</Grid>
				</Grid>
			</div>
		</Grid>
	)
}

export default CreatorMetaEdit
