import { Grid } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { InputFieldText } from '../../components/Inputs'
import {
	CountryListSelector,
	DropList,
	OwnerSelect,
} from '../../components/SelectableInputs'
import { countryList } from '../../components/countryList'
import { ChipInputList } from '../../components/ChipInput'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	stringIsValidAddress,
	stringIsValidEmail,
	stringIsValidGender,
	stringIsValidName,
	stringIsValidType,
} from '../../../utils/helpers/helperFuncs'
import { checkInputStartsWithHttp } from '../../../utils/helpers/tableHelper'

interface CreatorInfoComponentProps {
	name: string
	setName: Dispatch<SetStateAction<string>>
	userName: string
	setUserName: Dispatch<SetStateAction<string>>
	campaign: string[]
	setCampaign: Dispatch<SetStateAction<string[]>>
	type: string
	setType: Dispatch<SetStateAction<string>>
	email: string
	setEmail: Dispatch<SetStateAction<string>>
	gender: string
	setGender: Dispatch<SetStateAction<string>>
	geo: string[]
	setGeo: Dispatch<SetStateAction<string[]>>
	instagram: string
	setInstagram: Dispatch<SetStateAction<string>>
	tiktok: string
	setTiktok: Dispatch<SetStateAction<string>>
	youtube: string
	setYoutube: Dispatch<SetStateAction<string>>
	linkedin: string
	setLinkedin: Dispatch<SetStateAction<string>>
	portfolio: string
	setPortfolio: Dispatch<SetStateAction<string>>
	fiverr: string
	setFiverr: Dispatch<SetStateAction<string>>
	linkToIO: string
	setLinkToIO: Dispatch<SetStateAction<string>>
	linkToDrafts: string
	setLinkToDrafts: Dispatch<SetStateAction<string>>
	ownerEmail: string | null
	setOwnerEmail: Dispatch<SetStateAction<string | null>>
	address: string
	setAddress: Dispatch<SetStateAction<string>>
	status: boolean
	setStatus: Dispatch<SetStateAction<boolean>>
	isValid: boolean
	hasAgent: boolean
	setHasAgent: Dispatch<SetStateAction<boolean>>
	goodCommunicationBadge: boolean
	setGoodCommunicationBadge: Dispatch<SetStateAction<boolean>>
	highQualityWorkBadge: boolean
	setHighQualityWorkBadge: Dispatch<SetStateAction<boolean>>
	mandatoryFields?: any
	fieldValidations?: any
	permissions: any
}

const CreatorInfoComponent: React.FC<CreatorInfoComponentProps> = (props) => {
	const { campaign } = useTypedSelector((state) => state)
	const permissions = props.permissions

	const campaignList = campaign.campaign
		.filter((el: any) => el.role === 'M')
		.map((el: any) => el.campaign_name)
		.sort()

	return (
		<div style={{ width: '100%' }}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Full Name'
						type='text'
						value={props.name}
						onChange={props.setName}
						disabled={!permissions.edit}
						errormessage={
							props.name && !stringIsValidName(props.name)
								? 'Invalid name.'
								: undefined
						}
						mandatory={!props.fieldValidations?.name}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Username'
						type='text'
						value={props.userName}
						onChange={props.setUserName}
						disabled={!permissions.edit}
						errormessage={
							props.userName && !stringIsValidName(props.userName)
								? 'Invalid username.'
								: undefined
						}
						mandatory={!props.fieldValidations?.userName}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Grid container spacing={4}>
						<Grid item xs={6}>
							<DropList
								label='Type'
								options={['IM', 'UGC']}
								value={props.type}
								onChange={props.setType}
								disabled={!permissions.edit}
								errormessage={
									props.type && !stringIsValidType(props.type)
										? 'Invalid type.'
										: undefined
								}
								mandatory={!props.fieldValidations?.type}
							/>
						</Grid>
						<Grid item xs={6}>
							<DropList
								label='Gender'
								options={['Male', 'Female', 'Binary', 'None']}
								value={props.gender}
								onChange={props.setGender}
								disabled={!permissions.edit}
								errormessage={
									props.gender && !stringIsValidGender(props.gender)
										? 'Invalid gender.'
										: undefined
								}
								mandatory={!props.fieldValidations?.gender}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Email'
						type='text'
						value={props.email}
						onChange={props.setEmail}
						disabled={!permissions.edit}
						errormessage={
							props.email && !stringIsValidEmail(props.email)
								? 'Invalid email.'
								: undefined
						}
						mandatory={!props.fieldValidations?.email}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '0rem' }}>
				<Grid item xs={12} md={3}>
					<CountryListSelector
						label='Country'
						value={props.geo}
						options={countryList}
						disabled={!permissions.edit}
						singular
						onChange={props.setGeo}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<OwnerSelect
						onChange={props.setOwnerEmail}
						label='Owner'
						value={props.ownerEmail}
						disabled={!permissions.edit}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Address'
						type='text'
						value={props.address}
						onChange={props.setAddress}
						disabled={!permissions.edit}
						errormessage={
							props.address && !stringIsValidAddress(props.address)
								? 'Invalid address.'
								: undefined
						}
						mandatory={!props.fieldValidations?.address}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to LinkedIn'
						type='text'
						value={props.linkedin}
						onChange={props.setLinkedin}
						disabled={!permissions.edit}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '0rem' }}>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to Instagram'
						type='text'
						value={props.instagram}
						onChange={props.setInstagram}
						disabled={!permissions.edit}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to TikTok'
						type='text'
						value={props.tiktok}
						onChange={props.setTiktok}
						disabled={!permissions.edit}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to Fiverr'
						type='text'
						value={props.fiverr}
						onChange={props.setFiverr}
						disabled={!permissions.edit}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to YouTube'
						type='text'
						value={props.youtube}
						onChange={props.setYoutube}
						disabled={!permissions.edit}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '0rem' }}>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to Portfolio'
						type='text'
						value={props.portfolio}
						onChange={props.setPortfolio}
						disabled={!permissions.edit}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to Drafts'
						type='text'
						value={props.linkToDrafts}
						onChange={props.setLinkToDrafts}
						disabled={!permissions.edit}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Grid container spacing={4}>
						<Grid item xs={6}>
							<DropList
								label='Agent'
								options={['Yes', 'No']}
								value={props.hasAgent ? 'Yes' : 'No'}
								onChange={(val: any) => props.setHasAgent(val === 'Yes')}
								disabled={!permissions.edit}
							/>
						</Grid>
						<Grid item xs={6}>
							<DropList
								label='Good Communication'
								options={['Yes', 'No']}
								disabled={!permissions.edit}
								value={props.goodCommunicationBadge ? 'Yes' : 'No'}
								onChange={(val: any) =>
									props.setGoodCommunicationBadge(val === 'Yes')
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={3}>
					<Grid container spacing={4}>
						<Grid item xs={6}>
							<DropList
								label='High Quality Work'
								options={['Yes', 'No']}
								disabled={!permissions.edit}
								value={props.highQualityWorkBadge ? 'Yes' : 'No'}
								onChange={(val: any) =>
									props.setHighQualityWorkBadge(val === 'Yes')
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<DropList
								label='Status'
								options={['Active', 'Disabled']}
								disabled={!permissions.edit}
								value={props.status ? 'Active' : 'Disabled'}
								onChange={(val: any) => props.setStatus(val === 'Active')}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '0rem' }}>
				{/* <Grid item xs={12} md={3}>
					<InputFieldText
						label='Link to IO'
						type='text'
						value={props.linkToIO}
						onChange={(val: any) => props.setLinkToIO(val)}
						errormessage={
							props.linkToIO && !checkInputStartsWithHttp(props.linkToIO)
								? 'Link must start with http:// or https://'
								: undefined
						}
					/>
				</Grid> */}
				<Grid item xs={12} md={6}>
					<ChipInputList
						value={props.campaign}
						label='Campaigns'
						onChange={props.setCampaign}
						disabled={!permissions.edit}
						options={campaignList}
						openWithClick
						mandatory={!props.fieldValidations?.campaign}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default CreatorInfoComponent
