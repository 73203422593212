import { Public } from '@mui/icons-material'
import PublicIcon from '@mui/icons-material/Public'
import { Tooltip, useTheme } from '@mui/material'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { ReadMoreInlineButton, UnborderedButton } from './Buttons'
import { countryList } from './countryList'
import CustomModal from './CustomModal'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MoreIcon from '@mui/icons-material/More'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface PreviewContainerProps {
	children: React.ReactNode
	additionalStyle?: CSSProperties
	gridTemplate?: string
}

export const PreviewContainer = ({
	children,
	additionalStyle,
	gridTemplate = 'repeat(4, 1fr)',
}: PreviewContainerProps) => {
	const theme = useTheme()
	return (
		<div
			style={{
				background: theme.colors.preview.cardBackground,
				width: '95%',
				border: `1px solid ${theme.colors.preview.border}`,
				padding: '2rem 3rem 2rem 2rem',
				borderRadius: 10,
				display: 'grid',
				gridTemplateColumns: gridTemplate,
				gridAutoRows: 62,
				gap: '10px',
				rowGap: '30px',
				columnGap: '30px',
				...additionalStyle,
			}}
		>
			{children}
		</div>
	)
}

export const PreviewInfoTag = ({
	label,
	value,
	gridColumnSpan = 1,
	gridRowSpan = 1,
	isCountry = false,
	showMoreClick,
}: {
	label: string
	value: string
	gridColumnSpan?: number
	gridRowSpan?: number
	isCountry?: boolean
	showMoreClick: (value: any, title: string) => void
}) => {
	const theme = useTheme()
	const textRef = useRef<HTMLSpanElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const countriesRef = useRef<HTMLSpanElement>(null)
	const [isOverflowing, setIsOverflowing] = useState(false)
	const [numberOfLines, setNumberOfLines] = useState(1)

	const resizeTrigger = useTypedSelector((state) => state.resize.resizeTrigger)

	const checkOverflow = () => {
		if (!containerRef.current || !textRef.current) return

		const container = containerRef.current
		const textElement = textRef.current

		if (textElement.offsetWidth === 0 || textElement.offsetHeight === 0) {
			return
		}

		let newIsOverflowing = false
		let newNumberOfLines = 1

		const lineHeight =
			parseFloat(window.getComputedStyle(textElement).lineHeight) || 1.5
		newNumberOfLines = Math.floor(container.offsetHeight / lineHeight)

		const adjustedContainerWidth = container.clientWidth * 0.85
		const isHorizontallyTruncated =
			textElement.offsetWidth > adjustedContainerWidth
		const isVerticallyTruncated =
			textElement.scrollHeight > container.clientHeight

		if (isHorizontallyTruncated || isVerticallyTruncated) {
			newIsOverflowing = true
		}

		if (newIsOverflowing !== isOverflowing) {
			setIsOverflowing(newIsOverflowing)
		}

		setNumberOfLines(newNumberOfLines)
	}

	useEffect(() => {
		checkOverflow()
	}, [resizeTrigger, value])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 8,
				gridColumn: `span ${gridColumnSpan}`,
				gridRow: `span ${gridRowSpan}`,
			}}
		>
			<PreviewLabel text={label} />

			<div
				style={{
					background: theme.colors.base.white,
					width: '100%',
					border: `1px solid ${theme.colors.preview.border}`,
					padding: 10,
					borderRadius: 4,
					fontSize: theme.font.size.caption,
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					minHeight: '37px',
					height: '100%',
					position: 'relative',
				}}
				ref={containerRef}
			>
				{isCountry ? (
					(() => {
						const countries = Array.isArray(value)
							? value
							: value.includes(',')
							? value.split(',').map((country) => country.trim())
							: [value]
						return (
							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: 8,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
								ref={countriesRef}
							>
								{countries.map((country, index) => (
									<span
										key={country + index}
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: 4,
										}}
									>
										{typeof country === 'string' &&
										country.toLowerCase() === 'global' ? (
											<Public sx={{ height: '18px', width: '18px' }} />
										) : (
											<ReactCountryFlag
												style={{
													height: '18px',
													width: '18px',
													borderRadius: '50%',
													objectFit: 'cover',
												}}
												countryCode={country === 'UK' ? 'GB' : country.trim()}
												svg
											/>
										)}
										<span>{country.trim()}</span>
										{index < countries.length - 1 && <span></span>}
									</span>
								))}
							</span>
						)
					})()
				) : (
					<TextComponentFromInfoTag
						textRef={textRef}
						containerRef={containerRef}
						value={Array.isArray(value) ? value.join(', ') : value}
						isOverflowing={isOverflowing}
						numberOfLines={numberOfLines}
						onClick={() => showMoreClick(value, label)}
						label={label}
					/>
				)}
			</div>
		</div>
	)
}

export const PreviewArrayInfoTag = ({
	label,
	value,
	gridColumnSpan = 1,
	gridRowSpan = 1,
	showMoreClick,
}: {
	label: string
	value: string[]
	gridColumnSpan?: number
	gridRowSpan?: number
	showMoreClick: (value: any, title: string) => void
}) => {
	const theme = useTheme()
	const textRef = useRef<HTMLSpanElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const [isOverflowing, setIsOverflowing] = useState(false)
	const [numberOfLines, setNumberOfLines] = useState(1)

	const checkOverflow = () => {
		if (!containerRef.current || !textRef.current) return

		const container = containerRef.current
		const textElement = textRef.current

		let newIsOverflowing = false
		let newNumberOfLines = 1

		if (textElement) {
			const lineHeight =
				parseFloat(window.getComputedStyle(textElement).lineHeight) || 1.5
			newNumberOfLines = Math.floor(container.offsetHeight / lineHeight)

			const adjustedContainerWidth = container.clientWidth * 0.9
			const isHorizontallyTruncated =
				textElement.offsetWidth > adjustedContainerWidth

			const isVerticallyTruncated =
				textElement.scrollHeight > container.clientHeight

			newIsOverflowing = isVerticallyTruncated || isHorizontallyTruncated
		}

		setIsOverflowing(newIsOverflowing)
		setNumberOfLines(newNumberOfLines)
	}

	useEffect(() => {
		// Observer to handle content size changes
		const observer = new ResizeObserver(() => checkOverflow())

		if (containerRef.current) observer.observe(containerRef.current)

		return () => observer.disconnect()
	}, [value])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 8,
				gridColumn: `span ${gridColumnSpan}`,
				gridRow: `span ${gridRowSpan}`,
				overflow: 'hidden',
			}}
		>
			<PreviewLabel text={label} />
			<div
				style={{
					background: theme.colors.base.white,
					width: '100%',
					border: `1px solid ${theme.colors.preview.border}`,
					padding: '2px 10px 2px 4px',
					borderRadius: 4,
					fontSize: theme.font.size.caption,
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					minHeight: '37px',
					height: '100%',
					position: 'relative',
				}}
				ref={containerRef}
			>
				<TextComponentFromArrayInfoTag
					textRef={textRef}
					containerRef={containerRef}
					value={value}
					isOverflowing={isOverflowing}
					numberOfLines={numberOfLines}
					onClick={() => showMoreClick(value.join(' - '), label)}
					label={label}
				/>
			</div>
		</div>
	)
}

const TextComponentFromArrayInfoTag = ({
	textRef,
	containerRef,
	value,
	isOverflowing,
	numberOfLines = 1,
	onClick,
	label,
}: {
	textRef: React.RefObject<HTMLSpanElement>
	containerRef?: React.RefObject<HTMLDivElement>
	value: string[]
	isOverflowing: boolean
	numberOfLines?: number
	onClick: (value: any, title: string) => void
	label: string
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'flex-start',
				position: 'relative',
				width: '100%',
				maxWidth: '100%',
				overflow: 'hidden',
				height: 'inherit',
				paddingRight: isOverflowing ? '40px' : '0px',
			}}
			ref={containerRef}
		>
			<span
				style={{
					display: 'flex',
					flexWrap: isOverflowing ? 'wrap' : 'nowrap',
					gap: '4px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					lineHeight: 1.4,
					whiteSpace: 'nowrap',
					flexShrink: 1,
					minWidth: 0,
				}}
				ref={textRef}
			>
				{value &&
					value.map((el, index) => {
						return (
							<span
								key={index}
								style={{
									margin: '4px',
									border: `1px solid ${theme.colors.preview.border}`,
									padding: '4px 8px',
									borderRadius: '18px',
									textAlign: 'center',
									whiteSpace: 'nowrap',
									minWidth: 'fit-content',
									display: 'inline-block',
								}}
							>
								{el}
							</span>
						)
					})}
			</span>
			{isOverflowing && (
				<ReadMoreInlineButton
					onClick={() => onClick(value, label)}
					style={{
						position: 'absolute',
						right: 0,
						top: '50%',
						transform: 'translateY(-50%)',
						background: theme.colors.base.white,
						paddingLeft: '4px',
						height: 30,
					}}
				/>
			)}
		</div>
	)
}

const TextComponentFromInfoTag = ({
	textRef,
	containerRef,
	value,
	isOverflowing,
	numberOfLines = 1,
	onClick,
	label,
}: {
	textRef: any
	containerRef?: any
	value: string
	isOverflowing: boolean
	numberOfLines?: number
	onClick: (value: any, title: string) => void
	label: string
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'flex-start',
				position: 'relative',
				width: '100%',
				maxWidth: '100%',
				overflow: 'hidden',
				height: 'inherit',
			}}
			ref={containerRef}
		>
			<span
				style={{
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: numberOfLines,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
					lineHeight: 1.4,
					wordBreak: 'break-word',
					overflowWrap: 'break-word',
					flexShrink: 1,
					minWidth: 0,
					whiteSpace: 'pre-wrap',
				}}
				ref={textRef}
			>
				{value}
				{isOverflowing && (
					<ReadMoreInlineButton
						onClick={() => onClick(value, label)}
						style={{ marginLeft: '4px' }} // Adjust spacing as needed
					/>
				)}
			</span>
		</div>
	)
}

export const CategoryDivider = ({
	mainTitle,
	selected,
	total,
	selectedLabel,
	totalLabel,
	showMoreClick,
}: {
	mainTitle: string
	selected: string[]
	total: string[]
	selectedLabel: string
	totalLabel: string
	showMoreClick: (value: any, title: string) => void
}) => {
	const theme = useTheme()

	const nonSelected = total.filter((el) => !selected.includes(el))

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
			}}
		>
			<PreviewLabel
				text={mainTitle}
				additionalStyle={{
					fontSize: theme.font.size.buttons,
					fontWeight: theme.font.weight.bold,
					color: theme.colors.text.titles,
				}}
			/>

			<div
				style={{
					display: 'grid',
					gap: 20,
					width: '95%',
					gridTemplateColumns: 'repeat(1, 1fr)',
					gridAutoRows: 50,
					rowGap: '30px',
				}}
			>
				<PreviewInfoTag
					label={selectedLabel}
					value={selected.join(' - ')}
					showMoreClick={() =>
						showMoreClick(
							selected.join(' - '),
							`${mainTitle} - ${selectedLabel}`,
						)
					}
				/>
				<PreviewInfoTag
					label={totalLabel}
					value={nonSelected.join(' - ')}
					showMoreClick={() =>
						showMoreClick(
							nonSelected.join(' - '),
							`${mainTitle} - ${totalLabel}`,
						)
					}
				/>
			</div>
		</div>
	)
}

const handleLabelName = (key: string, map: any) => {
	if (map === undefined || !map[key]) {
		return key
	}
	return map[key]
}

export const PreviewGridInfoTag = ({
	label,
	value,
	gridColumnSpan = 1,
	gridRowSpan = 1,
	gridMainRowSpan = 1,
	objectLabelMap,
	extraElement = '',
}: {
	label: string
	value: any
	gridColumnSpan?: number
	gridRowSpan?: number
	gridMainRowSpan?: number
	objectLabelMap?: any
	extraElement?: string
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(1, 1fr)`,
				flexDirection: 'column',
				gap: 6,
				gridColumn: `span ${gridMainRowSpan}`,
				gridRow: `span ${gridRowSpan}`,
				gridAutoRows: 'min-content',
			}}
		>
			<span
				style={{
					color: theme.colors.preview.label,
					fontSize: theme.font.size.bodySmall,
					fontWeight: theme.font.weight.normal,
					height: '20px',
				}}
			>
				{label}
			</span>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: `repeat(${gridColumnSpan}, 1fr)`,
					flexDirection: 'column',
					gap: 8,
					// gridColumn: `span ${gridColumnSpan}`,
					gridRow: `span 1`,
					gridAutoRows: 61.5,
					rowGap: '10px',
					columnGap: '30px',
				}}
			>
				{Object.keys(value).map((el) => (
					<TextComponentFromGridInfoTag
						value={value[el]}
						label={handleLabelName(el, objectLabelMap)}
						extraElement={extraElement}
					/>
				))}
			</div>
		</div>
	)
}

const TextComponentFromGridInfoTag = ({
	value,
	label,
	extraElement,
}: {
	value: string
	label: string
	extraElement: string
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				background: theme.colors.base.white,
				width: '100%',
				border: `1px solid ${theme.colors.preview.border}`,
				padding: 10,
				borderRadius: 4,
				fontSize: theme.font.size.caption,
				display: 'flex',
				alignItems: 'center',
				gap: 10,
				justifyContent: 'space-between',
				//hardcoded value that matches the fontSize + padding
				height: '37px',
				// height: '100%',
			}}
		>
			<span>{label}</span>
			<span>{`${value}${extraElement}`}</span>
		</div>
	)
}

export const PreviewIOExpirationTag = ({
	latestContract,
	showMoreClick,
	setSelectedFile,
	setIsPreviewOpen,
	gridColumnSpan,
}: {
	latestContract: { expiration?: string; fileURL?: string }
	showMoreClick: (value: any, title: string) => void
	setSelectedFile: (file: string) => void
	setIsPreviewOpen: (isOpen: boolean) => void
	gridColumnSpan?: number
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(2, 1fr)`,
				flexDirection: 'column',
				gap: 6,
				gridColumn: `span ${gridColumnSpan || 1}`,
				gridRow: `span 1`,
				gridAutoRows: 50,
				columnGap: 30,
			}}
		>
			<PreviewFileTag
				label={'IO Preview'}
				fileUrl={latestContract?.fileURL || ''}
				onPreviewClick={(url: string) => {
					setIsPreviewOpen(true)
					setSelectedFile(url)
				}}
			/>
			<PreviewInfoTag
				label={'Expiration'}
				value={latestContract?.expiration || ''}
				showMoreClick={showMoreClick}
			/>
			{latestContract?.expiration && (
				<ReadMoreInlineButton
					label='More'
					onClick={showMoreClick as any}
					showIcon={true}
					style={{
						position: 'static',
						marginLeft: -8,
						marginTop: 18,
						backgroundColor: 'transparent',
						height: 30,
						width: 90,
					}}
				/>
			)}
		</div>
	)
}

export const PreviewFileTag = ({
	label,
	fileUrl,
	onPreviewClick,
	gridColumnSpan = 1,
	gridRowSpan = 1,
}: {
	label: string
	fileUrl: string
	gridColumnSpan?: number
	gridRowSpan?: number
	onPreviewClick: (fileUrl: string) => void
}) => {
	const theme = useTheme()
	const containerRef = useRef(null)

	const isClickable = !!fileUrl

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 8,
				gridColumn: `span ${gridColumnSpan}`,
				gridRow: `span ${gridRowSpan}`,
			}}
		>
			<PreviewLabel text={label} />

			<div
				style={{
					background: theme.colors.base.white,
					width: '100%',
					border: `1px solid ${theme.colors.preview.border}`,
					padding: 10,
					borderRadius: 4,
					fontSize: theme.font.size.body,
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					minHeight: '37px',
					height: '100%',
					position: 'relative',
					cursor: isClickable ? 'pointer' : 'default',
					color: isClickable
						? theme.colors.preview.blue300
						: theme.colors.base.gray500,
					textDecoration: 'none',
				}}
				ref={containerRef}
				onClick={isClickable ? () => onPreviewClick(fileUrl) : undefined}
			>
				{isClickable && (
					<>
						<VisibilityIcon
							style={{ marginRight: '4px', fill: theme.colors.preview.blue300 }}
						/>
						Preview
					</>
				)}
			</div>
		</div>
	)
}

const formatCountryString = (
	countries: {
		value: string
		label: string
		payout?: string
		volume?: string
	}[],
	theme: any,
	showFlag = false,
) => {
	// Check if all countries are selected
	const isGlobal = countries.length >= 245 // Assuming you have a constant for total countries

	// Filter countries with volume
	const countriesWithVolume = countries.filter(({ volume }) => !!volume)

	if (isGlobal) {
		if (countriesWithVolume.length > 0) {
			return [
				<span
					key={'global'}
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 10,
						flexShrink: 1,
						whiteSpace: 'nowrap',
					}}
				>
					{showFlag && <PublicIcon style={{ transform: 'scale(1)' }} />}
					<span
						style={{
							background: theme.colors.base.white,
							border: `1px solid ${theme.colors.preview.border}`,
							padding: '2px 10px 2px 4px',
							borderRadius: 6,
							fontSize: showFlag
								? theme.font.size.normal
								: theme.font.size.caption,
							display: 'flex',
							alignItems: 'center',
							gap: 10,
							minHeight: '28px',
							height: '80%',
							position: 'relative',
						}}
					>
						Global
					</span>
				</span>,
				...countriesWithVolume.map(({ label, payout, volume }) => {
					const [countryCode, countryName] = label.split(' - ')
					let displayText = `${countryCode} - ${countryName}`
					if (volume) displayText += ` - ${volume}`
					if (payout) displayText += ` - ${payout}`

					return (
						<span
							key={label}
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '5px',
								flexShrink: 1,
								whiteSpace: 'nowrap',
							}}
						>
							{showFlag && (
								<ReactCountryFlag
									style={{
										width: 30,
										height: 30,
										borderRadius: '100px',
										border: `1px solid ${theme.colors.black}`,
									}}
									countryCode={countryCode === 'UK' ? 'GB' : countryCode}
									svg
								/>
							)}
							<span
								style={{
									background: theme.colors.base.white,
									border: `1px solid ${theme.colors.preview.border}`,
									padding: '2px 10px 2px 4px',
									borderRadius: 6,
									fontSize: showFlag
										? theme.font.size.normal
										: theme.font.size.caption,
									display: 'flex',
									alignItems: 'center',
									gap: 10,
									minHeight: '28px',
									height: '80%',
									position: 'relative',
								}}
							>
								{displayText}
							</span>
						</span>
					)
				}),
			]
		} else {
			return (
				<span
					key={'global'}
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 10,
						flexShrink: 1,
						whiteSpace: 'nowrap',
					}}
				>
					{showFlag && <PublicIcon style={{ transform: 'scale(1)' }} />}
					<span
						style={{
							background: theme.colors.base.white,
							border: `1px solid ${theme.colors.preview.border}`,
							padding: '2px 10px 2px 4px',
							borderRadius: 6,
							fontSize: showFlag
								? theme.font.size.normal
								: theme.font.size.caption,
							display: 'flex',
							alignItems: 'center',
							gap: 10,
							minHeight: '28px',
							height: '80%',
							position: 'relative',
						}}
					>
						Global
					</span>
				</span>
			)
		}
	}

	// Default behavior: Show all countries
	return countries.map(({ label, payout, volume }, index) => {
		const [countryCode, countryName] = label.split(' - ')

		let displayText = `${countryCode} - ${countryName}`
		if (volume) displayText += ` | ${volume}`
		if (payout) displayText += ` | ${payout}$`

		return (
			<span
				key={label}
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '5px',
					flexShrink: 1,
					whiteSpace: 'nowrap',
				}}
			>
				{showFlag && (
					<ReactCountryFlag
						style={{
							width: 30,
							height: 30,
							borderRadius: '100px',
							border: `1px solid ${theme.colors.black}`,
						}}
						countryCode={countryCode === 'UK' ? 'GB' : countryCode}
						svg
					/>
				)}
				<span
					style={{
						background: theme.colors.base.white,
						border: `1px solid ${theme.colors.preview.border}`,
						padding: '2px 10px 2px 4px',
						borderRadius: 6,
						fontSize: showFlag
							? theme.font.size.normal
							: theme.font.size.caption,
						display: 'flex',
						alignItems: 'center',
						gap: 10,
						minHeight: '28px',
						height: '80%',
						position: 'relative',
					}}
				>
					{displayText}
				</span>
			</span>
		)
	})
}

export const CountryPayoutTag = ({
	label,
	countries,
	gridColumnSpan = 1,
	gridRowSpan = 1,
	showMoreClick,
}: {
	label: string
	countries: {
		value: string
		label: string
		payout?: string
		volume?: string
	}[]
	gridColumnSpan?: number
	gridRowSpan?: number
	showMoreClick: (value: any, title: string, searchBar: boolean) => void
}) => {
	const theme = useTheme()
	const containerRef = useRef<HTMLDivElement>(null)
	const textRef = useRef<HTMLSpanElement>(null)
	const [isOverflowing, setIsOverflowing] = useState(false)
	const [numberOfLines, setNumberOfLines] = useState(1)

	// const filteredCountries = countries.filter(
	// 	(
	// 		country,
	// 	): country is {
	// 		value: string
	// 		label: string
	// 		payout: string
	// 		volume?: string
	// 	} => !!country.payout,
	// )

	const checkOverflow = () => {
		if (!textRef.current || !containerRef.current) return

		requestAnimationFrame(() => {
			const textElement = textRef.current
			const containerElement = containerRef.current

			if (!textElement || !containerElement) return

			const textClone = document.createElement('span')
			textClone.textContent = textElement.textContent
			textClone.style.position = 'absolute'
			textClone.style.visibility = 'hidden'
			textClone.style.whiteSpace = 'nowrap'
			textClone.style.fontSize = window.getComputedStyle(textElement).fontSize
			textClone.style.fontWeight =
				window.getComputedStyle(textElement).fontWeight
			textClone.style.fontFamily =
				window.getComputedStyle(textElement).fontFamily
			textClone.style.letterSpacing =
				window.getComputedStyle(textElement).letterSpacing
			document.body.appendChild(textClone)

			const realTextWidth = textClone.offsetWidth
			document.body.removeChild(textClone)

			const containerWidth = containerElement.clientWidth
			setIsOverflowing(realTextWidth > containerWidth)

			const containerHeight = containerElement.offsetHeight
			const lineHeight =
				parseFloat(window.getComputedStyle(textElement).lineHeight) || 1.5
			setNumberOfLines(Math.ceil(containerHeight / lineHeight))
		})
	}

	useEffect(() => {
		checkOverflow()
		const handleResize = () => checkOverflow()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [countries])

	const displayedText = formatCountryString(countries, theme)
	const textWithFlag = formatCountryString(countries, theme, true)

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 8,
				gridColumn: `span ${gridColumnSpan}`,
				gridRow: `span ${gridRowSpan}`,
			}}
		>
			<span
				style={{
					color: theme.colors.preview.label,
					fontSize: theme.font.size.bodySmall,
					fontWeight: theme.font.weight.normal,
				}}
			>
				{label}
			</span>

			<div
				style={{
					background: theme.colors.base.white,
					width: '100%',
					border: `1px solid ${theme.colors.preview.border}`,
					padding: 10,
					borderRadius: 4,
					fontSize: theme.font.size.caption,
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					maxWidth: '100%',
					overflow: 'hidden',
					height: '37px',
					minHeight: '37px',
				}}
				ref={containerRef}
			>
				<span
					style={{
						display: 'inline-flex',
						alignItems: 'center',
						gap: '5px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						flexShrink: 1,
						minWidth: 0,
						maxWidth: '100%',
					}}
					ref={textRef}
				>
					{displayedText}
					{isOverflowing && (
						<ReadMoreInlineButton
							onClick={() => showMoreClick(textWithFlag, label, true)}
							style={{ marginLeft: '4px', bottom: 'auto', height: 30 }}
						/>
					)}
				</span>
			</div>
		</div>
	)
}

interface TruncatedTextProps {
	text: string
	/** Container width in px */
	maxWidth?: number
	/** Title for the expanded modal */
	expandedModalTitle?: string
	handleShowMoreClick: any
}

export const TruncatedTextWithReadMore: React.FC<TruncatedTextProps> = ({
	text,
	maxWidth = 300,
	expandedModalTitle = 'Full Text',
	handleShowMoreClick,
}) => {
	const theme = useTheme()
	const containerRef = useRef<HTMLDivElement>(null)
	const textRef = useRef<HTMLSpanElement>(null)

	const [overflowing, setOverflowing] = useState(false)
	const [showModal, setShowModal] = useState(false)

	// Adjust this if your "Read more" button is smaller/larger:
	const BUTTON_WIDTH = 85

	useEffect(() => {
		const checkOverflow = () => {
			if (!textRef.current) return
			/*
		  Because we give the container paddingRight = BUTTON_WIDTH,
		  the text <span> effectively gets that much less space.

		  We just do a simple check:
		  If text's scrollWidth > text's clientWidth,
		  it means we have an overflow and should show the button.
		*/
			setOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth)
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)
		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [text])

	return (
		<>
			<div
				ref={containerRef}
				style={{
					position: 'relative',
					width: `100%`,
					// Reserve space for the button on the right
					paddingRight: `${BUTTON_WIDTH}px`,
					boxSizing: 'border-box',

					// Single-line truncation
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}}
			>
				<span
					ref={textRef}
					style={{
						display: 'inline-block',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						// The span will be truncated within this container
						width: '100%',
					}}
				>
					{text}
				</span>

				{overflowing && (
					<div
						style={{
							position: 'absolute',
							bottom: '1.5px',
							right: 0,
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							background: `transparent`,
						}}
					>
						<ReadMoreInlineButton
							label='Read More'
							onClick={() => handleShowMoreClick(text)}
							style={{
								width: 'auto',
								backgroundColor: 'transparent',
								marginTop: '2rem',
							}}
						/>
					</div>
				)}
			</div>

			{/* Full-text Modal
			<CustomModal
				title={expandedModalTitle}
				open={showModal}
				onClose={() => setShowModal(false)}
				maxWidth='sm'
				fullWidth={false}
			>
				<div style={{ padding: '1rem' }}>{text}</div>
			</CustomModal> */}
		</>
	)
}

export const PreviewIconsComponent = ({
	label = 'Badges',
	icons,
}: {
	label?: string
	icons: any
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-evenly',
				overflow: 'hidden',
				alignItems: 'center',
				gap: '10px',
				background: theme.colors.base.white,
				border: '1px solid ' + theme.colors.preview.border,
				borderRadius: 40,
				textTransform: 'none',
				fontSize: theme.font.size.buttons,
				color: theme.colors.text.titles,
				padding: '0px 18px',
				height: 40,
			}}
		>
			<span>{label}:</span>
			{icons.map((el: any) => {
				return (
					<Tooltip title={el.name} arrow>
						{el.icon}
					</Tooltip>
				)
			})}
		</div>
	)
}
export interface EditableGridItemProps {
	children: React.ReactNode
	gridColumnSpan?: number
	gridRowSpan?: number
}

export const EditableGridItem: React.FC<EditableGridItemProps> = ({
	children,
	gridColumnSpan = 1,
	gridRowSpan = 1,
}) => {
	return (
		<div
			style={{
				gridColumn: `span ${gridColumnSpan}`,
				gridRow: `span ${gridRowSpan}`,
			}}
		>
			{children}
		</div>
	)
}

interface PreviewLabelProps {
	text: string
	additionalStyle?: React.CSSProperties
}

export const PreviewLabel: React.FC<PreviewLabelProps> = ({
	text,
	additionalStyle,
}) => {
	const theme = useTheme()

	return (
		<span
			style={{
				color: theme.colors.preview.label,
				fontSize: theme.font.size.bodySmall,
				fontWeight: theme.font.weight.normal,
				whiteSpace: 'nowrap',
				marginLeft: 10,
				...additionalStyle,
			}}
			className='preview-label'
		>
			{text}

			<style>
				{`
         @media (min-width: 1920px) {
			.preview-label {
			  margin-left: 0.3rem;
			}
		  }

        `}
			</style>
		</span>
	)
}
