import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { AppIdLinkPair } from '../../models/model.interface'
import { InputFieldText } from '../components/Inputs'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
	IconCircleWrapper,
	ReadMoreInlineButton,
	UnborderedButton,
} from '../components/Buttons'
import { CustomActionsMenu } from '../components/Menus'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { StyledLabel } from '../components/Informative'
import { chunkArrayIntoGroups } from '../../utils/helpers/helperFuncs'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'

export const CounterComponent = (props: {
	activeCount: number
	overallCount: number
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				padding: '10px',
				borderRadius: theme.dimentions.biggerRadius,
				border: '1px solid',
				borderColor: theme.colors.base.grey300,
				height: '23px',
				width: '180px',
			}}
		>
			<Typography
				fontWeight='500'
				color={theme.colors.base.grey900}
				position='relative'
				style={{ float: 'left' }}
			>
				{props.overallCount} Overall
			</Typography>
			<Typography
				fontWeight='500'
				color={theme.colors.base.green300}
				position='relative'
				style={{ float: 'right' }}
			>
				{props.activeCount} Active
			</Typography>
		</div>
	)
}

const deduplicateAppIdPairs = (pairs: AppIdLinkPair[]): AppIdLinkPair[] => {
	const seen = new Set<string>()
	return pairs.filter(({ app_id }) => {
		const trimmed = app_id.trim()
		if (!trimmed) {
			return true
		}
		if (seen.has(trimmed)) {
			// Already seen this app_id
			return false
		}
		seen.add(trimmed)
		return true
	})
}

const CheckUniqueAppIds = (
	all: AppIdLinkPair[],
	currentIndex: number,
	val: string,
) => {
	const trimmedVal = val.trim()
	if (!trimmedVal) return false // let empty be typed
	return all.some(
		(el, idx) => idx !== currentIndex && el.app_id.trim() === trimmedVal,
	)
}

interface AppsForAdvertiserSectionProps {
	appsData: AppIdLinkPair[]
	setAppsData: (value: AppIdLinkPair[]) => void
	advertiserType?: string
	apps?: string[]
	permissions: any
}

const AppsForAdvertiserSection: React.FC<AppsForAdvertiserSectionProps> = ({
	appsData,
	setAppsData,
	advertiserType,
	apps,
	permissions,
}) => {
	const theme = useTheme()

	// Local copy of appsData for editing performance:

	const [localApps, setLocalApps] = useState<AppIdLinkPair[]>(() =>
		deduplicateAppIdPairs(appsData),
	)
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
	const [hoverButton, setHoverButton] = useState(false)

	const isMandatoryAdvertiserType = advertiserType === 'Direct'

	// Sync local state if parent updates appsData
	useEffect(() => {
		setLocalApps(deduplicateAppIdPairs(appsData))
	}, [appsData])

	const updateBothStates = useCallback(
		(newArray: AppIdLinkPair[]) => {
			const deduped = deduplicateAppIdPairs(newArray)
			setLocalApps(deduped)
			setAppsData(deduped)
		},
		[setAppsData],
	)

	const handleDeleteAppId = (index: number) => {
		// remove that row from local
		const updated = localApps.filter((_, idx) => idx !== index)
		updateBothStates(updated)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
		setSelectedIndex(null)
	}

	const handleDeleteAppIds = () => {
		if (selectedIndex !== null) {
			const updated = localApps.filter((_, idx) => idx !== selectedIndex)
			updateBothStates(updated)
		}
		handleMenuClose()
	}

	// Turn localApps into groups of 2 rows, same as before
	const localAppsInPairs = chunkArrayIntoGroups(localApps)

	const handleAddApp = () => {
		const updated = [...localApps, { app_id: '', link: '' }]
		updateBothStates(updated)
	}

	return (
		<div style={{ width: '100%' }}>
			<div>
				<StyledLabel
					focused={false}
					error={false}
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginBottom: '0.5rem',
					}}
				>
					<span>Apps ID&apos;s</span>
					{isMandatoryAdvertiserType && (
						<span
							style={{
								color: theme.colors.base.red300,
								fontSize: '0.8rem',
								fontWeight: 'bold',
							}}
						>
							*
						</span>
					)}
				</StyledLabel>
			</div>

			<Grid container columnSpacing={10} rowSpacing={2}>
				{localAppsInPairs.map((row, rowIndex) => (
					<Grid container item xs={12} spacing={6} key={rowIndex}>
						{row.map((item, colIndex) => {
							return (
								<AppElement
									rowIndex={rowIndex}
									colIndex={colIndex}
									item={item}
									permissions={permissions}
									advertiserType={advertiserType}
									apps={apps}
									setLocalApps={setLocalApps}
									setAppsData={setAppsData}
									localApps={localApps}
									handleDeleteAppId={handleDeleteAppId}
								/>
							)
						})}
					</Grid>
				))}
			</Grid>

			{/* Add new row button */}
			<div
				style={{
					position: 'relative',
					marginTop: '0.8rem',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Box
					display='flex'
					alignItems='center'
					onMouseEnter={() => setHoverButton(true)}
					onMouseLeave={() => setHoverButton(false)}
				>
					<UnborderedButton
						onClick={handleAddApp}
						icon={
							<AddIcon
								style={{
									border: `1px solid ${theme.colors.text.titles}`,
									borderRadius: '50%',
									width: '16px',
									height: '16px',
									transition: 'background-color 0.3s',
									color: theme.colors.text.titles,
								}}
							/>
						}
						label='Add App ID'
						style={{
							textDecoration: 'underline',
							color: hoverButton
								? theme.colors.base.green300
								: theme.colors.text.titles,
							fontSize: theme.font.size.caption,
							transition: 'color 0.3s',
						}}
					/>
				</Box>
			</div>

			{/* Actions menu for deletes */}
			{anchorEl && (
				<CustomActionsMenu
					anchorEl={anchorEl}
					isOpen={Boolean(anchorEl)}
					handleClose={handleMenuClose}
					menuItems={[
						{
							label: 'Delete App Id',
							icon: <DeleteIcon />,
							onClick: handleDeleteAppIds,
							hoverColor: theme.colors.base.red300,
						},
					]}
				/>
			)}
		</div>
	)
}

export default AppsForAdvertiserSection

const AppElement = ({
	rowIndex,
	colIndex,
	item,
	permissions,
	advertiserType,
	apps,
	setLocalApps,
	setAppsData,
	localApps,
	handleDeleteAppId,
}: {
	rowIndex: number
	colIndex: number
	item: any
	permissions: any
	advertiserType: string | undefined
	apps: any
	setLocalApps: any
	setAppsData: any
	localApps: any
	handleDeleteAppId: any
}) => {
	const theme = useTheme()
	const indexInFullArray = rowIndex * 2 + colIndex
	const [appIdUsed, setAppIdUsed] = useState(false)

	const updateAppsData = useCallback(() => {
		setAppsData(localApps)
	}, [localApps, setAppsData])

	useEffect(() => {
		const DEBOUNCE_DELAY = 300
		const timerId = setTimeout(updateAppsData, DEBOUNCE_DELAY)

		return () => clearTimeout(timerId)
	}, [updateAppsData])

	return (
		<Grid item xs={12} sm={6} key={indexInFullArray}>
			<Grid
				container
				alignItems='center'
				spacing={1}
				sx={{
					backgroundColor: theme.colors.preview.cardBackground,
					borderRadius: '8px',
					paddingBottom: '0.5rem',
				}}
			>
				<Grid item xs={4}>
					<InputFieldText
						placeholder='Add App ID'
						noLabelSpace={true}
						type='text'
						value={item.app_id}
						disabled={!permissions.edit}
						style={{ width: '100%' }}
						mandatory={advertiserType === 'Direct' && (apps?.length || 0) === 0}
						errormessage={
							appIdUsed ? 'This App ID is already registered' : undefined
						}
						onChange={(val: string) => {
							setLocalApps((prev: AppIdLinkPair[]) => {
								const clone = [...prev]
								const isDuplicate = CheckUniqueAppIds(
									prev,
									indexInFullArray,
									val,
								)
								if (isDuplicate) {
									setAppIdUsed(true)
									return prev
								}
								setAppIdUsed(false)
								clone[indexInFullArray].app_id = val
								return clone
							})
						}}
					/>
				</Grid>

				<Grid item xs={7}>
					<InputFieldText
						placeholder='Add Link'
						noLabelSpace={true}
						color={
							item?.link && !checkInputStartsWithHttp(item?.link)
								? 'error'
								: 'primary'
						}
						errormessage={
							item.link && !checkInputStartsWithHttp(item.link)
								? "Links must start with 'http://', 'https://', 'G:', or 'g:'"
								: undefined
						}
						type='text'
						value={item.link}
						disabled={!permissions.edit}
						style={{ width: '103%' }}
						mandatory={advertiserType === 'Direct' && (apps?.length || 0) === 0}
						onChange={(val: string) => {
							setLocalApps((prev: any) => {
								const clone = [...prev]
								clone[indexInFullArray].link = val
								return clone
							})
						}}
						onBlur={() => {
							setAppsData(localApps)
						}}
					/>
				</Grid>

				<Grid
					item
					xs={1}
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<IconButton
						onClick={() => handleDeleteAppId(indexInFullArray)}
						disabled={!permissions.edit}
						sx={{ padding: 0 }}
					>
						<IconCircleWrapper
							size={20}
							circleColor={theme.colors.base.grey600}
							iconColor={theme.colors.base.white}
							hoverCircleColor={theme.colors.base.grey300}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CloseIcon sx={{ fontSize: 14, display: 'block' }} />
						</IconCircleWrapper>
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	)
}
