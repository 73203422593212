import { useEffect, useRef, useState } from 'react'
import { useTheme, Card, CardContent, Box } from '@mui/material'
import { DropList } from '../components/SelectableInputs'
import { StyledTextAreaField } from '../components/Inputs'
import DocumentRow from '../components/DocumentRow'
import {
	EditableGridItem,
	PreviewContainer,
} from '../components/PreviewComponents'
import { UnborderedButton } from '../components/Buttons'
import AddIcon from '@mui/icons-material/Add'
import { ContractsInterface } from '../../models/model.interface'
import { DOCUMENT_FILE_TYPES } from '../../config'

// Main Component
export const AdvertiserFinanceInfo = (props: {
	paymentTerms: string
	setPaymentTerms: (term: string) => void
	vat: string
	setVat: (vat: string) => void
	branch: string
	setBranch: (branch: string) => void
	invoiceInstructions: string
	setInvoiceInstructions: (instructions: string) => void
	settings: any
	loginRole: any
	mandatoryFields?: any
	fieldValidations?: any
	permissions: any
	contracts?: ContractsInterface[]
	setContracts?: (contracts: ContractsInterface[]) => void
}) => {
	const theme = useTheme()
	const permissions = props.permissions
	const [isHovered, setIsHovered] = useState(false)
	const [rows, setRows] = useState(8)
	const invoiceRef = useRef<any>(null)

	const handleAddDocument = () => {
		try {
			if (props.setContracts) {
				const newDocument: ContractsInterface = {
					fileURL: '',
					expiration: '',
					fileType: '',
					fileName: '',
					file: '',
				}
				const updatedContracts = props.contracts
					? [...props.contracts, newDocument]
					: [newDocument]
				props.setContracts(updatedContracts)
			} else {
				console.error('setContracts function is not provided')
			}
		} catch (error) {
			console.error('Error adding new document: ', error)
			throw error
		}
	}
	useEffect(() => {
		const rowHeight = 32
		const inputElement = invoiceRef.current
		if (!inputElement) return
		const resizeObserver = new ResizeObserver(() => {
			if (inputElement) {
				// -40 because it's the padding of the card
				const height = inputElement.clientHeight - 40
				const newRowsValue = Math.floor(height / rowHeight)
				setRows(newRowsValue)
			}
		})

		resizeObserver.observe(inputElement)

		return () => resizeObserver.disconnect()
	}, [])

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					gap: 4,
					width: '100%',
					maxWidth: '90vw',
					padding: '40px 80px 0 0',
					marginLeft: '-4rem',
				}}
			>
				<div
					style={{
						flex: 3,
						gap: 12,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Card
						sx={{
							background: theme.colors.preview.cardBackground,
							borderRadius: 6,
							padding: 1,
							overflow: 'hidden',
						}}
					>
						<CardContent>
							<PreviewContainer
								gridTemplate='repeat(4, 1fr)'
								additionalStyle={{
									border: 'none',
									width: '100%',
									maxHeight: 400,
									overflow: 'hidden',
									padding: '10px',
									columnGap: '30px',
								}}
							>
								<EditableGridItem gridColumnSpan={1}>
									<DropList
										label='Internal Legal Identity'
										placeholder='Select Legal Entity'
										options={props.settings.settings.branches}
										value={props.branch}
										onChange={(e: string) => props.setBranch(e)}
										mandatory
										disabled={!permissions.edit}
									/>
								</EditableGridItem>
								<EditableGridItem gridColumnSpan={1}>
									<DropList
										label='Payment Term'
										options={props.settings.settings.paymentTerms}
										value={props.paymentTerms}
										onChange={(e: string) => props.setPaymentTerms(e)}
										disabled={!permissions.edit}
										mandatory
									/>
								</EditableGridItem>
								<EditableGridItem gridColumnSpan={1}>
									<DropList
										label='VAT (%)'
										options={props.settings.settings.vatValues}
										value={props.vat}
										onChange={(e: string) => props.setVat(e)}
										disabled={!permissions.edit}
									/>
								</EditableGridItem>
							</PreviewContainer>
						</CardContent>
					</Card>
					<Card
						sx={{
							background: theme.colors.preview.cardBackground,
							borderRadius: 6,
							padding: 1,
							overflow: 'hidden',
						}}
					>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<Box
								sx={{
									fontWeight: theme.font.weight.bold,
									marginBottom: '-30px',
									paddingBottom: '30px',
									marginLeft: '20px',
								}}
							>
								Documents
							</Box>
							<PreviewContainer
								gridTemplate='repeat(11, 1fr)'
								additionalStyle={{
									border: 'none',
									width: '100%',
									flex: 1,
									overflowY: 'auto',
									padding: '10px',
									maxHeight: 500,
								}}
							>
								{props.contracts?.map(
									(contract: ContractsInterface, index: number) => (
										<EditableGridItem
											key={index}
											gridColumnSpan={11}
											gridRowSpan={1}
										>
											<DocumentRow
												document={contract}
												fileTypes={DOCUMENT_FILE_TYPES}
												onChange={(updatedDocument) => {
													try {
														const updatedContracts = [...props.contracts!]
														updatedContracts[index] = updatedDocument
														props.setContracts!(updatedContracts)
													} catch (error) {
														console.error('Error updating document: ', error)
														throw error
													}
												}}
												onDelete={() => {
													try {
														const updatedContracts = props.contracts!.filter(
															(_, i) => i !== index,
														)
														props.setContracts!(updatedContracts)
													} catch (error) {
														console.error('Error deleting document: ', error)
														throw error
													}
												}}
											/>
										</EditableGridItem>
									),
								)}
								{/* Add Document Button */}
								<EditableGridItem gridColumnSpan={10}>
									<UnborderedButton
										onClick={handleAddDocument}
										label={'Add Document'}
										onMouseEnter={() => setIsHovered(true)}
										onMouseLeave={() => setIsHovered(false)}
										icon={
											<AddIcon
												style={{
													border: `1px solid ${theme.colors.base.grey900}`,
													borderRadius: '100px',
													width: '14px',
													height: '14px',
													color: theme.colors.base.grey900,
													backgroundColor: isHovered
														? theme.colors.base.grey200
														: 'transparent',
													transition: 'background-color 0.3s',
												}}
											/>
										}
										style={{
											color: isHovered
												? theme.colors.base.green300
												: theme.colors.text.titles,
											fontSize: theme.font.size.caption,
											transition: 'color 0.3s',
											// marginLeft: '30px',
										}}
									/>
								</EditableGridItem>
							</PreviewContainer>
						</CardContent>
					</Card>
				</div>

				<Card
					sx={{
						flex: 2, // Finance takes less space than Documents
						background: theme.colors.preview.cardBackground,
						borderRadius: 6,
						padding: 1,
						overflow: 'hidden',
					}}
					ref={invoiceRef}
				>
					<CardContent style={{ marginTop: 10 }}>
						<StyledTextAreaField
							label='Invoice Instructions'
							value={props.invoiceInstructions}
							onChange={props.setInvoiceInstructions}
							disabled={!permissions.edit}
							rows={rows}
						/>
					</CardContent>
				</Card>
			</Box>
		</Box>
	)
}
