import { Box, Card, CardContent, useTheme } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { InputFieldText } from '../../components/Inputs'
import { ContractsInterface } from '../../../models/model.interface'
import { PreviewContainer } from '../../components/PreviewComponents'
import { EditableGridItem } from '../../components/PreviewComponents'
import { DropList } from '../../components/SelectableInputs'
import DocumentRow from '../../components/DocumentRow'
import { UnborderedButton } from '../../components/Buttons'
import AddIcon from '@mui/icons-material/Add'
import { DOCUMENT_FILE_TYPES } from '../../../config'

export const CreatorFinanceInfo = (props: {
	name: string
	iconUrl: string
	paymentTerm: string
	setPaymentTerm: any
	branch: string
	setBranch: Dispatch<SetStateAction<string>>
	vat: string
	setVat: any
	settings: any
	financeId: string
	setFinanceId: any
	// financeIdInUse: any
	// loginRole: any
	// mandatoryFields: any
	contracts: ContractsInterface[]
	setContracts: any
}) => {
	const theme = useTheme()
	const permissions = { edit: true }
	const [isHovered, setIsHovered] = useState(false)

	const handleAddDocument = () => {
		try {
			if (props.setContracts) {
				const newDocument: ContractsInterface = {
					fileURL: '',
					expiration: '',
					fileType: '',
					fileName: '',
					file: '',
				}
				const updatedContracts = props.contracts
					? [...props.contracts, newDocument]
					: [newDocument]
				props.setContracts(updatedContracts)
			} else {
				console.error('setContracts function is not provided')
			}
		} catch (error) {
			console.error('Error adding new document: ', error)
			throw error
		}
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					gap: 4,
					width: '100%',
					maxWidth: '80vw',
					padding: '40px 80px 0 0',
					marginLeft: '-4rem',
				}}
			>
				{/* Documents Card (2/3 width) */}
				<Card
					sx={{
						flex: 2,
						background: theme.colors.preview.cardBackground,
						borderRadius: 6,
						padding: 1,
						overflow: 'hidden',
					}}
				>
					<CardContent
						sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
					>
						<Box
							sx={{
								fontWeight: theme.font.weight.bold,
								marginBottom: '-30px',
								paddingBottom: '30px',
								marginLeft: '3.1rem',
							}}
						>
							Documents
						</Box>
						<PreviewContainer
							gridTemplate='repeat(10, 1fr)'
							additionalStyle={{
								border: 'none',
								width: '100%',
								flex: 1,
								overflowY: 'auto',
								padding: '10px',
								maxHeight: 500,
							}}
						>
							{props.contracts?.map(
								(contract: ContractsInterface, index: number) => (
									<EditableGridItem
										key={index}
										gridColumnSpan={10}
										gridRowSpan={1}
									>
										<DocumentRow
											document={contract}
											fileTypes={DOCUMENT_FILE_TYPES}
											onChange={(updatedDocument) => {
												try {
													const updatedContracts = [...props.contracts!]
													updatedContracts[index] = updatedDocument
													props.setContracts!(updatedContracts)
												} catch (error) {
													console.error('Error updating document: ', error)
													throw error
												}
											}}
											onDelete={() => {
												try {
													const updatedContracts = props.contracts!.filter(
														(_, i) => i !== index,
													)
													props.setContracts!(updatedContracts)
												} catch (error) {
													console.error('Error deleting document: ', error)
													throw error
												}
											}}
										/>
									</EditableGridItem>
								),
							)}
							{/* Add Document Button */}
							<EditableGridItem gridColumnSpan={10}>
								<UnborderedButton
									onClick={handleAddDocument}
									label={'Add Document'}
									onMouseEnter={() => setIsHovered(true)}
									onMouseLeave={() => setIsHovered(false)}
									icon={
										<AddIcon
											style={{
												border: `1px solid ${theme.colors.base.grey900}`,
												borderRadius: '100px',
												width: '14px',
												height: '14px',
												color: theme.colors.base.grey900,
												background: isHovered
													? theme.colors.base.grey200
													: 'transparent',
												transition: 'background-color 0.3s',
											}}
										/>
									}
									style={{
										color: isHovered
											? theme.colors.base.green300
											: theme.colors.text.titles,
										fontSize: theme.font.size.caption,
										transition: 'color 0.3s',
										marginLeft: '30px',
									}}
								/>
							</EditableGridItem>
						</PreviewContainer>
					</CardContent>
				</Card>

				{/* Finance Data Card (1/3 width, FULL DATA) */}
				<Card
					sx={{
						flex: 1, // Finance takes less space than Documents
						background: theme.colors.preview.cardBackground,
						borderRadius: 6,
						padding: 1,
						minHeight: 250,
						maxHeight: 250,
						overflow: 'hidden',
					}}
				>
					<CardContent>
						<PreviewContainer
							gridTemplate='repeat(10, 1fr)'
							additionalStyle={{
								border: 'none',
								width: '100%',
								minHeight: 250,
								maxHeight: 400,
								overflow: 'hidden',
								padding: '10px',
							}}
						>
							<EditableGridItem gridColumnSpan={5}>
								<InputFieldText
									label='Finance ID'
									type='text'
									value={props.financeId}
									onChange={props.setFinanceId}
									errormessage={''}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={5}>
								<DropList
									label='Internal Legal Identity'
									placeholder='Select Legal Entity'
									options={props.settings.settings.branches}
									value={props.branch}
									onChange={(e: string) => props.setBranch(e)}
									mandatory
									disabled={!permissions.edit}
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={5}>
								<DropList
									label='Payment Term'
									options={props.settings.settings.paymentTerms}
									value={props.paymentTerm}
									onChange={(e: string) => props.setPaymentTerm(e)}
									disabled={!permissions.edit}
									mandatory
								/>
							</EditableGridItem>
							<EditableGridItem gridColumnSpan={5}>
								<DropList
									label='VAT (%)'
									options={props.settings.settings.vatValues}
									value={props.vat}
									onChange={(e: string) => props.setVat(e)}
									disabled={!permissions.edit}
								/>
							</EditableGridItem>
						</PreviewContainer>
					</CardContent>
				</Card>
			</Box>
		</Box>
	)
}
