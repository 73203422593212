import { CheckBox, Public } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ClearIcon from '@mui/icons-material/Clear'
import DownloadIcon from '@mui/icons-material/Download'
import ErrorIcon from '@mui/icons-material/Error'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import FilterListIcon from '@mui/icons-material/FilterList'
import HelpIcon from '@mui/icons-material/Help'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LinkIcon from '@mui/icons-material/Link'
import SearchIcon from '@mui/icons-material/Search'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddIcon from '@mui/icons-material/Add'
import UploadIcon from '../../assets/svg/upload-icon-3.svg'
import {
	default as Visibility,
	default as VisibilityIcon,
} from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
	Autocomplete,
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	Checkbox,
	Chip,
	Dialog,
	FormControl,
	FormControlProps,
	IconButton,
	InputAdornment,
	InputBase,
	InputLabel,
	Menu,
	MenuItem,
	Modal,
	Popper,
	Select,
	styled,
	TextField,
	TextFieldProps,
	Theme,
	Tooltip,
	Typography,
	TypographyProps,
	useTheme,
} from '@mui/material'
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { debounce } from 'lodash'
import React, {
	CSSProperties,
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
	ChangeEvent,
} from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import ReactCountryFlag from 'react-country-flag'
import downloadFolderIconWhite from '../../assets/download-white.png'
import downloadFolderIcon from '../../assets/download.png'
import sendWhiteIcon from '../../assets/send-white.png'
import sendIcon from '../../assets/send.png'
import search from '../../assets/search-varys.svg'
import appsIcon from '../../assets/varys-applist-icon-2.svg'
import filterRemoveIcon from '../../assets/varys-filter-remove.svg'
import mediaPlanIcon from '../../assets/varys-media-plan-icon.svg'
import uploadFolderIconWhite from '../../assets/upload-white.png'
import uploadFolderIcon from '../../assets/upload.png'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ContractsInterface, UserInterface } from '../../models/model.interface'
import { convertPathName, stringAvatar } from '../../utils/helpers/helperFuncs'
import { PublishersList } from '../campaigns/CampaignComponents'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import moment from 'moment'
import { InfoField } from '../advertisers/AdvertiserPreviewPage'
import { StatusButton, SubmitButton, UnborderedButton } from './Buttons'
import { StyledLabel, StyledMenuItem } from './Informative'
import { StyledInputLabel } from '../campaigns/CampaignStyled'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import DocumentPreview from './DocumentPreview'

export const TableWrapper = (props: {
	style?: any
	children: any
	theme: Theme
	setRowWidth: any
	rowCount: number
	height?: string
}) => {
	const [tableWidth, setTableWidth] = useState(
		window.innerWidth - 20 - props.theme.dimentions.drawerWidth,
	)
	useEffect(() => {
		const handleResize = debounce((e: UIEvent) => {
			const target = e.target as Window
			const width = target.innerWidth - props.theme.dimentions.drawerWidth
			setTableWidth(width)
			props.setRowWidth(width / props.rowCount)
		}, 100)

		window.addEventListener('resize', handleResize, true)
		return () => {
			window.removeEventListener('resize', handleResize, true)
		}
	}, [props])

	return (
		<div style={props.style}>
			<div
				style={{
					position: 'absolute',
					fontSize: '150px',
					//align: 'center',
					marginLeft: '-10px',
					height: props.height ? props.height : '90vh',
					// overflowX: 'auto',
					width: '95vw',
					// width: tableWidth * 1.01,
				}}
			>
				{props.children}
			</div>
		</div>
	)
}

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
	paddingTop: '0px',
	paddingLeft: '0px',

	'& .MuiOutlinedInput-root': {
		backgroundColor: 'transparent',
		borderRadius: theme.dimentions.smallerRadius,

		padding: '0px',
		'& .MuiAutocomplete-input': {
			padding: '0px',
			// width: '800px',
		},
	},
	'& .MuiOutlinedInput-input': {
		backgroundColor: 'transparent',
		//borderRadius: theme.dimentions.smallerRadius,
		'& .MuiAutocomplete-popper': {
			width: '500px',
		},
		padding: '0px',
	},
}))

export const InfoTag = (props: { title: string }) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				<HelpIcon
					style={{
						fill: theme.colors.base.red300,
						top: '-1.3vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						paddingTop: '7px',
					}}
				/>
			</Tooltip>
		</div>
	)
}
export const ErrorTag = (props: { title: string; color?: string }) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				<ErrorIcon
					style={{
						fill: theme.colors.base.red300,
						top: '-1.3vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						paddingTop: '7px',
					}}
				/>
			</Tooltip>
		</div>
	)
}
export const CancelIconButton = styled(IconButton)(({ theme }) => ({
	flow: 'left',
	position: 'absolute',
	zIndex: 4,
	marginLeft: '140px',
	marginTop: '-20px',
	padding: 'unset',

	color: theme.colors.red,
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const UserNameWithAvatar = (props: {
	avatars: any
	value: string
	withoutName?: boolean
	position?: string
	country?: string
	bigPicture?: boolean
	onClick?: any
	selected?: boolean
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				// marginBlock: '25px',
				width: '20vw',
				gap: '0.5vw',
				justifyContent: 'inherit',
			}}
			data-tag={'_navbarButton'}
		>
			<button
				style={{
					display: 'flex',
					position: 'relative',
					border: 'none',
					cursor: props.onClick ? 'pointer' : 'inherit',
				}}
				onClick={props.onClick ? props.onClick : () => {}}
				data-tag={'_navbarButton'}
			>
				<Avatar
					data-tag={'_navbarButton'}
					src={props.avatars[props.value.toLowerCase()] || 'na '}
					{...stringAvatar(props.value)}
					style={{
						height: props.bigPicture ? '130px' : 'inherited',
						width: props.bigPicture ? '130px' : 'inherited',
						// border: '2px solid ' + theme.colors.base.white,
						boxShadow: `0px 0px 0px 2px ${
							props.selected ? theme.colors.base.white : 'rgba(0,0,0,0)'
						}`,
					}}
				/>
				{props.country && (
					<div
						style={{
							height: '1rem',
							width: '1rem',
							display: 'flex',
							position: 'absolute',
							top: '-4px',
							right: '-2px',
							borderRadius: '100px',
							overflow: 'hidden',
							boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
						}}
					>
						<ReactCountryFlag
							style={{
								width: '150%',
								scale: '1.3',
								position: 'relative',
							}}
							key={'flags' + props.country}
							className='roundedFlag'
							countryCode={props.country === 'UK' ? 'GB' : props.country}
							svg
						/>
					</div>
				)}

				{/* style={{
						height: '1rem',
						width: '1rem',
						display: 'flex',
						position: 'absolute',
						bottom: -4,
						left: 0,
						border: '1px solid black',
					}} */}
			</button>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					gap: '4px',
					color: theme.colors.text.titles,
				}}
			>
				{!props.withoutName && (
					<span
						style={{
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.buttons,
						}}
					>
						{props.value}
					</span>
				)}
				{props.position && (
					<span
						style={{
							fontWeight: theme.font.weight.skinny,
							fontSize: theme.font.size.body,
						}}
					>
						{props.position}
					</span>
				)}
			</div>
		</div>
	)
}
export const CancelButton = styled(Button)(({ theme }) => ({
	flow: 'left',
	position: 'relative',
	margin: '10px',
	width: '15%',
	background: theme.palette.primary.main,
	borderRadius: '10px',
	borderColor: theme.colors.lightGray,
	border: '1px solid rgba(0,0,0,0.2)',
	boxShadow: '1px 1px 0px 1px rgba(0,0,0,0.2)',
	color: theme.palette.primary.dark,
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const AddButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
	flow: 'right',
	position: 'relative',
	margin: '10px',
	width: '15%',
	background: theme.colors.buttonGradient,
	borderRadius: '10px',
	// boxShadow: theme.shadow.boxShadow,
	color: theme.colors.black,
	border: '1px solid rgba(0,0,0,0.2)',
	boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
	// ...(isActive && {
	// 	boxShadow: 'none',
	// 	translate: '2px,2px',
	// }),
}))

export const StyledButtonComp = styled(Button)(({ theme }) => ({
	flow: 'right',
	position: 'relative',

	width: '15%',
	background: theme.colors.gradient,
	borderRadius: '11px',
	// boxShadow: theme.shadow.boxShadow,
	color: theme.colors.black,
	// border: '1px solid rgba(0,0,0,0.2)',
	// boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const StyledBoxComp = styled(Box)(({ theme }) => ({
	flow: 'right',
	position: 'relative',

	width: '15%',
	background: theme.colors.gradient,
	borderRadius: '11px',
	// boxShadow: theme.shadow.boxShadow,
	color: theme.colors.black,
	// border: '1px solid rgba(0,0,0,0.2)',
	// boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const BorderGradientButton = (props: {
	tooltip?: string
	onClick: any
	children?: any
	style?: any
}) => {
	return (
		<StyledBoxComp
			style={{
				...props.style,
				margin: '0px 4px',
				width: props.style ? props.style.width ?? '200px' : '200px',
				padding: '1px',
				boxShadow: 'none',
				marginTop: props.style ? props.style.marginTop ?? '20px' : '20px',
			}}
		>
			{props.tooltip ? (
				<Tooltip
					title={props.tooltip}
					// style={{ marginLeft: '35px' }}
					placement='top-end'
				>
					<AddButton
						style={{
							margin: '0px',
							width: '100%',
							padding: '10px',
							paddingInline: '20px',
							boxShadow: 'none',
							border: 'none',
						}}
						onClick={props.onClick}
						children={props.children}
					></AddButton>
				</Tooltip>
			) : (
				<AddButton
					style={{
						margin: '0px',
						width: '100%',
						padding: '10px',
						paddingInline: '20px',
						boxShadow: 'none',
						border: 'none',
					}}
					onClick={props.onClick}
					children={props.children}
				></AddButton>
			)}
		</StyledBoxComp>
	)
}
export const PageWrapper = styled('div')(() => ({
	// marginInline: '2rem',
	// marginTop: '6rem',
	marginLeft: 40,
	marginTop: 34,
}))

export const Logo = styled(Box)(() => ({
	position: 'absolute',
	width: '120px',
	bottom: '3%',
	zIndex: 12,
}))

export const GreetingsComponent = styled(Typography)(({ theme }) => ({
	...theme.typography,
	color: theme.palette.common.black,
	fontSize: '20px',
	fontWeight: '600',
	marginLeft: '5px',
	marginBottom: '30px',
	// border: '1px solid rgba(0,0,0,0.1)',
	// 						borderRadius: '20px',
	// 						boxShadow: '-2px 2px 0px 1px rgba(0,0,0,0.4)',
}))
type MyT = React.ComponentType<TypographyProps<'span', { component?: 'span' }>>

// export const TitleComponent: MyT = styled(Typography)(({ theme }) => ({
// 	...theme.typography,
// 	color: theme.palette.common.black,
// 	fontSize: '30px',
// 	fontWeight: 'bold',
// 	// marginLeft: '15px',
// }))
export const GradientTitleComponent: MyT = styled(Typography)(({ theme }) => ({
	...theme.typography,
	color: theme.palette.common.black,
	fontSize: '30px',
	fontWeight: 'bold',
	marginLeft: '15px',
	background: '-webkit-linear-gradient(360deg, #ee1d4a 0%, #401381 100%)',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	width: 'fit-content',
}))

export const ItemTitle = styled('span')(({ theme }) => ({
	...theme.typography,
	fontWeight: '400',
}))

export const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '20%',
	borderRadius: theme.dimentions.biggerRadius,
	boxShadow: theme.shadow.boxShadow,
	backgroundColor: theme.palette.common.white,
	fontFamily: theme.typography.fontFamily,
	'&:hover': {
		backgroundColor: theme.palette.common.white,
	},

	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		left: '60%',
	},
}))

// export const SearchIconWrapper = styled('div')(() => ({
// 	padding: theme.spacing(0, 2),
// 	height: '100%',
// 	position: 'absolute',
// 	pointerEvents: 'none',
// 	display: 'flex',
// 	alignItems: 'center',
// 	justifyContent: 'center',
// 	zIndex: '20',
// 	'& .MuiSvgIcon-root': {
// 		fill: theme.colors.text.grey,
// 		color: theme.colors.text.grey,
// 	},
// }))

export const StyledInputBase = styled(InputBase)(({ theme, disabled }) => ({
	'& .MuiInputBase-input': {
		padding: theme.spacing(0.8, 1, 1, 1),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		width: 'inherit',
		height: '100% !important',
		backgroundColor: 'rgba(242, 242, 242, 1)',
		cursor: disabled ? 'not-allowed' : 'text',
	},
}))
// export const SimpleActionsButton = (props: {
// 	children?: any
// 	label?: string
// 	options?: any
// 	style?: any
// 	onClick?: any
// 	// arrowColor?: string
// 	// id?: string
// 	// noArrow?: boolean
// 	// onMouseEnter?: any
// 	// onMouseLeave?: any
// 	// type?: string
// }) => {
// 	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
// 	// const [arrowColor, setArrowColor] = useState(props.arrowColor)
// 	const open = Boolean(anchorEl)

// 	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
// 		setAnchorEl(event.currentTarget)
// 	}
// 	const handleClose = () => {
// 		setAnchorEl(null)
// 	}
// 	const MenuOptions = () => {
// 		if (props.options) {
// 			return props.options
// 		}
// 		return (
// 			<div>
// 				<MenuItem key={'menuption_edit'} onClick={handleClose}>
// 					Edit
// 				</MenuItem>
// 				<MenuItem key={'menuption_delete'} onClick={handleClose}>
// 					Delete
// 				</MenuItem>
// 			</div>
// 		)
// 	}
// 	// const handleHoverArrowColor = (hovered: boolean) => {
// 	// 	if (hovered) {
// 	// 		setArrowColor('inherit')
// 	// 	} else {
// 	// 		setArrowColor(props.arrowColor)
// 	// 	}
// 	// }
// 	// let style = {}
// 	// if (props.type === 'folder-icon') {
// 	// 	style = { ...props.style }
// 	// } else {
// 	// 	style = {
// 	// 		...props.style,
// 	// 		zIndex: 9,
// 	// 		background:
// 	// 			props.label === 'Selector' || props.label === 'settings'
// 	// 				? 'none'
// 	// 				: 'rgba(0, 0, 0, 0.02)',
// 	// 		boxShadow:
// 	// 			props.label === 'Selector' || props.label === 'settings'
// 	// 				? 'none'
// 	// 				: '1px 1px 1px 1px rgba(0,0,0,0.2)',
// 	// 		justifyContent: props.label === 'Selector' ? 'flex-start' : 'center',
// 	// 	}
// 	// }
// 	return (
// 		<div style={{ display: 'inherit' }}>
// 			<UnborderedButton
// 				onClick={(e: any) => {
// 					handleClick(e)
// 					//props.onClick()
// 				}}
// 				// style={style}
// 			>
// 				{props.label}
// 			</UnborderedButton>
// 			<StyledMenuItem
// 				id='demo-positioned-menu'
// 				aria-labelledby='demo-positioned-button'
// 				anchorEl={anchorEl}
// 				open={open}
// 				onClose={handleClose}
// 				onClick={handleClose}
// 				anchorOrigin={{
// 					vertical: 'top',
// 					horizontal: props.label === 'Selector' ? 'right' : 'left',
// 				}}
// 				transformOrigin={{
// 					vertical: 'top',
// 					horizontal: 'left',
// 				}}
// 			>
// 				<MenuOptions />
// 			</StyledMenuItem>
// 		</div>
// 	)
// }
export const ActionsButton = (props: {
	children?: any
	width: number
	row: any
	label?: string
	options?: any
	style?: any
	onClick?: any
	arrowColor?: string
	id?: string
	noArrow?: boolean
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [arrowColor, setArrowColor] = useState(props.arrowColor)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const MenuOptions = () => {
		if (props.options) {
			return props.options
		}
		return (
			<div>
				{/* <MenuItem onClick={handleClose}>Preview</MenuItem> */}
				<MenuItem key={'menuption_edit1'} onClick={handleClose}>
					Edit
				</MenuItem>
				<MenuItem key={'menuption_delete1'} onClick={handleClose}>
					Delete
				</MenuItem>
			</div>
		)
	}
	const handleHoverArrowColor = (hovered: boolean) => {
		if (hovered) {
			setArrowColor('inherit')
		} else {
			setArrowColor(props.arrowColor)
		}
	}
	return (
		<div style={{ display: 'inherit' }} id={props.id}>
			<StyledButton
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={(e) => {
					handleClick(e)
					//props.onClick()
				}}
				onMouseEnter={() => handleHoverArrowColor(true)}
				onMouseLeave={() => handleHoverArrowColor(false)}
				style={{
					...props.style,
					zIndex: 9,
					background:
						props.label === 'Selector' ||
						props.label === 'settings' ||
						props.label === 'Finance' ||
						props.label === 'Dashboard' ||
						props.label === 'Tools' ||
						props.label === 'Autoswitch' ||
						props.label === 'Standard' ||
						props.label === 'Advanced'
							? 'none'
							: 'rgba(0, 0, 0, 0.02)',
					boxShadow:
						props.label === 'Selector' ||
						props.label === 'settings' ||
						props.label === 'Finance' ||
						props.label === 'Dashboard' ||
						props.label === 'Tools' ||
						props.label === 'Autoswitch' ||
						props.label === 'Standard' ||
						props.label === 'Advanced'
							? 'none'
							: '1px 1px 0px 1px rgba(0,0,0,0.1)',
					justifyContent:
						props.label === 'Selector' || 'Tools' ? 'flex-start' : 'center',
				}}
			>
				{props.children || (props.width > 150 ? props.label : '')}
				{!props.noArrow && (
					<KeyboardArrowDownIcon
						style={{
							fontSize: '26px',
							color: props.arrowColor ? arrowColor : 'inherit',
						}}
					/>
				)}
			</StyledButton>
			<StyledMenuItem
				id='demo-positioned-menu'
				aria-labelledby='demo-positioned-button'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical:
						props.label === 'Tools' ||
						props.label === 'Selector' ||
						props.label === 'Finance' ||
						props.label === 'Advanced' ||
						props.label === 'Advertisers'
							? 'bottom'
							: 'top',
					horizontal: props.label === 'Standard' ? 'right' : 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuOptions />
			</StyledMenuItem>
		</div>
	)
}

// export const StyledMenuItem = styled(Menu)(({ theme }) => ({
// 	paddingTop: '0px',

// 	'& 	.MuiMenu-list': {
// 		minWidth: '185px',

// 		padding: '0px',
// 	},
// }))

export const InputFormField = (props: {
	label: string
	type: string
	onChange?: any
	required?: boolean
	value?: any
	color?:
		| 'primary'
		| 'secondary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning'
		| undefined
}) => {
	return (
		<TextField
			required={props.required}
			label={props.label}
			type={props.type}
			margin='dense'
			fullWidth
			value={props.value}
			onChange={props.onChange}
			variant='standard'
			color={props.color}
		/>
	)
}

export const StatusSelect = (props: { onChange: any }) => {
	return (
		<FormControl fullWidth variant='standard'>
			<InputLabel required id='demo-simple-select-label'>
				Status
			</InputLabel>
			<Select
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				label='Status'
				onChange={props.onChange}
				defaultValue={''}
			>
				<MenuItem value={'active'}>Active</MenuItem>
				<MenuItem value={'disabled'}>Disabled</MenuItem>
			</Select>
		</FormControl>
	)
}
export const PlusButton = styled(Button)(() => {
	const theme = useTheme()
	return {
		background: theme.colors.alerts.green,
		borderRadius: theme.dimentions.biggerRadius,
		position: 'relative',
		height: '20px',
		width: '20px',
		minWidth: '10px',
		color: theme.colors.alerts.grey900,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		// top: '300px',
	}
})
const StyledButton = styled(Button)(({ theme }) => ({
	flow: 'right',
	position: 'relative',
	margin: '0px',
	width: '100%',
	background: theme.palette.primary.main,
	borderRadius: '6px',
	boxShadow: theme.shadow.lightShadow,
	color: theme.palette.secondary.light,
}))

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	borderRadius: theme.dimentions.smallerRadius,
}))

export const PresetDialog = styled(Dialog)(({ theme }) => ({
	borderRadius: theme.dimentions.smallerRadius,

	'& .MuiDialog-paper': {
		width: '800px',
	},
}))

export const StyledTextField = styled(TextField)<TextFieldProps | any>(
	({ theme, showurlicon }) => {
		const showUrlIconBoolean = showurlicon === 'true'
		return {
			backgroundColor: 'rgba(238, 238, 238, 0.6)',
			height: 'auto',
			fontWeight: '500',
			fontSize: '14px',
			borderRadius: theme.dimentions.smallerRadius,
			minHeight: '40px',
			color: theme.colors.text.titles,
			'& .MuiOutlinedInput-input': {
				backgroundColor: 'transparent',
				borderRadius: theme.dimentions.smallerRadius,
				height: 'auto',
				fontSize: '14px',
				minHeight: '40px',
				fontWeight: '500',
				width: showUrlIconBoolean ? '80%' : '100%',
				transition: 'all 0.2s ease-in-out',
				color: theme.colors.text.titles,
			},
			'& .MuiOutlinedInput-root': {
				backgroundColor: 'transparent',
				borderRadius: theme.dimentions.smallerRadius,
				height: 'auto',
				minHeight: '40px',
				paddingLeft: '8px',
				color: theme.colors.text.titles,
			},
		}
	},
)

export const PopperMy = (props: any) => {
	return <Popper {...props} style={{ width: '400px' }} placement='top-start' />
}

export const EventNameAutocomplete = (props: {
	onChange: any
	onBlur?: any
	label?: string
	options: string[]
	disabled?: boolean
	value?: string[] | string
	float?: 'left' | 'right'
	fullWidth?: boolean
	name?: string
	ref?: any
	id?: string
}) => {
	const theme = useTheme()
	return (
		<StyledAutocomplete
			freeSolo
			disableCloseOnSelect
			disabled={props.disabled}
			options={props.options}
			id={props.id ? props.id : 'tags-filled'}
			value={props.value}
			PopperComponent={PopperMy}
			ListboxProps={{
				style: {
					fontSize: '11px',
					color: theme.colors.text.titles,
				},
			}}
			onChange={(a: any, n: any) => props.onChange(n)}
			onBlur={props.onBlur}
			renderInput={(params: any) => (
				<StyledTextField
					{...params}
					focused
					name={props.name}
					fullWidth
					ref={props.ref}
					variant='outlined'
					style={{
						float: props.float,
						fontSize: '11px',
						maxHeight: '40px',
						marginTop: '2px',
						background: props.value
							? theme.colors.base.white
							: theme.colors.base.grey250,
						opacity: props.value ? 1 : 0.4,
						color: theme.colors.text.titles,
					}}
					onKeyDown={(e: any) => {
						props.onChange(e.target.value)
					}}
					onKeyUp={(e: any) => {
						props.onChange(e.target.value)
					}}
					InputProps={{
						...params.InputProps,
						style: {
							fontSize: '11px',
							paddingLeft: '10px',
							color: theme.colors.text.titles,
						},
					}}
				/>
			)}
		/>
	)
}

// export const PidInputList = (props: {
// 	onChange?: any
// 	label?: string
// 	options: any[]
// 	hint?: string
// 	hintmargin?: string
// 	disabled?: boolean
// 	publishers?: any
// 	value?: string[] | string
// 	float?: 'left' | 'right'
// 	fullWidth?: boolean
// 	multiple?: boolean
// 	singular?: boolean
// 	checked?: boolean
// 	changePublisher?: any
// 	style?: React.CSSProperties
// }) => {
// 	return (
// 		<StyledAutocomplete
// 			multiple={props.multiple ? false : true}
// 			freeSolo={!props.singular}
// 			disabled={props.disabled}
// 			options={props.options}
// 			style={props.style}
// 			id='tags-filled'
// 			value={props.value}
// 			onChange={(a: any, n: any) => {
// 				props.onChange(n)
// 			}}
// 			renderTags={(value: any[], getTagProps: any) => {
// 				const chips = value.map((option: any, index: number) => {
// 					return (
// 						<Chip
// 							icon={
// 								props.checked ? (
// 									<StyledCheckboxFormControl
// 										label={option}
// 										control={
// 											<Checkbox
// 												key={'timeframe'}
// 												inputProps={{
// 													'aria-label': 'controlled',
// 												}}
// 												style={{ marginLeft: '0px', color: '#ee1d4a' }}
// 											/>
// 										}
// 									/>
// 								) : (
// 									'unset'
// 								)
// 							}
// 							variant='outlined'
// 							label={
// 								<PublishersList value={props.publishers} disabled={false} />
// 							}
// 							deleteIcon={<ClearIcon />}
// 							{...getTagProps({ index })}
// 						/>
// 					)
// 				})

// 				return chips
// 			}}
// 			renderInput={(params: any) => (
// 				<>
// 					{props.hint ? <InfoTag title={props.hint} /> : null}
// 					<StyledTextField
// 						{...params}
// 						focused
// 						label={props.label}
// 						margin='dense'
// 						fullWidth
// 						variant='outlined'
// 						style={{
// 							float: props.float,
// 						}}
// 						InputProps={{
// 							...params.InputProps,
// 							style: {
// 								paddingLeft: '10px',
// 								height: '100px',
// 							},
// 						}}
// 						InputLabelProps={{
// 							style: { top: '-20%' },
// 						}}
// 					/>
// 				</>
// 			)}
// 		/>
// 	)
// }
export const CountryListSelector = (props: {
	onChange?: any
	label?: string
	options?: any[]
	disabled?: boolean
	value?: string[]
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	float?: 'left' | 'right'
	style?: React.CSSProperties
	onBlur?: any
	loginRole?: string
	chipStyle?: React.CSSProperties
}) => {
	const [open, setOpen] = useState(false)
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (
			(list.length === 0 && value === 'GLOBAL') ||
			list.indexOf(value.substring(0, 2)) !== -1 ||
			list.indexOf(value.substring(0, 2).toLowerCase()) !== -1
		) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (
			props.value?.includes(el.substring(0, 2)) ||
			props.value?.includes(el.substring(0, 2).toLowerCase())
		) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, 'GLOBAL', ...notSelectedOptions]

	const renderValueIfEmpty = (values: string[]) => {
		if (values?.length === 0 || (values?.length === 1 && values[0] === '')) {
			return ['GLOBAL']
		}
		return values.map((el: string) => el.toUpperCase())
	}
	const optionsCovertor = (options: string) => {
		let option
		if (options === 'GLOBAL') option = options
		else option = options.length > 2 ? options.substring(0, 2) : options
		const checkedOptions = option === 'UK' ? 'GB' : option
		return checkedOptions
	}
	const renderOptionsWhenOpening = (propsd: any, options: any) => {
		const checkedOption = optionsCovertor(options)
		return checkedOption !== 'GLOBAL' ? (
			<li {...propsd} key={propsd.id}>
				<ReactCountryFlag
					style={{
						width: '2em',
						height: '2em',
						borderRadius: '30px',
						display: 'block',
					}}
					key={options + '_flags'}
					countryCode={checkedOption}
					svg
				/>
				<MenuItem
					key={options}
					value={options}
					style={StyleForSelectedOption(options, props.value || [])}
				>
					{options}
				</MenuItem>
			</li>
		) : (
			<></>
		)
	}
	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((options: any, index: number) => {
			const checkedOption = optionsCovertor(options)
			if (!open) {
				if (index <= 5) {
					return (
						<React.Fragment key={index + '-fragment-option'}>
							{index !== 5 ? (
								<Chip
									style={props.chipStyle}
									icon={
										checkedOption !== 'GLOBAL' ? (
											<ReactCountryFlag
												style={{
													width: '2em',
													height: '2em',
													borderRadius: '30px',
												}}
												countryCode={checkedOption}
												svg
											/>
										) : (
											<Public />
										)
									}
									variant='outlined'
									label={options}
									deleteIcon={
										// checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>
										<ClearIcon />
									}
									{...getTagProps({ index })}
								/>
							) : (
								<Chip
									style={{ marginLeft: '18px' }}
									onClick={() => {
										setOpen(!open)
									}}
									variant='outlined'
									label={` + ${(value?.length || 5) - 5}`}
								/>
							)}
						</React.Fragment>
					)
				}
			} else {
				return (
					<Chip
						icon={
							checkedOption !== 'GLOBAL' ? (
								<ReactCountryFlag
									style={{
										width: '2em',
										height: '2em',
										borderRadius: '30px',
									}}
									countryCode={checkedOption}
									svg
								/>
							) : (
								<Public />
							)
						}
						variant='outlined'
						label={options}
						// deleteIcon={checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>}
						deleteIcon={<ClearIcon />}
						{...getTagProps({ index })}
					/>
				)
			}
		})
		return chips
	}
	const renderInputField = (params: any) => (
		<>
			<StyledInputLabel>{props.label}</StyledInputLabel>
			<StyledTextField
				{...params}
				focused
				label={undefined}
				margin='dense'
				fullWidth
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
				}}
				InputProps={{
					...params.InputProps,
					style: {
						paddingLeft: '10px',
					},
				}}
				onClick={() => !props.disabled && setOpen(!open)}
			/>
		</>
	)
	const customFilter = (options: any, state: any) => {
		const geoCodeResult =
			state.inputValue.length <= 2
				? options.filter((el: any) => {
						return el
							.substring(0, 2)
							.toLowerCase()
							.includes(state.inputValue.toLowerCase())
				  })
				: []
		const countryResult = options.filter((el: any) => {
			return (
				el.toLowerCase().includes(state.inputValue.toLowerCase()) &&
				el.substring(0, 2).toLowerCase() !== state.inputValue.toLowerCase()
			)
		})
		return [...geoCodeResult, ...countryResult]
	}

	return (
		<StyledAutocomplete
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={open}
			options={orderedOptions || []}
			style={props.style}
			id='tags-filled'
			value={renderValueIfEmpty(props.value as string[])}
			onChange={(a: any, n: any) => {
				if (n[0] === 'GLOBAL') n.shift()
				if (n.includes('GLOBAL')) props.onChange([])
				else {
					const result = n.map((el: string) => {
						return el.substring(0, 2) === 'GB' ? 'UK' : el.substring(0, 2)
					})
					props.onChange(result)
				}
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				if (value === 'GLOBAL') return option === value
				return option.substring(0, 2) === value.substring(0, 2)
			}}
			onBlur={() => {
				setOpen(false)
				return props.onBlur
			}}
			filterOptions={customFilter}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
		/>
	)
}

export const StaticChipInputList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	hintmargin?: string
	disabled?: boolean
	value?: string[]
	float?: 'left' | 'right'
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
}) => {
	const theme = useTheme()
	const ref = useRef()
	const [open, setOpen] = useState(false)

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (list.indexOf(value) !== -1) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		// if (props.seeAll && option === 'All') {
		// 	return null
		// }
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={StyleForSelectedOption(option, props.value ? props.value : [])}
				>
					{option}
				</MenuItem>
			</div>
		)
	}
	const renderInputField = (params: any) => {
		return (
			<>
				<StyledTextField
					{...params}
					focused
					label={props.label}
					margin='dense'
					fullWidth
					key={params + 'key-input'}
					variant='outlined'
					style={{
						border: 0,
						clip: 'rect(0 0 0 0)',
						height: '1px',
						margin: '-1px',
						overflow: 'hidden',
						padding: 0,
						position: 'absolute',
						width: '130px',
						whiteSpace: 'nowrap',
						float: props.float,
					}}
					InputProps={{
						...params.InputProps,
						style: {
							paddingLeft: '10px',
						},
					}}
					InputLabelProps={{
						style: { top: '-10px' },
					}}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
					placeholder={props.placeholder}
				/>
				<AddButton
					style={{ minWidth: '100px' }}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
				>
					<span>{props.placeholder}</span>{' '}
					{open ? (
						<KeyboardArrowUpIcon style={{ scale: '1', color: 'inherit' }} />
					) : (
						<KeyboardArrowDownIcon style={{ scale: '1', color: 'inherit' }} />
					)}
				</AddButton>
				<button
					style={{
						border: 'none',
						borderRadius: '60px',
						height: '24px',
						width: '24px',
						fontSize: '12px',
						display: 'flex',
						alignItems: 'center',
						background: theme.colors.base.grey300,
						cursor: props.value?.length === 0 ? 'not-allowed' : 'pointer',
					}}
					onClick={() => {
						props.onChange([])
					}}
					disabled={props.value && props.value.length === 0}
				>
					<FilterAltOffIcon fontSize={'inherit'} />
				</button>
			</>
		)
	}
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll && props.value!.length === props.options!.length
					? false
					: open
			}
			options={orderedOptions}
			style={props.style}
			id='tags-filled'
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value!.length === props.options!.length
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			onChange={(a: any, n: any) => {
				if (props.onlyOneOptionAllowed) {
					n.reverse()
					n[0] ? props.onChange([n[0]]) : props.onChange([])
				}
				if (props.onlyOptionsAllowed) {
					if (compareArrays(n, props.options as string[])) {
						props.onChange(n)
					} else return null
				} else props.onChange(n)
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={() => <></>}
			renderInput={renderInputField}
		/>
	)
}

export const ChipInputList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	disabled?: boolean
	value?: string[]
	float?: 'left' | 'right'
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
	clickableFunction?: (option: any, ev: React.MouseEvent) => void
	errored?: boolean
	errormessage?: any
	inputStyle?: any
	anchorId?: string
	chipStyle?: React.CSSProperties & { hover?: React.CSSProperties }
}) => {
	const theme = useTheme()
	const ref = useRef()
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<any>('')

	useEffect(() => {
		if (props.anchorId) {
			setAnchorEl(document.getElementById(props.anchorId))
		}
	}, [])

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]): any => {
		if (list.indexOf(value) !== -1) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
				position: 'relative',
			}
		}
	}

	const errorStyling = () => {
		if (props.errormessage) {
			return {
				boxShadow: 'red 0px 0px 7px',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={StyleForSelectedOption(option, props.value ? props.value : [])}
				>
					{option}
				</MenuItem>
			</div>
		)
	}

	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((option: any, index: number) => {
			const CustomLabel = (props: any) => {
				const { children } = props
				return (
					<div
						style={{
							whiteSpace: 'normal',
							overflow: 'auto',
							textOverflow: 'ellipsis',
							paddingLeft: '2px',
							paddingRight: '2px',
						}}
					>
						{children}
					</div>
				)
			}

			return (
				<Chip
					variant='outlined'
					onClick={(ev: React.MouseEvent) => {
						if (props.clickableFunction) {
							props.clickableFunction(option, ev)
						}
					}}
					label={
						props.ShowSelected ? <CustomLabel>{option}</CustomLabel> : option
					}
					deleteIcon={<ClearIcon />}
					style={{
						width: 'auto',
						minWidth: props.ShowSelected ? '100px' : '0px',
						height: 'auto',
						...props.chipStyle,
						transition: 'background-color 0.3s ease',
					}}
					{...getTagProps({ index })}
					onMouseEnter={(e: any) => {
						if (props.chipStyle?.hover) {
							e.currentTarget.style.backgroundColor =
								props.chipStyle.hover.backgroundColor
						}
					}}
					onMouseLeave={(e: any) => {
						e.currentTarget.style.backgroundColor =
							props.chipStyle?.background || '#fff'
					}}
				/>
			)
		})

		return chips
	}

	const CustomPopper = function (props: any) {
		return (
			<Popper
				{...props}
				disablePortal
				anchorEl={anchorEl ? anchorEl : props.anchorEl}
				style={{ width: 'auto', backgroundColor: '#fff' }}
				placement='bottom-start'
			/>
		)
	}
	const renderInputField = (params: any) => (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '20px',
				}}
			>
				<StyledInputLabel>{props.label}</StyledInputLabel>
				{props.hint ? <InfoTag title={props.hint} /> : null}
			</div>
			<StyledTextField
				{...params}
				focused
				label={undefined}
				margin='dense'
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
					margin: '0px',
					// backgroundColor: props.errored ? '#eeafaf' : 'rgba(238,238,238,0.6)',
					boxShadow: props.errored ? 'red 0px 0px 7px' : 'none',
					...errorStyling(),
					...props.inputStyle,
				}}
				InputProps={{
					...params.InputProps,
					style: {
						...props.inputStyle,
						paddingLeft: '10px',
						backgroundColor: '#fff',
					},
				}}
				// InputLabelProps={{
				// 	style: { top: '-10px' },
				// }}
				onClick={() => {
					if (props.openWithClick && !props.disabled) {
						setOpen(!open)
					}
				}}
				placeholder={props.placeholder}
			/>
			{props.errormessage ? <ErrorTag title={props.errormessage} /> : null}
		</>
	)
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll &&
				props.value &&
				props.options &&
				props.value.length === props.options.length
					? false
					: open
			}
			options={orderedOptions}
			style={props.style}
			id={'tags-filled'}
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value.length === (props.options?.length || 0)
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			onChange={(a: any, n: any) => {
				if (props.onlyOneOptionAllowed) {
					n.reverse()
					n[0] ? props.onChange([n[0]]) : props.onChange([])
				}
				if (props.onlyOptionsAllowed) {
					if (compareArrays(n, props.options as string[])) {
						props.onChange(n)
					} else return null
				} else props.onChange(n)
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
			PopperComponent={CustomPopper}
		/>
	)
}

const StyledChipTextField = styled(TextField)`
	.MuiOutlinedInput-root {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0.2rem;
	}
`

const StyledChipInputLabel = styled(InputLabel)(({ theme }) => ({
	fontWeight: 500,
	fontSize: theme.font.size.caption,
	marginLeft: '0.5rem',
	marginBottom: '0.3rem',
	backgroundColor: theme.colors.base.white,
	color: theme.colors.base.grey600,
}))

export const ChipInputField = ({
	label,
	value,
	onChange,
	options,
	style,
	inputProps,
	placeholder,
	chipStyle,
}: {
	label: string
	value: string[]
	onChange: (value: string[]) => void
	options: any[]
	style?: React.CSSProperties
	inputProps?: any
	placeholder?: string
	chipStyle?: React.CSSProperties & { hover?: React.CSSProperties }
}) => {
	const theme = useTheme()
	const [inputValue, setInputValue] = useState('')
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
			borderRadius: '5px',
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.grey300
		}
		if (hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' && inputValue.trim()) {
			onChange([...value, inputValue.trim()])
			setInputValue('')
			event.preventDefault()
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				...style,
			}}
		>
			<StyledChipInputLabel>{label}</StyledChipInputLabel>
			<StyledChipTextField
				variant='outlined'
				fullWidth
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				onKeyDown={handleKeyDown}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				InputProps={{
					...inputProps,
					startAdornment: (
						<InputAdornment position='start'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'nowrap',
									gap: '4px',
									overflowX: 'auto',
									whiteSpace: 'nowrap',
								}}
							>
								{value.map((chip, index) => (
									<Chip
										key={index}
										label={chip}
										onDelete={() => onChange(value.filter((v) => v !== chip))}
										style={{
											...chipStyle,
											display: 'inline-flex',
											transition: 'background-color 0.3s ease',
											color: theme.colors.text.titles,
										}}
										// onMouseEnter={(e: any) => {
										// 	if (chipStyle?.hover) {
										// 		e.currentTarget.style.backgroundColor =
										// 			chipStyle.hover.backgroundColor
										// 	}
										// }}
										// onMouseLeave={(e: any) => {
										// 	e.currentTarget.style.backgroundColor =
										// 		chipStyle?.backgroundColor || '#fff'
										// }}
									/>
								))}
							</div>
						</InputAdornment>
					),
				}}
				placeholder={placeholder}
				style={{
					...responsiveStyle(),
					backgroundColor: theme.colors.base.white,
				}}
			/>
		</div>
	)
}

export const StyledFormControl = (
	props: FormControlProps & { children: any; fullWidth?: boolean },
) => {
	const StyledForm = styled(FormControl)(({ theme }) => ({
		width: props.fullWidth ? '100%' : '45%',
		marginTop: '20px',
	}))
	return (
		<StyledForm focused {...props}>
			{props.children}
		</StyledForm>
	)
}

export const OwnerSelect = (props: {
	onChange: any
	disabled?: boolean
	float?: 'right' | 'left'
	label: string
	value: any
	fontSize?: string
	multiple?: boolean
}) => {
	const { users } = useTypedSelector((state) => state)
	const usersList: JSX.Element[] = users.users
		.filter((user: UserInterface) => {
			return !user.blocked
		})
		.map((user: UserInterface) => user.name)
	const getNameFromUserEmail = (email: string) => {
		const user = users.users.filter((el: UserInterface) => el.email === email)
		if (user[0]) {
			return user[0].name
		}
	}
	const handleChange = (event: any, value: any) => {
		const user = users.users.filter((el: UserInterface) => el.name === value)
		props.onChange(user[0].email)
	}

	return (
		<div style={{ float: props.float, marginTop: '0px' }}>
			<StyledInputLabel>{props.label}</StyledInputLabel>
			<Autocomplete
				style={{
					minHeight: '40px',
					width: '100%',
					background: 'rgb(243,243,243)',
					borderRadius: '6px',
				}}
				sx={{
					'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						minHeight: '40px',
						padding: '0px',
					},
				}}
				options={usersList}
				renderInput={(params) => (
					<TextField
						{...params}
						style={{ height: 'inherit' }}
						placeholder={props.label}
					/>
				)}
				onChange={handleChange}
				value={getNameFromUserEmail(props.value)}
			/>
		</div>
	)
}

export const StyledTextAreaField = (props: {
	label: string
	value: string
	onChange: any
}) => {
	return (
		<TextField
			label={props.label}
			value={props.value}
			key={props.label + 'textarea'}
			onChange={(e) => props.onChange(e.target.value)}
			fullWidth
			variant='outlined'
			multiline
			focused
			rows={6}
			InputLabelProps={{ shrink: true, style: { marginTop: '-12px' } }}
			inputProps={{
				style: {
					padding: '20px',
				},
			}}
			color={'primary'}
			style={{
				marginTop: '12px',
				borderRadius: '8px',
				backgroundColor: 'rgb(243,243,243)',
			}}
		/>
	)
}
export const StyledNameField = (props: {
	label: string
	type: string
	value: string | number | null | undefined
	onChange?: any
	onBlur?: any
	onFocus?: any
	disabled?: boolean
	keyname?: string
	hint?: any
	float?: 'right' | 'left'
	errormessage?: any
	warning?: any
	checked?: boolean
	multiline?: boolean
	style?: any
	customInputLabel?: string
	color?:
		| 'primary'
		| 'secondary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning'
		| undefined
	showUrlIcon?: boolean
	inputProps?: any
	placeholder?: string
	noLabelSpace?: boolean
	sx?: any
	fullWidth?: boolean
}) => {
	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = () => setShowPassword(!showPassword)
	const handleMouseDownPassword = () => setShowPassword(!showPassword)
	const handleEndAdornment = (type: string) => {
		if (type === 'password') {
			return (
				<InputAdornment position='end'>
					<IconButton
						aria-label='toggle password visibility'
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
					>
						{showPassword ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			)
		}
		if (type === 'custom') {
			return (
				<InputAdornment position='start'>
					{props.customInputLabel}
				</InputAdornment>
			)
		} else {
			return <></>
		}
	}

	const errorStyling = () => {
		if (props.errormessage) {
			return {
				backgroundColor: '#ffcdd2',
				boxShadow: '0px 0px 0px 1px #d32f2f',
				color: '#d32f2f',
			}
		}
	}
	const { showUrlIcon, label, ...otherProps } = props
	return (
		<div
			style={{
				display: 'flex',
				position: 'relative',
				width: props.fullWidth ? '100%' : '',
				flexDirection: 'column',
			}}
		>
			{!props.noLabelSpace && (
				<div style={{ display: 'flex', gap: '20px' }}>
					<StyledInputLabel>{props.label}</StyledInputLabel>
					{props.showUrlIcon && props.value && !props.errormessage && (
						<button
							onClick={() => {
								const newTab: any = window.open(props.value as string, '_blank')
								newTab.focus()
							}}
							style={{
								position: 'absolute',
								right: '4%',
								top: '43%',
								border: 'none',
								cursor: 'pointer',
								zIndex: '2',
							}}
						>
							<LinkIcon></LinkIcon>
						</button>
					)}
					{props.hint ? (
						<div
							style={{
								position: 'relative',
								width: '20%',
							}}
						>
							<InfoTag title={props.hint} />
						</div>
					) : null}
					{props.errormessage ? <ErrorTag title={props.errormessage} /> : null}
					{props.warning ? (
						<ErrorTag title={props.warning} color={'rgb(237,108,4)'} />
					) : null}
				</div>
			)}
			<StyledTextField
				multiline={props.multiline}
				focused
				{...otherProps}
				key={props.keyname ? props.keyname : 'InputName' + props.label}
				margin='dense'
				type={props.type === 'password' && showPassword ? 'text' : props.type}
				value={props.value}
				fullWidth
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				style={{
					...props.style,
					...errorStyling(),
					//  marginTop: '22px'
				}}
				onChange={(e: any) => {
					if (props.value === 'All') {
						const newState = e.target.value.slice(4)
						props.onChange(newState)
					} else {
						props.onChange(e.target.value)
					}
				}}
				variant='outlined'
				color={
					props.color ? props.color : props.checked ? 'success' : 'primary'
				}
				placeholder={props.placeholder}
				InputProps={{
					// <-- This is where the toggle button is added.
					...props.inputProps,
					endAdornment: props.customInputLabel
						? handleEndAdornment('custom')
						: handleEndAdornment(props.type),
				}}
				showurlicon={
					props.showUrlIcon ? props.showUrlIcon.toString() : undefined
				}
				sx={props.sx}
			/>
		</div>
	)
}

// export const TableSearchComponent = (props: {
// 	setSearch: any
// 	search: string
// 	style?: any
// 	filterHandler?: any
// 	searchWithTimer?: boolean
// 	presetSearch?: string
// 	placeholder?: string
// }) => {
// 	const [value, setValue] = useState('')
// 	// const [value, setValue] = useState(props.search)
// 	const timer = useRef<any>(null)

// 	const handleSearch = (e: any) => {
// 		setValue(e.target.value)
// 	}

// 	useEffect(() => {
// 		setValue(props.search)
// 	}, [props.search])

// 	useEffect(() => {
// 		// Clear any existing timer each time 'value' changes
// 		if (timer.current) {
// 			clearTimeout(timer.current)
// 		}

// 		// Set a new timer
// 		timer.current = setTimeout(() => {
// 			// if (!props.presetSearch) {
// 			props.setSearch(value)
// 			// }
// 		}, 200)

// 		// Clear the timer if the component is unmounted
// 		return () => {
// 			if (timer.current) {
// 				clearTimeout(timer.current)
// 			}
// 		}
// 	}, [value])

// 	//TODO: try with autofocus true
// 	return (
// 		<TableSearch style={{ ...props.style }}>
// 			<SearchIconWrapper>
// 				{/* <SearchIcon /> */}
// 				<img
// 					src={search}
// 					alt={'search-icon'}
// 					style={{
// 						zIndex: '10',
// 						width: '1rem',
// 						height: '1rem',
// 					}}
// 				/>
// 			</SearchIconWrapper>
// 			<StyledInputBase
// 				placeholder={
// 					props.placeholder ? `Search in ${props.placeholder}...` : 'Search...'
// 				}
// 				inputProps={{
// 					'aria-label': 'search',
// 				}}
// 				autoFocus={!props.filterHandler}
// 				onChange={(e: any) => {
// 					handleSearch(e)
// 					// props.searchWithTimer
// 					// 	? handleSearch(e)
// 					// 	: props.setSearch(e.target.value)
// 				}}
// 				// value={props.searchWithTimer ? value : props.search}
// 				value={value}
// 				style={{ width: '100%' }}
// 				disabled={!props.placeholder}
// 			/>
// 		</TableSearch>
// 	)
// }
export const FilterSearchComponent = (props: {
	setSearch: any
	search: string[]
	style?: any
	applyFilter?: any
	filterLabel?: any
	currentFilters?: any
	clearFilter?: any
	optionsFilters?: any
	setFilterHander?: any
	filtersKey?: any
	customFilter?: any
}) => {
	let options: any = []
	for (const key in props.optionsFilters) {
		if (key === props.currentFilters) {
			if (typeof [...props.optionsFilters[key]][0] === 'string') {
				options = [...props.optionsFilters[key]].sort()
			} else if (
				typeof [...props.optionsFilters[key]][0] === 'object' &&
				!Array.isArray([...props.optionsFilters[key]][0])
			) {
				for (const element of props.optionsFilters[key]) {
					if (key === 'email' || key === 'publisher_manager') {
						options.push(element.name)
					} else {
						options.push(element[key])
					}
				}
				options.sort()
				// console.log(props.optionsFilters[key])
			}
			// if (options)
			break
		}
	}
	// const StyleForSelectedOption = (value: string, list: string[]) => {
	// 	if (list.indexOf(value) !== -1) {
	// 		return { fontWeight: '900', backgroundColor: 'rgba(238,238,238,0.7)' }
	// 	}
	// }
	// const renderedOptions = (opt: any) => {
	// 	return (
	// 		<MenuItem
	// 			key={opt.id}
	// 			value={opt.key}
	// 			onClick={() => {
	// 				props.setSearch((prev: any) =>
	// 					prev ? [...prev, opt.key] : [opt.key],
	// 				)
	// 			}}
	// 			style={StyleForSelectedOption(opt.key, props.search)}
	// 		>
	// 			{opt.key}
	// 		</MenuItem>
	// 	)
	// }

	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		}
	}, [props.currentFilters])
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		} else {
			props.setSearch([])
		}
	}, [])

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'inherit',
					width: '100%',
				}}
			>
				{options.length === 0 ? (
					<StyledInputBase
						placeholder={'Filter for ' + props.filterLabel}
						inputProps={{
							'aria-label': 'search',
						}}
						autoFocus
						onChange={(e: any) => {
							props.setSearch(e.target.value)
						}}
						value={props.search}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								props.applyFilter()
								props.setFilterHander(false)
							}
						}}
					/>
				) : (
					<ChipInputList
						onChange={props.setSearch}
						options={options}
						value={props.search}
						openWithClick={true}
						style={
							{
								height: 'inherit',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '8px',
								width: 'inherit',
								// marginTop: '-25px',
							} as any
						}
						onlyOptionsAllowed
					/>
					// <Autocomplete
					// 	style={{
					// 		height: '50px',
					// 	}}
					// 	sx={{
					// 		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
					// 			{
					// 				border: 'none',
					// 			},
					// 		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					// 			border: 'none',
					// 		},
					// 	}}
					// 	options={options}
					// 	renderOption={(option: any) => {
					// 		return renderedOptions(option)
					// 	}}
					// 	renderInput={(params) => (
					// 		<TextField
					// 			{...params}
					// 			style={{ height: 'inherit' }}
					// 			placeholder={props.filterLabel}
					// 		/>
					// 	)}
					// 	onChange={(_, value) => {
					// 		if (!value) {
					// 			props.setSearch([])
					// 		} else {
					// 			props.setSearch((prev: any) =>
					// 				prev ? [...prev, value] : [value],
					// 			)
					// 		}
					// 	}}
					// 	onKeyDown={(e) => {
					// 		if (e.key === 'Enter') {
					// 			props.applyFilter()
					// 			props.setFilterHander(false)
					// 		}
					// 	}}
					// 	getOptionLabel={(option: any) => {
					// 		return option.toString()
					// 	}}
					// 	value={props.search}
					// />
				)}
			</div>
			<AddButton
				style={{ fontSize: '12px' }}
				onClick={() => props.applyFilter()}
			>
				Apply
			</AddButton>
			<button
				style={{
					fontSize: '12px',
					border: 'none',
					boxShadow: 'none',
					textDecoration: 'solid underline 2px',
					textUnderlineOffset: '6px',
					marginLeft: '4px',
					paddingLeft: '0px',
					cursor: 'pointer',
				}}
				onClick={props.clearFilter}
				disabled={props.search.length === 0}
			>
				Clear
			</button>
		</>
	)
}

export const GradientedText = styled(Typography)(({ theme }) => ({
	background: '-webkit-linear-gradient(360deg, #ee1d4a 0%, #401381 100%)',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	fontSize: '20px',
	fontWeight: 'bold',
	width: 'fit-content',
}))

export const StyledHeader = styled(Typography)(({ theme }) => ({
	marginInlineStart: '60px',
	color: 'black',
	fontSize: '30px',
	fontWeight: 'bold',
}))

export const StyledBadge = styled(Badge)(() => {
	const theme = useTheme()
	return {
		'& .MuiBadge-badge': {
			right: 2,
			top: 18,
			background: theme.colors.base.green200,
		},
	}
})

interface FileUploadButtonProps {
	uploadedFile: ContractsInterface | null
	onUpload: (event: ChangeEvent<HTMLInputElement>) => void
	onDelete: () => void
	sx?: object
	isPreview?: boolean
	label?: string
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
	uploadedFile,
	onUpload,
	onDelete,
	sx,
	isPreview = false,
	label,
}) => {
	const theme = useTheme()
	const [isPreviewOpen, setPreviewOpen] = useState(false)
	const [isHovered, setIsHovered] = useState(false)

	return (
		<div>
			<StyledLabel style={{ display: 'flex', flexDirection: 'row' }}>
				<span>{label}</span>
			</StyledLabel>

			<Box
				sx={{
					width: '100%',
					backgroundColor: theme.colors.base.white,
					border: '2px solid',
					borderColor: theme.colors.base.grey200,
					padding: '4px',
					borderRadius: '8px',
					position: 'relative',
					...(sx || {}),
				}}
			>
				{uploadedFile ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							textTransform: 'none',
							fontSize: theme.font.size.body,
							padding: '6px 10px',
							borderRadius: '6px',
							cursor: 'pointer',
							backgroundColor: theme.colors.base.white,
							'&:hover': {
								backgroundColor: theme.colors.base.grey250,
							},
						}}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						onClick={() => setPreviewOpen(true)}
					>
						<Tooltip title={uploadedFile.fileName} arrow>
							<Typography
								variant='body2'
								noWrap
								sx={{
									color: theme.colors.base.grey500,
									maxWidth: '70%',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									fontSize: theme.font.size.body,
								}}
							>
								{uploadedFile.fileName}
							</Typography>
						</Tooltip>

						{isHovered && (
							<Box sx={{ display: 'flex', gap: 0.5 }}>
								<Tooltip title='Preview file' arrow>
									<IconButton
										aria-label='Preview file'
										sx={{
											padding: '2px',
											color: theme.colors.base.grey500,
											'&:hover': {
												color: theme.colors.base.black,
											},
										}}
									>
										<PreviewIcon fontSize='small' />
									</IconButton>
								</Tooltip>
								{/* {!isPreview && (
									<Tooltip title='Delete file' arrow>
										<IconButton
											onClick={(e) => {
												e.stopPropagation()
												onDelete()
											}}
											aria-label='Delete file'
											sx={{
												padding: '4px',
												color: theme.colors.base.grey500,
												'&:hover': {
													color: theme.colors.base.black,
												},
											}}
										>
											<DeleteIcon fontSize='small' />
										</IconButton>
									</Tooltip>
								)} */}
							</Box>
						)}
					</Box>
				) : (
					<Button
						variant='text'
						component='label'
						fullWidth
						disabled={isPreview}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textTransform: 'none',
							color: theme.colors.base.grey500,
							fontSize: theme.font.size.body,
							gap: 0.5,
							border: '2px dashed',
							borderColor: theme.colors.base.grey200,
							backgroundColor: theme.colors.base.white,
							height: '27px',
							width: '100%',
							borderRadius: '6px',
							'&:hover': {
								backgroundColor: theme.colors.base.grey250,
							},
						}}
					>
						<img
							src={UploadIcon}
							alt='Upload Icon'
							style={{
								width: 18,
								height: 18,
							}}
						/>
						<span
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								fontSize: theme.font.size.body,
							}}
						>
							Upload file
						</span>
						<input type='file' hidden onChange={onUpload} />
					</Button>
				)}

				{/* Document Preview Modal */}
				<Modal
					open={isPreviewOpen}
					onClose={() => setPreviewOpen(false)}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Box
						sx={{
							width: '80vw',
							height: '80vh',
							backgroundColor: theme.colors.base.white,
							padding: 2,
							borderRadius: 2,
							boxShadow: 24,
						}}
					>
						{uploadedFile && (
							<DocumentPreview doc={[{ uri: uploadedFile.fileURL }]} />
						)}
					</Box>
				</Modal>
			</Box>
		</div>
	)
}
