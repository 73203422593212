import { ActionType } from '../action-types'
import { Action } from '../actions'

interface SuccessState {
	isSuccess: boolean
	successDetails: {
		title: string
		message: string
	} | null
}

const initialState: SuccessState = {
	isSuccess: false,
	successDetails: null,
}

const successReducer = (
	state: SuccessState = initialState,
	action: Action,
): SuccessState => {
	switch (action.type) {
		case ActionType.SET_SUCCESS:
			if (!state.isSuccess) { // prevent showing success message if another success message is already shown
				return {
					...state,
					isSuccess: true,
					successDetails: action.payload,
				}
			}
			return state
		case ActionType.CLEAR_SUCCESS:
			return {
				...state,
				isSuccess: false,
				successDetails: null,
			}
		default:
			return state
	}
}

export default successReducer
