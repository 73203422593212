import { ActionType } from '../action-types/index'
import { Action } from '../actions'

interface ResizeState {
	resizeTrigger: number
}

const initialState: ResizeState = {
	resizeTrigger: 0,
}

const resizeReducer = (
	state: ResizeState = initialState,
	action: Action,
): ResizeState => {
	switch (action.type) {
		case ActionType.TRIGGER_RESIZE_CHECK:
			return {
				...state,
				resizeTrigger: (state.resizeTrigger + 1) % 10000,
			}
		default:
			return state
	}
}

export default resizeReducer
