import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { triggerResizeCheck } from '../../state/actions'; 

const ResizeListener = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            // console.log('Global resize detected.');
            dispatch(triggerResizeCheck());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);

    return null; 
};

export default ResizeListener;
